import { ReactNode, useMemo, useState } from 'react';
import { usePopper } from 'react-popper';
import { useMountedState, useRafLoop } from 'react-use';

import { usePortal, useUniqueId } from 'hooks';

import { MODIFIERS } from './constants';
import Tooltip from './Tooltip';

interface Props {
  className?: string;
  isShown?: boolean;
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

interface TriggerProps {
  'aria-describedby'?: string;
  ref: (referenceElement: HTMLElement | null) => void;
}

const useStaticTooltip = (
  tooltip: ReactNode,
  { className, isShown = true, placement = 'top' }: Props = {},
): TriggerProps => {
  const id = useUniqueId();
  const isEnabled = Boolean(tooltip) || tooltip === 0;
  const isMounted = useMountedState();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const {
    attributes,
    styles: popperStyles,
    update,
  } = usePopper(referenceElement, popperElement, {
    modifiers: [{ name: 'arrow', options: { element: arrowElement } }, ...MODIFIERS],
    placement,
  });
  const computedPlacement = attributes.popper
    ? (attributes.popper['data-popper-placement'] as Props['placement'])
    : placement;
  const ariaAttributes = useMemo(() => (isShown ? { 'aria-describedby': id } : {}), [id, isShown]);
  const triggerProps = useMemo(
    () => ({ ...ariaAttributes, ref: setReferenceElement }),
    [ariaAttributes],
  );

  useRafLoop(() => {
    if (isMounted() && update) {
      update();
    }
  });

  usePortal(
    <Tooltip
      className={className}
      id={id}
      placement={computedPlacement}
      ref={setPopperElement}
      style={popperStyles.popper}
      {...attributes.popper}
    >
      {tooltip}
      <Tooltip.Arrow ref={setArrowElement} style={popperStyles.arrow} />
    </Tooltip>,
    { disabled: !isEnabled || !isShown },
  );

  return triggerProps;
};

export default useStaticTooltip;
