import { removeWebsktopRef, WebsktopRef } from '@websktop/commons';
import { useCallback } from 'react';

import { useSetUser } from '../atom';

type DeleteWebsktopRef = (websktopRefId: WebsktopRef['id']) => void;

const useDeleteWebsktopRef = (): DeleteWebsktopRef => {
  const setUser = useSetUser();

  const deleteWebsktopRef: DeleteWebsktopRef = useCallback(
    (websktopRefId) => {
      setUser((user) => {
        if (user === null) {
          throw new Error('Cannot update user data');
        }

        return {
          ...user,
          websktopsRefs: removeWebsktopRef(user.websktopsRefs, websktopRefId),
        };
      });
    },
    [setUser],
  );

  return deleteWebsktopRef;
};

export default useDeleteWebsktopRef;
