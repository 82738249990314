import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import Item from './Item';
import ItemsGroup from './ItemsGroup';
import styles from './Menu.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {}

const Menu: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <div className={classNames(styles.menu, className)} {...props}>
    {children}
  </div>
);

export default Object.assign(Menu, {
  Item,
  ItemsGroup,
});
