import { CONTENT_ELEMENT_ID, WEBSKTOP_CONTENT_ELEMENT_ID } from 'constants/dom';
import { useTabbableElementsNavigation } from 'hooks';
import { focusFirstTabbableChild } from 'lib';
import { useKey } from 'react-use';

const focusContent = () => {
  const websktopContentElement = document.getElementById(WEBSKTOP_CONTENT_ELEMENT_ID);

  if (websktopContentElement) {
    websktopContentElement.focus();
    return;
  }

  // If we're not on a Websktop page, let's focus first focusable element in Content
  const contentElement = document.getElementById(CONTENT_ELEMENT_ID);
  focusFirstTabbableChild(contentElement);
};

const useArrowNavigation = (
  sidebarElement: HTMLElement | null,
  { disabled }: { disabled: boolean },
) => {
  useTabbableElementsNavigation(sidebarElement, { disabled });

  useKey(
    (event) => !disabled && event.key === 'ArrowRight',
    (event) => {
      event.preventDefault();
      focusContent();
    },
    undefined,
    [disabled],
  );
};

export default useArrowNavigation;
