import { StatusCode } from '@websktop/commons';

export default class HttpError<Payload = unknown> extends Error {
  payload?: Payload;
  status?: StatusCode;

  constructor(message: string, status: StatusCode, payload?: Payload) {
    super(message);
    this.payload = payload;
    this.status = status;
  }
}

export const isHttpError = (value: unknown): value is HttpError => value instanceof HttpError;
