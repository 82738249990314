import isUrl from './isUrl';

const normalizeUrl = (url: string): string | null => {
  if (isUrl(url)) {
    return url;
  }

  const urlCandidate = url.startsWith('//') ? 'http:' + url : 'http://' + url;

  if (!isUrl(urlCandidate)) {
    return null;
  }

  return urlCandidate;
};

export default normalizeUrl;
