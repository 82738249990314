import { ClipboardMimeType } from 'constants/mime-type';

import { DATA_ATTRIBUTE_NAME } from '../constants';
import { ItemsState } from '../types';

const decodeItemsState = (html: string): ItemsState | null => {
  try {
    const dom = new DOMParser().parseFromString(html, ClipboardMimeType.HTML);
    const dataElement = dom.querySelector(`[${DATA_ATTRIBUTE_NAME}]`);
    const json = dataElement?.getAttribute(DATA_ATTRIBUTE_NAME);

    if (!json) {
      return null;
    }

    return JSON.parse(json);
  } catch {
    return null;
  }
};

export default decodeItemsState;
