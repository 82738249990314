import { FunctionComponent } from 'react';

import { SplashLoader } from 'components';
import { useIsAuthenticated } from 'state';

import AppLayout from '../AppLayout';

interface Props {
  className?: string;
}

const AppLoader: FunctionComponent<Props> = ({ className }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <AppLayout className={className} sidebar={isAuthenticated}>
      <SplashLoader />
    </AppLayout>
  );
};

export default AppLoader;
