import { Upload } from '@websktop/commons';
import { useCallback, useState } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';

import api from 'api';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { urlToFile } from 'lib';
import { useShowErrorNotification } from 'state';

type Action = (file: File | null) => void;

interface State {
  error: Error | undefined;
  file: File | null;
  isLoading: boolean;
  isUploading: boolean;
  uploadPromise: Promise<Upload | null> | undefined; // Undefined means that no change was done to file yet; null means that file was removed.
}

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const useUploadFile = (initialFileUrl?: string): [State, Action] => {
  const showErrorNotification = useShowErrorNotification();
  const handleUnmountedError = useUnmountedErrorHandler();
  const [file, setFile] = useState<File | null>(null);
  const [uploadPromise, setUploadPromise] = useState<Promise<Upload | null> | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(initialFileUrl));

  const [{ error, loading: isUploading }, upload] = useAsyncFn(
    async (file: File | null) => {
      if (file === null) {
        setUploadPromise(Promise.resolve(null));
        return;
      }

      return handleUnmountedError(async () => {
        const promise = api.uploads.upload(file);
        setUploadPromise(promise);
        return await promise;
      });
    },
    [handleUnmountedError],
  );

  const uploadFile = useCallback(
    (file: File | null) => {
      setFile(file);
      upload(file);
    },
    [upload],
  );

  useEffectOnce(() => {
    if (initialFileUrl) {
      urlToFile(API_ORIGIN + initialFileUrl)
        .then((file) => {
          setFile(file);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          showErrorNotification(error);
        });
    }
  });

  return [{ error, file, isLoading, isUploading, uploadPromise }, uploadFile];
};

export default useUploadFile;
