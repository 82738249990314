import { useCallback } from 'react';

import api from 'api';

const useUpdatePassword = (): typeof api.auth.updatePassword => {
  const updatePassword: typeof api.auth.updatePassword = useCallback(async (payload) => {
    await api.auth.updatePassword(payload);
  }, []);

  return updatePassword;
};

export default useUpdatePassword;
