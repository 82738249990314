import { ClientMessage, Websktop } from '@websktop/commons';
import { useEffect } from 'react';

import getActiveWebsktopsSocket from '../getActiveWebsktopsSocket';

import useUsersCursorsUpdates from './useUsersCursorsUpdates';
import useWebsktopOnlineUsersUpdates from './useWebsktopOnlineUsersUpdates';
import useWebsktopUpdates from './useWebsktopUpdates';

const useWebsktopCollaborativeUpdates = (websktopId: Websktop['id']) => {
  const socket = getActiveWebsktopsSocket();

  useWebsktopOnlineUsersUpdates(websktopId);
  useUsersCursorsUpdates(websktopId);
  useWebsktopUpdates();

  useEffect(() => {
    const handleReconnect = () => {
      socket.emit(ClientMessage.JOIN_WEBSKTOP, websktopId);
    };

    socket.on('connect', handleReconnect);
    socket.emit(ClientMessage.JOIN_WEBSKTOP, websktopId);

    return () => {
      socket.off('connect', handleReconnect);
      socket.emit(ClientMessage.LEAVE_WEBSKTOP, websktopId);
    };
  }, [socket, websktopId]);
};

export default useWebsktopCollaborativeUpdates;
