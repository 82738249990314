import { Folder, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, Message, Modal } from 'components';
import { useUniqueId } from 'hooks';
import { getItemsCountMessage } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { useDeleteFolder, useFolder } from 'state';

import styles from './DeleteFolderModal.module.scss';

interface Props {
  className?: string;
  folderId: Folder['id'];
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const DeleteFolderModal: FunctionComponent<Props> = ({
  className,
  folderId,
  isOpen,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const deleteFolder = useDeleteFolder(websktopId);
  const folder = useFolder(websktopId, folderId);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await deleteFolder(folderId);
      });
    },
    [deleteFolder, folderId, handleUnmountedError, onClose],
  );

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button
            autoFocus
            form={id}
            loading={isSubmitting}
            icon="trash-alt"
            type="submit"
            variant="danger"
          >
            Delete
          </Button>
        </Modal.Footer>
      }
      header={<h2>Delete folder</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <p>Are you sure you want to delete this folder?</p>

        {folder.items.length === 0 && <Message variant="info">This folder is empty.</Message>}

        {folder.items.length !== 0 && (
          <Message variant="warning">
            This folder is not empty ({getItemsCountMessage(folder.items)}).
          </Message>
        )}

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default DeleteFolderModal;
