import classNames from 'classnames';
import { FunctionComponent, useId } from 'react';

import { SOLID_COLORS } from '../../constants';
import SolidColor from '../SolidColor';

import styles from './SolidColorInput.module.scss';

interface Props {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

const SolidColorInput: FunctionComponent<Props> = ({ className, value, onChange }) => {
  const id = useId();

  return (
    <div className={classNames(className, styles.colors)}>
      {SOLID_COLORS.map((color, index) => (
        <SolidColor
          checked={color === value}
          className={styles.color}
          color={color}
          id={id}
          key={index}
          title={color}
          onChange={() => onChange(color)}
        />
      ))}
    </div>
  );
};

export default SolidColorInput;
