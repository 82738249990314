import { getItemsDeep, Item, Websktop } from '@websktop/commons';
import { uniq } from 'lodash-es';
import { selector, useRecoilValue, useRecoilValueLoadable, waitForAllSettled } from 'recoil';

import { optimisticUserSelector } from '../user/atom';

import { currentWebsktopIdAtom, optimisticWebsktopFamily } from './atom';

const allWebsktopsSelector = selector<Websktop[]>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'all-websktops-selector',
  get: ({ get }) => {
    const user = get(optimisticUserSelector);
    const currentWebsktopId = get(currentWebsktopIdAtom);
    const userWebsktopsIds = user ? user.websktopsRefs.map(({ id }) => id) : [];
    const websktopsIds = uniq(
      [...userWebsktopsIds, currentWebsktopId].filter(Boolean),
    ) as Websktop['id'][];
    const websktops = get(waitForAllSettled(websktopsIds.map((id) => optimisticWebsktopFamily(id))))
      .filter((loadable) => loadable.state === 'hasValue')
      .map((loadable) => loadable.contents as Websktop);

    return websktops;
  },
});

const allItemsMapSelector = selector<Record<Websktop['id'], Item[]>>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'all-items-map-selector',
  get: ({ get }) => {
    const websktops = get(allWebsktopsSelector);
    const entries = websktops.map((websktop) => {
      const items = Object.values(getItemsDeep(websktop.folder)).filter((item) =>
        Boolean(item.parentId),
      );
      return [websktop.id, items];
    });

    return Object.fromEntries(entries);
  },
});

const itemWebsktopMapSelector = selector<Record<Item['id'], Websktop['id']>>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'item-websktop-map-selector',
  get: ({ get }) => {
    const itemsMap = get(allItemsMapSelector);
    const entries = Object.entries(itemsMap).flatMap(([websktopId, items]) =>
      items.map((item) => [item.id, websktopId]),
    );
    return Object.fromEntries(entries);
  },
});

export const allItemsSelector = selector<Item[]>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'all-items-selector',
  get: ({ get }) => {
    const itemsMap = get(allItemsMapSelector);
    return Object.values(itemsMap).flat();
  },
});

export const useAllWebsktops = () => useRecoilValue(allWebsktopsSelector);

export const useAllItemsLoadable = () => useRecoilValueLoadable(allItemsSelector);

export const useItemWebsktopMap = () => useRecoilValue(itemWebsktopMapSelector);

export const useItemWebsktopMapLoadable = () => useRecoilValueLoadable(itemWebsktopMapSelector);
