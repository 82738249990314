import { BillingCycle } from '@websktop/commons';
import { FunctionComponent } from 'react';

import { Toggle } from 'components';
import { useUniqueId } from 'hooks';

interface Props {
  className?: string;
  value: BillingCycle;
  onChange: (value: BillingCycle) => void;
}

const BillingCycleInput: FunctionComponent<Props> = ({ className, value, onChange }) => {
  const id = useUniqueId();

  return (
    <Toggle
      checked={value === 'year'}
      className={className}
      id={id}
      labelLeft="Pay monthly"
      labelRight="Pay annually (-16.7%)"
      size="large"
      title="Choose billing cycle"
      onChange={(event) => onChange(event.currentTarget.checked ? 'year' : 'month')}
    />
  );
};

export default BillingCycleInput;
