import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './Loader.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {}

const Loader: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <div className={classNames(styles.loader, className)} {...props}>
    Loading...
  </div>
);

export default Loader;
