import { Id, Websktop } from '../types';

import removeItemFromFolder from './removeItemFromFolder';

const removeItemFromWebsktop = (websktop: Websktop, itemId: Id): Websktop => ({
  ...websktop,
  folder: removeItemFromFolder(websktop.folder, itemId),
});

export default removeItemFromWebsktop;
