import { Plan, Websktop, iconToPayload } from '@websktop/commons';
import { FormEvent, FunctionComponent, useCallback, useMemo } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';

import { Button, IconInput, Message, Modal, TextInput } from 'components';
import { DEFAULT_WEBSKTOP_BACKGROUND_COLOR, DEFAULT_WEBSKTOP_TEXT_COLOR } from 'constants/websktop';
import { useUniqueId, useUploadFile } from 'hooks';
import { getFormFieldsErrors, useFormState } from 'lib';
import { WebsktopIcon } from 'modules/entity-icons';
import { IMAGE_SIZE } from 'modules/grid-layout';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { UpgradeImageSizePromo } from 'modules/plans';
import { useAuthenticatedUser, useUpdateWebsktop, useWebsktop } from 'state';
import { EntityIcon, isEmojiIcon, isFileSizeError, isUploadedIcon } from 'types';

import styles from './EditWebsktopModal.module.scss';
import getWebsktopIcon from './getWebsktopIcon';

interface FormState {
  name: string;
  primaryIcon: EntityIcon;
}

interface Props {
  className?: string;
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const EditWebsktopModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const user = useAuthenticatedUser();
  const websktop = useWebsktop(websktopId);
  const [{ error: uploadError, file, uploadPromise }, setFile] = useUploadFile(
    websktop.primaryIcon.upload?.url,
  );
  const handleUnmountedError = useUnmountedErrorHandler();
  const fileUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);
  const updateWebsktop = useUpdateWebsktop(websktopId);
  const [form, { patchFormState }] = useFormState<FormState>({
    name: websktop.name,
    primaryIcon: null, // this is initialized in useEffectOnce below because it's async
  });

  useEffectOnce(() => {
    getWebsktopIcon(websktop).then((primaryIcon) => {
      patchFormState({ primaryIcon });
    });
  });

  const updateWebsktopPayload = useMemo(
    () => ({
      backgroundColor: websktop.backgroundColor,
      backgroundId: websktop.background ? websktop.background.id : null,
      backgroundType: websktop.background ? websktop.background.type : 'cover',
      name: form.name,
      primaryIcon: {
        ...iconToPayload(websktop.primaryIcon),
        emoji: isEmojiIcon(form.primaryIcon) ? form.primaryIcon : '',
        uploadId: isUploadedIcon(form.primaryIcon) ? websktop.primaryIcon.upload?.id || null : null,
      },
      readAccess: websktop.readAccess,
      textColor: websktop.textColor,
      textShadowColor: websktop.textShadowColor,
    }),
    [form, websktop],
  );

  const submit = useCallback(async () => {
    return updateWebsktop(updateWebsktopPayload, {
      primaryIcon: {
        fallbackUrl: isUploadedIcon(form.primaryIcon) ? fileUrl : undefined,
        promise: isUploadedIcon(form.primaryIcon) ? uploadPromise : undefined,
      },
    });
  }, [fileUrl, form, updateWebsktop, updateWebsktopPayload, uploadPromise]);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await submit();
      });
    },
    [handleUnmountedError, onClose, submit],
  );

  const handlePrimaryIconChange = (primaryIcon: EntityIcon) => {
    if (isUploadedIcon(primaryIcon)) {
      setFile(primaryIcon);
    }

    patchFormState({ primaryIcon });
  };

  const formErrors = getFormFieldsErrors<keyof FormState>(error);

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button form={id} loading={isSubmitting} icon="check" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      }
      header={<h2>Edit Websktop</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <TextInput
          autoFocus
          disabled={isSubmitting}
          error={formErrors.name?.message}
          id="name"
          label="Name"
          required
          value={form.name}
          onChange={(event) => patchFormState({ name: event.target.value })}
        />

        <IconInput id="icon" label="Icon" onChange={handlePrimaryIconChange}>
          <WebsktopIcon
            backgroundColor={DEFAULT_WEBSKTOP_BACKGROUND_COLOR}
            className={className}
            iconEmoji={isEmojiIcon(form.primaryIcon) ? form.primaryIcon : ''}
            iconUrl={isUploadedIcon(form.primaryIcon) ? fileUrl : undefined}
            name={form.name}
            size={IMAGE_SIZE}
            textColor={DEFAULT_WEBSKTOP_TEXT_COLOR}
          />
        </IconInput>

        {isFileSizeError(uploadError) && user.subscription.plan === Plan.FREE && (
          <UpgradeImageSizePromo />
        )}

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default EditWebsktopModal;
