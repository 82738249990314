import { isFolder, isLink, Item } from '@websktop/commons';
import { FunctionComponent } from 'react';

import { FolderIcon, LinkIcon } from 'modules/entity-icons';
import { IMAGE_SIZE } from 'modules/grid-layout';

interface Props {
  canDrop?: boolean;
  item: Item;
}

const ItemIcon: FunctionComponent<Props> = ({ canDrop, item }) => {
  if (isFolder(item)) {
    return <FolderIcon isOpen={canDrop} size={IMAGE_SIZE} />;
  }

  if (isLink(item)) {
    return (
      <LinkIcon
        iconEmoji={item.primaryIcon.emoji}
        iconUrl={item.primaryIcon.upload?.url}
        name={item.name}
        size={IMAGE_SIZE}
        url={item.url}
      />
    );
  }

  throw new Error('Given item is neither a bookmark nor a folder');
};

export default ItemIcon;
