import { Websktop, WebsktopUserRef } from '@websktop/commons';
import { AtomEffect, atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import api from 'api';

import { fetchOrReadCache, persistEffect } from '../lib';

const REACT_APP_TARGET = process.env.REACT_APP_TARGET;

const getWebsktopUsersLocalStorageKey = (websktopId: Websktop['id']): string =>
  `websktop-users-${websktopId}`;

const websktopUsersAtomFamily = atomFamily<WebsktopUserRef[], Websktop['id']>({
  key: 'websktop-users',
  default: async (id: Websktop['id']) => {
    return fetchOrReadCache(getWebsktopUsersLocalStorageKey(id), () => {
      return api.websktop.getUsers(id);
    });
  },
  effects: (websktopId: Websktop['id']) =>
    [
      REACT_APP_TARGET === 'extension' &&
        persistEffect(getWebsktopUsersLocalStorageKey(websktopId)),
    ].filter(Boolean) as AtomEffect<WebsktopUserRef[]>[],
});

export const useWebsktopUsers = (id: Websktop['id']) => useRecoilValue(websktopUsersAtomFamily(id));

export const useSetWebsktopUsers = (id: Websktop['id']) =>
  useSetRecoilState(websktopUsersAtomFamily(id));
