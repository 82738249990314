import { isApiError } from '@websktop/commons';
import { useAsyncFn } from 'react-use';

import { Link } from 'components';
import { useShowNotification, useStartTrial as useStartTrialRaw } from 'state';

const useStartTrial = () => {
  const startTrialRaw = useStartTrialRaw();
  const showNotification = useShowNotification();

  return useAsyncFn(async () => {
    try {
      await startTrialRaw();
      showNotification({
        content: (
          <>
            <p>Enjoy all of the Professional Plan features.</p>
            <p>We're happy to see you on board!</p>
          </>
        ),
        title: 'Your 30-day trial has started!',
        type: 'success',
      });
    } catch (error) {
      showNotification({
        content: (
          <>
            {isApiError(error) && <p>{error.message}</p>}

            <p>
              Please try again. If the issue keeps happening, please{' '}
              <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
                contact our support
              </Link>
              .
            </p>
          </>
        ),
        title: 'Cannot start trial',
        type: 'error',
      });
    }
  }, [startTrialRaw, showNotification]);
};

export default useStartTrial;
