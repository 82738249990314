import { ApiFormErrorPayload, isApiFormError } from '@websktop/commons';

const NO_ERRORS = {};

const getFormFieldsErrors = <Fields extends string>(
  apiError: unknown,
): ApiFormErrorPayload<Fields>['fields'] => {
  if (!isApiFormError(apiError)) {
    return NO_ERRORS;
  }

  return apiError.payload.fields;
};

export default getFormFieldsErrors;
