import { useCallback } from 'react';

import api from 'api';

const useResendVerificationEmail = (): typeof api.auth.resendVerificationEmail => {
  const resendVerificationEmail: typeof api.auth.resendVerificationEmail = useCallback(
    (payload) => api.auth.resendVerificationEmail(payload),
    [],
  );

  return resendVerificationEmail;
};

export default useResendVerificationEmail;
