import { User } from '@websktop/commons';

import { useUser } from '../atom';

const useAuthenticatedUser = (): User => {
  const user = useUser();

  if (!user) {
    throw new Error('Authentication required');
  }

  return user;
};

export default useAuthenticatedUser;
