import { BillingCycle, User } from '@websktop/commons';
import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Link, Modal } from 'components';
import { TRIAL_EXPIRED_MODAL_STORAGE_KEY } from 'constants/trial';
import { trackEvent } from 'modules/analytics';
import { BillingCycleInput, CurrencyInput, Plans } from 'modules/plans';
import routing from 'modules/routing';
import { useAuthenticatedUser } from 'state';
import { Currency } from 'types';

import styles from './TrialExpiredModal.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SEPARATOR = ',';

const doNotShowAgain = (user: User) => {
  const trialExpiredModal = localStorage.getItem(TRIAL_EXPIRED_MODAL_STORAGE_KEY);
  const set = trialExpiredModal
    ? new Set([...trialExpiredModal.split(SEPARATOR), user.id])
    : new Set([user.id]);
  const userIdsWhoClosedModal = Array.from(set).join(SEPARATOR);
  localStorage.setItem(TRIAL_EXPIRED_MODAL_STORAGE_KEY, userIdsWhoClosedModal);
};

const TrialExpiredModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const user = useAuthenticatedUser();
  const [currency, setCurrency] = useState<Currency>('usd');
  const [billingCycle, setBillingCycle] = useState<BillingCycle>('year');

  const handleClose = () => {
    trackEvent('trial-expired-modal', 'close');
    doNotShowAgain(user);
    onClose();
  };

  const handleContinue = () => {
    trackEvent('trial-expired-modal', 'continue-free-plan');
    doNotShowAgain(user);
    onClose();
  };

  const handlePurchase = async () => {
    trackEvent('trial-expired-modal', 'purchase-pro-plan');
    navigate(routing.upgrade({ billingCycle, currency }));
    doNotShowAgain(user);
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      header={<h1>Trial expired</h1>}
      isOpen={isOpen}
      panelClassName={styles.panel}
      onClose={handleClose}
    >
      <p>Your free 30-day trial of Professional Plan is over.</p>

      <p>You can continue using Websktop on a Free Plan or purchase the Professional Plan.</p>

      <p>
        If you have any questions feel free to{' '}
        <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
          contact our support
        </Link>
        .
      </p>

      <div className={styles.controls}>
        <CurrencyInput value={currency} onChange={setCurrency} />
        <BillingCycleInput value={billingCycle} onChange={setBillingCycle} />
      </div>

      <Plans
        actionFreePlan={<Button onClick={handleContinue}>Continue with Free Plan</Button>}
        actionProfessionalPlan={
          <Button variant="primary" onClick={handlePurchase}>
            Get Professional Plan
          </Button>
        }
        billingCycle={billingCycle}
        className={styles.plans}
        currency={currency}
      />
    </Modal>
  );
};

export default TrialExpiredModal;
