import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './SsoLink.module.scss';

interface Props extends HTMLProps<HTMLAnchorElement> {
  logo: string;
  logoTitle: string;
}

const SsoLink: FunctionComponent<Props> = ({ children, className, logo, logoTitle, ...props }) => (
  <a className={classNames(styles.ssoLink, className)} {...props}>
    <img alt={logoTitle} className={styles.logo} src={logo} />
    <span className={styles.label}>{children}</span>
  </a>
);

export default SsoLink;

export { styles };
