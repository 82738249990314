import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { getQueryParams } from 'lib';

const useQueryParams = <Params extends string>() => {
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const queryParams = useMemo(() => getQueryParams<Params>(), [location]);
  return queryParams;
};

export default useQueryParams;
