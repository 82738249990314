import { useEffect } from 'react';

import { useDemoExpiredModal } from 'modals';
import { useIsDemoExpired } from 'state';

const useHandleDemoExpiration = () => {
  const demoExpiredModal = useDemoExpiredModal();
  const isDemoExpired = useIsDemoExpired();

  const openModal = demoExpiredModal.open;

  useEffect(() => {
    if (isDemoExpired) {
      openModal();
    }
  }, [isDemoExpired, openModal]);
};

export default useHandleDemoExpiration;
