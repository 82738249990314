import { FunctionComponent } from 'react';

import facebookLogo from 'assets/facebook.svg';
import githubLogo from 'assets/github.svg';
import googleLogo from 'assets/google.svg';
import routing from 'modules/routing';

import SsoLink from '../SsoLink';

import styles from './SsoAuth.module.scss';
import { patchQueryParams } from 'lib';

interface Props {
  className?: string;
  redirect: string | string[];
  type: 'Sign in' | 'Sign up';
}

const SsoAuth: FunctionComponent<Props> = ({ className, redirect, type }) => (
  <div className={className}>
    <SsoLink
      className={styles.socialMediaLink}
      href={patchQueryParams(routing.auth.google, { redirect })}
      logo={googleLogo}
      logoTitle="Google logo"
    >
      {type} with Google
    </SsoLink>

    <SsoLink
      className={styles.socialMediaLink}
      href={patchQueryParams(routing.auth.facebook, { redirect })}
      logo={facebookLogo}
      logoTitle="Facebook logo"
    >
      {type} with Facebook
    </SsoLink>

    <SsoLink
      className={styles.socialMediaLink}
      href={patchQueryParams(routing.auth.github, { redirect })}
      logo={githubLogo}
      logoTitle="GitHub logo"
    >
      {type} with GitHub
    </SsoLink>
  </div>
);

export default SsoAuth;
