import { updateUserWebsktopRef, User, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';

import { useSetUser } from '../atom';
import { useAuthenticatedUser, useWebsktopRef } from '../state';

type UnsubscribeWebsktop = () => Promise<void>;

const useUnsubscribeWebsktop = (websktopId: Websktop['id']): UnsubscribeWebsktop => {
  const { id: userId } = useAuthenticatedUser();
  const setUser = useSetUser();
  const optimisticUpdates = useOptimisticUpdates();
  const websktopRef = useWebsktopRef(websktopId);

  const unsubscribeWebsktop: UnsubscribeWebsktop = useCallback(async () => {
    const optimisticUpdate = optimisticUpdates.create<User>(userId, (user) => {
      return updateUserWebsktopRef(user, { ...websktopRef, isSubscribed: false });
    });

    try {
      const user = await api.user.updateSubscriptions({ [websktopId]: false });
      setUser(user);
    } finally {
      optimisticUpdates.dispose(optimisticUpdate.id);
    }
  }, [optimisticUpdates, setUser, userId, websktopId, websktopRef]);

  return unsubscribeWebsktop;
};

export default useUnsubscribeWebsktop;
