import { Link, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  linkId: Link['id'];
  point: Point;
  websktopId: Websktop['id'];
}

const linkContextMenuAtom = createModalAtom<State>('link-context-menu');

export const useLinkContextMenu = () => useModal(linkContextMenuAtom);
