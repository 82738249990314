import addCommand from './addCommand';

/**
 * @see https://developers.piwik.pro/en/latest/data_collection/web/javascript_tracking_client/api.html?highlight=trackEvent#page-views
 * @see https://help.piwik.pro/support/questions/how-can-i-track-a-single-page-application-manual-method/
 */
const trackPageView = (customPageTitle?: string) => {
  addCommand(['setCustomUrl', window.location.href]);
  addCommand(['trackPageView', customPageTitle]);
};

export default trackPageView;
