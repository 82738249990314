import classNames from 'classnames';
import { FunctionComponent } from 'react';

import FileInput, { Props as FileInputProps } from '../FileInput';
import LabeledInput from '../LabeledInput';

import styles from './LabeledFileInput.module.scss';

export interface Props extends FileInputProps {
  className?: string;
  description?: string;
  error?: string;
  label: string;
}

const LabeledFileInput: FunctionComponent<Props> = ({
  accept,
  className,
  disabled,
  description,
  error,
  id,
  label,
  required,
  value,
  onChange,
  ...props
}) => (
  <LabeledInput
    className={classNames(styles.labeledFileInput, className)}
    description={description}
    error={error}
    id={id}
    label={label}
    required={required}
  >
    <FileInput
      accept={accept}
      disabled={disabled}
      id={id}
      value={value}
      onChange={onChange}
      {...props}
    />
  </LabeledInput>
);

export default LabeledFileInput;
