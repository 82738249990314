import { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { WORKERS_COUNT } from '../constants';
import { searchQueryAtom, searchResultsAtom } from '../state';
import { SearchConfig, WorkerClientMessage, WorkerMessage } from '../types';
import { useAllItemsLoadable } from '../../websktops';

const useSearchWorkers = (config: SearchConfig): void => {
  const query = useRecoilValue(searchQueryAtom);
  const setResults = useSetRecoilState(searchResultsAtom);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const itemsLoadable = useAllItemsLoadable();
  const lastResultTimestamp = useRef(0);
  const lastUsedWorkerIndexRef = useRef(0);
  const isLoaded = itemsLoadable.state === 'hasValue';

  useEffect(() => {
    const workers = Array.from({ length: WORKERS_COUNT }, () => {
      return new Worker(new URL('../worker.ts', import.meta.url), { type: 'module' });
    });

    setWorkers(workers);

    return () => {
      workers.forEach((worker) => worker.terminate());
      setWorkers([]);
    };
  }, []);

  useEffect(() => {
    const listener = (event: MessageEvent) => {
      const { action, requestedAt, results }: WorkerMessage = event.data;

      if (action === 'set-results' && requestedAt > lastResultTimestamp.current) {
        setResults(results);
        lastResultTimestamp.current = requestedAt;
      }
    };

    workers.forEach((worker) => worker.addEventListener('message', listener));

    return () => {
      workers.forEach((worker) => worker.removeEventListener('message', listener));
    };
  }, [setResults, workers]);

  useEffect(() => {
    if (!isLoaded) {
      return;
    }

    const initSearchIndexMessage: WorkerClientMessage = {
      action: 'init-search-index',
      items: itemsLoadable.contents,
      config,
    };

    workers.forEach((worker) => {
      worker.postMessage(initSearchIndexMessage);
    });
  }, [config, isLoaded, itemsLoadable.contents, workers]);

  useEffect(() => {
    if (workers.length === 0) {
      return;
    }

    const workerIndex = (lastUsedWorkerIndexRef.current + 1) % WORKERS_COUNT;
    const worker = workers[workerIndex];

    const setQueryMessage: WorkerClientMessage = {
      action: 'set-query',
      requestedAt: Date.now(),
      query,
    };

    worker.postMessage(setQueryMessage);

    lastUsedWorkerIndexRef.current = workerIndex;
  }, [query, workers]);
};

export default useSearchWorkers;
