import { Folder, Item, Point, Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { ClipboardMimeType } from 'constants/mime-type';
import { getUrlsFromHtml, getUrlsFromText, systemClipboard } from 'lib';
import { useCloneItems, useMoveItems, useShowErrorNotification } from 'state';

import { useCreateLinksFromUrls } from '../../websktops';
import { EMPTY_ITEMS_STATE } from '../constants';
import { decodeItemsState, encodeItemsState } from '../lib';
import { useCanPaste, useClipboardItems, useSetClipboardState } from '../state';
import { Clipboard, ItemsState } from '../types';

const useClipboard = (websktopId: Websktop['id']): Clipboard => {
  const showErrorNotification = useShowErrorNotification();
  const createLinksFromUrls = useCreateLinksFromUrls(websktopId);
  const cloneItems = useCloneItems(websktopId);
  const moveItems = useMoveItems(websktopId);
  const canPaste = useCanPaste();
  const setClipboardState = useSetClipboardState();

  const copy = useCallback(
    (items: Item[]) => {
      const state: ItemsState = { actionId: uuidV4(), items, mode: 'copy', websktopId };
      setClipboardState(encodeItemsState(state));
    },
    [setClipboardState, websktopId],
  );

  const cut = useCallback(
    (items: Item[]) => {
      const state: ItemsState = { actionId: uuidV4(), items, mode: 'cut', websktopId };
      setClipboardState(encodeItemsState(state));
    },
    [setClipboardState, websktopId],
  );

  const pasteUrls = useCallback(
    async (urls: string[], targetFolder: Folder, targetPoint?: Point) => {
      try {
        await createLinksFromUrls({
          parentId: targetFolder.id,
          urls,
          ...targetPoint,
        });
      } catch (error) {
        showErrorNotification(error);
      }
    },
    [createLinksFromUrls, showErrorNotification],
  );

  const pasteItems = useCallback(
    async (itemsState: ItemsState, targetFolder: Folder, targetPoint?: Point) => {
      const { items, mode, websktopId: sourceWebsktopId } = itemsState;

      try {
        if (mode === 'copy') {
          await cloneItems(items, targetFolder, targetPoint);
        }

        if (mode === 'cut') {
          setClipboardState(encodeItemsState(EMPTY_ITEMS_STATE));
          await moveItems({ items, sourceWebsktopId, targetFolder, targetPoint });
        }
      } catch (error) {
        if (mode === 'cut') {
          setClipboardState(encodeItemsState(itemsState));
        }

        showErrorNotification(error);
      }
    },
    [cloneItems, moveItems, setClipboardState, showErrorNotification],
  );

  const clipboardItems = useClipboardItems();

  const paste: Clipboard['paste'] = useCallback(
    async ({ state: stateParam, targetFolder, targetPoint }) => {
      let state = stateParam;

      if (!state) {
        try {
          state = await systemClipboard.read();
        } catch (error) {
          // systemClipboard.read() failed, resort to pasting whatever is in memory
          pasteItems(clipboardItems, targetFolder, targetPoint);
          return;
        }
      }

      const html = state[ClipboardMimeType.HTML];
      const itemsState = decodeItemsState(html);

      if (itemsState && itemsState.items.length > 0) {
        pasteItems(itemsState, targetFolder, targetPoint);
      } else {
        const text = state[ClipboardMimeType.TEXT];
        const urlsFromHtml = getUrlsFromHtml(html);
        const urlsFromText = getUrlsFromText(text);
        const urls = urlsFromHtml.length > 0 ? urlsFromHtml : urlsFromText;
        pasteUrls(urls, targetFolder, targetPoint);
      }
    },
    [clipboardItems, pasteItems, pasteUrls],
  );

  return {
    canPaste,
    copy,
    cut,
    paste,
  };
};

export default useClipboard;
