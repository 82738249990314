import { StatusCode, User } from '@websktop/commons';
import { useEffect, useState } from 'react';
import { useLatest, useNetworkState, usePrevious } from 'react-use';

import api from 'api';
import { clearSessionData, patchQueryParams } from 'lib';
import routing from 'modules/routing';
import { isDemoExpiredError, isHttpError } from 'types';

import { getSyncInterval } from '../../lib';
import { useOptimisticUpdatesState } from '../../optimistic-updates';
import { useSetUser } from '../atom';
import { useAuthenticatedUser } from '../state';

const useSyncUser = () => {
  const setUser = useSetUser();
  const user = useAuthenticatedUser();
  const { effectiveType: connectionType, online: isOnline } = useNetworkState();
  const interval = getSyncInterval(connectionType);
  const [error, setError] = useState<unknown | null>(null);
  const [optimisticUpdatesState] = useOptimisticUpdatesState<User>();
  const hasPendingUpdates = optimisticUpdatesState.some(
    (optimisticUpdate) => optimisticUpdate.entityId === user.id,
  );
  const hasPendingUpdatesRef = useLatest(hasPendingUpdates);
  const wasOffline = usePrevious(!isOnline);

  useEffect(() => {
    if (hasPendingUpdatesRef.current || !isOnline) {
      return;
    }

    const refetch = async () => {
      try {
        const user = await api.user.get();

        if (hasPendingUpdatesRef.current) {
          return;
        }

        setUser(user);
      } catch (error) {
        if (!isHttpError(error)) {
          return;
        }

        if (isDemoExpiredError(error)) {
          clearInterval(intervalId);
          return;
        }

        if (error.status === StatusCode.FORBIDDEN) {
          clearInterval(intervalId);
          setError(error);
        }

        if (error.status === StatusCode.UNAUTHENTICATED) {
          clearSessionData();
          window.location.href = patchQueryParams(routing.auth.signIn, { signedOut: 'true' });
        }
      }
    };

    const intervalId = setInterval(refetch, interval);

    if (wasOffline) {
      refetch();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [hasPendingUpdatesRef, interval, isOnline, setUser, wasOffline]);

  if (error) {
    throw error;
  }
};

export default useSyncUser;
