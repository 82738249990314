import User from '../User';
import UserCursor from '../UserCursor';
import UserRef from '../UserRef';
import Websktop from '../Websktop';

export enum ServerMessage {
  ERROR = 'websktop:error',
  SET_ONLINE_USERS = 'websktop:online-users:set',
  SET_USER_CURSOR = 'websktop:online-users:set-cursor',
  SET_WEBSKTOP = 'websktop:set',
}

export type ErrorHandler = (error: unknown) => void;

export type SetWebsktopOnlineUsersHandler = (
  websktopId: Websktop['id'],
  usersRefs: UserRef[],
) => void;

export type SetUserCursorHandler = (
  websktopId: Websktop['id'],
  userId: User['id'],
  cursor: UserCursor,
) => void;

export type SetWebsktopHandler = (websktop: Websktop) => void;

export interface ServerToClientEvents {
  [ServerMessage.ERROR]: ErrorHandler;
  [ServerMessage.SET_ONLINE_USERS]: SetWebsktopOnlineUsersHandler;
  [ServerMessage.SET_USER_CURSOR]: SetUserCursorHandler;
  [ServerMessage.SET_WEBSKTOP]: SetWebsktopHandler;
}
