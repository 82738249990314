import { IUseNetworkState } from 'react-use/lib/useNetworkState';

const getSyncInterval = (connectionType: IUseNetworkState['effectiveType']): number => {
  switch (connectionType) {
    case 'slow-2g':
      return 20000;
    case '2g':
      return 10000;
    case '3g':
      return 5000;
    default:
      return 3000;
  }
};

export default getSyncInterval;
