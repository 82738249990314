/**
 * Protocol specification: @see https://docs.microsoft.com/en-us/previous-versions/windows/internet-explorer/ie-developer/platform-apis/aa753582(v=vs.85)?redirectedfrom=MSDN
 */

import { escape } from 'lodash-es';

import { Bookmark, Folder, isBookmark, isFolder, Item } from './types';

const DEFAULT_TITLE = 'Bookmarks';
const TAB_SIZE = 4;

const getIndent = (level: number): string => ''.padStart(level * TAB_SIZE, ' ');

const entriesToAttributes = (entries: [string, string | number | undefined][]): string =>
  entries
    .filter(([_attribute, value]) => Boolean(value))
    .map(([attribute, value]) => `${attribute}="${value}"`)
    .join(' ');

const bookmarkToNetscapeFormat = (bookmark: Bookmark, level: number): string => {
  const { addDate, icon, name, url } = bookmark;
  const indent = getIndent(level);

  const attributes = entriesToAttributes([
    ['ADD_DATE', addDate],
    ['HREF', encodeURI(url)],
    ['ICON', icon],
  ]);

  return `${indent}<DT><A ${attributes}>${escape(name)}</A>`;
};

const folderToNetscapeFormat = (folder: Folder, level: number): string => {
  const { addDate, children, name } = folder;
  const indent = getIndent(level);
  const attributes = entriesToAttributes([['ADD_DATE', addDate]]);

  return [
    `${indent}<DT><H3${attributes ? ' ' : ''}${attributes}>${escape(name)}</H3>`,
    itemsToNetscapeFormat(children, level),
  ].join('\n');
};

const itemsToNetscapeFormat = (items: Item[], level: number): string => {
  const indent = getIndent(level);

  return [
    `${indent}<DL><p>`,
    ...items.filter(isBookmark).map((bookmark) => bookmarkToNetscapeFormat(bookmark, level + 1)),
    ...items.filter(isFolder).map((folder) => folderToNetscapeFormat(folder, level + 1)),
    `${indent}</DL><p>`,
  ].join('\n');
};

const createBookmarksHtml = (items: Item[], title: string = DEFAULT_TITLE) => `\
<!DOCTYPE NETSCAPE-Bookmark-file-1>
<!-- This is an automatically generated file.
    It will be read and overwritten.
    DO NOT EDIT! -->
<META HTTP-EQUIV="Content-Type" CONTENT="text/html; charset=UTF-8">
<TITLE>${title}</TITLE>
<H1>${title}</H1>
${itemsToNetscapeFormat(items, 0)}
`;

export default createBookmarksHtml;
