import { WebsktopRef } from '../types';

const removeWebsktopRef = (
  websktopsRefs: WebsktopRef[],
  websktopId: WebsktopRef['id'],
): WebsktopRef[] => {
  const sortedWebsktopsRefs = [...websktopsRefs].sort((a, b) => a.order - b.order);

  return sortedWebsktopsRefs
    .filter((websktopsRef) => websktopsRef.id !== websktopId)
    .map((websktopRef, index) => ({ ...websktopRef, order: index }));
};

export default removeWebsktopRef;
