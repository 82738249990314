import addCommand from './addCommand';

/**
 * @see https://developers.piwik.pro/en/latest/data_collection/web/javascript_tracking_client/api.html#setUserId
 */
const setUserId = (userId: string) => {
  addCommand(['setUserId', userId]);
};

export default setUserId;
