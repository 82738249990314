import { Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useCopyToClipboard } from 'react-use';

import { Button, Modal, useStaticTooltip } from 'components';
import { useTimedToggle } from 'hooks';
import { getAbsoluteUrl } from 'lib';
import routing from 'modules/routing';

import styles from './WebsktopPublishedModal.module.scss';

interface Props {
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const COPY_TOOLTIP_DURATION = 1000;

const WebsktopPublishedModal: FunctionComponent<Props> = ({ isOpen, websktopId, onClose }) => {
  const websktopUrl = getAbsoluteUrl(routing.websktop(websktopId));
  const [, copy] = useCopyToClipboard();
  const [isCopyTooltipShown, showCopyTooltip] = useTimedToggle(COPY_TOOLTIP_DURATION);
  const copyTooltipTriggerProps = useStaticTooltip('Copied!', { isShown: isCopyTooltipShown });

  const handleCopyUrl = () => {
    copy(websktopUrl);
    showCopyTooltip();
  };

  return (
    <Modal
      footer={
        <div className={styles.footer}>
          <Button
            icon="link"
            variant="primary"
            onClick={handleCopyUrl}
            {...copyTooltipTriggerProps}
          >
            Copy link
          </Button>

          <Button icon="check" onClick={onClose}>
            Got it
          </Button>
        </div>
      }
      header={<h2>Your Websktop is now public!</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <p>
        Now you can send a link to this Websktop to anyone and they will be able to view it in
        read-only mode.
        <br />
        Even without an account!
      </p>
    </Modal>
  );
};

export default WebsktopPublishedModal;
