import { BaseTile } from '../types';

const createGrid = <Tile extends BaseTile>(
  tiles: Tile[],
  columnsCount: number,
  rowsCount: number,
) => {
  return Array.from({ length: rowsCount }, (_, y) => {
    return Array.from({ length: columnsCount }, (_, x) => {
      return tiles.find((tile) => tile.y === y && tile.x === x) || null;
    });
  });
};

export default createGrid;
