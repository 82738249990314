import { Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import {
  atom,
  atomFamily,
  DefaultValue,
  selector,
  selectorFamily,
  SetterOrUpdater,
  useRecoilValue,
  useRecoilValueLoadable,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import api from 'api';

import { fetchOrReadCache, persistEffect } from '../lib';
import { applyOptimisticUpdates, optimisticUpdatesAtom } from '../optimistic-updates';

const getWebsktopLocalStorageKey = (websktopId: Websktop['id']): string => `websktop-${websktopId}`;

export const websktopAtomFamily = atomFamily<Websktop, Websktop['id']>({
  key: 'websktop',
  default: async (id: Websktop['id']) => {
    return fetchOrReadCache(getWebsktopLocalStorageKey(id), () => {
      return api.websktop.get(id);
    });
  },
  effects: (websktopId: Websktop['id']) => [persistEffect(getWebsktopLocalStorageKey(websktopId))],
});

export const optimisticWebsktopFamily = selectorFamily<Websktop, Websktop['id']>({
  key: 'optimistic-websktop',
  get: (id) => {
    return ({ get }) => {
      const optimisticUpdates = get(optimisticUpdatesAtom);
      const websktop = get(websktopAtomFamily(id));
      return applyOptimisticUpdates(websktop, optimisticUpdates);
    };
  },
});

export const useWebsktop = (id: Websktop['id']) => useRecoilValue(optimisticWebsktopFamily(id));

export const useWebsktopLoadable = (id: Websktop['id']) =>
  useRecoilValueLoadable(websktopAtomFamily(id));

export const useResetWebsktop = (id: Websktop['id']) => useResetRecoilState(websktopAtomFamily(id));

export const useRefetchWebsktop = (id: Websktop['id']) => {
  const setWebsktop = useSetWebsktop(id);

  return useCallback(async () => {
    const websktop = await api.websktop.get(id);
    setWebsktop(websktop);
  }, [id, setWebsktop]);
};

/**
 * @deprecated Use useSetAnyWebsktop instead
 */
export const useSetWebsktop = (id: Websktop['id']): SetterOrUpdater<Websktop> => {
  const setState = useSetRecoilState(websktopAtomFamily(id));

  const setWebsktop: SetterOrUpdater<Websktop> = useCallback(
    (websktop) => {
      setState((current) => {
        const incomingWebsktop = typeof websktop === 'function' ? websktop(current) : websktop;
        const isNewer =
          incomingWebsktop.updatedAt > current.updatedAt ||
          incomingWebsktop.contentModifiedAt > current.contentModifiedAt;
        return isNewer ? incomingWebsktop : current;
      });
    },
    [setState],
  );

  return setWebsktop;
};

export const currentWebsktopIdAtom = atom<Websktop['id'] | null>({
  key: 'current-websktop-id',
  default: null,
});

export const useCurrentWebsktopId = () => useRecoilValue(currentWebsktopIdAtom);

export const useSetCurrentWebsktopId = () => useSetRecoilState(currentWebsktopIdAtom);

const setAnyWebsktopSelector = selector<Websktop | null>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'set-any-websktop-selector',
  set: ({ get, set }, newWebsktop) => {
    if (newWebsktop === null || newWebsktop instanceof DefaultValue) {
      return;
    }

    const current = get(websktopAtomFamily(newWebsktop.id));
    const isNewer =
      newWebsktop.updatedAt > current.updatedAt ||
      newWebsktop.contentModifiedAt > current.contentModifiedAt;

    if (isNewer) {
      set(websktopAtomFamily(newWebsktop.id), newWebsktop);
    }
  },
  get: () => {
    return null;
  },
});

export const useSetAnyWebsktop = () => useSetRecoilState(setAnyWebsktopSelector);
