import { Link, removeItemsFromWebsktop, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSelection } from '../../selection';

import { useSetWebsktop } from '../atom';

type DeleteLink = (linkId: Link['id']) => Promise<void>;

const useDeleteLink = (websktopId: Websktop['id']): DeleteLink => {
  const setWebsktop = useSetWebsktop(websktopId);
  const [selection, { toggle }] = useSelection();
  const optimisticUpdates = useOptimisticUpdates();

  const deleteLink: DeleteLink = useCallback(
    async (linkId: Link['id']) => {
      const isSelected = selection.includes(linkId);

      if (isSelected) {
        toggle([linkId]);
      }

      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop) => {
        return removeItemsFromWebsktop(websktop, [linkId]);
      });

      try {
        const websktop = await api.websktop.deleteLink({ websktopId, linkId });
        setWebsktop(websktop);
      } catch (error) {
        if (isSelected) {
          toggle([linkId]);
        }

        throw error;
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, selection, setWebsktop, toggle, websktopId],
  );

  return deleteLink;
};

export default useDeleteLink;
