import { useCallback } from 'react';
import { v4 } from 'uuid';

import { Notification } from 'types';

import { useSetNotifications } from '../atom';

type ShowNotification = (notificationProps: Omit<Notification, 'id'>) => Notification['id'];

const useShowNotification = (): ShowNotification => {
  const setNotifications = useSetNotifications();

  const showNotification: ShowNotification = useCallback(
    (notificationProps) => {
      const id = v4();
      const notification = { ...notificationProps, id };
      setNotifications((notifications) => [...notifications, notification]);
      return id;
    },
    [setNotifications],
  );

  return showNotification;
};

export default useShowNotification;
