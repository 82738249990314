import { isObject } from '@websktop/commons';

export default interface UrlsDropEvent {
  urls: string[];
}

export const isUrlsDropEvent = (value: unknown): value is UrlsDropEvent => {
  return (
    isObject(value) &&
    Array.isArray(value.urls) &&
    value.urls.every((url) => typeof url === 'string')
  );
};
