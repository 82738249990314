import useMergedRef from '@react-hook/merged-ref';
import { WebsktopRef } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';
import { useNavigate } from 'react-router-dom';

import { TRANSPARENT_IMAGE_SRC } from 'constants/images';
import { useIsOnline } from 'hooks';
import routing from 'modules/routing';

import { DND_SIDEBAR_ITEM } from '../constants';
import WebsktopLink from '../WebsktopLink';

import styles from './DraggableWebsktopLink.module.scss';
import useItemsDrop from './useItemsDrop';

const OPEN_WEBSKTOP_DELAY = 1000;

interface Props {
  websktopRef: WebsktopRef;
  onMoveWebsktopRef: (draggedItemId: WebsktopRef['id'], targetItemId: WebsktopRef['id']) => void;
}

const DraggableWebsktopLink: FunctionComponent<Props> = ({ websktopRef, onMoveWebsktopRef }) => {
  const websktopId = websktopRef.id;
  const isOnline = useIsOnline();
  const ref = useRef<HTMLAnchorElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();
  const isDndEnabled = isOnline;
  const isItemsDndEnabled = isOnline;

  const [{ isOver: areItemsOver, canDrop: canDropItems }, itemsDropRef] = useItemsDrop(
    { websktopId },
    isItemsDndEnabled,
  );

  const [{ isOver }, dropRef] = useDrop(
    {
      accept: isDndEnabled ? DND_SIDEBAR_ITEM : [],
      hover(dragged: WebsktopRef, monitor) {
        const clientOffset = monitor.getClientOffset();

        if (!ref.current || clientOffset === null) {
          return;
        }

        onMoveWebsktopRef(dragged.id, websktopRef.id);
      },
      collect(monitor) {
        return { isOver: monitor.isOver() };
      },
    },
    [onMoveWebsktopRef, websktopRef],
  );

  const [, dragRef, preview] = useDrag(
    {
      canDrag: isDndEnabled,
      item: () => websktopRef,
      type: DND_SIDEBAR_ITEM,
    },
    [isDndEnabled, websktopRef],
  );

  useEffect(() => {
    if (!areItemsOver || !canDropItems) {
      return;
    }

    const timeoutId = window.setTimeout(
      () => navigate(routing.websktop(websktopId)),
      OPEN_WEBSKTOP_DELAY,
    );

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [areItemsOver, canDropItems, isOver, navigate, websktopId]);

  const linkRef = useMergedRef(ref, dragRef, dropRef, itemsDropRef);

  return (
    <>
      <WebsktopLink
        className={classNames({
          [styles.dragged]: isDragging,
          [styles.isOver]: isOver,
          [styles.canDropItems]: canDropItems,
          [styles.itemsOver]: areItemsOver,
        })}
        ref={isDndEnabled ? linkRef : undefined}
        websktopRef={websktopRef}
        onDragEnd={() => setIsDragging(false)}
        onDragStart={() => setIsDragging(true)}
      />
      <DragPreviewImage connect={preview} src={TRANSPARENT_IMAGE_SRC} />
    </>
  );
};

export default DraggableWebsktopLink;
