import { StatusCode, User } from '@websktop/commons';
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

import api from 'api';
import { clearSessionData } from 'lib';
import { hasSessionCookie } from 'modules/auth';
import { isHttpError } from 'types';

import { persistEffect } from '../lib';
import { applyOptimisticUpdates, optimisticUpdatesAtom } from '../optimistic-updates';

const LOCAL_STORAGE_KEY = 'user';

export const userAtom = atom<User | null>({
  key: 'user',
  default:
    hasSessionCookie() || process.env.REACT_APP_TARGET === 'extension'
      ? api.user.get().catch((error) => {
          const cachedStateJson = localStorage.getItem(LOCAL_STORAGE_KEY);
          const shouldUseCachedValue =
            cachedStateJson !== null &&
            (error instanceof TypeError ||
              (isHttpError(error) && error.status !== StatusCode.UNAUTHENTICATED));

          if (shouldUseCachedValue) {
            return JSON.parse(cachedStateJson);
          }

          clearSessionData();
          return null;
        })
      : null,
  effects: [persistEffect(LOCAL_STORAGE_KEY)],
});

export const optimisticUserSelector = selector<User | null>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'optimistic-user',
  get: ({ get }) => {
    const user = get(userAtom);

    if (user === null) {
      return null;
    }

    const optimisticUpdates = get(optimisticUpdatesAtom);
    return applyOptimisticUpdates(user, optimisticUpdates);
  },
});

export const useUser = () => useRecoilValue(optimisticUserSelector);

export const useSetUser = () => useSetRecoilState(userAtom);
