import { Link, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  parentId: Link['parentId'];
  point: Point;
  websktopId: Websktop['id'];
}

const newLinkModalAtom = createModalAtom<State>('new-link-modal');

export const useNewLinkModal = () => useModal(newLinkModalAtom);
