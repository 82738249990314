import { Placement } from '@popperjs/core';
import classNames from 'classnames';
import { ReactNode, useState } from 'react';
import Modal from 'react-modal';
import { useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';

import { useTabbableElementsNavigation } from 'hooks';

import { MODIFIERS } from './constants';
import styles from './Popup.module.scss';

export interface Props {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  placement?: Placement;
  referenceElement: HTMLElement | null;
  onClose: () => void;
}

const Popup = ({
  children,
  className,
  isOpen,
  placement = 'bottom-start',
  referenceElement,
  onClose,
}: Props) => {
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { attributes, styles: popperStyles } = usePopper(referenceElement, popperElement, {
    modifiers: MODIFIERS,
    placement,
  });

  useRootClose(popperElement, onClose, { disabled: !isOpen });
  useTabbableElementsNavigation(popperElement, { disabled: !isOpen });

  return (
    <Modal
      className={classNames(styles.modal, className)}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div
        className={className}
        ref={setPopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {children}
      </div>
    </Modal>
  );
};

export default Popup;
