import {
  addItemsToWebsktop,
  CreateLinkPayload,
  findEmptyPoint,
  getFolder,
  Icon,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';
import { v4 as uuidV4 } from 'uuid';

import api from 'api';
import { NULL_ICON_PAYLOAD } from 'constants/icons';

import { useOptimisticUpdates } from '../../optimistic-updates';

import { useSetWebsktop, useWebsktop } from '../atom';
import { createOptimisticLink } from '../lib';
import { ImageUpload } from '../types';

import useUpdateSelectionSafe from './useUpdateSelectionSafe';

type CreateLink = (payload: CreateLinkPayload, iconUpload?: ImageUpload) => Promise<void>;

const useCreateLink = (websktopId: Websktop['id']): CreateLink => {
  const setWebsktop = useSetWebsktop(websktopId);
  const websktop = useWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();
  const updateSelectionSafe = useUpdateSelectionSafe();

  const createLink: CreateLink = useCallback(
    async (payload, iconUpload) => {
      const folder = getFolder(websktop.folder, payload.parentId);
      const points = folder.items.map((item) => ({ x: item.x, y: item.y }));
      const { x, y } = findEmptyPoint(
        points,
        typeof payload.x !== 'undefined' && typeof payload.y !== 'undefined'
          ? { x: payload.x, y: payload.y }
          : undefined,
      );

      const optimisticUpdate = optimisticUpdates.create<Websktop>(
        websktopId,
        (websktop, optimisticUpdateId) => {
          const primaryIconUpload =
            typeof iconUpload?.fallbackUrl === 'undefined'
              ? null
              : { id: '', url: iconUpload.fallbackUrl };

          const primaryIcon: Icon = {
            id: uuidV4(),
            ...NULL_ICON_PAYLOAD,
            ...payload.primaryIcon,
            upload: primaryIconUpload,
          };

          const optimisticLink = createOptimisticLink({
            ...payload,
            id: optimisticUpdateId,
            primaryIcon,
            x,
            y,
          });

          return addItemsToWebsktop(websktop, [optimisticLink]);
        },
      );

      try {
        const primaryIconUpload = await iconUpload?.promise;
        const uploadId = primaryIconUpload ? primaryIconUpload.id : null;
        const response = await api.websktop.createLink({
          websktopId,
          ...payload,
          primaryIcon: {
            ...NULL_ICON_PAYLOAD,
            ...payload.primaryIcon,
            uploadId,
          },
        });
        updateSelectionSafe([response.link.id]);
        setWebsktop(response.websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, updateSelectionSafe, websktop.folder, websktopId],
  );

  return createLink;
};

export default useCreateLink;
