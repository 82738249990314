import { Upload } from '@websktop/commons';

import api from './lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const uploads = {
  async upload(file: File): Promise<Upload> {
    const form = new FormData();
    form.append('file', file);
    const upload = await api.postForm<Upload>(`${API_ORIGIN}/uploads`, form);
    return upload;
  },
};

export default uploads;
