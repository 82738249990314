import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Link, Panel } from 'components';
import { patchQueryParams } from 'lib';
import { EmergencyActions } from 'modules/errors';
import routing from 'modules/routing';
import { useIsAuthenticated } from 'state';

import styles from './UnknownError.module.scss';

const UnknownError: FunctionComponent = () => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to={patchQueryParams(routing.auth.signIn, { redirect: location.pathname })} />;
  }

  return (
    <Panel className={styles.error}>
      <h1>Unknown error occurred</h1>

      <p>Please refresh the page and try again.</p>

      <p>If this does not help, you can try clearing your browser cache.</p>

      <p>
        If clearing cache does not help, please{' '}
        <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
          contact our support
        </Link>
        .
      </p>

      <p>
        Alternatively you can email us at{' '}
        <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
          {process.env.REACT_APP_EMAIL_CONTACT}
        </Link>
        .
      </p>

      <EmergencyActions />
    </Panel>
  );
};

export default UnknownError;
