import { Link } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  links: Link[];
}

const openLinksConfirmationModalAtom = createModalAtom<State>('open-links-confirmation-modal');

export const useOpenLinksConfirmationModal = () => useModal(openLinksConfirmationModalAtom);
