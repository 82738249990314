import { Item, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  itemsIds: Item['id'][];
  point: Point;
  websktopId: Websktop['id'];
}

const itemsContextMenuAtom = createModalAtom<State>('items-context-menu');

export const useItemsContextMenu = () => useModal(itemsContextMenuAtom);
