import classNames from 'classnames';
import { FunctionComponent } from 'react';

import WebsktopLogo from '../WebsktopLogo';

import styles from './SplashLoader.module.scss';

interface Props {
  className?: string;
}

const SplashLoader: FunctionComponent<Props> = ({ className }) => (
  <div className={classNames(styles.splashLoader, className)}>
    <WebsktopLogo className={styles.logo} color="dynamic" />
  </div>
);

export default SplashLoader;
