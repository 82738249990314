import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const deleteWebsktopModalAtom = createModalAtom<State>('delete-websktop-modal');

export const useDeleteWebsktopModal = () => useModal(deleteWebsktopModalAtom);
