import { Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useClipboard, useItems, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useCut: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const { cut } = useClipboard(websktopId);
  const items = useItems(websktopId, selection);

  const handleCut = useCallback(() => cut(items), [cut, items]);

  useHotkey('cut', handleCut, { disabled: !isEnabled || items.length === 0 });
};

export default useCut;
