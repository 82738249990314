import { Item, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, Message, Modal } from 'components';
import { useUniqueId } from 'hooks';
import { getItemsCountMessage } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { useDeleteItems, useItems } from 'state';

import styles from './DeleteItemsModal.module.scss';

interface Props {
  className?: string;
  isOpen: boolean;
  itemsIds: Item['id'][];
  websktopId: Websktop['id'];
  onClose: () => void;
}

const DeleteItemsModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  itemsIds,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const items = useItems(websktopId, itemsIds);
  const deleteItems = useDeleteItems();

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await deleteItems(websktopId, items);
      });
    },
    [deleteItems, handleUnmountedError, items, onClose, websktopId],
  );

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button
            autoFocus
            form={id}
            loading={isSubmitting}
            icon="trash-alt"
            type="submit"
            variant="danger"
          >
            Delete
          </Button>
        </Modal.Footer>
      }
      header={<h2>Delete</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <p>Are you sure you want to delete {getItemsCountMessage(items)}?</p>

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default DeleteItemsModal;
