import { CreateFolderPayload, Folder } from '../types';

const folderToCreatePayload = (folder: Folder): CreateFolderPayload => {
  if (!folder.parentId) {
    throw new Error('Cannot map root folder to create payload');
  }

  return {
    name: folder.name,
    note: folder.note,
    parentId: folder.parentId,
    x: folder.x,
    y: folder.y,
  };
};

export default folderToCreatePayload;
