import { Websktop } from '@websktop/commons';
import { Component, ReactNode } from 'react';

import WebsktopError from '../WebsktopError';

interface Props {
  children: ReactNode;
  error: Error;
  websktopId: Websktop['id'];
}

interface State {
  error: unknown;
}

class WebsktopErrorBoundary extends Component<Props, State> {
  state: State = {
    error: undefined,
  };

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.websktopId !== prevProps.websktopId) {
      this.setState({ error: undefined });
    }
  }

  render() {
    const { children, websktopId } = this.props;
    const error = this.props.error || this.state.error;

    if (!error) {
      return children;
    }

    if (error instanceof Error) {
      return <WebsktopError error={error} websktopId={websktopId} />;
    }

    throw error;
  }
}

export default WebsktopErrorBoundary;
