import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { persistEffect } from '../lib';

import { State, isState } from './types';

export const LOCAL_STORAGE_KEY = 'sidebar-state';

export const INITIAL_STATE: State = 'auto';

const initialValue = localStorage.getItem(LOCAL_STORAGE_KEY);
const parsedInitialValue = initialValue === null ? INITIAL_STATE : JSON.parse(initialValue);

export const sidebarStateAtom = atom<State>({
  key: 'sidebar-state',
  default: isState(parsedInitialValue) ? parsedInitialValue : INITIAL_STATE,
  effects: [persistEffect(LOCAL_STORAGE_KEY)],
});

export const useSidebarState = () => useRecoilState(sidebarStateAtom);

export const useSetSidebarState = () => useSetRecoilState(sidebarStateAtom);
