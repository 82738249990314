import { removeWebsktopRef, User, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useAuthenticatedUser, useDeleteWebsktopRef } from '../../user';

import { useResetWebsktop } from '../atom';

type DeleteWebsktop = () => Promise<void>;

const useDeleteWebsktop = (websktopId: Websktop['id']): DeleteWebsktop => {
  const user = useAuthenticatedUser();
  const resetWebsktop = useResetWebsktop(websktopId);
  const deleteWebsktopRef = useDeleteWebsktopRef();
  const optimisticUpdates = useOptimisticUpdates();

  const deleteWebsktop: DeleteWebsktop = useCallback(async () => {
    const optimisticUpdate = optimisticUpdates.create<User>(user.id, (user) => ({
      ...user,
      websktopsRefs: removeWebsktopRef(user.websktopsRefs, websktopId),
    }));

    try {
      await api.websktop.deleteWebsktop({ websktopId });
      resetWebsktop();
      deleteWebsktopRef(websktopId);
    } finally {
      optimisticUpdates.dispose(optimisticUpdate.id);
    }
  }, [deleteWebsktopRef, optimisticUpdates, resetWebsktop, user.id, websktopId]);

  return deleteWebsktop;
};

export default useDeleteWebsktop;
