import { WebsktopRef } from '@websktop/commons';

import useAuthenticatedUser from './useAuthenticatedUser';

const useWebsktopRef = (id: WebsktopRef['id']): WebsktopRef => {
  const user = useAuthenticatedUser();
  const websktopRef = user.websktopsRefs.find((websktopRef) => websktopRef.id === id);

  if (!websktopRef) {
    throw new Error('WebsktopRef does not exist');
  }

  return websktopRef;
};

export default useWebsktopRef;
