import { useCallback } from 'react';

import api from 'api';

import { useSetUser } from '../atom';
import { useSetUserSettings } from 'state/user-settings';

const useVerify = (): typeof api.auth.verify => {
  const setUser = useSetUser();
  const setUserSettings = useSetUserSettings();

  const verify: typeof api.auth.verify = useCallback(
    async (payload) => {
      const user = await api.auth.verify(payload);
      const userSettings = await api.user.getSettings();
      setUser(user);
      setUserSettings(userSettings);
      return user;
    },
    [setUser, setUserSettings],
  );

  return verify;
};

export default useVerify;
