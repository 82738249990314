import { Folder, getFolder, Websktop } from '@websktop/commons';
import { useMemo } from 'react';

import { useWebsktop } from '../atom';

const useFolder = (websktopId: Websktop['id'], folderId: Folder['id']): Folder => {
  const websktop = useWebsktop(websktopId);
  const rootFolder = websktop.folder;
  const folder = useMemo(() => getFolder(rootFolder, folderId), [rootFolder, folderId]);
  return folder;
};

export default useFolder;
