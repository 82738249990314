import { FunctionComponent, memo } from 'react';
import Joyride, { Step } from 'react-joyride';

import { Link } from 'components';
import { useCompleteTour, useIsTourRunning } from 'state';

import { INSTRUCTIONS } from './constants';
import styles from './Tour.module.scss';
import { useIsTouchDevice } from 'hooks';
import { useMedia } from 'react-use';

const GOOGLE_CHROME_EXTENSION_URL =
  'https://chrome.google.com/webstore/detail/websktop-speed-dial/hmhoefiijgfmodaajchjhehjhpjhekib';

interface StepsParameters {
  isSmallDevice: boolean;
  isTouchDevice: boolean;
}

const getSteps = ({ isSmallDevice, isTouchDevice }: StepsParameters): Step[] => {
  const rightClick = isTouchDevice ? 'Long-touch' : 'Right-click';

  const steps: (Step | undefined)[] = [
    {
      target: '[data-tour="websktop"]',
      disableBeacon: true,
      placement: 'center',
      title: 'Welcome to Websktop!',
      content: (
        <div className={styles.tooltipContent}>
          Let us give you a <strong>quick tour</strong> around the app.
        </div>
      ),
    },
    {
      target: '[data-tour="websktop"]',
      disableBeacon: true,
      placement: 'bottom',
      title: 'Your Websktop',
      content: (
        <div className={styles.tooltipContent}>
          <ul>
            <li>
              <strong>{rightClick}</strong> the background to add bookmarks, folders, import
              bookmarks &amp; customize
            </li>
            <li>
              <strong>{rightClick}</strong> icons for more options
            </li>
            <li>
              <strong>Paste</strong> links to create bookmarks
            </li>
            {!isTouchDevice && (
              <li>
                <strong>Draw rectangle</strong> for mass-selection
              </li>
            )}
            <li>
              <strong>Drag &amp; drop</strong> {isTouchDevice ? '' : 'selected '}icons
            </li>
            {!isTouchDevice && (
              <li>
                <strong>Keyboard shortcuts</strong> available
              </li>
            )}
          </ul>
        </div>
      ),
    },
    {
      target: '[data-tour="multiple-websktops"]',
      placement: isSmallDevice ? 'top-start' : 'right-start',
      disableBeacon: true,
      title: 'Multiple Websktops',
      content: (
        <div className={styles.tooltipContent}>
          <p>Browse, manage &amp; create your Websktops here.</p>

          <ul>
            <li>
              <strong>{rightClick}</strong> for more options
            </li>
            <li>
              <strong>Drag &amp; drop</strong> to reorder
            </li>
          </ul>
        </div>
      ),
    },
    isTouchDevice
      ? undefined
      : {
          target: '[data-tour="permission"]',
          placement: isSmallDevice ? 'center' : 'bottom',
          disableBeacon: true,
          title: 'Clipboard permission',
          content: (
            <div className={styles.tooltipContent}>
              <p>Our app needs to access your clipboard to provide the best user experience.</p>

              <p>
                Make sure to grant this permission when your web browser asks you for it - otherwise
                copy/pasting functionality will be limited.
              </p>
            </div>
          ),
        },
    {
      target: '[data-tour="permission"]',
      placement: isSmallDevice ? 'center' : 'bottom',
      disableBeacon: true,
      title: 'Pop-ups permission',
      content: (
        <div className={styles.tooltipContent}>
          <p>
            We offer mass-open functionality for bookmarks - your web browser or browser extensions
            may block it.
          </p>

          <p>To prevent this from happening, please disable pop-up blocking at "websktop.com".</p>

          <p>
            Don't worry, <strong>we never show ads or any other annoying pop-ups.</strong>
          </p>
        </div>
      ),
    },
    {
      target: '[data-tour="permission"]',
      placement: isSmallDevice ? 'center' : 'bottom',
      disableBeacon: true,
      title: 'Browser integration',
      content: (
        <div className={styles.tooltipContent}>
          {window.chrome && (
            <>
              <p>
                Install our{' '}
                <Link
                  className={styles.link}
                  href={GOOGLE_CHROME_EXTENSION_URL}
                  target="_blank"
                  type="external"
                >
                  Chrome Extension
                </Link>{' '}
                to take full advantage of your browser.
              </p>

              <p>The extension will set Websktop as your speed dial page (aka "new tab page").</p>

              <p>It will also improve loading time of the app &amp; your data.</p>
            </>
          )}

          {!window.chrome && (
            <>
              <p>
                Take advantage by setting Websktop as your homepage. It's more than a speed dial.
              </p>

              <p>Here are instructions for different browsers:</p>

              <ul className={styles.browsers}>
                {INSTRUCTIONS.map(({ name, href }) => (
                  <li className={styles.browser} key={name}>
                    <Link href={href} target="_blank" type="external">
                      {name}
                    </Link>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      ),
    },
  ];

  return steps.filter(Boolean) as Step[];
};

const Tour: FunctionComponent = () => {
  const completeTour = useCompleteTour();
  const isTourRunning = useIsTourRunning();
  const isTouchDevice = useIsTouchDevice();
  const isSmallDevice = useMedia('(max-width: 600px)');
  const steps = getSteps({ isSmallDevice, isTouchDevice });

  return (
    <>
      <Joyride
        callback={(data) => {
          if (data.lifecycle === 'complete' && ['skipped', 'finished'].includes(data.status)) {
            completeTour();
          }
        }}
        continuous
        disableScrollParentFix
        hideCloseButton
        locale={{
          last: "Ok, let's go!",
        }}
        run={isTourRunning}
        showProgress
        showSkipButton
        spotlightPadding={0}
        steps={steps}
        styles={{
          options: {
            primaryColor: '#3498ff', // --color--primary--background
            width: isSmallDevice ? 350 : 450,
          },
        }}
      />

      <div className={styles.permission} data-tour="permission" />
    </>
  );
};

export default memo(Tour);
