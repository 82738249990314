import { Websktop } from '@websktop/commons';

import { useUser } from '../atom';

const useDefaultWebsktopId = (): Websktop['id'] | null => {
  const user = useUser();

  if (!user || user.websktopsRefs.length === 0) {
    return null;
  }

  return user.websktopsRefs[0].id;
};

export default useDefaultWebsktopId;
