import { Folder, ImportBookmarksPayload, Point, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent, useMemo } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, LabeledFileInput, Link, Message, Modal } from 'components';
import { useUniqueId } from 'hooks';
import { getFormFieldsErrors, useFormState } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { useImportBookmarks } from 'state';

import { INSTRUCTIONS } from './constants';
import styles from './ImportBookmarksModal.module.scss';

interface FormState {
  files: FileList | null;
}

interface Props {
  className?: string;
  isOpen: boolean;
  parentId: NonNullable<Folder['parentId']>;
  point: Point;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const ImportBookmarksModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  parentId,
  point,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const [form, { patchFormState }] = useFormState<FormState>({ files: null });
  const file = form.files && form.files.length > 0 ? form.files[0] : null;
  const importBookmarks = useImportBookmarks(websktopId);
  const importBookmarksPayload = useMemo(
    () => ({ file, parentId, ...point }),
    [file, parentId, point],
  );

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        if (!importBookmarksPayload.file) {
          throw new Error('No HTML file provided');
        }

        await importBookmarks(importBookmarksPayload as ImportBookmarksPayload<File>);
      });
    },
    [handleUnmountedError, importBookmarks, importBookmarksPayload, onClose],
  );

  const formErrors = getFormFieldsErrors<'file'>(error);

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button
            disabled={form.files === null || form.files.length === 0}
            form={id}
            loading={isSubmitting}
            icon="upload"
            type="submit"
            variant="primary"
          >
            Import
          </Button>
        </Modal.Footer>
      }
      header={<h2>Import browser bookmarks</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <p>
          First you need to export your browser bookmarks to an <code>.html</code> file. Here are
          instructions for different browsers:
        </p>

        <ul className={styles.browsers}>
          {INSTRUCTIONS.map(({ icon, name, href }) => (
            <li className={styles.browser} key={name}>
              <Link href={href} target="_blank" type="external">
                {icon && <img alt={name} className={styles.browserIcon} src={icon} />}
                {name}
              </Link>
            </li>
          ))}
        </ul>

        <LabeledFileInput
          accept=".html"
          autoFocus
          disabled={isSubmitting}
          error={formErrors.file?.message}
          id="file"
          label="File"
          required
          value={form.files}
          onChange={(event) => patchFormState({ files: event.target.files })}
        />

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default ImportBookmarksModal;
