import { Plan, User, UserRole, Websktop, iconToPayload } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { useAsyncFn, useCopyToClipboard } from 'react-use';

import { Badge, Button, Message, Modal, useStaticTooltip } from 'components';
import { useTimedToggle, useUniqueId } from 'hooks';
import { getAbsoluteUrl, useFormState } from 'lib';
import { useInviteWebsktopUsersModal, useUpgradeModal, useWebsktopPublishedModal } from 'modals';
import { useUnmountedErrorHandler } from 'modules/notifications';
import RolesDropdown, {
  getRoleDescription,
  getRoleIcon,
  getRoleLabel,
  getRoleVariant,
} from 'modules/roles-dropdown';
import routing from 'modules/routing';
import {
  useAuthenticatedUser,
  useSyncWebsktopUsers,
  useUpdateWebsktop,
  useUpdateWebsktopUsersRoles,
  useWebsktop,
  useWebsktopUsers,
} from 'state';

import AccessEntry from './AccessEntry';
import ReadAccess from './ReadAccess';
import styles from './SharingSettingsModal.module.scss';

interface FormState {
  readAccess: Websktop['readAccess'];
  userRoles: Record<User['id'], UserRole>;
}

interface Props {
  className?: string;
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const COPY_TOOLTIP_DURATION = 1000;

const SharingSettingsModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const [, copy] = useCopyToClipboard();
  const upgradeModal = useUpgradeModal();
  const websktopPublishedModal = useWebsktopPublishedModal();
  const websktop = useWebsktop(websktopId);
  const updateWebsktop = useUpdateWebsktop(websktopId);
  const users = useWebsktopUsers(websktopId);
  const inviteUsersModal = useInviteWebsktopUsersModal();
  const updateWebsktopUsersRoles = useUpdateWebsktopUsersRoles(websktopId);
  const user = useAuthenticatedUser();
  const [formState, { patchFormState }] = useFormState<FormState>({
    readAccess: websktop.readAccess,
    userRoles: {},
  });
  const currentUser = users.find(({ id }) => id === user.id);
  const otherUsers = users.filter(({ id }) => id !== user.id);
  const otherUsersToShow = otherUsers.filter(({ role }) => role !== UserRole.NONE);
  const websktopUrl = getAbsoluteUrl(routing.websktop(websktopId));
  const [isCopyTooltipShown, showCopyTooltip] = useTimedToggle(COPY_TOOLTIP_DURATION);
  const copyTooltipTriggerProps = useStaticTooltip('Copied!', { isShown: isCopyTooltipShown });

  useSyncWebsktopUsers(websktopId);

  const handleRoleChange = (userId: User['id'], role: UserRole) => {
    patchFormState({
      userRoles: {
        ...formState.userRoles,
        [userId]: role,
      },
    });
  };

  const submitWebsktop = useCallback(async () => {
    const invariantAttributes = {
      backgroundColor: websktop.backgroundColor,
      backgroundId: websktop.background ? websktop.background.id : null,
      backgroundType: websktop.background ? websktop.background.type : 'contain',
      primaryIcon: iconToPayload(websktop.primaryIcon),
      name: websktop.name,
      readAccess: websktop.readAccess,
      textColor: websktop.textColor,
      textShadowColor: websktop.textShadowColor,
    };

    if (websktop.readAccess === formState.readAccess) {
      return Promise.resolve();
    }

    return updateWebsktop({ ...invariantAttributes, readAccess: formState.readAccess });
  }, [formState.readAccess, updateWebsktop, websktop]);

  const submitWebsktopUsers = useCallback(() => {
    if (Object.keys(formState.userRoles).length === 0) {
      return Promise.resolve();
    }

    return updateWebsktopUsersRoles(formState.userRoles);
  }, [formState.userRoles, updateWebsktopUsersRoles]);

  const submit = useCallback(
    () => Promise.all([submitWebsktop(), submitWebsktopUsers()]),
    [submitWebsktop, submitWebsktopUsers],
  );

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        const [websktop] = await submit();
        const isPublic = websktop && websktop.readAccess === 'public';

        if (isPublic) {
          websktopPublishedModal.open({ websktopId: websktop.id });
        }
      });
    },
    [handleUnmountedError, onClose, submit, websktopPublishedModal.open],
  );

  const handleCopyUrl = () => {
    copy(websktopUrl);
    showCopyTooltip();
  };

  const handleAddUsersClick = () => {
    if (user.subscription.plan === Plan.FREE) {
      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to share this Websktop?</h2>
            You need a Professional Plan to <strong>share</strong> Websktops.
          </div>
        ),
        source: 'sharing modal - invite user',
      });
    } else {
      inviteUsersModal.open({ websktopId });
    }
  };

  const handleReadAccessClick: MouseEventHandler = (event) => {
    if (user.subscription.plan === Plan.FREE) {
      event.preventDefault();

      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to publish your Websktop?</h2>
            You need a Professional Plan to make your Websktops <strong>public</strong>.
          </div>
        ),
        source: 'sharing modal - update read access',
      });
    }
  };

  const handleChangeRoleClick: MouseEventHandler = (event) => {
    if (user.subscription.plan === Plan.FREE) {
      event.preventDefault();

      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to control permissions in this Websktop?</h2>
            You need a Professional Plan to <strong>control permissions</strong>.
          </div>
        ),
        source: 'sharing modal - update user role',
      });
    }
  };

  const isAdmin = currentUser && currentUser.role === UserRole.ADMIN;
  const isEditor = currentUser && currentUser.role === UserRole.EDITOR;
  const isViewer = currentUser && currentUser.role === UserRole.VIEWER;
  const isNobody = !currentUser || currentUser.role === UserRole.NONE;

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer
          className={classNames(styles.footer, {
            [styles.twoButtons]: websktop.readAccess === 'public' || otherUsersToShow.length > 0,
          })}
        >
          {(websktop.readAccess === 'public' || otherUsersToShow.length > 0) && (
            <Button icon="link" onClick={handleCopyUrl} {...copyTooltipTriggerProps}>
              Copy link
            </Button>
          )}

          <Button
            disabled={!isAdmin}
            form={id}
            loading={isSubmitting}
            icon="check"
            type="submit"
            variant="primary"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      }
      header={<h2>Sharing settings</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      {error && (
        <Message className={styles.error} variant="error">
          {error.message}
        </Message>
      )}

      <div>
        <div>
          {!isNobody && (
            <AccessEntry label="You">
              {isAdmin && (
                <Badge
                  icon={getRoleIcon(UserRole.ADMIN)}
                  size="small"
                  tooltip={getRoleDescription(UserRole.ADMIN)}
                  variant={getRoleVariant(UserRole.ADMIN)}
                >
                  {getRoleLabel(UserRole.ADMIN)}
                </Badge>
              )}

              {isEditor && (
                <Badge
                  icon={getRoleIcon(UserRole.EDITOR)}
                  size="small"
                  tooltip={getRoleDescription(UserRole.EDITOR)}
                  variant={getRoleVariant(UserRole.EDITOR)}
                >
                  {getRoleLabel(UserRole.EDITOR)}
                </Badge>
              )}

              {isViewer && (
                <Badge
                  icon={getRoleIcon(UserRole.VIEWER)}
                  size="small"
                  tooltip={getRoleDescription(UserRole.VIEWER)}
                  variant={getRoleVariant(UserRole.VIEWER)}
                >
                  {getRoleLabel(UserRole.VIEWER)}
                </Badge>
              )}
            </AccessEntry>
          )}

          <ReadAccess
            disabled={!isAdmin || isSubmitting}
            value={formState.readAccess}
            onChange={(readAccess) => patchFormState({ readAccess })}
            onClick={handleReadAccessClick}
          />
        </div>

        {(isAdmin || otherUsersToShow.length > 0) && (
          <div className={styles.users}>
            <div className={styles.label}>Users with acccess</div>
            <div>
              {otherUsersToShow.map((userRef) => (
                <AccessEntry key={userRef.id} label={userRef.name || `@${userRef.username}`}>
                  <RolesDropdown
                    disabled={!isAdmin || isSubmitting}
                    showNoneRole
                    value={formState.userRoles[userRef.id] || userRef.role}
                    onChange={(role) => handleRoleChange(userRef.id, role)}
                    onClick={handleChangeRoleClick}
                  />
                </AccessEntry>
              ))}
            </div>

            {isAdmin && (
              <div className={styles.moreUsers}>
                <Button icon="users" onClick={handleAddUsersClick}>
                  Add users
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SharingSettingsModal;
