import { useCallback } from 'react';

import api from 'api';

const useRegister = (): typeof api.auth.register => {
  const register: typeof api.auth.register = useCallback(async (payload) => {
    await api.auth.register(payload);
  }, []);

  return register;
};

export default useRegister;
