import {
  Folder,
  getItemsDeep,
  getLinksDeep,
  isFolder,
  isLink,
  Item,
  Link,
} from '@websktop/commons';

const INDENT = '  ';

const getIndent = (level = 0): string => INDENT.repeat(level);

const linkToText = (link: Link, level = 0): string => {
  return `${getIndent(level)}- ${link.url}`;
};

const folderToText = (folder: Folder, level = 0): string => {
  const name = folder.name.trim();
  const items = itemsToText(folder.items, level + 1);
  const prefix = `${getIndent(level)}- ${name}`;

  if (items.length === 0) {
    return prefix;
  }

  return `${prefix}\n${items}`;
};

const itemsToText = (items: Item[], level = 0): string => {
  const itemsDeep = items.reduce<Record<string, Item>>(
    (result, item) => ({ ...result, ...getItemsDeep(item) }),
    {},
  );
  const itemsDeepCount = Object.keys(itemsDeep).length;

  if (itemsDeepCount === 0 && level === 0) {
    return '';
  }

  if (itemsDeepCount === 1 && level === 0) {
    const items = Object.values(itemsDeep);
    const item = items[0];

    if (isLink(item)) {
      return item.url;
    }

    return item.name;
  }

  return items
    .reduce<string[]>((result, item) => {
      const isEmptyFolder = isFolder(item) && getLinksDeep([item]);

      if (isEmptyFolder) {
        return result;
      }

      return [...result, isFolder(item) ? folderToText(item, level) : linkToText(item, level)];
    }, [])
    .join('\n');
};

export default itemsToText;
