const downloadHtmlFile = (html: string, filename: string) => {
  const blob = new Blob([html], { type: 'text/html' });

  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = filename;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};

export default downloadHtmlFile;
