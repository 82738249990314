import { Point } from '@websktop/commons';

import { ICON_HEIGHT, ICON_WIDTH, SPACING_COLUMN, SPACING_ROW } from 'modules/grid-layout';

const getClosestWebsktopLocationFromPoint = (relativePoint: Point): Point => {
  const x = (relativePoint.x - SPACING_COLUMN / 2) / (ICON_WIDTH + SPACING_COLUMN);
  const y = (relativePoint.y - SPACING_ROW / 2) / (ICON_HEIGHT + SPACING_ROW);
  const floorX = Math.floor(x);
  const floorY = Math.floor(y);

  return { x: floorX, y: floorY };
};

export default getClosestWebsktopLocationFromPoint;
