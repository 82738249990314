import type { CSSProperties, FunctionComponent } from 'react';
import { useDragLayer, XYCoord } from 'react-dnd';

import { DND_SIDEBAR_ITEM } from '../constants';
import WebsktopLink from '../WebsktopLink';

import styles from './WebsktopLinkDragLayer.module.scss';

const layerStyles: CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 100,
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
};

function getItemStyles(currentOffset: XYCoord | null) {
  if (!currentOffset) {
    return { display: 'none' };
  }

  const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

const WebsktopLinkDragLayer: FunctionComponent = () => {
  const { currentOffset, isDragging, item, itemType } = useDragLayer((monitor) => ({
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
  }));

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(currentOffset)}>
        {itemType === DND_SIDEBAR_ITEM && (
          <WebsktopLink className={styles.link} websktopRef={item} />
        )}
      </div>
    </div>
  );
};

export default WebsktopLinkDragLayer;
