import { Websktop, WebsktopRef } from '@websktop/commons';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { useSetUser } from '../atom';

type UpdateWebsktopRef = SetterOrUpdater<WebsktopRef>;

const useUpdateWebsktopRef = (websktopId: Websktop['id']): UpdateWebsktopRef => {
  const setUser = useSetUser();

  const updateWebsktopRef: UpdateWebsktopRef = useCallback(
    (updatedWebsktopRef) => {
      setUser((user) => {
        if (user === null) {
          throw new Error('Cannot update user data');
        }

        return {
          ...user,
          websktopsRefs: user.websktopsRefs.map((websktopRef) => {
            if (websktopRef.id === websktopId) {
              if (typeof updatedWebsktopRef === 'function') {
                return updatedWebsktopRef(websktopRef);
              }

              return updatedWebsktopRef;
            }

            return websktopRef;
          }),
        };
      });
    },
    [setUser, websktopId],
  );

  return updateWebsktopRef;
};

export default useUpdateWebsktopRef;
