import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

import styles from './Tooltip.module.scss';

const Arrow = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children, className, ...props }, ref) => (
    <div {...props} className={classNames(styles.arrow, className)} ref={ref} />
  ),
);

export default Arrow;
