import { BillingCycle } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import { FREE_PLAN, PROFESSIONAL_PLAN } from 'constants/plans';
import { Currency } from 'types';

import Plan from '../Plan';

import styles from './Plans.module.scss';

interface Props {
  actionFreePlan?: ReactNode;
  actionProfessionalPlan?: ReactNode;
  billingCycle: BillingCycle;
  className?: string;
  currency: Currency;
}

const Plans: FunctionComponent<Props> = ({
  actionFreePlan,
  actionProfessionalPlan,
  billingCycle,
  className,
  currency,
}) => (
  <div className={classNames(styles.plans, className)}>
    <Plan
      action={actionFreePlan}
      billingCycle={billingCycle}
      className={styles.plan}
      currency={currency}
      features={FREE_PLAN.features}
      monthlyPrice={FREE_PLAN.prices[billingCycle][currency]}
      name={FREE_PLAN.name}
      plan={FREE_PLAN.type}
    />

    <Plan
      action={actionProfessionalPlan}
      billingCycle={billingCycle}
      className={styles.plan}
      currency={currency}
      features={PROFESSIONAL_PLAN.features}
      monthlyPrice={PROFESSIONAL_PLAN.prices[billingCycle][currency]}
      name={PROFESSIONAL_PLAN.name}
      plan={PROFESSIONAL_PLAN.type}
    />
  </div>
);

export default Plans;
