import { noop } from 'lodash-es';
import { FunctionComponent, ReactNode } from 'react';

import { Button, ButtonProps } from 'components';
import { useUpgradeModal } from 'modals';

interface Props extends Omit<ButtonProps, 'ref'> {
  message?: ReactNode;
  source: string;
}

const UpgradePlanButton: FunctionComponent<Props> = ({
  children = 'Upgrade plan',
  message,
  source,
  onClick = noop,
  ...props
}) => {
  const upgradeModal = useUpgradeModal();

  const handleClick = () => {
    onClick();
    upgradeModal.open({ message, source });
  };

  return (
    <Button icon="star" type="button" variant="primary" onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export default UpgradePlanButton;
