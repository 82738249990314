import { useLocation, useResolvedPath } from 'react-router-dom';

interface Options {
  caseSensitive?: boolean;
  end?: boolean;
}
// Source:
// https://github.com/remix-run/react-router/blob/9bdf8aea2643b2b743901872c193c29f11c0fd7c/packages/react-router-dom/index.tsx#L314-L328
const useIsActive = (to: string, options: Options = {}): boolean => {
  const location = useLocation();
  const path = useResolvedPath(to);
  const locationPathname = options.caseSensitive
    ? location.pathname
    : location.pathname.toLowerCase();
  const toPathname = options.caseSensitive ? path.pathname : path.pathname.toLowerCase();

  return (
    locationPathname === toPathname ||
    (!options.end &&
      locationPathname.startsWith(toPathname) &&
      locationPathname.charAt(toPathname.length) === '/')
  );
};

export default useIsActive;
