import { Link, isLink, Folder } from '../types';

import findItem from './findItem';

const getLink = (folder: Folder, linkId: Link['id']): Link => {
  const item = findItem(folder, linkId);

  if (!isLink(item)) {
    throw new Error('Link does not exist');
  }

  return item;
};

export default getLink;
