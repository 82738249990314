import { findItem, Item, Websktop } from '@websktop/commons';
import { useMemo } from 'react';

import { useWebsktop } from '../atom';

const useItems = (websktopId: Websktop['id'], itemsIds: Item['id'][]): Item[] => {
  const websktop = useWebsktop(websktopId);
  const rootFolder = websktop.folder;
  const items = useMemo(() => {
    return itemsIds.reduce<Item[]>((items, itemId) => {
      const item = findItem(rootFolder, itemId);

      // We want to get all the items from a websktop, but in some cases it is impossible,
      // like when an item is selected and it is absent after websktop synchronization.
      // For this kind of situations we just ignore absent items.
      if (typeof item === 'undefined') {
        return items;
      }

      return [...items, item];
    }, []);
  }, [rootFolder, itemsIds]);
  return items;
};

export default useItems;
