import { DATA_ATTRIBUTE_NAME } from '../constants';
import { ItemsState } from '../types';

import itemsToHtml from './itemsToHtml';

const encodeItemsStateToHtml = (state: ItemsState) => {
  const containerElement = document.createElement('div');
  containerElement.setAttribute(DATA_ATTRIBUTE_NAME, JSON.stringify(state));
  containerElement.innerHTML = itemsToHtml(state.items);
  return containerElement.outerHTML;
};

export default encodeItemsStateToHtml;
