import { Action } from './types';

const hotkeysMap: Record<Action, string> = {
  copy: 'Mod+C',
  cut: 'Mod+X',
  delete: 'Del',
  edit: 'E',
  folderUp: 'Mod+Up',
  open: 'Enter',
  'open-deep': 'Opt+Enter',
  'open-in-new-tab': 'Mod+Enter',
  paste: 'Mod+V',
  rename: 'R',
  selectAll: 'Mod+A',
};

export default hotkeysMap;
