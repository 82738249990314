import { Item, isFolder } from '../types';

const getItemsDeep = (item: Item): Record<Item['id'], Item> => {
  const items: Record<Item['id'], Item> = {
    [item.id]: item,
  };

  if (isFolder(item)) {
    for (const child of item.items) {
      Object.assign(items, getItemsDeep(child));
    }
  }

  return items;
};

export default getItemsDeep;
