import { Point } from '@websktop/commons';

const createVirtualReference = (point: Point | undefined) => ({
  getBoundingClientRect(): DOMRect {
    const left = typeof point === 'undefined' ? 0 : point.x;
    const top = typeof point === 'undefined' ? 0 : point.y;

    return {
      x: left,
      y: top,
      top,
      left,
      bottom: 1 + top,
      right: 1 + left,
      width: 1,
      height: 1,
      toJSON: () => null,
    };
  },
});

export default createVirtualReference;
