import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Panel } from 'components';
import { patchQueryParams } from 'lib';
import routing from 'modules/routing';
import { useIsAuthenticated } from 'state';

import styles from './NotFoundError.module.scss';

const NotFoundError: FunctionComponent = () => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <Navigate to={patchQueryParams(routing.auth.signIn, { redirect: location.pathname })} />;
  }

  return (
    <Panel className={styles.error}>
      <h1>Websktop not found</h1>

      <p>This Websktop does not exist.</p>
    </Panel>
  );
};

export default NotFoundError;
