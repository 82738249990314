import classNames from 'classnames';
import { forwardRef, HTMLAttributes } from 'react';

import styles from './Tooltip.module.scss';

import Arrow from './Arrow';

interface Props extends HTMLAttributes<HTMLDivElement> {
  placement?: 'top' | 'right' | 'bottom' | 'left';
}

const Tooltip = forwardRef<HTMLDivElement, Props>(
  ({ children, className, placement = 'top', ...props }, ref) => (
    <div
      className={classNames(styles.tooltip, className, {
        [styles.top]: placement === 'top',
        [styles.right]: placement === 'right',
        [styles.bottom]: placement === 'bottom',
        [styles.left]: placement === 'left',
      })}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);

export default Object.assign(Tooltip, { Arrow });
