// https://fontawesome.com/v4.7.0/icons/

import { library } from '@fortawesome/fontawesome-svg-core';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faSquare as farFaSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faAnglesLeft,
  faAnglesRight,
  faArrowDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClipboard,
  faCodeBranch,
  faCog,
  faCut,
  faDollarSign,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEuroSign,
  faEye,
  faFile,
  faFileExport,
  faFolder,
  faFolderOpen,
  faGift,
  faGlobe,
  faHandshake,
  faHeadset,
  faHome,
  faImage,
  faInfoCircle,
  faKey,
  faLevelUpAlt,
  faLink,
  faListUl,
  faLock,
  faMousePointer,
  faObjectGroup,
  faPencilAlt,
  faPlus,
  faQuestion,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faShareAlt,
  faStar,
  faSyncAlt,
  faThumbtack,
  faTrashAlt,
  faTimes,
  faUpload,
  faUsers,
  faUserSecret,
  faWifi,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faTwitter);

library.add(farFaSquare);

library.add(
  faAnglesLeft,
  faAnglesRight,
  faArrowDown,
  faCheck,
  faCheckCircle,
  faCheckSquare,
  faClipboard,
  faCodeBranch,
  faCog,
  faCut,
  faDollarSign,
  faEllipsisH,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faExternalLinkSquareAlt,
  faEuroSign,
  faEye,
  faFile,
  faFileExport,
  faFolder,
  faFolderOpen,
  faGift,
  faGlobe,
  faHandshake,
  faHeadset,
  faHome,
  faImage,
  faInfoCircle,
  faKey,
  faLevelUpAlt,
  faLink,
  faListUl,
  faLock,
  faMousePointer,
  faObjectGroup,
  faPencilAlt,
  faPlus,
  faQuestion,
  faSearch,
  faSignInAlt,
  faSignOutAlt,
  faShareAlt,
  faStar,
  faSyncAlt,
  faThumbtack,
  faTrashAlt,
  faTimes,
  faUpload,
  faUsers,
  faUserSecret,
  faWifi,
);

export default FontAwesomeIcon;
