import { Item } from '@websktop/commons';

import { useClipboardItems } from '../state';

const useIsItemCut = (id: Item['id']): boolean => {
  const { items, mode } = useClipboardItems();
  return mode === 'cut' && items.some((item) => item.id === id);
};

export default useIsItemCut;
