import {
  addItemsToWebsktop,
  arrangeNewItems,
  Folder,
  Item,
  Point,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetWebsktop } from '../atom';

import useUpdateSelectionSafe from './useUpdateSelectionSafe';

type CloneItems = (items: Item[], targetFolder: Folder, targetPoint?: Point) => void;

const useCloneItems = (websktopId: Websktop['id']): CloneItems => {
  const setWebsktop = useSetWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();
  const updateSelectionSafe = useUpdateSelectionSafe();

  const cloneItems: CloneItems = useCallback(
    async (items, targetFolder, targetPoint) => {
      if (items.length === 0) {
        return;
      }

      const locations = arrangeNewItems(targetFolder, items, targetPoint);
      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop, id) => {
        const optimisticItems = items.map((item) => ({
          ...item,
          parentId: targetFolder.id,
          id,
          x: locations[item.id].x,
          y: locations[item.id].y,
        }));
        return addItemsToWebsktop(websktop, optimisticItems);
      });

      try {
        const response = await api.websktop.cloneItems({
          websktopId,
          itemsIds: items.map((item) => item.id),
          sourceFolderId: items[0].parentId!,
          targetFolderId: targetFolder.id,
          targetPoint,
        });
        updateSelectionSafe(response.items.map((item) => item.id));
        setWebsktop(response.websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, updateSelectionSafe, websktopId],
  );

  return cloneItems;
};

export default useCloneItems;
