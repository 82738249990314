import {
  CloneItemsPayload,
  CreateFolderPayload,
  CreateLinkPayload,
  CreateLinksFromUrlsPayload,
  CreateWebsktopPayload,
  Folder,
  ImportBookmarksPayload,
  InviteUserPayload,
  Item,
  Link,
  MoveItemsPayload,
  UpdateFolderPayload,
  UpdateLinkPayload,
  UpdateWebsktopPayload,
  UpdateWebsktopUsersRolesPayload,
  WebsktopUserRef,
  Websktop,
  CloneItemsResponse,
  CreateFolderResponse,
  CreateLinkResponse,
  CreateLinksFromUrlsResponse,
  ImportBookmarksResponse,
  MoveItemsResponse,
} from '@websktop/commons';

import api from './lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

type CreateFolderParams = CreateFolderPayload & { websktopId: Websktop['id'] };

type CreateLinkParams = CreateLinkPayload & { websktopId: Websktop['id'] };

type PatchFolderParams = UpdateFolderPayload & {
  websktopId: Websktop['id'];
  folderId: Folder['id'];
};

type PatchLinkParams = UpdateLinkPayload & { websktopId: Websktop['id']; linkId: Link['id'] };

type PatchWebsktopParams = UpdateWebsktopPayload & { websktopId: Websktop['id'] };

type DeleteFolderParams = { folderId: Folder['id']; websktopId: Websktop['id'] };

type DeleteItemsParams = {
  itemsIds: Item['id'][];
  websktopId: Websktop['id'];
};

type DeleteLinkParams = { linkId: Link['id']; websktopId: Websktop['id'] };

type DeleteWebsktopParams = { websktopId: Websktop['id'] };

type ImportBookmarksParams = ImportBookmarksPayload<File> & { websktopId: Websktop['id'] };

type CloneItemsParams = CloneItemsPayload & {
  websktopId: Websktop['id'];
  targetFolderId: Folder['id'];
};

type CreateLinksFromUrlsParams = CreateLinksFromUrlsPayload & {
  folderId: Folder['id'];
  websktopId: Websktop['id'];
};

type MoveItemsParams = MoveItemsPayload & {
  websktopId: Websktop['id'];
  targetFolderId: Folder['id'];
};

type UpdateUsersRolesParams = {
  websktopId: Websktop['id'];
  roles: UpdateWebsktopUsersRolesPayload;
};

type InviteUserParams = {
  websktopId: Websktop['id'];
  invitations: InviteUserPayload[];
};

const websktop = {
  async cloneItems({
    websktopId,
    targetFolderId,
    ...payload
  }: CloneItemsParams): Promise<CloneItemsResponse> {
    return api.patchJson<CloneItemsResponse>(
      `${API_ORIGIN}/websktop/${websktopId}/folder/${targetFolderId}/cloneItems`,
      payload,
    );
  },

  async createFolder({
    websktopId,
    ...payload
  }: CreateFolderParams): Promise<CreateFolderResponse> {
    return api.postJson<CreateFolderResponse>(
      `${API_ORIGIN}/websktop/${websktopId}/folder`,
      payload,
    );
  },

  async createLink({ websktopId, ...payload }: CreateLinkParams): Promise<CreateLinkResponse> {
    return api.postJson<CreateLinkResponse>(`${API_ORIGIN}/websktop/${websktopId}/link`, payload);
  },

  async createLinksFromUrls({
    folderId,
    websktopId,
    ...payload
  }: CreateLinksFromUrlsParams): Promise<CreateLinksFromUrlsResponse> {
    return api.postJson<CreateLinksFromUrlsResponse>(
      `${API_ORIGIN}/websktop/${websktopId}/folder/${folderId}/linksFromUrls`,
      payload,
    );
  },

  async createWebsktop(payload: CreateWebsktopPayload): Promise<Websktop> {
    return api.postJson<Websktop>(`${API_ORIGIN}/websktop`, payload);
  },

  async deleteFolder({ folderId, websktopId }: DeleteFolderParams): Promise<Websktop> {
    return api.deleteJson<Websktop>(`${API_ORIGIN}/websktop/${websktopId}/folder/${folderId}`);
  },

  async deleteItems({ websktopId, itemsIds }: DeleteItemsParams): Promise<Websktop> {
    return api.deleteJson<Websktop>(`${API_ORIGIN}/websktop/${websktopId}/items`, {
      body: JSON.stringify(itemsIds),
      headers: {
        Accept: 'application/json',
        'content-type': 'application/json',
      },
    });
  },

  async deleteLink({ linkId, websktopId }: DeleteLinkParams): Promise<Websktop> {
    return api.deleteJson<Websktop>(`${API_ORIGIN}/websktop/${websktopId}/link/${linkId}`);
  },

  async deleteWebsktop({ websktopId }: DeleteWebsktopParams): Promise<Websktop['id']> {
    await api.delete(`${API_ORIGIN}/websktop/${websktopId}`);
    return websktopId;
  },

  async importBookmarks({
    file,
    parentId,
    websktopId,
    x,
    y,
  }: ImportBookmarksParams): Promise<ImportBookmarksResponse> {
    const form = new FormData();
    form.append('file', file);
    if (parentId) {
      form.append('parentId', parentId);
    }
    if (typeof x !== 'undefined') {
      form.append('x', String(x));
    }
    if (typeof y !== 'undefined') {
      form.append('y', String(y));
    }

    return api.postForm<ImportBookmarksResponse>(
      `${API_ORIGIN}/websktop/${websktopId}/import-bookmarks`,
      form,
    );
  },

  async get(id: Websktop['id']): Promise<Websktop> {
    return api.getJson<Websktop>(`${API_ORIGIN}/websktop/${id}`);
  },

  async getUsers(id: Websktop['id']): Promise<WebsktopUserRef[]> {
    return api.getJson<WebsktopUserRef[]>(`${API_ORIGIN}/websktop/${id}/users`);
  },

  async fork(id: Websktop['id']): Promise<Websktop> {
    return api.postJson<Websktop>(`${API_ORIGIN}/websktop/${id}/fork`);
  },

  async inviteUsers({ websktopId, invitations }: InviteUserParams): Promise<WebsktopUserRef[]> {
    return api.postJson<WebsktopUserRef[]>(
      `${API_ORIGIN}/websktop/${websktopId}/invite`,
      invitations,
    );
  },

  async moveItems({
    websktopId,
    targetFolderId,
    ...payload
  }: MoveItemsParams): Promise<MoveItemsResponse> {
    return api.patchJson<MoveItemsResponse>(
      `${API_ORIGIN}/websktop/${websktopId}/folder/${targetFolderId}/moveItems`,
      payload,
    );
  },

  async patchFolder({ websktopId, folderId, ...payload }: PatchFolderParams): Promise<Websktop> {
    return api.patchJson<Websktop>(
      `${API_ORIGIN}/websktop/${websktopId}/folder/${folderId}`,
      payload,
    );
  },

  async patchLink({ websktopId, linkId, ...payload }: PatchLinkParams): Promise<Websktop> {
    return api.patchJson<Websktop>(`${API_ORIGIN}/websktop/${websktopId}/link/${linkId}`, payload);
  },

  async patchWebsktop({ websktopId, ...payload }: PatchWebsktopParams): Promise<Websktop> {
    return api.patchJson<Websktop>(`${API_ORIGIN}/websktop/${websktopId}`, payload);
  },

  async updateUsersRoles({
    websktopId,
    roles,
  }: UpdateUsersRolesParams): Promise<WebsktopUserRef[]> {
    return api.patchJson<WebsktopUserRef[]>(`${API_ORIGIN}/websktop/${websktopId}/roles`, roles);
  },
};

export default websktop;
