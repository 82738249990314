import {
  Folder,
  isAnyItemAncestorOf,
  isFolderPointEmpty,
  Point,
  Websktop,
} from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCopyToClipboard } from 'react-use';

import { ContextMenu, ContextMenuProps, Menu } from 'components';
import { useIsOnline } from 'hooks';
import { getAbsoluteUrl } from 'lib';
import { exportBookmarks } from 'modules/bookmarks-exporter';
import routing from 'modules/routing';
import { useHotkey, WebsktopHotkey } from 'modules/websktop-hotkeys';
import {
  useClipboard,
  useClipboardItems,
  useFolder,
  useFolderCreate,
  usePermissions,
  useSelection,
  useWebsktop,
} from 'state';

import { useImportBookmarksModal } from '../import-bookmarks-modal';
import { useNewLinkModal } from '../new-link-modal';
import { usePersonalizeModal } from '../personalize-modal';
import { useSharingSettingsModal } from '../sharing-settings-modal';

interface Props extends Omit<ContextMenuProps, 'children'> {
  folderId: NonNullable<Folder['id']>;
  gridPoint: Point | null;
  websktopId: Websktop['id'];
}

const HOTKEY_DELAY = 50;

const BackgroundContextMenu: FunctionComponent<Props> = ({
  folderId,
  gridPoint,
  point,
  websktopId,
  onClose,
  ...props
}) => {
  const navigate = useNavigate();
  const isOnline = useIsOnline();
  const [, copy] = useCopyToClipboard();
  const websktop = useWebsktop(websktopId);
  const folder = useFolder(websktopId, folderId);
  const { parentId } = folder;
  const { canEditItems, canEditWebsktop, canViewWebsktop } = usePermissions(websktopId);
  const personalizeModal = usePersonalizeModal();
  const sharingSettingsModal = useSharingSettingsModal();
  const importBookmarksModal = useImportBookmarksModal();
  const newLinkModal = useNewLinkModal();
  const folderCreate = useFolderCreate(websktopId);
  const clipboard = useClipboard(websktopId);
  const disableNew = gridPoint === null || !isFolderPointEmpty(folder, gridPoint);
  const [, { set }] = useSelection();
  const clipboardItems = useClipboardItems();
  const isMovingFolderIntoItself =
    clipboardItems.mode === 'cut' && isAnyItemAncestorOf(clipboardItems.items, folder);
  const areSharingSettingsEnabled = canViewWebsktop && isOnline;
  const isPersonalizeEnabled = canEditWebsktop && isOnline;
  const isItemsCreationEnabled = canEditItems && isOnline;
  const isPasteEnabled = canEditItems && isOnline;

  const handleOpenParentFolder = () => {
    if (!parentId) {
      return;
    }

    navigate(routing.websktop.folder(websktopId, parentId));
    onClose();
  };

  const handlePersonalize = () => {
    personalizeModal.open({ websktopId });
    onClose();
  };

  const handleCopyUrl = () => {
    const websktopUrl = getAbsoluteUrl(routing.websktop.folder(websktopId, folderId));
    copy(websktopUrl);
    onClose();
  };

  const handleCreateFolder = () => {
    if (gridPoint !== null) {
      folderCreate.create({ parentId: folderId, x: gridPoint.x, y: gridPoint.y });
    }
    onClose();
  };

  const handleCreateLink = () => {
    if (gridPoint !== null) {
      newLinkModal.open({ parentId: folderId, point: gridPoint, websktopId });
    }
    onClose();
  };

  const handleImportBookmarks = () => {
    if (gridPoint !== null) {
      importBookmarksModal.open({ parentId: folderId, point: gridPoint, websktopId });
    }
    onClose();
  };

  const handlePaste = () => {
    if (gridPoint !== null) {
      clipboard.paste({ targetFolder: folder, targetPoint: gridPoint });
    }
    onClose();
  };

  const handleSelectAll = () => {
    const itemsIds = folder.items.map((item) => item.id);
    set(itemsIds);
    onClose();
  };

  const handleSharingSettings = () => {
    sharingSettingsModal.open({ websktopId });
    onClose();
  };

  const handleExport = () => {
    exportBookmarks(folder.items);
  };

  const isFolderUpHotkeyPressed = useHotkey('folderUp', handleOpenParentFolder, {
    delay: HOTKEY_DELAY,
    disabled: !parentId,
    options: { capture: true },
    stopPropagation: true,
  });

  const isPasteHotkeyPressed = useHotkey('paste', handlePaste, {
    delay: HOTKEY_DELAY,
    disabled: !clipboard.canPaste,
  });

  const isSelectAllHotkeyPressed = useHotkey('selectAll', handleSelectAll, {
    delay: HOTKEY_DELAY,
    preventDefault: true,
  });

  return (
    <ContextMenu point={point} onClose={onClose} {...props}>
      <Menu>
        {typeof parentId === 'string' && (
          <Menu.ItemsGroup>
            <Menu.Item.Link
              addon={<WebsktopHotkey action="folderUp" />}
              href={routing.websktop.folder(websktopId, parentId)}
              icon="level-up-alt"
              pressed={isFolderUpHotkeyPressed}
              onClick={onClose}
            >
              Open parent folder
            </Menu.Item.Link>
          </Menu.ItemsGroup>
        )}

        {isPasteEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button
              addon={<WebsktopHotkey action="paste" />}
              disabled={!clipboard.canPaste || isMovingFolderIntoItself}
              icon="arrow-down"
              pressed={isPasteHotkeyPressed}
              onClick={handlePaste}
            >
              Paste
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}

        {isItemsCreationEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button disabled={disableNew} icon="plus" onClick={handleCreateLink}>
              New bookmark
            </Menu.Item.Button>

            <Menu.Item.Button disabled={disableNew} icon="folder" onClick={handleCreateFolder}>
              New folder
            </Menu.Item.Button>

            <Menu.Item.Button disabled={disableNew} icon="upload" onClick={handleImportBookmarks}>
              Import browser bookmarks
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}

        {(areSharingSettingsEnabled || websktop.readAccess === 'public') && (
          <Menu.ItemsGroup>
            {websktop.readAccess === 'public' && (
              <Menu.Item.Button icon="clipboard" onClick={handleCopyUrl}>
                Copy sharing link
              </Menu.Item.Button>
            )}

            {areSharingSettingsEnabled && (
              <Menu.Item.Button icon="share-alt" onClick={handleSharingSettings}>
                Sharing settings
              </Menu.Item.Button>
            )}
          </Menu.ItemsGroup>
        )}

        <Menu.ItemsGroup>
          <Menu.Item.Button
            addon={<WebsktopHotkey action="selectAll" />}
            disabled={folder.items.length === 0}
            icon="object-group"
            pressed={isSelectAllHotkeyPressed}
            onClick={handleSelectAll}
          >
            Select all
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          <Menu.Item.Button icon="file-export" onClick={handleExport}>
            Export bookmarks
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        {isPersonalizeEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button icon="image" onClick={handlePersonalize}>
              Personalize
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}
      </Menu>
    </ContextMenu>
  );
};

export default BackgroundContextMenu;
