import { getItemsDeep, Websktop } from '@websktop/commons';
import { intersection } from 'lodash-es';
import { useEffect } from 'react';
import { useDragLayer } from 'react-dnd';

import { useWebsktop } from '../../websktops';

import { useSetSelectionState } from '../state';

const useSyncSelection = (websktopId: Websktop['id']) => {
  const websktop = useWebsktop(websktopId);
  const rootFolder = websktop.folder;
  const setSelectionState = useSetSelectionState();
  const isDragging = useDragLayer((monitor) => monitor.isDragging());

  useEffect(() => {
    if (isDragging) {
      return;
    }

    setSelectionState((current) => {
      const ids = intersection(current, Object.keys(getItemsDeep(rootFolder)));
      return ids.length === current.length ? current : ids;
    });
  }, [isDragging, rootFolder, setSelectionState]);
};

export default useSyncSelection;
