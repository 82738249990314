import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

import { useAppLayout } from 'modules/app-layout';

import Icon from '../../Icon';

import linkStyles from './Link.module.scss';

interface Props extends Omit<LinkProps, 'to'> {
  className?: string;
  href: string;
  icon: IconProp;
}

const Link: FunctionComponent<Props> = ({
  children,
  className,
  href,
  icon,
  onBlur,
  onFocus,
  onMouseOut,
  onMouseOver,
  ...props
}) => {
  const { isSidebarWide } = useAppLayout();

  return (
    <NavLink
      className={({ isActive }) => {
        return classNames(styles.link, className, { [styles.active]: isActive });
      }}
      to={href}
      {...props}
    >
      <Icon className={styles.icon} icon={icon} />

      {isSidebarWide && <div className={styles.name}>{children}</div>}
    </NavLink>
  );
};

export default Link;
export const styles = linkStyles;
