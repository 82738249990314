import { v4 as uuidV4 } from 'uuid';

import { Direction } from 'types';

import { BaseTile } from '../types';

const getPossibleTiles = (tiles: BaseTile[], startingTile: BaseTile, direction: Direction) => {
  if (direction === 'left') {
    return tiles
      .filter((tile) => tile.x < startingTile.x)
      .sort((tile1, tile2) => tile2.y - tile1.y);
  }

  if (direction === 'right') {
    return tiles
      .filter((tile) => tile.x > startingTile.x)
      .sort((tile1, tile2) => tile1.y - tile2.y);
  }

  if (direction === 'up') {
    return tiles
      .filter((tile) => tile.y < startingTile.y)
      .sort((tile1, tile2) => tile2.x - tile1.x);
  }

  if (direction === 'down') {
    return tiles
      .filter((tile) => tile.y > startingTile.y)
      .sort((tile1, tile2) => tile1.x - tile2.x);
  }

  throw new Error(`Unsupported "direction" value: ${direction}`);
};

const getBestTiles = (tiles: BaseTile[], startingTile: BaseTile, direction: Direction) => {
  if (direction === 'left' || direction === 'right') {
    return tiles.filter((tile) => tile.y === startingTile.y);
  }

  if (direction === 'up' || direction === 'down') {
    return tiles.filter((tile) => tile.x === startingTile.x);
  }

  throw new Error(`Unsupported "direction" value: ${direction}`);
};

const getTilesDistance = (tile1: BaseTile, tile2: BaseTile): number => {
  return (tile1.x - tile2.x) ** 2 + (tile1.y - tile2.y) ** 2;
};

const getNextTile = (
  tiles: BaseTile[],
  startingTile: BaseTile,
  direction: Direction,
): BaseTile | undefined => {
  const possibleTiles = getPossibleTiles(tiles, startingTile, direction);
  const bestTiles = getBestTiles(possibleTiles, startingTile, direction);
  const [bestTile] = possibleTiles.sort((tile1, tile2) => {
    const isTile1Best = bestTiles.includes(tile1);
    const isTile2Best = bestTiles.includes(tile2);

    if (isTile1Best && !isTile2Best) {
      return -1;
    }

    if (!isTile1Best && isTile2Best) {
      return 1;
    }

    const distance1 = getTilesDistance(startingTile, tile1);
    const distance2 = getTilesDistance(startingTile, tile2);

    return distance1 - distance2;
  });

  return bestTile;
};

const getNextTileId = (
  tiles: BaseTile[],
  startingTileId: BaseTile['id'] | null,
  direction: Direction,
): BaseTile['id'] | undefined => {
  const startingTile: BaseTile = tiles.find((tile) => tile.id === startingTileId) || {
    id: uuidV4(),
    x: -1,
    y: -1,
  };
  const nextTile = getNextTile(tiles, startingTile, direction);

  return nextTile ? nextTile.id : undefined;
};

export default getNextTileId;
