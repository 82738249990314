import { difference } from 'lodash-es';
import { useEffect, useMemo } from 'react';
import { usePrevious } from 'react-use';

type ClassName = string;
type ClassNames = Record<ClassName, boolean>;

const useBodyClassNames = (classNames: ClassNames): void => {
  const activeClassNames = useMemo(
    () =>
      Object.entries(classNames)
        .filter(([, active]) => active)
        .map(([className]) => className),
    [classNames],
  );
  const previousClassNames = usePrevious(activeClassNames);

  useEffect(() => {
    const classNamesToRemove = difference(previousClassNames, activeClassNames);
    document.body.classList.add(...activeClassNames);
    document.body.classList.remove(...classNamesToRemove);
  }, [activeClassNames, previousClassNames]);
};

export default useBodyClassNames;
