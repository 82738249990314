import { noop } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';

const useTimedToggle = (duration: number): [boolean, () => void] => {
  const [lastOnTimestamp, setLastOnTimestamp] = useState<number>();
  const [isOn, setIsOn] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setLastOnTimestamp(Date.now());
  }, []);

  useEffect(() => {
    if (typeof lastOnTimestamp === 'undefined') {
      return noop;
    }

    setIsOn(true);
    const timeout = setTimeout(() => setIsOn(false), duration);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [duration, lastOnTimestamp]);

  return [isOn, toggle];
};

export default useTimedToggle;
