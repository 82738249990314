import { FunctionComponent } from 'react';

import { Button, Link, Modal } from 'components';
import routing from 'modules/routing';
import { useLogout } from 'state';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const DemoExpiredModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const logout = useLogout();

  const handleClose = async () => {
    await logout();
    onClose();
    window.location.href = routing.index;
  };

  const handleSignUp = async () => {
    await logout();
    onClose();
    window.location.href = routing.auth.signUp.toString();
  };

  return (
    <Modal
      footer={
        <Modal.Footer>
          <Button icon="sign-in-alt" variant="primary" onClick={handleSignUp}>
            Sign up
          </Button>
        </Modal.Footer>
      }
      header={<h1>Demo account expired</h1>}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <p>We hope that you enjoyed using Websktop, and we encourage you to create an account.</p>

      <p>
        In the next step you will be able to choose whether you want to keep your data or start with
        a clean slate.
      </p>

      <p>Not sure yet? You are welcome to try another demo.</p>

      <p>
        Do you have any questions or suggestions? You can always{' '}
        <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
          chat with our support team
        </Link>
        .
      </p>
    </Modal>
  );
};

export default DemoExpiredModal;
