import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import Icon from '../../../Icon';
import RawLink, { type Props as LinkProps } from '../../../Link';
import itemStyles from '../Item.module.scss';

import styles from './Link.module.scss';

interface Props extends LinkProps {
  addon?: ReactNode;
  icon?: IconProp;
  pressed?: boolean;
  variant?: 'danger' | 'default';
}

const Link: FunctionComponent<Props> = ({
  addon,
  children,
  className,
  pressed,
  icon,
  variant = 'default',
  ...props
}) => (
  <RawLink
    className={classNames(itemStyles.item, styles.link, className, {
      [itemStyles.danger]: variant === 'danger',
      [itemStyles.default]: variant === 'default',
      [itemStyles.pressed]: pressed,
      [styles.danger]: variant === 'danger',
      [styles.default]: variant === 'default',
    })}
    {...props}
  >
    {icon && <Icon className={itemStyles.icon} icon={icon} />}
    <div className={itemStyles.content}>{children}</div>
    {addon && <div className={itemStyles.addon}>{addon}</div>}
  </RawLink>
);

export default Link;
