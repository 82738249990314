import { HTMLProps, ReactElement } from 'react';

import { useGrid } from '../../hooks';
import { BaseTile, GridLayoutOptions } from '../../types';

import styles from './Grid.module.scss';

interface RenderTileProps<Tile extends BaseTile> {
  tile: Tile;
}

interface Props<Tile extends BaseTile> extends Omit<HTMLProps<HTMLDivElement>, 'children'> {
  children: (props: RenderTileProps<Tile>) => ReactElement;
  options: GridLayoutOptions;
  tiles: Tile[];
}

const Grid = <Tile extends BaseTile>({
  children: renderTile,
  options,
  tiles,
  ...props
}: Props<Tile>) => {
  const grid = useGrid(tiles, options);

  return (
    <div {...props}>
      {grid.map((row, y) => (
        <div className={styles.row} key={y}>
          {row.map((tile, x) => (
            <div className={styles.cell} key={x}>
              {tile ? renderTile({ tile }) : null}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Grid;
