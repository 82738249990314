import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './StatusBar.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {}

const StatusBar: FunctionComponent<Props> = ({ children, className, ...restProps }) => (
  <div className={classNames(styles.statusBar, className)} {...restProps}>
    {children}
  </div>
);

export default StatusBar;
