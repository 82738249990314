import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { useAppLayout } from 'modules/app-layout';
import { useSetSidebarState } from 'state';

import Icon from '../../Icon';
import { styles as linkStyles } from '../Link';

import styles from './ToggleSidebarButton.module.scss';

interface Props {
  className?: string;
}

const ToggleSidebarButton: FunctionComponent<Props> = ({ className }) => {
  const setSidebarState = useSetSidebarState();
  const { isSidebarWide } = useAppLayout();
  const label = isSidebarWide ? 'Hide sidebar' : 'Show sidebar';

  const handleToggleSidebar = () => {
    setSidebarState(isSidebarWide ? 'narrow' : 'wide');
  };

  return (
    <button
      className={classNames(linkStyles.link, styles.toggleSidebarButton, className)}
      title={label}
      type="button"
      onClick={handleToggleSidebar}
    >
      <Icon className={linkStyles.icon} icon={isSidebarWide ? 'angles-left' : 'angles-right'} />
      {isSidebarWide && <div className={linkStyles.name}>{label}</div>}
    </button>
  );
};

export default ToggleSidebarButton;
