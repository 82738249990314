import type { Icon, IconPayload } from '../types';

const iconToPayload = (icon: Icon): IconPayload => ({
  backgroundColor: icon.backgroundColor,
  emoji: icon.emoji,
  text: icon.text,
  textColor: icon.textColor,
  uploadId: icon.upload ? icon.upload.id : null,
});

export default iconToPayload;
