import classNames from 'classnames';
import { FunctionComponent, useState } from 'react';
import { useKey, useLatest, useMountedState } from 'react-use';

import { Notification } from 'components';
import { isUserTextInputElement } from 'lib';
import { useNotifications, useRemoveNotification } from 'state';

import styles from './Notifications.module.scss';

const REMOVE_TIMEOUT = 100;

const Notifications: FunctionComponent = () => {
  const notifications = useNotifications();
  const [hiddenNotifications, setHiddenNotifications] = useState<string[]>([]);
  const removeNotification = useRemoveNotification();
  const removeNotificationRef = useLatest(removeNotification);
  const isMounted = useMountedState();

  const remove = (notificationId: string) => {
    setHiddenNotifications((current) => [...current, notificationId]);

    setTimeout(() => {
      if (isMounted()) {
        removeNotificationRef.current(notificationId);
        setHiddenNotifications((current) => current.filter((id) => id !== notificationId));
      }
    }, REMOVE_TIMEOUT);
  };

  useKey(
    (event) => event.key === 'Escape' && !isUserTextInputElement(event.target),
    (event) => {
      const notification = notifications[0];

      if (notification) {
        event.preventDefault();
        event.stopPropagation();
        remove(notification.id);
      }
    },
    undefined,
    [isMounted, notifications, remove],
  );

  if (notifications.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.notifications}>
        {notifications.map(({ content, id, title, type }) => {
          const hidden = hiddenNotifications.includes(id);

          return (
            <Notification
              className={classNames(styles.notification, { [styles.hidden]: hidden })}
              content={content}
              key={id}
              title={title}
              type={type}
              onClose={hidden ? undefined : () => remove(id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Notifications;
