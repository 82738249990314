import { TouchEventHandler } from 'react';

import useLongTouch from './useLongTouch';

const useTouchContextMenu = <T>(callback: TouchEventHandler<T>): TouchEventHandler<T> => {
  return useLongTouch<T>((event) => {
    callback(event);

    // Since iOS browsers do not trigger `contextmenu` event,
    // we trigger it manually, to prevent react-dnd dragging.
    // https://bugs.webkit.org/show_bug.cgi?id=213953
    document.dispatchEvent(new CustomEvent('contextmenu'));
  });
};

export default useTouchContextMenu;
