import { isEntity, type default as Entity } from './Entity';
import { isUploadedIcon, type default as UploadedIcon } from './UploadedIcon';

export default interface Icon extends Entity {
  backgroundColor: string;
  emoji: string;
  text: string;
  textColor: string;
  upload: UploadedIcon | null;
}

export const isIcon = (value: unknown): value is Icon => {
  return (
    isEntity(value) &&
    typeof value.backgroundColor === 'string' &&
    typeof value.emoji === 'string' &&
    typeof value.text === 'string' &&
    typeof value.textColor === 'string' &&
    (value.upload === null || isUploadedIcon(value.upload))
  );
};
