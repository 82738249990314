import classNames from 'classnames';
import { ChangeEventHandler, FunctionComponent, MouseEventHandler } from 'react';

import { Icon as IconComponent } from 'components';
import { SearchResult } from 'types';

import Result from '../Result';

import styles from './SearchPanel.module.scss';

interface Props {
  autoFocus?: boolean;
  className?: string;
  query: string;
  results: SearchResult[];
  onQueryChange: (query: string) => void;
  onResultClick?: MouseEventHandler<HTMLAnchorElement>;
}

const SearchPanel: FunctionComponent<Props> = ({
  autoFocus,
  className,
  query,
  results,
  onQueryChange,
  onResultClick,
}) => {
  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onQueryChange(event.target.value);
  };

  return (
    <div className={classNames(styles.searchPanel, className)}>
      <div className={styles.query}>
        <input
          autoFocus={autoFocus}
          className={styles.input}
          placeholder="Search..."
          value={query}
          onChange={handleChange}
        />

        <IconComponent className={styles.inputIcon} icon="search" />
      </div>

      <div className={styles.results}>
        {results.map(({ item, matches }, index) => (
          <Result item={item} key={item.id} matches={matches} onClick={onResultClick} />
        ))}

        {results.length === 0 && query.length > 0 && (
          <p className={styles.message}>Nothing matches your search query</p>
        )}
      </div>
    </div>
  );
};

export default SearchPanel;
