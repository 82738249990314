import { Plan } from '@websktop/commons';
import { FunctionComponent, ReactNode, useEffect } from 'react';
import { useMount, usePrevious } from 'react-use';

import { Button, Features, InfoIcon, Link, Modal } from 'components';
import { trackEvent } from 'modules/analytics';
import { useStartTrial } from 'modules/auth';
import routing from 'modules/routing';
import { useAuthenticatedUser } from 'state';

// https://unsplash.com/photos/Fsgzm8N0hIY
import imageOptimized from './image_optimized.jpg';
import imageWebp1x from './image_1x.webp';
import imageWebp2x from './image_2x.webp';
import imageWebp3x from './image_3x.webp';
import styles from './StartTrialModal.module.scss';

interface Props {
  isOpen: boolean;
  message?: ReactNode;
  source: string;
  onClose: () => void;
}

const StartTrialModal: FunctionComponent<Props> = ({ isOpen, message, source, onClose }) => {
  const user = useAuthenticatedUser();
  const plan = user.subscription.plan;
  const previousPlan = usePrevious(plan);
  const hasUpgradedPlan = previousPlan === Plan.FREE && plan === Plan.PROFESSIONAL;
  const [{ loading: isStartingTrial }, startTrial] = useStartTrial();

  const handleClose = () => {
    onClose();
    trackEvent('start-trial-modal', 'close', source);
  };

  const handleStartTrial = () => {
    trackEvent('start-trial-modal', 'submit', source);
    startTrial();
  };

  useMount(() => {
    trackEvent('start-trial-modal', 'open', source);
  });

  useEffect(() => {
    if (hasUpgradedPlan) {
      onClose();
    }
  }, [hasUpgradedPlan, onClose]);

  return (
    <Modal
      className={styles.modal}
      header={<h1>Start your free trial now</h1>}
      isOpen={isOpen}
      onClose={handleClose}
    >
      {message && (
        <div className={styles.card}>
          <picture>
            <source srcSet={`${imageWebp1x} 1x, ${imageWebp2x} 2x, ${imageWebp3x} 3x`} />
            <img
              alt="Woman using a laptop"
              className={styles.image}
              height={164}
              src={imageOptimized}
              width={182}
            />
          </picture>

          <div>{message}</div>
        </div>
      )}

      <p>You're just 1 click away from starting a Professional Plan trial.</p>

      <Features
        className={styles.features}
        features={[
          <>
            Trial lasts <strong>30 days</strong> and is <strong>100% free</strong>.
            <InfoIcon
              className={styles.infoIcon}
              tooltip={<div className={styles.tooltip}>No credit card required.</div>}
            />
          </>,
          <>
            All Professional Plan features will be available.{' '}
            <Link href={routing.pricing} target="_blank" type="external">
              Learn more
            </Link>
            .
          </>,
          <>
            You can go back to the Free Plan after the trial.
            <InfoIcon
              className={styles.infoIcon}
              tooltip={
                <div className={styles.tooltip}>
                  After the trial is over, we will ask you if you'd like to purchase a subscription
                  or go back to the Free Plan.
                </div>
              }
            />
          </>,
          <>
            Your data from the trial period will not be deleted.
            <InfoIcon
              className={styles.infoIcon}
              tooltip={
                <div className={styles.tooltip}>
                  For example, you can create as many Websktops as you wish - they will still be
                  available to you after the trial is over.
                </div>
              }
            />
          </>,
        ]}
      />

      <div className={styles.actions}>
        <Button className={styles.action} size="large" variant="default" onClick={onClose}>
          No, thanks
        </Button>

        <Button
          className={styles.action}
          loading={isStartingTrial}
          size="large"
          variant="primary"
          onClick={handleStartTrial}
        >
          Start trial
        </Button>
      </div>
    </Modal>
  );
};

export default StartTrialModal;
