import { FunctionComponent } from 'react';

import { Panel } from 'components';

import UpgradePlanPromo from './UpgradePlanPromo';

const UpgradeImageSizePromo: FunctionComponent = () => (
  <UpgradePlanPromo
    message={
      <Panel>
        <h2>Would you like to upload larger images?</h2>
        Professional Plan supports images up to <strong>25 MB</strong>.
      </Panel>
    }
    size="small"
  >
    Would you like to upload larger images?
  </UpgradePlanPromo>
);

export default UpgradeImageSizePromo;
