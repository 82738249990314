import { InviteUserPayload, Websktop, WebsktopUserRef } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useSetWebsktopUsers } from '../atom';

type InviteUsers = (invitations: InviteUserPayload[]) => Promise<WebsktopUserRef[]>;

const useInviteWebsktopUsers = (websktopId: Websktop['id']): InviteUsers => {
  const setWebsktopUsers = useSetWebsktopUsers(websktopId);

  const inviteUsers = useCallback<InviteUsers>(
    async (invitations) => {
      const websktopUserRefs = await api.websktop.inviteUsers({ websktopId, invitations });

      setWebsktopUsers(websktopUserRefs);

      return websktopUserRefs;
    },
    [setWebsktopUsers, websktopId],
  );
  return inviteUsers;
};

export default useInviteWebsktopUsers;
