import queryString from 'query-string';

import isRelativeUrl from './isRelativeUrl';

const patchQueryParams = (url: string, params: Record<string, string | string[]>): string => {
  const isRelative = isRelativeUrl(url);
  const urlObject = new URL(url, document.baseURI);
  const currentParams = queryString.parse(urlObject.search);
  const newParams = { ...currentParams, ...params };
  const finalParams = Object.fromEntries(Object.entries(newParams).filter(([, value]) => value));
  urlObject.search = queryString.stringify(finalParams);

  if (isRelative) {
    return urlObject.href.substring(urlObject.origin.length);
  }

  return urlObject.href;
};

export default patchQueryParams;
