import { Folder, Id, isFolder } from '../types';

const removeItemFromFolder = (folder: Folder, itemId: Id): Folder => {
  const itemIndex = folder.items.findIndex((item) => item.id === itemId);

  if (itemIndex === -1) {
    return {
      ...folder,
      items: folder.items.map((item) => {
        return isFolder(item) ? removeItemFromFolder(item, itemId) : item;
      }),
    };
  }

  return {
    ...folder,
    items: [...folder.items.slice(0, itemIndex), ...folder.items.slice(itemIndex + 1)],
  };
};

export default removeItemFromFolder;
