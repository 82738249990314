import { Entity, UserSettings } from '@websktop/commons';
import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

import api from 'api';
import { hasSessionCookie } from 'modules/auth';

import { fetchOrReadCache, persistEffect } from '../lib';
import { applyOptimisticUpdates, optimisticUpdatesAtom } from '../optimistic-updates';

import { DEFAULT_SETTINGS, USER_SETTINGS_ENTITY_ID } from './constants';

const LOCAL_STORAGE_KEY = 'user-settings';

const userSettingsAtom = atom<UserSettings>({
  key: 'user-settings',
  default: fetchOrReadCache(LOCAL_STORAGE_KEY, () => {
    return hasSessionCookie() || process.env.REACT_APP_TARGET === 'extension'
      ? api.user.getSettings().catch(() => DEFAULT_SETTINGS)
      : DEFAULT_SETTINGS;
  }),
  effects: [persistEffect(LOCAL_STORAGE_KEY)],
});

const userSettingsEntitySelector = selector<UserSettings & Entity>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'entity-user-settings',
  get: ({ get }) => {
    const userSettings = get(userSettingsAtom);

    return { ...userSettings, id: USER_SETTINGS_ENTITY_ID };
  },
});

const optimisticUserSettingsSelector = selector<UserSettings>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'optimistic-user-settings',
  get: ({ get }) => {
    const userSettings = get(userSettingsEntitySelector);

    const optimisticUpdates = get(optimisticUpdatesAtom);
    return applyOptimisticUpdates(userSettings, optimisticUpdates);
  },
});

export const useUserSettings = () => useRecoilValue(optimisticUserSettingsSelector);

export const useSetUserSettings = () => useSetRecoilState(userSettingsAtom);
