import { usePrevious } from 'react-use';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useAllItemsLoadable } from 'state';

import { DEFAULT_SEARCH_CONFIG } from '../constants';
import { safeSearchResultsSelector, searchQueryAtom } from '../state';
import type { SearchConfig, SearchState, SetQuery } from '../types';

import useSearchWorkers from './useSearchWorkers';

const useSearch = (config: SearchConfig = DEFAULT_SEARCH_CONFIG): [SearchState, SetQuery] => {
  const [query, setQuery] = useRecoilState(searchQueryAtom);
  const results = useRecoilValue(safeSearchResultsSelector);
  const itemsLoadable = useAllItemsLoadable();
  const wasLoaded = usePrevious(itemsLoadable.state === 'hasValue');
  const isLoaded = wasLoaded || itemsLoadable.state === 'hasValue';

  useSearchWorkers(config);

  return [{ isLoaded, query, results }, setQuery];
};

export default useSearch;
