import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const sharingSettingsModalAtom = createModalAtom<State>('sharing-settings-modal');

export const useSharingSettingsModal = () => useModal(sharingSettingsModalAtom);
