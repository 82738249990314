import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

import Icon from '../Icon';
import Loader from '../Loader';

import styles from './Button.module.scss';

export interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'size'> {
  icon?: IconProp;
  inline?: boolean;
  loading?: boolean;
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit';
  variant?: 'danger' | 'default' | 'primary';
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      disabled,
      icon,
      inline,
      loading,
      size = 'medium',
      type = 'button',
      variant = 'default',
      ...props
    },
    ref,
  ) => (
    <button
      className={classNames(styles.button, className, {
        [styles.compact]: !children,
        [styles.danger]: variant === 'danger',
        [styles.default]: variant === 'default',
        [styles.inline]: inline,
        [styles.large]: size === 'large',
        [styles.primary]: variant === 'primary',
        [styles.small]: size === 'small',
      })}
      disabled={loading || disabled}
      ref={ref}
      type={type}
      {...props}
    >
      {icon && <Icon className={styles.icon} icon={icon} />}

      {children && <span className={styles.label}>{children}</span>}

      {loading && (
        <div className={styles.loader}>
          <Loader />
        </div>
      )}
    </button>
  ),
);

export default Button;

export { styles };
