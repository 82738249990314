import { Folder, isFolder } from '../types';

const getFoldersMapDeep = (folder: Folder): Record<Folder['id'], Folder> => {
  const folders: Record<Folder['id'], Folder> = {
    [folder.id]: folder,
  };

  for (const item of folder.items) {
    if (isFolder(item)) {
      Object.assign(folders, getFoldersMapDeep(item));
    }
  }

  return folders;
};

export default getFoldersMapDeep;
