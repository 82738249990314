import { Entity } from '@websktop/commons';
import { useCallback, useMemo } from 'react';
import { useLatest } from 'react-use';
import { v4 as uuidV4 } from 'uuid';

import { useOptimisticUpdatesState } from './state';
import { OptimisticUpdate, OptimisticUpdates, Update } from './types';

const useOptimisticUpdates = (): OptimisticUpdates => {
  const [optimisticUpdates, setOptimisticUpdates] = useOptimisticUpdatesState();
  const optimisticUpdatesRef = useLatest(optimisticUpdates);

  const create = useCallback(
    <E extends Entity>(entityId: E['id'], update: Update<E>) => {
      const optimisticUpdate: OptimisticUpdate<E> = { entityId, id: uuidV4(), update };
      setOptimisticUpdates((optimisticUpdates) => [...optimisticUpdates, optimisticUpdate as any]);
      return optimisticUpdate;
    },
    [setOptimisticUpdates],
  );

  const dispose: OptimisticUpdates['dispose'] = useCallback(
    (ids) => {
      const idsArray = Array.isArray(ids) ? ids : [ids];
      setOptimisticUpdates((current) => current.filter(({ id }) => !idsArray.includes(id)));
    },
    [setOptimisticUpdates],
  );

  const has: OptimisticUpdates['has'] = useCallback(
    (id) => optimisticUpdatesRef.current.some((optimisticUpdate) => optimisticUpdate.id === id),
    [optimisticUpdatesRef],
  );

  const api = useMemo(() => ({ create, dispose, has }), [create, dispose, has]);

  return api;
};

export default useOptimisticUpdates;
