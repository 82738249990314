export const SOLID_COLORS = [
  '#41526c',
  '#ff8826',
  '#f11230',
  '#d8363f',
  '#c90555',
  '#c51277',
  '#9d1787',
  '#8a2695',
  '#7352a6',
  '#008742',
  '#007a1c',
  '#008473',
  '#197d97',
  '#0066ae',
  '#656dd3',
  '#8a8dd5',
  '#8667b5',
  '#008285',
  '#466860',
  '#525e55',
  '#7e7260',
  '#4d4b4a',
  '#505d6a',
  '#4a5559',
];
