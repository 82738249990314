import { Folder, removeItemsFromWebsktop, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSelection } from '../../selection';

import { useSetWebsktop } from '../atom';

type DeleteFolder = (folderId: Folder['id']) => Promise<void>;

const useDeleteFolder = (websktopId: Websktop['id']): DeleteFolder => {
  const setWebsktop = useSetWebsktop(websktopId);
  const [selection, { toggle }] = useSelection();
  const optimisticUpdates = useOptimisticUpdates();

  const deleteFolder: DeleteFolder = useCallback(
    async (folderId: Folder['id']) => {
      const isSelected = selection.includes(folderId);

      if (isSelected) {
        toggle([folderId]);
      }

      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop) => {
        return removeItemsFromWebsktop(websktop, [folderId]);
      });

      try {
        const websktop = await api.websktop.deleteFolder({ websktopId, folderId });
        setWebsktop(websktop);
      } catch (error) {
        if (isSelected) {
          toggle([folderId]);
        }

        throw error;
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, selection, setWebsktop, toggle, websktopId],
  );

  return deleteFolder;
};

export default useDeleteFolder;
