import { BackgroundImage } from '@websktop/commons';
import { CSSProperties } from 'react';

interface Props {
  background?: BackgroundImage | null;
  backgroundColor: CSSProperties['backgroundColor'];
  textColor: CSSProperties['color'];
  textShadowColor: CSSProperties['color'];
}

const getBackgroundStyle = ({
  background,
  backgroundColor,
  textColor,
  textShadowColor,
}: Props): CSSProperties | undefined => {
  const style: CSSProperties = {
    backgroundColor,
    color: textColor,
    textShadow: `1px 1px 1px ${textShadowColor}`,
  };

  if (!background) {
    return style;
  }

  const url = new URL(background.url, process.env.REACT_APP_URL).toString();
  style.backgroundImage = `url(${url})`;

  if (background.type === 'repeat') {
    style.backgroundRepeat = 'repeat';
  }

  if (background.type === 'cover') {
    style.backgroundSize = 'cover';
    style.backgroundRepeat = 'no-repeat';
    style.backgroundPosition = 'center center';
  }

  if (background.type === 'contain') {
    style.backgroundSize = 'contain';
    style.backgroundRepeat = 'no-repeat';
    style.backgroundPosition = 'center center';
  }

  return style;
};

export default getBackgroundStyle;
