import { HTMLProps } from 'react';

type AnchorProps = HTMLProps<HTMLAnchorElement>;

const openLink = (href: string, options: Pick<AnchorProps, 'rel' | 'target'>): void => {
  const link = Object.assign(document.createElement('a'), { href }, options);
  const event = new MouseEvent('click', { view: window, bubbles: true, cancelable: true });
  link.dispatchEvent(event);
};

export default openLink;
