import queryString from 'query-string';

const getQueryParams = <Params extends string>(
  url = document.URL,
): Record<Params, string | string[]> => {
  const urlObject = new URL(url, document.baseURI);
  const params = queryString.parse(urlObject.search);
  const finalParams = Object.fromEntries(Object.entries(params).filter(([, value]) => value));
  return finalParams as Record<Params, string | string[]>;
};

export default getQueryParams;
