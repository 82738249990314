import { atom, useRecoilState, useSetRecoilState } from 'recoil';

import { State } from './types';

export const INITIAL_STATE: State = [];

export const selectionStateAtom = atom<State>({ key: 'selection', default: INITIAL_STATE });

export const useSelectionState = () => useRecoilState(selectionStateAtom);

export const useSetSelectionState = () => useSetRecoilState(selectionStateAtom);
