import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import classNames from 'classnames';
import { ChangeEvent, FunctionComponent, ReactNode, useId, useState } from 'react';

import { FILE_INPUT_ACCEPT } from 'constants/images';
import { EntityIcon } from 'types';

import Icon from '../Icon';
import LabeledInput, { Props as LabeledInputProps } from '../LabeledInput';
import Popup from '../Popup';

import styles from './IconInput.module.scss';

interface Props extends Pick<LabeledInputProps, 'error' | 'id' | 'label' | 'required'> {
  children: ReactNode;
  className?: string;
  onChange: (icon: EntityIcon) => void;
}

const IconInput: FunctionComponent<Props> = ({
  children,
  className,
  error,
  id,
  label,
  required,
  onChange,
}) => {
  const emojiPickerId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);

  const handleClick = () => {
    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsEmojiOpen(false);
  };

  const handleDefaultClick = () => {
    onChange(null);
    handleClose();
  };

  const handleEmojiClick = () => {
    setIsEmojiOpen(true);
  };

  const handleUploadClick = () => {
    handleClose();
  };

  const handleEmojiSelect = ({ id }: { id: string }) => {
    onChange(id);
    handleClose();
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files && files.length > 0 ? files[0] : null;
    onChange(file);
    handleClose();
  };

  return (
    <LabeledInput error={error} id={id} label={label} required={required}>
      <button
        className={classNames(styles.iconInput, className)}
        ref={setReferenceElement}
        type="button"
        onClick={handleClick}
      >
        {children}
      </button>

      <Popup
        className={styles.popup}
        isOpen={isOpen && !isEmojiOpen}
        referenceElement={referenceElement}
        onClose={handleClose}
      >
        <button className={styles.option} onClick={handleDefaultClick}>
          <Icon className={classNames(styles.icon, styles.default)} icon="times" />
          <span className={styles.label}>Default</span>
        </button>

        <button className={styles.option} onClick={handleEmojiClick}>
          <div className={classNames(styles.icon, styles.emoji)}>
            <em-emoji id="blush" size="3ex" />
          </div>
          <span className={styles.label}>Emoji</span>
        </button>

        <label className={styles.option} onClick={handleUploadClick}>
          <Icon className={classNames(styles.icon, styles.upload)} icon="upload" />
          <span className={styles.label}>Upload</span>

          <input
            accept={FILE_INPUT_ACCEPT}
            className={styles.fileInput}
            type="file"
            onChange={handleFileChange}
            /**
             * Prevents closing the Popup before file picker dialog is opened.
             * Popup will be closed after file picker dialog is successfully submitted.
             * Popup will be kept open if file picker dialog is cancelled.
             */
            onClick={(event) => event.stopPropagation()}
          />
        </label>
      </Popup>

      <Popup
        className={styles.emojiPicker}
        isOpen={isEmojiOpen}
        referenceElement={referenceElement}
        onClose={handleClose}
      >
        <Picker
          autoFocus
          data={data}
          id={emojiPickerId}
          navPosition="bottom"
          onEmojiSelect={handleEmojiSelect}
        />
      </Popup>
    </LabeledInput>
  );
};

export default IconInput;
