import { ReactNode } from 'react';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  message?: ReactNode;
  source: string;
}

const startTrialModalAtom = createModalAtom<State>('start-trial-modal');

export const useStartTrialModal = () => useModal<State>(startTrialModalAtom);
