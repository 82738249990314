import { Folder, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useFolder, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { folderId: Folder['id']; websktopId: Websktop['id'] };

const useSelectAll: UseWebsktopHotkey<Params> = ({ folderId, websktopId }, isEnabled) => {
  const folder = useFolder(websktopId, folderId);
  const [, { set }] = useSelection();

  const handleSelectAll = useCallback(() => {
    const itemsIds = folder.items.map((item) => item.id);
    set(itemsIds);
  }, [folder.items, set]);

  useHotkey('selectAll', handleSelectAll, {
    disabled: !isEnabled || folder.items.length === 0,
    preventDefault: isEnabled,
  });
};

export default useSelectAll;
