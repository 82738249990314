import { FunctionComponent } from 'react';

import { useSearchModal } from 'modals';

import StatusBarButton from '../StatusBarButton';

interface Props {}

const SearchButton: FunctionComponent<Props> = () => {
  const searchModal = useSearchModal();

  const handleClick = () => {
    searchModal.open();
  };

  return <StatusBarButton icon="search" onClick={handleClick} />;
};

export default SearchButton;
