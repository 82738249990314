import { useEffect } from 'react';

import trackPageView from './trackPageView';

interface Config {
  shouldTrack: boolean;
}

const DEFAULT_CONFIG: Config = {
  shouldTrack: true,
};

const useTrackPageView = (title: string, { shouldTrack }: Config = DEFAULT_CONFIG) => {
  useEffect(() => {
    if (!shouldTrack) {
      return;
    }

    trackPageView(title);
  }, [shouldTrack, title]);
};

export default useTrackPageView;
