import { useCallback } from 'react';
import { useMountedState } from 'react-use';

import { useShowErrorNotification } from 'state';

type AsyncFunction<T> = () => Promise<T>;

const useUnmountedErrorHandler = <T>() => {
  const isMounted = useMountedState();
  const showErrorNotification = useShowErrorNotification();

  const unmountedErrorHandler = useCallback(
    async (fn: AsyncFunction<T>) => {
      try {
        return await fn();
      } catch (error) {
        if (!isMounted()) {
          showErrorNotification(error);
        }

        throw error;
      }
    },
    [isMounted, showErrorNotification],
  );

  return unmountedErrorHandler;
};

export default useUnmountedErrorHandler;
