import { useCallback } from 'react';

import api from 'api';

const useResetPassword = (): typeof api.auth.resetPassword => {
  const resetPassword: typeof api.auth.resetPassword = useCallback(async (payload) => {
    await api.auth.resetPassword(payload);
  }, []);

  return resetPassword;
};

export default useResetPassword;
