import { FunctionComponent, lazy } from 'react';

import { useIsAuthenticated } from 'state';

import AuthenticatedModalsEager from './AuthenticatedModals';
import UnauthenticatedModalsEager from './UnauthenticatedModals';

const AuthenticatedModalsLazy = lazy(() => import('./AuthenticatedModals'));
const UnauthenticatedModalsLazy = lazy(() => import('./UnauthenticatedModals'));

const AuthenticatedModals =
  process.env.REACT_APP_TARGET === 'extension' ? AuthenticatedModalsEager : AuthenticatedModalsLazy;

const UnauthenticatedModals =
  process.env.REACT_APP_TARGET === 'extension'
    ? UnauthenticatedModalsEager
    : UnauthenticatedModalsLazy;

const Modals: FunctionComponent = () => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return <UnauthenticatedModals />;
  }

  return <AuthenticatedModals />;
};

export default Modals;
