import { Entity, PatchUserSettingsPayload, UserSettings } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetUserSettings } from '../atom';
import { USER_SETTINGS_ENTITY_ID } from '../constants';

type UpdateUserSettings = (payload: PatchUserSettingsPayload) => Promise<void>;

const useUpdateUserSettings = (): UpdateUserSettings => {
  const setUserSettings = useSetUserSettings();
  const optimisticUpdates = useOptimisticUpdates();

  const updateUserSettings = useCallback(
    async (payload: PatchUserSettingsPayload) => {
      const optimisticUpdate = optimisticUpdates.create<Entity & UserSettings>(
        USER_SETTINGS_ENTITY_ID,
        (userSettings) => ({ ...userSettings, ...payload }),
      );

      try {
        const userSettings = await api.user.patchSettings(payload);
        setUserSettings(userSettings);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setUserSettings],
  );

  return updateUserSettings;
};

export default useUpdateUserSettings;
