import { Folder, isFolder, Item } from '../types';

const findItem = (folder: Folder, itemId: Folder['id']): Item | undefined => {
  if (folder.id === itemId) {
    return folder;
  }

  for (const item of folder.items) {
    if (isFolder(item)) {
      const subItem = findItem(item, itemId);

      if (subItem) {
        return subItem;
      }
    }

    if (item.id === itemId) {
      return item;
    }
  }

  return undefined;
};

export default findItem;
