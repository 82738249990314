import classNames from 'classnames';
import { FunctionComponent, ReactNode, useEffect } from 'react';

import { Panel } from 'components';
import { trackEvent } from 'modules/analytics';

import UpgradePlanButton from '../UpgradePlanButton';

import styles from './UpgradePlanPromo.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
  message?: ReactNode;
  size?: 'small' | 'medium';
}

const UpgradePlanPromo: FunctionComponent<Props> = ({ children, className, message, size }) => {
  useEffect(() => {
    trackEvent('upgrade-plan-promo', 'show');
  }, []);

  return (
    <Panel className={classNames(styles.upgradePlanPromo, className)} variant="info">
      <div className={styles.content}>{children}</div>

      <div className={styles.buttonContainer}>
        <UpgradePlanButton
          className={styles.button}
          message={message}
          size={size}
          source="upgrade plan promo - upgrade plan button"
        >
          Try Professional Plan
        </UpgradePlanButton>
      </div>
    </Panel>
  );
};
export default UpgradePlanPromo;
