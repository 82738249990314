import useMergedRef from '@react-hook/merged-ref';
import { isFolder, isLink, Item, Point, Websktop as WebsktopType } from '@websktop/commons';
import { FocusEvent, FunctionComponent, memo, MouseEvent, TouchEvent, useCallback } from 'react';
import { DragPreviewImage, useDrag, useDragLayer, useDrop } from 'react-dnd';

import { TRANSPARENT_IMAGE_SRC } from 'constants/images';
import { DND_WEBSKTOP_ITEM } from 'constants/websktop';
import { useTouchContextMenu } from 'hooks';
import { isCtrlKey, isUserTextInputElement } from 'lib';
import { useFolderContextMenu, useItemsContextMenu, useLinkContextMenu } from 'modals';
import { WebsktopIcon } from 'modules/grid-layout';
import routing from 'modules/routing';
import {
  useFolderCreate,
  useIsItemCut,
  useIsSearchActive,
  useItemRename,
  useMoveItems,
  useOptimisticUpdates,
  useSearchResultsIdsSet,
  useShowErrorNotification,
  useUserSettings,
} from 'state';

import { createFolderClickHandler, TouchEventManager } from '../../lib';
import ItemIcon from '../ItemIcon';
import { ItemsSelection } from '../../hooks';

interface Props {
  isDragEnabled: boolean;
  isDropEnabled?: boolean;
  isMultipleSelection: boolean;
  isSelected: boolean;
  item: Item;
  selectionRef: {
    current: ItemsSelection;
  };
  websktopId: WebsktopType['id'];
}

const WebsktopItem: FunctionComponent<Props> = ({
  isDragEnabled,
  isDropEnabled,
  isMultipleSelection,
  isSelected,
  item,
  selectionRef,
  websktopId,
}) => {
  const searchResultsIdsSet = useSearchResultsIdsSet();
  const optimisticIds = useOptimisticUpdates();
  const userSettings = useUserSettings();
  const itemRename = useItemRename(websktopId);
  const moveItems = useMoveItems(websktopId);
  const folderContextMenu = useFolderContextMenu();
  const linkContextMenu = useLinkContextMenu();
  const itemsContextMenu = useItemsContextMenu();
  const showErrorNotification = useShowErrorNotification();
  const folderCreate = useFolderCreate(websktopId);
  const isNewFolder = item.id === folderCreate.folder?.id;
  const isRenaming = itemRename.item?.id === item.id;
  const isEditing = isNewFolder || isRenaming;
  const isOptimisticItem = optimisticIds.has(item.id);
  const isDragging = useDragLayer((monitor) => monitor.isDragging());
  const isDisabled = isOptimisticItem;
  const isCut = useIsItemCut(item.id);
  const isSearchActive = useIsSearchActive();
  const isInSearchResults = isSearchActive && searchResultsIdsSet.has(item.id);
  const [, dragRef, preview] = useDrag(
    () => ({
      canDrag: isDragEnabled,
      item,
      type: DND_WEBSKTOP_ITEM,
    }),
    [isDragEnabled, item],
  );

  const [{ canDrop, isOver }, dropRef] = useDrop(
    () => ({
      accept: isDropEnabled ? DND_WEBSKTOP_ITEM : [],

      canDrop: () => isFolder(item) && !selectionRef.current.ids.includes(item.id),

      collect: (monitor) => ({
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver(),
      }),

      drop: async () => {
        if (!isFolder(item)) {
          return;
        }

        const selection = selectionRef.current;
        const items = selection.items;

        try {
          await moveItems({ items, targetFolder: item });
          selection.reset();
        } catch (error) {
          showErrorNotification(error);
          throw error;
        }
      },
    }),
    [isDropEnabled, item, moveItems, selectionRef, showErrorNotification],
  );

  const ref = useMergedRef(dragRef, dropRef);

  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (isDisabled || event.ctrlKey || event.metaKey) {
      event.preventDefault();
      return;
    }

    if (isFolder(item)) {
      createFolderClickHandler(item)(event);
    }
  };

  const showContextMenu = useCallback(
    (point: Point) => {
      const selection = selectionRef.current;

      if (isDisabled) {
        return;
      }

      if (selection.ids.length > 1 && selection.ids.includes(item.id)) {
        itemsContextMenu.open({ itemsIds: selection.ids, point, websktopId });
        return;
      }

      if (!selection.ids.includes(item.id)) {
        selection.set([item.id]);
      }

      if (isFolder(item)) {
        folderContextMenu.open({ folderId: item.id, point, websktopId });
      } else {
        linkContextMenu.open({ linkId: item.id, point, websktopId });
      }
    },
    [
      folderContextMenu,
      isDisabled,
      item,
      itemsContextMenu,
      linkContextMenu,
      selectionRef,
      websktopId,
    ],
  );

  const onContextMenu = useCallback(
    (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      showContextMenu({ x: event.clientX, y: event.clientY });
    },
    [showContextMenu],
  );

  const handleTouchContextMenu = useTouchContextMenu((event) => {
    const point = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    showContextMenu(point);
  });

  const handleTouchStart = useCallback(
    (event: TouchEvent<HTMLAnchorElement>) => {
      TouchEventManager.markHandled(event);
      selectionRef.current.set([item.id]);
      handleTouchContextMenu(event);
    },
    [handleTouchContextMenu, item.id, selectionRef],
  );

  const handleFocus = (event: FocusEvent) => {
    if (!isUserTextInputElement(event.target)) {
      event.stopPropagation();
    }

    event.preventDefault();
  };

  const handleMouseDown = (event: MouseEvent<HTMLAnchorElement>) => {
    const selection = selectionRef.current;

    event.stopPropagation();

    if (isDisabled) {
      event.preventDefault();
      return;
    }

    if (event.ctrlKey || event.metaKey) {
      selection.toggle([item.id]);
      return;
    }

    if (!selection.ids.includes(item.id)) {
      // It should happen after dragging is started.
      // See https://github.com/realizee/websktop/pull/282#discussion_r810452265
      setTimeout(selection.set, 0, [item.id]);
    }

    if (isFolder(item)) {
      createFolderClickHandler(item)(event);
    }
  };

  const handleMouseEnter = (event: MouseEvent) => {
    const selection = selectionRef.current;

    if (isDisabled || isEditing || isDragging || selection.ids.length > 1 || isCtrlKey(event)) {
      return;
    }

    selection.set([item.id]);
  };

  const getName = () => {
    if (isOptimisticItem && !item.name) {
      return 'Creating...';
    }

    return isEditing && itemRename.isEditing ? itemRename.name : item.name;
  };

  return (
    <>
      <WebsktopIcon
        data-id={item.id}
        image={<ItemIcon canDrop={isOver && canDrop} item={item} />}
        isCut={isCut}
        isDisabled={isOptimisticItem}
        isEditing={isEditing}
        isMultipleSelection={isMultipleSelection}
        isNotInSearchResults={isSearchActive && !isInSearchResults}
        isSelected={isSelected}
        key={item.id}
        name={getName()}
        // Even though `canDrag` is false, react-dnd sets draggable=true on referenced element
        ref={isDragEnabled || isDropEnabled ? ref : undefined}
        tabIndex={-1}
        target={isLink(item) && userSettings.forceNewTab ? '_blank' : undefined}
        type={isFolder(item) ? 'internal' : 'external'}
        url={isFolder(item) ? routing.websktop.folder(websktopId, item.id) : item.url}
        onClick={handleClick}
        onContextMenu={onContextMenu}
        onFocus={handleFocus}
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onNameCancel={isNewFolder ? folderCreate.cancel : itemRename.cancel}
        onNameChange={isNewFolder ? folderCreate.setName : itemRename.setName}
        onNameSubmit={isNewFolder ? folderCreate.submit : itemRename.submit}
        onTouchStart={handleTouchStart}
      />

      <DragPreviewImage connect={preview} src={TRANSPARENT_IMAGE_SRC} />
    </>
  );
};

export default memo(WebsktopItem);
