import { Item } from '@websktop/commons';
import { uniq } from 'lodash-es';
import { useCallback } from 'react';
import { SetterOrUpdater } from 'recoil';

import { INITIAL_STATE, useSelectionState } from './state';
import { State } from './types';

interface Actions {
  add: (itemsIds: Item['id'][]) => void;
  reset: () => void;
  set: SetterOrUpdater<State>;
  toggle: (itemsIds: Item['id'][]) => void;
}

const useSelection = (): [State, Actions] => {
  const [state, setState] = useSelectionState();

  const add: Actions['add'] = useCallback(
    (itemsIds) => {
      setState((current) => uniq([...current, ...itemsIds]));
    },
    [setState],
  );

  const reset: Actions['reset'] = useCallback(() => setState(INITIAL_STATE), [setState]);

  const toggle: Actions['toggle'] = useCallback(
    (itemsIds) => {
      setState((current) => {
        return itemsIds.reduce((result, itemId) => {
          const index = result.indexOf(itemId);

          if (index === -1) {
            return [...result, itemId];
          }

          return [...result.slice(0, index), ...result.slice(index + 1)];
        }, current);
      });
    },
    [setState],
  );

  return [state, { add, reset, set: setState, toggle }];
};

export default useSelection;
