import { Folder, isLink } from '@websktop/commons';
import { MouseEventHandler } from 'react';

import { createMultiLinkHandler } from 'lib';

const createFolderClickHandler = (folder: Folder): MouseEventHandler<HTMLAnchorElement> => {
  const links = folder.items
    .map((item) => (isLink(item) ? item.url : null))
    .filter(Boolean) as string[];

  return createMultiLinkHandler(links, { target: '_blank', rel: 'noopener noreferrer' });
};

export default createFolderClickHandler;
