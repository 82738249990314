import { FunctionComponent } from 'react';

import { useTooltip } from 'components';
import { useRunTour } from 'state';

import StatusBarButton from '../StatusBarButton';

interface Props {
  className?: string;
}

const HelpButton: FunctionComponent<Props> = ({ className }) => {
  const runTour = useRunTour();
  const tooltipProps = useTooltip('Take a tour again', { placement: 'bottom' });

  const handleHelp = () => {
    runTour();
  };

  return <StatusBarButton onClick={handleHelp} icon="question" {...tooltipProps} />;
};

export default HelpButton;
