import { ReactNode, useCallback, useMemo } from 'react';

import { Panel } from 'components';
import { canStartTrial } from 'modules/auth';
import { useAuthenticatedUser } from 'state';

import { useStartTrialModal } from '../start-trial-modal';
import { useUpgradePlanModal } from '../upgrade-plan-modal';

interface OpenParameters {
  message: ReactNode;
  source: string;
}

const useUpgradeModal = () => {
  const user = useAuthenticatedUser();
  const { open: openStartTrialModal } = useStartTrialModal();
  const { open: openUpgradePlanModal } = useUpgradePlanModal();

  const open = useCallback(
    ({ message, source }: OpenParameters) => {
      if (canStartTrial(user)) {
        openStartTrialModal({ message, source });
      } else {
        openUpgradePlanModal({ message: <Panel>{message}</Panel> });
      }
    },
    [openStartTrialModal, openUpgradePlanModal, user],
  );

  const upgradeModal = useMemo(() => ({ open }), [open]);

  return upgradeModal;
};

export default useUpgradeModal;
