import { useEffect } from 'react';

import { useUser } from 'state';

import setUserId from './setUserId';

const usePiwik = () => {
  const user = useUser();
  const userId = user?.id;

  useEffect(() => {
    if (userId) {
      setUserId(userId);
    }
  }, [userId]);
};

export default usePiwik;
