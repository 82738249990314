import { UserRole } from '@websktop/commons';
import { noop } from 'lodash-es';
import { FunctionComponent, MouseEventHandler, useState } from 'react';

import { DropdownButton, Menu } from 'components';

import { getRoleDescription, getRoleIcon, getRoleLabel } from './lib';
import styles from './RolesDropdown.module.scss';

interface Props {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  showNoneRole?: boolean;
  value: UserRole;
  onChange: (value: UserRole) => void;
  onClick?: MouseEventHandler;
}

const ROLES = [UserRole.VIEWER, UserRole.EDITOR, UserRole.ADMIN];

const RolesDropdown: FunctionComponent<Props> = ({
  autoFocus,
  className,
  disabled,
  showNoneRole,
  value,
  onChange,
  onClick = noop,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (newValue: UserRole) => {
    setIsOpen(false);
    onChange(newValue);
  };

  const handleClick: MouseEventHandler = (event) => {
    onClick(event);

    if (!event.defaultPrevented) {
      setIsOpen(() => !isOpen);
    }
  };

  return (
    <DropdownButton
      autoFocus={autoFocus}
      className={className}
      disabled={disabled}
      icon={getRoleIcon(value)}
      isOpen={isOpen}
      label={<div className={styles.label}>{getRoleLabel(value)}</div>}
      size="small"
      title={getRoleDescription(value)}
      onClick={handleClick}
      onClose={() => setIsOpen(false)}
    >
      <Menu>
        <Menu.ItemsGroup>
          {ROLES.map((role) => (
            <Menu.Item.Button
              icon={getRoleIcon(role)}
              key={role}
              onClick={() => handleChange(role)}
            >
              <div>
                <strong>{getRoleLabel(role)}</strong>
              </div>
              <div>{getRoleDescription(role)}</div>
            </Menu.Item.Button>
          ))}
        </Menu.ItemsGroup>

        {showNoneRole && (
          <Menu.ItemsGroup>
            <Menu.Item.Button
              icon={getRoleIcon(UserRole.NONE)}
              variant="danger"
              onClick={() => handleChange(UserRole.NONE)}
            >
              <div>
                <strong>Remove access</strong>
              </div>
              <div>User won't have any dedicated permissions</div>
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}
      </Menu>
    </DropdownButton>
  );
};

export default RolesDropdown;
