import classNames from 'classnames';
import {
  FunctionComponent,
  ImgHTMLAttributes,
  ReactEventHandler,
  useEffect,
  useState,
} from 'react';

import styles from './Image.module.scss';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string;
}

const FALLBACK_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzg0IDUxMiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aAogICAgZmlsbD0iIzQ0NDQ0NCIKICAgIHN0cm9rZT0icmdiYSgyNTUsIDI1NSwgMjU1LCAwLjcpIgogICAgc3Ryb2tlLXdpZHRoPSI3cHgiCiAgICBkPSJtIDIwMS44NjQwNCw4NS4zMzM1MDQgYyAtNTMuMjEyNjIsMCAtODcuNjc4NTgsMjEuODAxOTc2IC0xMTQuNzM3ODg4LDYwLjY4MzkzNiAtNC45MDg2NjIsNy4wNTMzMyAtMy4zOTUzMywxNi43MjM5OSAzLjQ1MTk5NywyMS45MTU5OCBsIDI4Ljc1ODY0MSwyMS44MDU5OCBjIDYuOTE1MzIsNS4yNDMzMyAxNi43NTQ2NSw0LjAxNzMzIDIyLjE2ODY0LC0yLjc2NTMzIDE2LjY5OTMyLC0yMC45MjA2NSAyOS4wODY2NCwtMzIuOTY1OTcgNTUuMTcxMjgsLTMyLjk2NTk3IDIwLjUwOTMxLDAgNDUuODc3MjksMTMuMTk5MzIgNDUuODc3MjksMzMuMDg3MyAwLDE1LjAzNDY1IC0xMi40MTEzMiwyMi43NTU5OCAtMzIuNjYxOTcsMzQuMTA5MyAtMjMuNjE1MzEsMTMuMjM5OTkgLTU0Ljg2NTk1LDI5LjcxNzMxIC01NC44NjU5NSw3MC45MzY2IHYgNi41MjUzMiBjIDAsOC44MzY2NiA3LjE2MzMzLDE1Ljk5OTk5IDE1Ljk5OTk5LDE1Ljk5OTk5IGggNDguMzEzOTUgYyA4LjgzNjY2LDAgMTUuOTk5OTgsLTcuMTYzMzMgMTUuOTk5OTgsLTE1Ljk5OTk5IHYgLTMuODQ4NjYgYyAwLC0yOC41NzMzIDgzLjUxMTkyLC0yOS43NjMzIDgzLjUxMTkyLC0xMDcuMDg0NTYgNi43ZS00LC01OC4yMjkyNyAtNjAuNDAwNjEsLTEwMi4zOTk4OTYgLTExNi45ODc4OCwtMTAyLjM5OTg5NiB6IG0gLTYuNjgwNjYsMjQ4Ljk3MjQxNiBjIC0yNS40NjM5OCwwIC00Ni4xODA2MiwyMC43MTY2NSAtNDYuMTgwNjIsNDYuMTgwNjIgMCwyNS40NjMzMSAyMC43MTY2NCw0Ni4xNzk5NiA0Ni4xODA2Miw0Ni4xNzk5NiAyNS40NjM5NywwIDQ2LjE4MDYyLC0yMC43MTY2NSA0Ni4xODA2MiwtNDYuMTgwNjIgMCwtMjUuNDYzOTggLTIwLjcxNjY1LC00Ni4xNzk5NiAtNDYuMTgwNjIsLTQ2LjE3OTk2IHoiPgogIDwvcGF0aD4KPC9zdmc+Cg==';

const Image: FunctionComponent<Props> = ({ className, src: srcProp, ...props }) => {
  const [src, setSrc] = useState(srcProp);

  const handleError: ReactEventHandler<HTMLImageElement> = () => {
    setSrc(FALLBACK_IMAGE);
  };

  useEffect(() => {
    setSrc(srcProp);
  }, [srcProp]);

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <img
      {...props}
      className={classNames(styles.image, className)}
      src={src}
      onError={handleError}
    />
  );
};

export default Image;
