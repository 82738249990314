import { addItemsToWebsktop, ImportBookmarksPayload, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetWebsktop } from '../atom';
import { createOptimisticFolder } from '../lib';

import useUpdateSelectionSafe from './useUpdateSelectionSafe';

type ImportBookmarks = (payload: ImportBookmarksPayload<File>) => Promise<void>;

const useImportBookmarks = (websktopId: Websktop['id']): ImportBookmarks => {
  const setWebsktop = useSetWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();
  const updateSelectionSafe = useUpdateSelectionSafe();

  const importBookmarks: ImportBookmarks = useCallback(
    async (payload) => {
      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop, id) => {
        const optimisticFolder = createOptimisticFolder({
          id,
          name: 'Imported bookmarks',
          ...payload,
          parentId: payload.parentId || null,
          x: Number(payload.x),
          y: Number(payload.y),
        });
        return addItemsToWebsktop(websktop, [optimisticFolder]);
      });

      try {
        const response = await api.websktop.importBookmarks({ websktopId, ...payload });
        updateSelectionSafe([response.folder.id]);
        setWebsktop(response.websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, updateSelectionSafe, websktopId],
  );

  return importBookmarks;
};

export default useImportBookmarks;
