import { useEffect } from 'react';

import { useUser } from 'state';

import openReplayTracker from './openReplayTracker';

const useOpenReplay = () => {
  const user = useUser();
  const id = user ? user.id : null;
  const email = user ? user.email : null;
  const name = user ? user.name || user.username : null;

  useEffect(() => {
    if (id !== null) {
      openReplayTracker?.setUserID(id);
    }
  }, [id]);

  useEffect(() => {
    if (email !== null) {
      openReplayTracker?.setMetadata('email', email);
    }
  }, [email]);

  useEffect(() => {
    if (name !== null) {
      openReplayTracker?.setMetadata('name', name);
    }
  }, [name]);
};

export default useOpenReplay;
