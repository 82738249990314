const getTabbableElements = (container: HTMLElement | Element | null): HTMLElement[] => {
  if (container === null) {
    return [];
  }

  // https://allyjs.io/data-tables/focusable.html
  // https://stackoverflow.com/a/30753870
  const selectors = [
    `a[href]:not([tabindex="-1"])`,
    `area[href]:not([tabindex="-1"])`,
    `button:not([disabled]):not([tabindex="-1"])`,
    `iframe:not([tabindex="-1"])`,
    `input:not([disabled]):not([tabindex="-1"])`,
    `select:not([disabled]):not([tabindex="-1"])`,
    `textarea:not([disabled]):not([tabindex="-1"])`,
    `[tabindex]:not([tabindex="-1"])`,
    `[contentEditable=true]:not([tabindex="-1"])`,
  ];

  return Array.from(container.querySelectorAll(selectors.join(', ')));
};

export default getTabbableElements;
