import { Folder, isUserSubscribedToWebsktop, Websktop as WebsktopType } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, lazy, useCallback, useRef, useState } from 'react';
import { useLatest, useUpdateEffect } from 'react-use';

import { Badge, StatusBar } from 'components';
import { useIsAnyInputFocused, useIsOnline, useMediaQuery } from 'hooks';
import { useIsAnyModalOpen, useSharingSettingsModal } from 'modals';
import Tour from 'modules/tour';
import { useWebsktopHotkeys } from 'modules/websktop-hotkeys';
import {
  useIsDemoUser,
  usePermissions,
  useSelection,
  useSyncSelection,
  useUser,
  useWebsktop,
  useWebsktopCollaborativeUpdates,
} from 'state';

import Breadcrumbs from '../Breadcrumbs';
import CloneButton from '../CloneButton';
import Content from '../Content';
import DemoNotice from '../DemoNotice';
import HelpButton from '../HelpButton';
import MenuButton from '../MenuButton';
import SearchEager from '../Search';
import SearchButton from '../SearchButton';
import SubscribeButton from '../SubscribeButton';
import UnauthenticatedUserNotice from '../UnauthenticatedUserNotice';

import styles from './Websktop.module.scss';

const SearchLazy = lazy(() => import('../Search'));

const Search = process.env.REACT_APP_TARGET === 'extension' ? SearchEager : SearchLazy;

interface Props {
  folderId?: Folder['id'];
  websktopId: WebsktopType['id'];
}

const Websktop: FunctionComponent<Props> = (props) => {
  const { websktopId } = props;
  const contentRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isOnline = useIsOnline();
  const isDemoUser = useIsDemoUser();
  const user = useUser();
  const websktop = useWebsktop(websktopId);
  const { canEditItems, canViewWebsktop } = usePermissions(websktopId);
  const isAnyModalOpen = useIsAnyModalOpen();
  const isAnyInputFocused = useIsAnyInputFocused();
  const isAnyModalOpenRef = useLatest(isAnyModalOpen);
  const isAnyInputFocusedRef = useLatest(isAnyInputFocused);
  const sharingSettingsModal = useSharingSettingsModal();
  const areHotkeysEnabled = !isMenuOpen && !isAnyModalOpen && !isAnyInputFocused;
  const areSharingSettingsEnabled = isOnline;
  const isSubscribedToWebsktop = isUserSubscribedToWebsktop(user, websktopId);
  const defaultFolderId = websktop.folder.id;
  const folderId = props.folderId || defaultFolderId;
  const isMobile = useMediaQuery('<s', false);
  const [selection] = useSelection();

  useUpdateEffect(() => {
    if (contentRef.current && !isAnyModalOpenRef.current && !isAnyInputFocusedRef.current) {
      contentRef.current.focus();
    }
  }, [contentRef, isAnyModalOpenRef, isAnyInputFocusedRef, selection]);

  useSyncSelection(websktopId);
  useWebsktopHotkeys({ websktopId, folderId }, areHotkeysEnabled);
  useWebsktopCollaborativeUpdates(websktopId);

  const handleSharingSettings = areSharingSettingsEnabled
    ? () => {
        sharingSettingsModal.open({ websktopId });
      }
    : undefined;

  const handleMenuClose = useCallback(() => setIsMenuOpen(false), []);
  const handleMenuOpen = useCallback(() => setIsMenuOpen(true), []);

  return (
    <>
      {isSubscribedToWebsktop && <Tour />}

      <div className={styles.wrapper}>
        {isDemoUser && <DemoNotice />}

        {!user && <UnauthenticatedUserNotice />}

        <StatusBar className={styles.statusBar}>
          <Breadcrumbs folderId={folderId} websktopId={websktopId} />

          <div className={styles.meta}>
            {!isMobile && <Search className={classNames(styles.search, styles.metaItem)} />}

            <div className={classNames(styles.indicators, styles.metaItem)}>
              {!isOnline && (
                <Badge
                  className={styles.indicator}
                  icon="wifi"
                  size="small"
                  tooltip="You cannot make changes while offline"
                  tooltipPlacement="bottom"
                  variant="warning"
                >
                  Offline
                </Badge>
              )}

              {!canEditItems && (
                <Badge
                  className={styles.indicator}
                  icon="eye"
                  size="small"
                  tooltip="If you want to edit this Websktop you have to clone it first"
                  tooltipPlacement="bottom"
                  variant="info"
                >
                  Read-only
                </Badge>
              )}

              {websktop.readAccess === 'public' && (
                <Badge
                  className={styles.indicator}
                  disabled={!canViewWebsktop || !isOnline}
                  icon="globe"
                  size="small"
                  tooltip="Anyone on the internet with the link can view this Websktop"
                  tooltipPlacement="bottom"
                  variant="info"
                  onClick={canViewWebsktop ? handleSharingSettings : undefined}
                >
                  Public
                </Badge>
              )}

              {websktop.readAccess === 'private' && (
                <Badge
                  className={styles.indicator}
                  disabled={!canViewWebsktop || !isOnline}
                  icon="lock"
                  size="small"
                  tooltip="Only people with access can open this Websktop with the link"
                  tooltipPlacement="bottom"
                  variant="success"
                  onClick={canViewWebsktop ? handleSharingSettings : undefined}
                >
                  Private
                </Badge>
              )}
            </div>

            <div className={classNames(styles.buttons, styles.metaItem)}>
              {isMobile && <SearchButton />}

              {user && (
                <>
                  {!isSubscribedToWebsktop && isOnline && <CloneButton websktopId={websktopId} />}

                  {!isSubscribedToWebsktop && isOnline && (
                    <SubscribeButton websktopId={websktopId} />
                  )}

                  {isSubscribedToWebsktop && <HelpButton />}
                </>
              )}
            </div>
          </div>
        </StatusBar>

        <Content
          className={styles.content}
          folderId={folderId}
          ref={contentRef}
          websktopId={websktopId}
        />

        <MenuButton
          className={styles.plusButton}
          folderId={folderId}
          isMenuOpen={isMenuOpen}
          websktopId={websktopId}
          onClose={handleMenuClose}
          onOpen={handleMenuOpen}
        />
      </div>
    </>
  );
};

export default Websktop;
