import { Currency } from 'types';

const formatPrice = (price: number, currency: Currency): string => {
  const full = Math.floor(price);
  const cents = Math.round((price - full) * 100);
  const value = `${full}.${String(cents).padStart(2, '0')}`;

  if (currency === 'eur') {
    return value + '€';
  }

  return '$' + value;
};

export default formatPrice;
