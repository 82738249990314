import { SIDEBAR_WIDTH, SIDEBAR_WIDTH_WIDE } from 'constants/css';
import { useBodyClassNames, useMediaQuery } from 'hooks';
import { useSidebarState } from 'state';

import styles from './AppLayout.module.scss';

const useAppLayout = () => {
  const [sidebarState] = useSidebarState();
  const isMobile = useMediaQuery('<s', false);
  const autoIsSidebarWide = !isMobile;
  const isSidebarWide = sidebarState === 'wide' || (sidebarState === 'auto' && autoIsSidebarWide);

  useBodyClassNames({
    [styles.wideSidebar]: isSidebarWide,
  });

  return {
    isSidebarWide,
    sidebarWidth: isSidebarWide ? SIDEBAR_WIDTH_WIDE : SIDEBAR_WIDTH,
  };
};

export default useAppLayout;
