const fetchImageBase64 = async (url: string): Promise<string> =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            if (!reader.result) {
              throw new Error('Cannot read image data');
            }

            resolve(reader.result.toString());
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        }),
    );

export default fetchImageBase64;
