import { Folder, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  folderId: Folder['id'];
  websktopId: Websktop['id'];
}

const deleteFolderModalAtom = createModalAtom<State>('delete-folder-modal');

export const useDeleteFolderModal = () => useModal(deleteFolderModalAtom);
