import { useEffect } from 'react';

import { useQueryParams } from 'hooks';
import { trackEvent } from 'modules/analytics';

const useTrackSsoAuth = () => {
  const queryParams = useQueryParams<'ssoProvider'>();

  useEffect(() => {
    const { ssoProvider } = queryParams;

    if (typeof ssoProvider === 'string') {
      trackEvent('auth', 'sso', ssoProvider);
    }
  }, [queryParams]);
};

export default useTrackSsoAuth;
