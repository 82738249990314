import classNames from 'classnames';
import { forwardRef, HTMLProps, ReactNode, SyntheticEvent } from 'react';

import InfoIcon from '../InfoIcon';

import styles from './LabeledInput.module.scss';

export interface Props extends Omit<HTMLProps<HTMLDivElement>, 'label'> {
  description?: string;
  error?: string;
  id: string;
  info?: ReactNode;
  label: ReactNode;
  required?: boolean;
}

const preventDefault = (event: SyntheticEvent) => event.preventDefault();

const LabeledInput = forwardRef<HTMLDivElement, Props>(
  ({ children, className, description, error, id, info, label, required, ...props }, ref) => (
    <div className={classNames(styles.labeledInput, className)} ref={ref} {...props}>
      <label className={styles.label} htmlFor={id}>
        {label}

        {required && (
          <span aria-label="required" className={styles.required}>
            *
          </span>
        )}

        {info && <InfoIcon className={styles.info} tooltip={info} onClick={preventDefault} />}
      </label>

      <div className={styles.input}>{children}</div>

      {description && <div className={styles.description}>{description}</div>}

      {error && <div className={styles.error}>{error}</div>}
    </div>
  ),
);

export default LabeledInput;
