import { Folder, Item } from '@websktop/commons';
import intersection from 'rectangle-overlap';

import { ICON_HEIGHT, ICON_WIDTH, SPACING_COLUMN, SPACING_ROW } from 'modules/grid-layout';
import { Rectangle } from 'types';

const getItemsInRectangle = (folder: Folder, rectangle?: Rectangle): Item[] => {
  if (!rectangle) {
    return [];
  }

  const rectangleBox = {
    x: rectangle.x + 1,
    y: rectangle.y + 1,
    width: Math.max(rectangle.width, 1),
    height: Math.max(rectangle.height, 1),
  };

  return folder.items.filter((item) => {
    const minX = item.x * (ICON_WIDTH + SPACING_COLUMN);
    const minY = item.y * (ICON_HEIGHT + SPACING_ROW);
    const itemBox = {
      x: minX,
      y: minY,
      width: ICON_WIDTH,
      height: ICON_HEIGHT,
    };
    const intersectionRectangle = intersection(rectangleBox, itemBox);
    return intersectionRectangle !== null;
  });
};

export default getItemsInRectangle;
