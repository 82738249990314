import { BackgroundImage, iconToPayload, Plan, Websktop } from '@websktop/commons';
import classNames from 'classnames';
import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react';
import { HexAlphaColorPicker, HexColorPicker } from 'react-colorful';
import { useAsyncFn } from 'react-use';
import { v4 as uuidV4 } from 'uuid';

import { Button, ImageInput, LabeledInput, Message, Modal, Radio } from 'components';
import { useUniqueId, useUploadFile } from 'hooks';
import { useFormState } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { UpgradeImageSizePromo } from 'modules/plans';
import {
  useAuthenticatedUser,
  usePersonalizePreviewBackgroundColor,
  usePersonalizePreviewBackgroundImage,
  usePersonalizePreviewTextColor,
  usePersonalizePreviewTextShadowColor,
  useUpdateWebsktop,
  useWebsktop,
} from 'state';
import { isFileSizeError } from 'types';

import { ColorInput, SolidColorInput } from './components';
import { SOLID_COLORS } from './constants';
import { getPreviewStyle } from './lib';
import styles from './PersonalizeModal.module.scss';
import { FormState } from './types';

interface Props {
  className?: string;
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const PREVIEW_WIDTH = 320;
const PREVIEW_HEIGHT = 180;
const TYPES: BackgroundImage['type'][] = ['cover', 'contain', 'repeat'];

const PersonalizeModal: FunctionComponent<Props> = ({ className, isOpen, websktopId, onClose }) => {
  const id = useUniqueId();
  const user = useAuthenticatedUser();
  const handleUnmountedError = useUnmountedErrorHandler();
  const websktop = useWebsktop(websktopId);
  const updateWebsktop = useUpdateWebsktop(websktopId);
  const [, setPreviewBackgroundColor] = usePersonalizePreviewBackgroundColor();
  const [, setPreviewBackgroundImage] = usePersonalizePreviewBackgroundImage();
  const [, setPreviewTextColor] = usePersonalizePreviewTextColor();
  const [, setPreviewTextShadowColor] = usePersonalizePreviewTextShadowColor();
  const [{ error: uploadError, file, isLoading, uploadPromise }, setFile] = useUploadFile(
    websktop.background?.url,
  );
  const [formState, { patchFormState }] = useFormState<FormState>({
    backgroundColor: websktop.backgroundColor,
    backgroundType: websktop.background ? websktop.background.type : TYPES[0],
    textColor: websktop.textColor,
    textShadowColor: websktop.textShadowColor,
  });
  const [customColor, setCustomColor] = useState(formState.backgroundColor);
  const isCustomColorUsed =
    customColor === formState.backgroundColor && !SOLID_COLORS.includes(customColor);

  const submit = useCallback(async () => {
    const invariantAttributes = {
      backgroundId: websktop.background ? websktop.background.id : null,
      primaryIcon: iconToPayload(websktop.primaryIcon),
      name: websktop.name,
      readAccess: websktop.readAccess,
    };

    return updateWebsktop(
      { ...invariantAttributes, ...formState },
      {
        background: {
          promise: uploadPromise,
          fallbackUrl: file ? URL.createObjectURL(file) : undefined,
        },
      },
    );
  }, [file, formState, uploadPromise, updateWebsktop, websktop]);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await submit();
      });
    },
    [handleUnmountedError, onClose, submit],
  );

  useEffect(() => {
    setPreviewBackgroundImage(() => {
      if (!file) {
        return null;
      }

      return {
        id: uuidV4(),
        type: formState.backgroundType,
        url: URL.createObjectURL(file),
      };
    });

    return () => {
      setPreviewBackgroundImage(undefined);
    };
  }, [file, formState.backgroundType, setPreviewBackgroundImage]);

  useEffect(() => {
    setPreviewBackgroundColor(formState.backgroundColor);

    return () => {
      setPreviewBackgroundColor(undefined);
    };
  }, [formState.backgroundColor, setPreviewBackgroundColor]);

  useEffect(() => {
    setPreviewTextColor(formState.textColor);

    return () => {
      setPreviewTextColor(undefined);
    };
  }, [formState.textColor, setPreviewTextColor]);

  useEffect(() => {
    setPreviewTextShadowColor(formState.textShadowColor);

    return () => {
      setPreviewTextShadowColor(undefined);
    };
  }, [formState.textShadowColor, setPreviewTextShadowColor]);

  return (
    <Modal
      className={classNames(styles.modal, className)}
      footer={
        <Modal.Footer>
          <Button
            disabled={Boolean(isLoading || uploadError)}
            form={id}
            loading={isSubmitting}
            icon="check"
            type="submit"
            variant="primary"
          >
            Save
          </Button>
        </Modal.Footer>
      }
      header={<h2>Personalize</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <ColorInput
          checked={isCustomColorUsed}
          ColorPicker={HexColorPicker}
          label="Background"
          value={customColor}
          onChange={(color) => {
            setCustomColor(color);
            patchFormState({ backgroundColor: color });
          }}
        >
          <SolidColorInput
            className={styles.solidColors}
            value={formState.backgroundColor}
            onChange={(backgroundColor) => patchFormState({ backgroundColor })}
          />
        </ColorInput>

        <div className={styles.textColors}>
          <div>
            <ColorInput
              className={styles.textColor}
              ColorPicker={HexAlphaColorPicker}
              label="Text"
              value={formState.textColor}
              onChange={(textColor) => patchFormState({ textColor })}
            />
          </div>

          <div>
            <ColorInput
              className={styles.textColor}
              ColorPicker={HexAlphaColorPicker}
              label="Text shadow"
              value={formState.textShadowColor}
              onChange={(textShadowColor) => patchFormState({ textShadowColor })}
            />
          </div>
        </div>

        {file && (
          <LabeledInput id="background-type" label="Image fit mode">
            <div className={styles.types}>
              {TYPES.map((backgroundType) => (
                <Radio
                  checked={backgroundType === formState.backgroundType}
                  disabled={isSubmitting}
                  id={`background-type-${backgroundType}`}
                  key={backgroundType}
                  label={backgroundType[0].toUpperCase() + backgroundType.substring(1)}
                  title={backgroundType[0].toUpperCase() + backgroundType.substring(1)}
                  onChange={() => patchFormState({ backgroundType })}
                />
              ))}
            </div>
          </LabeledInput>
        )}

        <ImageInput
          className={styles.imageInput}
          disabled={isSubmitting}
          error={uploadError?.message}
          id="image"
          isLoading={isLoading}
          label="Wallpaper"
          previewStyle={{
            width: PREVIEW_WIDTH,
            height: PREVIEW_HEIGHT,
            ...getPreviewStyle(formState),
          }}
          value={file}
          onChange={setFile}
        />

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}

        {isFileSizeError(uploadError) && user.subscription.plan === Plan.FREE && (
          <UpgradeImageSizePromo />
        )}
      </form>
    </Modal>
  );
};

export default PersonalizeModal;
