import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { formatInitials, getInitials } from 'lib';

import styles from './InitialsIcon.module.scss';

const INITIALS_SIZE_FACTOR = 0.6;

interface Props {
  backgroundColor: string;
  className?: string;
  name: string;
  size: number;
  textColor: string;
}

const InitialsIcon: FunctionComponent<Props> = ({
  backgroundColor,
  className,
  name,
  size,
  textColor,
}) => {
  const initials = formatInitials(getInitials(name || '?'));

  return (
    <div
      className={classNames(styles.initialsIcon, className)}
      style={{
        backgroundColor,
        color: textColor,
        fontSize: size * INITIALS_SIZE_FACTOR,
        height: size,
        width: size,
      }}
    >
      {initials}
    </div>
  );
};

export default InitialsIcon;
