import { Folder, isFolder, Item } from '../types';

import findItem from './findItem';

const isMovingFolderIntoItself = (items: Item[], targetFolderId: Folder['id']): boolean => {
  const folders = items.filter(isFolder);

  return folders.some((folder) => {
    const isMovingWithinFolder = folder.parentId === targetFolderId;
    return !isMovingWithinFolder && findItem(folder, targetFolderId);
  });
};

export default isMovingFolderIntoItself;
