import { FunctionComponent, useMemo } from 'react';

import { Modal } from 'components';
import { SearchPanel } from 'modules/search';
import { useSearch } from 'state';

import styles from './SearchModal.module.scss';

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

const MAX_RESULTS_COUNT = 30;

const SearchModal: FunctionComponent<Props> = ({ className, isOpen, onClose }) => {
  const [{ isLoaded, query, results }, setQuery] = useSearch();
  const topResults = useMemo(() => results.slice(0, MAX_RESULTS_COUNT), [results]);

  if (!isLoaded) {
    return null;
  }

  return (
    <Modal className={className} header={<h2>Search</h2>} isOpen={isOpen} onClose={onClose}>
      <div className={styles.content}>
        <SearchPanel
          autoFocus
          className={styles.panel}
          query={query}
          results={topResults}
          onQueryChange={setQuery}
          onResultClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default SearchModal;
