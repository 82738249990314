import classNames from 'classnames';
import { forwardRef, TextareaHTMLAttributes } from 'react';

import LabeledInput from '../LabeledInput';

import styles from './TextareaInput.module.scss';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  description?: string;
  error?: string;
  id: string;
  label: string;
  value: string;
}

const TextareaInput = forwardRef<HTMLTextAreaElement, Props>(
  ({ className, description, error, id, label, required, ...props }, ref) => (
    <LabeledInput
      className={classNames(styles.textareaInput, className)}
      description={description}
      error={error}
      id={id}
      label={label}
      required={required}
    >
      <textarea
        className={styles.input}
        id={id}
        name={id}
        ref={ref}
        required={required}
        {...props}
      />
    </LabeledInput>
  ),
);

export default TextareaInput;
