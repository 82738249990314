import { FunctionComponent, Suspense } from 'react';

import { LoadingModal } from 'components';

import BackgroundContextMenu, { useBackgroundContextMenu } from './background-context-menu';
import DeleteFolderModal, { useDeleteFolderModal } from './delete-folder-modal';
import DeleteItemsModal, { useDeleteItemsModal } from './delete-items-modal';
import DeleteLinkModal, { useDeleteLinkModal } from './delete-link-modal';
import DeleteWebsktopModal, { useDeleteWebsktopModal } from './delete-websktop-modal';
import DemoExpiredModal, { useDemoExpiredModal } from './demo-expired-modal';
import EditFolderModal, { useEditFolderModal } from './edit-folder-modal';
import EditLinkModal, { useEditLinkModal } from './edit-link-modal';
import EditWebsktopModal, { useEditWebsktopModal } from './edit-websktop-modal';
import FolderContextMenu, { useFolderContextMenu } from './folder-context-menu';
import ImportBookmarksModal, { useImportBookmarksModal } from './import-bookmarks-modal';
import InviteUsersModal, { useInviteWebsktopUsersModal } from './invite-users-modal';
import ItemsContextMenu, { useItemsContextMenu } from './items-context-menu';
import LinkContextMenu, { useLinkContextMenu } from './link-context-menu';
import NewLinkModal, { useNewLinkModal } from './new-link-modal';
import NewWebsktopModal, { useNewWebsktopModal } from './new-websktop-modal';
import OpenLinksConfirmationModal, {
  useOpenLinksConfirmationModal,
} from './open-links-confirmation-modal';
import PersonalizeModal, { usePersonalizeModal } from './personalize-modal';
import SearchModal, { useSearchModal } from './search-modal';
import SharingSettingsModal, { useSharingSettingsModal } from './sharing-settings-modal';
import StartTrialModal, { useStartTrialModal } from './start-trial-modal';
import TrialExpiredModal, { useTrialExpiredModal } from './trial-expired-modal';
import UpgradePlanModal, { useUpgradePlanModal } from './upgrade-plan-modal';
import WebsktopContextMenu, { useWebsktopContextMenu } from './websktop-context-menu';
import WebsktopPublishedModal, { useWebsktopPublishedModal } from './websktop-published-modal';

const AuthenticatedModals: FunctionComponent = () => {
  const backgroundContextMenu = useBackgroundContextMenu();
  const deleteFolderModal = useDeleteFolderModal();
  const deleteItemsModal = useDeleteItemsModal();
  const deleteLinkModal = useDeleteLinkModal();
  const deleteWebsktopModal = useDeleteWebsktopModal();
  const demoExpiredModal = useDemoExpiredModal();
  const editFolderModal = useEditFolderModal();
  const editLinkModal = useEditLinkModal();
  const editWebsktopModal = useEditWebsktopModal();
  const folderContextMenu = useFolderContextMenu();
  const importBookmarksModal = useImportBookmarksModal();
  const inviteUsersModal = useInviteWebsktopUsersModal();
  const itemsContextMenu = useItemsContextMenu();
  const linkContextMenu = useLinkContextMenu();
  const newLinkModal = useNewLinkModal();
  const newWebsktopModal = useNewWebsktopModal();
  const openLinksConfirmationModal = useOpenLinksConfirmationModal();
  const personalizeModal = usePersonalizeModal();
  const searchModal = useSearchModal();
  const sharingSettingsModal = useSharingSettingsModal();
  const startTrialModal = useStartTrialModal();
  const trialExpiredModal = useTrialExpiredModal();
  const upgradePlanModal = useUpgradePlanModal();
  const websktopContextMenu = useWebsktopContextMenu();
  const websktopPublishedModal = useWebsktopPublishedModal();

  return (
    <>
      {/* Modals */}

      {deleteFolderModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={deleteFolderModal.close} />}>
          <DeleteFolderModal
            isOpen={deleteFolderModal.isOpen}
            folderId={deleteFolderModal.data.folderId}
            websktopId={deleteFolderModal.data.websktopId}
            onClose={deleteFolderModal.close}
          />
        </Suspense>
      )}

      {deleteItemsModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={deleteItemsModal.close} />}>
          <DeleteItemsModal
            isOpen={deleteItemsModal.isOpen}
            itemsIds={deleteItemsModal.data.itemsIds}
            websktopId={deleteItemsModal.data.websktopId}
            onClose={deleteItemsModal.close}
          />
        </Suspense>
      )}

      {deleteLinkModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={deleteLinkModal.close} />}>
          <DeleteLinkModal
            isOpen={deleteLinkModal.isOpen}
            linkId={deleteLinkModal.data.linkId}
            websktopId={deleteLinkModal.data.websktopId}
            onClose={deleteLinkModal.close}
          />
        </Suspense>
      )}

      {deleteWebsktopModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={deleteWebsktopModal.close} />}>
          <DeleteWebsktopModal
            isOpen={deleteWebsktopModal.isOpen}
            websktopId={deleteWebsktopModal.data.websktopId}
            onClose={deleteWebsktopModal.close}
          />
        </Suspense>
      )}

      {demoExpiredModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={demoExpiredModal.close} />}>
          <DemoExpiredModal isOpen={demoExpiredModal.isOpen} onClose={demoExpiredModal.close} />
        </Suspense>
      )}

      {editFolderModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={editFolderModal.close} />}>
          <EditFolderModal
            folder={editFolderModal.data.folder}
            isOpen={editFolderModal.isOpen}
            websktopId={editFolderModal.data.websktopId}
            onClose={editFolderModal.close}
          />
        </Suspense>
      )}

      {editLinkModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={editLinkModal.close} />}>
          <EditLinkModal
            isOpen={editLinkModal.isOpen}
            link={editLinkModal.data.link}
            websktopId={editLinkModal.data.websktopId}
            onClose={editLinkModal.close}
          />
        </Suspense>
      )}

      {editWebsktopModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={editWebsktopModal.close} />}>
          <EditWebsktopModal
            isOpen={editWebsktopModal.isOpen}
            websktopId={editWebsktopModal.data.websktopId}
            onClose={editWebsktopModal.close}
          />
        </Suspense>
      )}

      {inviteUsersModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={inviteUsersModal.close} />}>
          <InviteUsersModal
            isOpen={inviteUsersModal.isOpen}
            websktopId={inviteUsersModal.data.websktopId}
            onClose={inviteUsersModal.close}
          />
        </Suspense>
      )}

      {importBookmarksModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={importBookmarksModal.close} />}>
          <ImportBookmarksModal
            isOpen={importBookmarksModal.isOpen}
            parentId={importBookmarksModal.data.parentId}
            point={importBookmarksModal.data.point}
            websktopId={importBookmarksModal.data.websktopId}
            onClose={importBookmarksModal.close}
          />
        </Suspense>
      )}

      {newLinkModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={newLinkModal.close} />}>
          <NewLinkModal
            isOpen={newLinkModal.isOpen}
            parentId={newLinkModal.data.parentId}
            point={newLinkModal.data.point}
            websktopId={newLinkModal.data.websktopId}
            onClose={newLinkModal.close}
          />
        </Suspense>
      )}

      {newWebsktopModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={newWebsktopModal.close} />}>
          <NewWebsktopModal isOpen={newWebsktopModal.isOpen} onClose={newWebsktopModal.close} />
        </Suspense>
      )}

      {openLinksConfirmationModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={openLinksConfirmationModal.close} />}>
          <OpenLinksConfirmationModal
            isOpen={openLinksConfirmationModal.isOpen}
            links={openLinksConfirmationModal.data.links}
            onClose={openLinksConfirmationModal.close}
          />
        </Suspense>
      )}

      {personalizeModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={personalizeModal.close} />}>
          <PersonalizeModal
            isOpen={personalizeModal.isOpen}
            websktopId={personalizeModal.data.websktopId}
            onClose={personalizeModal.close}
          />
        </Suspense>
      )}

      {searchModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={searchModal.close} />}>
          <SearchModal isOpen={searchModal.isOpen} onClose={searchModal.close} />
        </Suspense>
      )}

      {sharingSettingsModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={sharingSettingsModal.close} />}>
          <SharingSettingsModal
            isOpen={sharingSettingsModal.isOpen}
            websktopId={sharingSettingsModal.data.websktopId}
            onClose={sharingSettingsModal.close}
          />
        </Suspense>
      )}

      {startTrialModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={startTrialModal.close} />}>
          <StartTrialModal
            isOpen={startTrialModal.isOpen}
            message={startTrialModal.data.message}
            source={startTrialModal.data.source}
            onClose={startTrialModal.close}
          />
        </Suspense>
      )}

      {trialExpiredModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={trialExpiredModal.close} />}>
          <TrialExpiredModal isOpen={trialExpiredModal.isOpen} onClose={trialExpiredModal.close} />
        </Suspense>
      )}

      {upgradePlanModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={upgradePlanModal.close} />}>
          <UpgradePlanModal
            isOpen={upgradePlanModal.isOpen}
            message={upgradePlanModal.data.message}
            title={upgradePlanModal.data.title}
            onClose={upgradePlanModal.close}
          />
        </Suspense>
      )}

      {websktopPublishedModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={websktopPublishedModal.close} />}>
          <WebsktopPublishedModal
            isOpen={websktopPublishedModal.isOpen}
            websktopId={websktopPublishedModal.data.websktopId}
            onClose={websktopPublishedModal.close}
          />
        </Suspense>
      )}

      {/* Context Menus */}

      {backgroundContextMenu.isOpen && (
        <BackgroundContextMenu
          gridPoint={backgroundContextMenu.data.gridPoint}
          isOpen={backgroundContextMenu.isOpen}
          folderId={backgroundContextMenu.data.folderId}
          point={backgroundContextMenu.data.point}
          websktopId={backgroundContextMenu.data.websktopId}
          onClose={backgroundContextMenu.close}
        />
      )}

      {folderContextMenu.isOpen && (
        <FolderContextMenu
          folderId={folderContextMenu.data.folderId}
          isOpen={folderContextMenu.isOpen}
          point={folderContextMenu.data.point}
          websktopId={folderContextMenu.data.websktopId}
          onClose={folderContextMenu.close}
        />
      )}

      {itemsContextMenu.isOpen && (
        <ItemsContextMenu
          isOpen={itemsContextMenu.isOpen}
          itemsIds={itemsContextMenu.data.itemsIds}
          point={itemsContextMenu.data.point}
          websktopId={itemsContextMenu.data.websktopId}
          onClose={itemsContextMenu.close}
        />
      )}

      {linkContextMenu.isOpen && (
        <LinkContextMenu
          isOpen={linkContextMenu.isOpen}
          linkId={linkContextMenu.data.linkId}
          point={linkContextMenu.data.point}
          websktopId={linkContextMenu.data.websktopId}
          onClose={linkContextMenu.close}
        />
      )}

      {websktopContextMenu.isOpen && (
        <WebsktopContextMenu
          isOpen={websktopContextMenu.isOpen}
          point={websktopContextMenu.data.point}
          websktopId={websktopContextMenu.data.websktopId}
          onClose={websktopContextMenu.close}
        />
      )}
    </>
  );
};

export default AuthenticatedModals;
