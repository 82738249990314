import { Websktop, findEmptyPoint, getFolder } from '@websktop/commons';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { v4 as uuidV4 } from 'uuid';

import { useShowErrorNotification } from 'state/notifications';
import { createOptimisticFolder, useCreateFolder, useWebsktop } from 'state/websktops';

import { folderCreateAtom } from './state';
import { type FolderCreate } from './types';

const useFolderCreate = (websktopId: Websktop['id']): FolderCreate => {
  const websktop = useWebsktop(websktopId);
  const [state, setState] = useRecoilState(folderCreateAtom);
  const createFolder = useCreateFolder(websktopId);
  const showErrorNotification = useShowErrorNotification();

  const create: FolderCreate['create'] = useCallback(
    (payload) => {
      const folder = getFolder(websktop.folder, payload.parentId!);
      const points = folder.items.map((item) => ({ x: item.x, y: item.y }));
      const { x, y } = findEmptyPoint(
        points,
        typeof payload.x !== 'undefined' && typeof payload.y !== 'undefined'
          ? { x: payload.x, y: payload.y }
          : undefined,
      );

      const optimisticFolder = createOptimisticFolder({
        id: uuidV4(),
        parentId: payload.parentId!,
        x,
        y,
        name: '',
      });

      setState({ folder: optimisticFolder, websktopId });
    },
    [websktop.folder, setState, websktopId],
  );

  const setName: FolderCreate['setName'] = useCallback(
    (name: string) => {
      setState((current) => {
        if (!current) {
          throw new Error('Cannot set name when not editing');
        }

        const updatedFolder = { ...current.folder, name };
        return { ...current, folder: updatedFolder };
      });
    },
    [setState],
  );

  const submit: FolderCreate['submit'] = useCallback(async () => {
    if (state === null) {
      throw new Error('Cannot submit when not editing');
    }

    try {
      setState(null);
      await createFolder({
        name: state.folder.name,
        note: '',
        parentId: state.folder.parentId!,
        x: state.folder.x,
        y: state.folder.y,
      });
    } catch (error) {
      showErrorNotification(error);
    }
  }, [createFolder, setState, showErrorNotification, state]);

  const cancel: FolderCreate['cancel'] = () => {
    setState(null);
  };

  return { ...state, cancel, create, setName, submit };
};

export default useFolderCreate;
