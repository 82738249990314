import { Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useSetUser } from '../atom';

type SubscribeWebsktop = () => Promise<void>;

const useSubscribeWebsktop = (websktopId: Websktop['id']): SubscribeWebsktop => {
  const setUser = useSetUser();

  const pinWebsktop: SubscribeWebsktop = useCallback(async () => {
    const user = await api.user.updateSubscriptions({ [websktopId]: true });
    setUser(user);
  }, [setUser, websktopId]);

  return pinWebsktop;
};

export default useSubscribeWebsktop;
