import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { Notification } from 'types';

const notificationsAtom = atom<Notification[]>({
  key: 'notifications',
  default: [],
});

export const useNotifications = () => useRecoilValue(notificationsAtom);

export const useSetNotifications = () => useSetRecoilState(notificationsAtom);
