import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

import { Icon } from 'components';

import styles from './StatusBarButton.module.scss';

interface Props extends HTMLProps<HTMLButtonElement> {
  className?: string;
  icon: FontAwesomeIconProps['icon'];
}

const StatusBarButton = forwardRef<HTMLButtonElement, Props>(
  ({ className, icon, ...restProps }, ref) => (
    <button className={classNames(styles.button, className)} ref={ref} {...restProps} type="button">
      <Icon className={styles.icon} icon={icon} />
    </button>
  ),
);

export default StatusBarButton;
