import { Link, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, Message, Modal } from 'components';
import { useUniqueId } from 'hooks';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { useDeleteLink } from 'state';

import styles from './DeleteLinkModal.module.scss';

interface Props {
  className?: string;
  isOpen: boolean;
  linkId: Link['id'];
  websktopId: Websktop['id'];
  onClose: () => void;
}

const DeleteLinkModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  linkId,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const deleteLink = useDeleteLink(websktopId);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await deleteLink(linkId);
      });
    },
    [deleteLink, handleUnmountedError, linkId, onClose],
  );

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button
            autoFocus
            form={id}
            loading={isSubmitting}
            icon="trash-alt"
            type="submit"
            variant="danger"
          >
            Delete
          </Button>
        </Modal.Footer>
      }
      header={<h2>Delete bookmark</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <p>Are you sure you want to delete this bookmark?</p>

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default DeleteLinkModal;
