import { iconToPayload, Link, Plan, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent, useCallback, useMemo } from 'react';
import { useAsyncFn, useEffectOnce } from 'react-use';

import { Button, IconInput, Message, Modal, TextareaInput, TextInput } from 'components';
import { useUniqueId, useUploadFile } from 'hooks';
import { getFormFieldsErrors, useFormState } from 'lib';
import { LinkIcon } from 'modules/entity-icons';
import { IMAGE_SIZE } from 'modules/grid-layout';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { UpgradeImageSizePromo } from 'modules/plans';
import { useAuthenticatedUser, useUpdateLink } from 'state';
import { EntityIcon, isEmojiIcon, isFileSizeError, isUploadedIcon } from 'types';

import styles from './EditLinkModal.module.scss';
import getLinkIcon from './getLinkIcon';

interface FormState {
  name: string;
  note: string;
  primaryIcon: EntityIcon;
  url: string;
}

interface Props {
  className?: string;
  isOpen: boolean;
  link: Link;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const EditLinkModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  link,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const user = useAuthenticatedUser();
  const [{ error: uploadError, file, uploadPromise }, setFile] = useUploadFile(
    link.primaryIcon.upload?.url,
  );
  const fileUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);
  const handleUnmountedError = useUnmountedErrorHandler();
  const updateLink = useUpdateLink(websktopId);
  const [form, { patchFormState }] = useFormState<FormState>({
    name: link.name,
    note: link.note,
    primaryIcon: null, // this is initialized in useEffectOnce below because it's async
    url: link.url,
  });

  useEffectOnce(() => {
    getLinkIcon(link).then((primaryIcon) => {
      patchFormState({ primaryIcon });
    });
  });

  const updateLinkPayload = useMemo(
    () => ({
      name: form.name.trim(),
      note: form.note,
      parentId: link.parentId,
      primaryIcon: {
        ...iconToPayload(link.primaryIcon),
        emoji: isEmojiIcon(form.primaryIcon) ? form.primaryIcon : '',
        uploadId: isUploadedIcon(form.primaryIcon) ? link.primaryIcon.upload?.id || null : null,
      },
      x: link.x,
      y: link.y,
      url: form.url,
    }),
    [form, link],
  );

  const submit = useCallback(async () => {
    await updateLink(link, updateLinkPayload, {
      primaryIcon: {
        fallbackUrl: isUploadedIcon(form.primaryIcon) ? fileUrl : undefined,
        promise: isUploadedIcon(form.primaryIcon) ? uploadPromise : undefined,
      },
    });
  }, [fileUrl, form, link, updateLink, updateLinkPayload, uploadPromise]);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await submit();
      });
    },
    [handleUnmountedError, onClose, submit],
  );

  const handlePrimaryIconChange = (primaryIcon: EntityIcon) => {
    if (isUploadedIcon(primaryIcon)) {
      setFile(primaryIcon);
    }

    patchFormState({ primaryIcon });
  };

  const formErrors = getFormFieldsErrors<keyof FormState>(error);

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button form={id} loading={isSubmitting} icon="check" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      }
      header={<h2>Edit bookmark</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <TextInput
          autoFocus
          disabled={isSubmitting}
          error={formErrors.url?.message}
          id="url"
          label="URL"
          placeholder="https://example.com"
          required
          value={form.url}
          onChange={(event) => patchFormState({ url: event.target.value })}
        />

        <TextInput
          disabled={isSubmitting}
          error={formErrors.name?.message}
          id="name"
          label="Name"
          value={form.name}
          onChange={(event) => patchFormState({ name: event.target.value })}
        />

        <IconInput id="icon" label="Icon" onChange={handlePrimaryIconChange}>
          <LinkIcon
            className={className}
            iconEmoji={isEmojiIcon(form.primaryIcon) ? form.primaryIcon : ''}
            iconUrl={isUploadedIcon(form.primaryIcon) ? fileUrl : undefined}
            name={form.name}
            size={IMAGE_SIZE}
            url={form.url}
          />
        </IconInput>

        {isFileSizeError(uploadError) && user.subscription.plan === Plan.FREE && (
          <UpgradeImageSizePromo />
        )}

        <TextareaInput
          disabled={isSubmitting}
          error={formErrors.note?.message}
          id="note"
          label="Notes"
          rows={3}
          value={form.note}
          onChange={(event) => patchFormState({ note: event.target.value })}
        />

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default EditLinkModal;
