import { Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, useTooltip } from 'components';
import { useIsOnline } from 'hooks';
import routing from 'modules/routing';
import { useShowErrorNotification, useUnsubscribeWebsktop } from 'state';

interface Props {
  className?: string;
  websktopId: Websktop['id'];
}

const UnsubscribeButton: FunctionComponent<Props> = ({ className, websktopId }) => {
  const navigate = useNavigate();
  const isOnline = useIsOnline();
  const showErrorNotification = useShowErrorNotification();
  const unsubscribeWebsktop = useUnsubscribeWebsktop(websktopId);
  const unpinButtonTooltip = useTooltip(
    <>
      <strong>You are offline</strong>
      <div>You cannot unpin websktop offline.</div>
    </>,
    { placement: 'top' },
  );

  const handleUnsubscribe = async () => {
    try {
      await unsubscribeWebsktop();
      navigate(routing.index);
    } catch (error) {
      showErrorNotification(error);
    }
  };

  return (
    <Button
      className={className}
      disabled={!isOnline}
      type="button"
      variant="primary"
      onClick={handleUnsubscribe}
      {...(isOnline ? undefined : unpinButtonTooltip)}
    >
      Unpin
    </Button>
  );
};

export default UnsubscribeButton;
