import { Item, removeItemsFromWebsktop, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSelection } from '../../selection';

import { useSetAnyWebsktop } from '../atom';

type DeleteItems = (websktopId: Websktop['id'], items: Item[]) => Promise<void>;

const useDeleteItems = (): DeleteItems => {
  const setWebsktop = useSetAnyWebsktop();
  const [selection, { toggle }] = useSelection();
  const optimisticUpdates = useOptimisticUpdates();

  const deleteItems: DeleteItems = useCallback(
    async (websktopId, items) => {
      if (items.length === 0) {
        return;
      }

      const itemsIds = items.map((item) => item.id);
      const selectedIds = itemsIds.filter((itemId) => selection.includes(itemId));
      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop) => {
        return removeItemsFromWebsktop(websktop, itemsIds);
      });

      if (selectedIds.length > 0) {
        toggle(selectedIds);
      }

      try {
        const websktop = await api.websktop.deleteItems({ websktopId, itemsIds });
        setWebsktop(websktop);
      } catch (error) {
        if (selectedIds.length > 0) {
          toggle(selectedIds);
        }

        throw error;
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, selection, setWebsktop, toggle],
  );

  return deleteItems;
};

export default useDeleteItems;
