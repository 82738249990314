import { FunctionComponent } from 'react';

import ClearCacheButton from '../ClearCacheButton';
import RefreshPageButton from '../RefreshPageButton';

import styles from './EmergencyActions.module.scss';

const EmergencyActions: FunctionComponent = () => {
  return (
    <div className={styles.actions}>
      <RefreshPageButton className={styles.action} />

      <ClearCacheButton className={styles.action} />
    </div>
  );
};

export default EmergencyActions;
