import classNames from 'classnames';
import { isMacOs } from 'lib';
import { Fragment, FunctionComponent, HTMLProps } from 'react';

import Key from '../Key';

import styles from './KeyboardShortcut.module.scss';

interface Props extends Omit<HTMLProps<HTMLElement>, 'children'> {
  hotkey: string;
}

const visualKeys: Record<string, string> = {
  Down: '↓',
  Left: '←',
  Mod: isMacOs ? '⌘' : 'Ctrl',
  Opt: isMacOs ? '⌥' : 'Alt',
  Right: '→',
  Up: '↑',
};

const KeyboardShortcut: FunctionComponent<Props> = ({ className, hotkey, ...props }) => {
  const keys = hotkey
    .split('+')
    .map((key) => key[0].toUpperCase() + key.substring(1))
    .map((key) => visualKeys[key] || key);
  const [firstKey, ...otherKeys] = keys;

  return (
    <kbd className={classNames(styles.keyboardShortcut, className)} {...props}>
      <Key>{firstKey}</Key>

      {otherKeys.map((key, index) => (
        <Fragment key={index}>
          <span className={styles.plus}>+</span>
          <Key>{key}</Key>
        </Fragment>
      ))}
    </kbd>
  );
};

export default KeyboardShortcut;
