import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { FunctionComponent, MouseEventHandler, ReactNode } from 'react';

import Icon from '../Icon';
import { useTooltip } from '../Tooltip';

import styles from './Badge.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  icon?: IconProp;
  size?: 'small' | 'medium';
  tooltip?: ReactNode;
  tooltipPlacement?: 'top' | 'right' | 'bottom' | 'left';
  variant?:
    | 'brand-blue'
    | 'brand-red'
    | 'danger'
    | 'default'
    | 'error'
    | 'info'
    | 'primary'
    | 'success'
    | 'warning';
  onClick?: MouseEventHandler;
}

const Badge: FunctionComponent<Props> = ({
  children,
  className,
  disabled,
  icon,
  size = 'medium',
  tooltip,
  tooltipPlacement,
  variant = 'default',
  onClick,
  ...props
}) => {
  const tooltipProps = useTooltip(tooltip, { placement: tooltipPlacement });
  const Element = typeof onClick === 'undefined' ? 'div' : 'button';

  return (
    <Element
      className={classNames(styles.badge, className, {
        [styles.brandBlue]: variant === 'brand-blue',
        [styles.brandRed]: variant === 'brand-red',
        [styles.clickable]: typeof onClick !== 'undefined',
        [styles.danger]: variant === 'danger',
        [styles.default]: variant === 'default',
        [styles.error]: variant === 'error',
        [styles.info]: variant === 'info',
        [styles.primary]: variant === 'primary',
        [styles.small]: size === 'small',
        [styles.success]: variant === 'success',
        [styles.warning]: variant === 'warning',
      })}
      disabled={typeof onClick === 'undefined' ? undefined : disabled}
      tabIndex={typeof onClick === 'undefined' ? undefined : 0}
      type={typeof onClick === 'undefined' ? undefined : 'button'}
      onClick={onClick}
      {...props}
      {...tooltipProps}
    >
      {icon && <Icon className={styles.icon} icon={icon} />}
      {children}
    </Element>
  );
};

export default Badge;
