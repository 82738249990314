import {
  Icon,
  UpdateWebsktopPayload,
  User,
  Websktop,
  iconToPayload,
  websktopToWebsktopRef,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useAuthenticatedUser, useUpdateWebsktopRef } from '../../user';
import { useSetAnyWebsktop, useWebsktop } from '../atom';
import { ImageUpload } from '../types';

type UpdateWebsktop = (
  payload: UpdateWebsktopPayload,
  uploads?: {
    background?: ImageUpload;
    primaryIcon?: ImageUpload;
  },
) => Promise<Websktop>;

const useUpdateWebsktop = (websktopId: Websktop['id']): UpdateWebsktop => {
  const user = useAuthenticatedUser();
  const websktop = useWebsktop(websktopId);
  const updateWebsktopRef = useUpdateWebsktopRef(websktopId);
  const setAnyWebsktop = useSetAnyWebsktop();
  const optimisticUpdates = useOptimisticUpdates();

  const updateWebsktop: UpdateWebsktop = useCallback(
    async (payload, uploads) => {
      const primaryIconUpload =
        typeof uploads?.primaryIcon?.fallbackUrl === 'undefined'
          ? websktop.primaryIcon.upload
          : { id: '', url: uploads.primaryIcon.fallbackUrl };

      const primaryIcon: Icon = {
        ...websktop.primaryIcon,
        ...payload.primaryIcon,
        upload: primaryIconUpload,
      };

      const optimisticWebsktop: Websktop = {
        ...websktop,
        background: uploads?.background?.fallbackUrl
          ? { id: '', url: uploads.background.fallbackUrl, type: payload.backgroundType }
          : websktop.background,
        backgroundColor: payload.backgroundColor,
        name: payload.name,
        primaryIcon,
      };

      const userOptimisticUpdate = optimisticUpdates.create<User>(user.id, (user) => ({
        ...user,
        websktopsRefs: user.websktopsRefs.map((ref) => {
          if (ref.id !== websktop.id) {
            return ref;
          }

          const { isSubscribed, order, userRole } = ref;
          return websktopToWebsktopRef(optimisticWebsktop, { isSubscribed, order, userRole });
        }),
      }));

      const websktopOptimisticUpdate = optimisticUpdates.create<Websktop>(
        websktop.id,
        () => optimisticWebsktop,
      );

      try {
        const [primaryIconUpload, backgroundUpload] = await Promise.all([
          uploads?.primaryIcon?.promise,
          uploads?.background?.promise,
        ]);
        let primaryIconUploadId = payload.primaryIcon.uploadId;
        let backgroundId = payload.backgroundId;

        if (typeof primaryIconUpload !== 'undefined') {
          primaryIconUploadId = primaryIconUpload?.id ?? null;
        }

        if (typeof backgroundUpload !== 'undefined') {
          backgroundId = backgroundUpload?.id ?? null;
        }

        const updatedWebsktop = await api.websktop.patchWebsktop({
          websktopId: websktop.id,
          ...payload,
          backgroundId,
          primaryIcon: {
            ...iconToPayload(websktop.primaryIcon),
            ...payload.primaryIcon,
            uploadId: primaryIconUploadId,
          },
        });
        updateWebsktopRef(({ isSubscribed, order, userRole }) => {
          return websktopToWebsktopRef(updatedWebsktop, { isSubscribed, order, userRole });
        });
        setAnyWebsktop(updatedWebsktop);
        return updatedWebsktop;
      } finally {
        optimisticUpdates.dispose([userOptimisticUpdate.id, websktopOptimisticUpdate.id]);
      }
    },
    [optimisticUpdates, setAnyWebsktop, updateWebsktopRef, user.id, websktop],
  );

  return updateWebsktop;
};

export default useUpdateWebsktop;
