import isUrl from './isUrl';
import normalizeUrl from './normalizeUrl';

const getUrlsFromHtml = (html: string): string[] => {
  try {
    const div = document.createElement('div');
    div.innerHTML = html;
    const anchors = Array.from(div.querySelectorAll('a')) as HTMLAnchorElement[];
    const hrefs = anchors.map((anchor) => (anchor.href || '').trim());
    const urls = hrefs.filter((url) => {
      const normalizedUrl = normalizeUrl(url);
      return normalizedUrl === null ? false : isUrl(normalizedUrl);
    });

    return urls;
  } catch {
    return [];
  }
};

export default getUrlsFromHtml;
