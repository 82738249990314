import { StatusCode } from '@websktop/commons';
import { AtomEffect } from 'recoil';

import { isHttpError } from 'types';

const persistEffect =
  <T>(key: string): AtomEffect<T> =>
  ({ getPromise, node, onSet, resetSelf, setSelf, trigger }) => {
    if (trigger === 'get') {
      const persistedStateJson = localStorage.getItem(key);

      if (persistedStateJson !== null) {
        getPromise(node)
          .then((value) => {
            if (value === null || value === undefined) {
              localStorage.removeItem(key);
              resetSelf();
            } else {
              setSelf(value);
              localStorage.setItem(key, JSON.stringify(value));
            }
          })
          .catch((error) => {
            if (
              isHttpError(error) &&
              (error.status === StatusCode.UNAUTHENTICATED || error.status === StatusCode.FORBIDDEN)
            ) {
              localStorage.removeItem(key);
              resetSelf();
            }
          });
        setSelf(JSON.parse(persistedStateJson));
      }
    }

    onSet((state) => {
      if (state === null || state === undefined) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(state));
      }
    });
  };

export default persistEffect;
