import useMergedRef from '@react-hook/merged-ref';
import classNames from 'classnames';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
} from 'react';
import { LinkProps as RouterLinkProps } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';

import { Link } from 'components';
import { isSafeUrl } from 'lib';

import styles from './WebsktopIcon.module.scss';

interface Props extends Omit<RouterLinkProps, 'to'> {
  autoFocus?: boolean;
  image: ReactNode;
  isCut?: boolean;
  isDisabled?: boolean;
  isEditing?: boolean;
  isMultipleSelection?: boolean;
  isNotInSearchResults?: boolean;
  isSelected?: boolean;
  name: string;
  type: 'external' | 'internal';
  url: string;
  onNameCancel: () => void;
  onNameChange: (name: string) => void;
  onNameSubmit: () => void;
}

const WebsktopIcon = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      autoFocus,
      className,
      image,
      isCut,
      isDisabled,
      isEditing,
      isMultipleSelection,
      isNotInSearchResults,
      isSelected,
      name,
      type,
      url,
      onNameCancel,
      onNameChange,
      onNameSubmit,
      ...props
    },
    propRef,
  ) => {
    const linkRef = useRef<HTMLAnchorElement | null>(null);
    const ref = useMergedRef(linkRef, propRef);
    const nameInputRef = useRef<(TextareaAutosize & HTMLTextAreaElement) | null>(null);
    const shouldIgnoreNextBlurEventRef = useRef<boolean>(false);

    const handleBlur = () => {
      if (isEditing && !shouldIgnoreNextBlurEventRef.current) {
        onNameSubmit();
      }

      shouldIgnoreNextBlurEventRef.current = false;
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      onNameChange(event.target.value);
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isEditing && event.key === 'Escape') {
        event.preventDefault();
        event.stopPropagation();
        shouldIgnoreNextBlurEventRef.current = true;
        linkRef.current?.focus();
        onNameCancel();
      }

      if (isEditing && event.key === 'Enter') {
        event.preventDefault();
        event.stopPropagation();
        shouldIgnoreNextBlurEventRef.current = true;
        linkRef.current?.focus();
        onNameSubmit();
      }
    };

    const handleMouseDown = (event: MouseEvent<HTMLTextAreaElement>) => {
      event.stopPropagation();
      event.nativeEvent.stopPropagation();
    };

    useEffect(() => {
      if (isEditing && nameInputRef.current) {
        nameInputRef.current.select();
      }
    }, [isEditing, nameInputRef]);

    return (
      <Link
        className={classNames(styles.websktopIcon, className, {
          [styles.cut]: isCut,
          [styles.disabled]: isDisabled,
          [styles.editing]: isEditing,
          [styles.notInSearch]: isNotInSearchResults,
          [styles.selected]: isSelected,
          [styles.selectedMany]: isSelected && isMultipleSelection,
        })}
        draggable={false}
        href={isEditing || !isSafeUrl(url) ? undefined : url}
        ref={ref}
        title={url}
        type={type}
        {...props}
      >
        <div className={styles.imageContainer}>{image}</div>

        {!isEditing && <div className={styles.name}>{name}</div>}

        {isEditing && (
          <TextareaAutosize
            autoFocus
            className={styles.nameInput}
            maxRows={10}
            ref={nameInputRef}
            required
            value={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onMouseDown={handleMouseDown}
          />
        )}
      </Link>
    );
  },
);

export default WebsktopIcon;
