import { isFolder } from '@websktop/commons';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import {
  ChangeEventHandler,
  FocusEventHandler,
  FunctionComponent,
  KeyboardEventHandler,
  useEffect,
  useState,
} from 'react';
import { useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal';
import { useNavigate } from 'react-router-dom';
import { useKey } from 'react-use';

import { Icon as IconComponent, Key } from 'components';
import { ensureChildInView, isUserTextInputElement, openLink } from 'lib';
import { useIsAnyModalOpen } from 'modals';
import routing from 'modules/routing';
import { useItemWebsktopMap, useSetIsSearchFocused } from 'state';
import { SearchResult } from 'types';

import Result from '../Result';

import { MODIFIERS } from './constants';
import styles from './SearchDropdown.module.scss';

interface Props {
  className?: string;
  query: string;
  results: SearchResult[];
  onQueryChange: (query: string) => void;
}

const isArrowDown = isHotkey('Down');
const isArrowUp = isHotkey('Up');
const isEnter = isHotkey('Enter');
const isEscape = isHotkey('Esc');
const isSlash = isHotkey('/');

const SearchDropdown: FunctionComponent<Props> = ({ className, query, results, onQueryChange }) => {
  const navigate = useNavigate();
  const isAnyModalOpen = useIsAnyModalOpen();
  const itemWebsktopMap = useItemWebsktopMap();
  const setIsSearchFocused = useSetIsSearchFocused();
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const { attributes, styles: popperStyles } = usePopper(referenceElement, popperElement, {
    modifiers: MODIFIERS,
    placement: 'bottom-start',
  });
  const [currentIndex, setCurrentIndex] = useState<number | null>(results.length === 0 ? null : 0);
  const [isOpen, setIsOpen] = useState(true);
  const isClosed = !isOpen || query.length === 0;

  const clear = () => {
    onQueryChange('');
  };

  const blur = () => {
    referenceElement?.blur();
  };

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  const setSelectedElement = (index: number | null) => {
    setCurrentIndex(index);
    ensureChildInView(popperElement, index);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if (popperElement && !popperElement.contains(event.relatedTarget)) {
      close();
      setIsSearchFocused(false);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    onQueryChange(event.target.value);
  };

  const handleClick = () => {
    open();
  };

  const handleFocus: FocusEventHandler<HTMLInputElement> = () => {
    open();
    setIsSearchFocused(true);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if ((isArrowDown(event) || isArrowUp(event)) && !isClosed) {
      event.preventDefault();
      event.stopPropagation();

      if (currentIndex === null) {
        if (results.length > 0) {
          setSelectedElement(isArrowDown(event) ? 0 : results.length - 1);
        }
      } else {
        const offset = isArrowDown(event) ? 1 : -1;
        const newIndex = currentIndex + offset;

        if (newIndex >= results.length) {
          setSelectedElement(0);
        } else if (newIndex < 0) {
          setSelectedElement(results.length - 1);
        } else {
          setSelectedElement(newIndex);
        }
      }

      return;
    }

    if (isEnter(event)) {
      event.preventDefault();
      event.stopPropagation();

      if (currentIndex === null) {
        return;
      }

      const result = results[currentIndex];
      const item = result.item;

      if (isFolder(item)) {
        const websktopId = itemWebsktopMap[item.id];
        navigate(routing.websktop.folder(websktopId, item.id));
      } else {
        openLink(item.url, { rel: 'noopener noreferrer', target: '_blank' });
      }

      close();
      clear();
      blur();

      return;
    }

    if (isEscape(event)) {
      event.preventDefault();
      event.stopPropagation();

      close();
      clear();
      blur();
      return;
    }

    open();
  };

  const handleRootClose = (event: any) => {
    if (
      popperElement &&
      !popperElement.contains(event.target) &&
      referenceElement !== event.target
    ) {
      close();
    }
  };

  useKey(
    (event) => isSlash(event) && !isUserTextInputElement(event.target) && !isAnyModalOpen,
    (event) => {
      event.preventDefault();
      referenceElement?.focus();
    },
    undefined,
    [referenceElement, isAnyModalOpen],
  );

  useEffect(
    () => {
      setCurrentIndex(results.length === 0 ? null : 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    results.map((result) => result.item.id),
  );

  useRootClose(popperElement, handleRootClose, {
    disabled: isClosed,
  });

  return (
    <>
      <div className={classNames(styles.searchDropdown, className)}>
        <input
          autoCapitalize="off"
          autoComplete="off"
          autoCorrect="off"
          className={styles.input}
          data-lpignore="true"
          placeholder="Search..."
          ref={setReferenceElement}
          spellCheck="false"
          value={query}
          onBlur={handleBlur}
          onChange={handleChange}
          onClick={handleClick}
          onFocus={handleFocus}
          onKeyDown={handleKeyDown}
        />

        <IconComponent className={styles.inputIcon} icon="search" />

        <Key className={styles.focusHotkey}>/</Key>
      </div>

      <Portal>
        <div
          className={classNames(styles.results, {
            [styles.closed]: isClosed,
          })}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {results.map(({ item, matches }, index) => (
            <Result
              isSelected={index === currentIndex}
              item={item}
              key={item.id}
              matches={matches}
              onClick={close}
            />
          ))}

          {results.length === 0 && query.length > 0 && (
            <div className={styles.message}>Nothing matches your search query</div>
          )}
        </div>
      </Portal>
    </>
  );
};

export default SearchDropdown;
