import { isApiError, isError } from '../types';

const getErrorMessage = (error: unknown): string | undefined => {
  if (isApiError(error)) {
    return error.message;
  }

  if (isError(error)) {
    return error.message;
  }

  if (error) {
    return 'Something went wrong.';
  }

  return undefined;
};

export default getErrorMessage;
