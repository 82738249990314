import { WebsktopRef } from '@websktop/commons';
import { useCallback } from 'react';

import { useSetUser } from '../atom';

type AddWebsktopRef = (websktopRef: WebsktopRef) => void;

const useAddWebsktopRef = (): AddWebsktopRef => {
  const setUser = useSetUser();

  const addWebsktopRef: AddWebsktopRef = useCallback(
    (websktopRef) => {
      setUser((user) => {
        if (user === null) {
          throw new Error('Cannot update user data');
        }

        return {
          ...user,
          websktopsRefs: [...user.websktopsRefs, websktopRef],
        };
      });
    },
    [setUser],
  );

  return addWebsktopRef;
};

export default useAddWebsktopRef;
