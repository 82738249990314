import Icon from '../../Icon';
import UploadedIcon from '../../UploadedIcon';
import { isObject } from '../../lib';

interface IconPayload extends Pick<Icon, 'backgroundColor' | 'emoji' | 'text' | 'textColor'> {
  uploadId: UploadedIcon['id'] | null;
}

export const isIconPayload = (value: unknown): value is IconPayload => {
  return (
    isObject(value) &&
    typeof value.backgroundColor === 'string' &&
    typeof value.emoji === 'string' &&
    typeof value.text === 'string' &&
    typeof value.textColor === 'string' &&
    (value.uploadId === null || typeof value.uploadId === 'string')
  );
};

export default IconPayload;
