import { User, Websktop } from '../types';

const isUserSubscribedToWebsktop = (user: User | null, websktopId: Websktop['id']): boolean => {
  if (!user) {
    return false;
  }

  return user.websktopsRefs.some(
    (websktopRef) => websktopRef.id === websktopId && websktopRef.isSubscribed,
  );
};

export default isUserSubscribedToWebsktop;
