import { ReactNode } from 'react';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  message?: ReactNode;
  title?: ReactNode;
}

const upgradePlanModalAtom = createModalAtom<State>('upgrade-plan-modal');

export const useUpgradePlanModal = () => useModal<State>(upgradePlanModalAtom);
