import { StatusCode } from '@websktop/commons';

import { isHttpError } from 'types';

const fetchOrReadCache = async <T>(key: string, fetch: () => T | Promise<T>): Promise<T> => {
  try {
    return await fetch();
  } catch (error) {
    const cachedStateJson = localStorage.getItem(key);
    const shouldUseCachedValue =
      cachedStateJson !== null &&
      (error instanceof TypeError ||
        (isHttpError(error) && error.status !== StatusCode.UNAUTHENTICATED));

    if (shouldUseCachedValue) {
      return JSON.parse(cachedStateJson);
    }

    throw error;
  }
};
export default fetchOrReadCache;
