import { addItemsToWebsktop, findEmptyPoint, getFolder, Link, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useShowErrorNotification } from '../../notifications';
import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetWebsktop, useWebsktop } from '../atom';
import { createOptimisticLink } from '../lib';

import useUpdateSelectionSafe from './useUpdateSelectionSafe';

type CreateLinksFromUrls = (
  payload: Pick<Link, 'parentId'> & Partial<Pick<Link, 'x' | 'y'>> & { urls: string[] },
) => Promise<void>;

const useCreateLinksFromUrls = (websktopId: Websktop['id']): CreateLinksFromUrls => {
  const setWebsktop = useSetWebsktop(websktopId);
  const showErrorNotification = useShowErrorNotification();
  const websktop = useWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();
  const updateSelectionSafe = useUpdateSelectionSafe();

  const createLinksFromUrls: CreateLinksFromUrls = useCallback(
    async ({ parentId, urls, x, y }) => {
      const targetFolder = getFolder(websktop.folder, parentId);
      const targetPoint =
        typeof x !== 'undefined' && typeof y !== 'undefined' ? { x, y } : undefined;
      const points = targetFolder.items.map((item) => ({ x: item.x, y: item.y }));

      const optimisticUpdatesIds = urls.map((url) => {
        const { x, y } = findEmptyPoint(points, targetPoint);
        const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop, id) => {
          const optimisticLink = createOptimisticLink({ id, parentId, url, x, y });
          points.push({ x, y });
          return addItemsToWebsktop(websktop, [optimisticLink]);
        });

        return optimisticUpdate.id;
      });

      try {
        const response = await api.websktop.createLinksFromUrls({
          folderId: parentId,
          urls,
          websktopId,
          x,
          y,
        });
        updateSelectionSafe(response.links.map((link) => link.id));
        setWebsktop(response.websktop);
      } catch (error) {
        showErrorNotification(error);
      } finally {
        optimisticUpdates.dispose(optimisticUpdatesIds);
      }
    },
    [
      optimisticUpdates,
      setWebsktop,
      showErrorNotification,
      updateSelectionSafe,
      websktop.folder,
      websktopId,
    ],
  );

  return createLinksFromUrls;
};

export default useCreateLinksFromUrls;
