import { getErrorMessage } from '@websktop/commons';
import { Component, ReactElement } from 'react';

import { EmergencyActions } from 'modules/errors';

import AppLayout from '../AppLayout';
import Link from '../Link';
import Message from '../Message';
import Panel from '../Panel';

import styles from './ErrorBoundary.module.scss';
import isChunkLoadFailedError from './isChunkLoadFailedError';

interface Props {
  children: ReactElement;
  title?: string;
}

interface State {
  error: unknown;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    error: undefined,
  };

  static getDerivedStateFromError(error: unknown) {
    if (isChunkLoadFailedError(error)) {
      window.location.reload();
      return { error: undefined };
    }

    return { error };
  }

  render() {
    const { children, title = 'Application Error' } = this.props;
    const { error } = this.state;

    if (!error) {
      return children;
    }

    const message = getErrorMessage(error);
    const hasLoadingChunkFailed = Boolean(message && message.startsWith('Loading chunk'));

    if (hasLoadingChunkFailed) {
      window.location.reload();
    }

    return (
      <AppLayout>
        <div className={styles.content}>
          <Panel>
            <h1>{title}</h1>

            <p>Please refresh the page and try again.</p>

            <p>If this does not help, you can try clearing your browser cache.</p>

            <p>
              If clearing cache does not help, please email us at{' '}
              <Link href={process.env.REACT_APP_EMAIL_CONTACT} type="mailto">
                {process.env.REACT_APP_EMAIL_CONTACT}
              </Link>
              .
            </p>

            <EmergencyActions />
          </Panel>

          <Panel>
            <Message variant="error">{message}</Message>
          </Panel>
        </div>
      </AppLayout>
    );
  }
}

export default ErrorBoundary;
