import { Folder, Item } from '../types';

import findItem from './findItem';

const getItemsByIds = (folder: Folder, itemsIds: Item['id'][]): Item[] => {
  return itemsIds.map((itemId) => {
    const item = findItem(folder, itemId);

    if (typeof item === 'undefined') {
      throw new Error('Item does not exist');
    }

    return item;
  });
};

export default getItemsByIds;
