import { User, UserRef } from '../types';

const userToUserRef = (user: User): UserRef => ({
  avatar: user.avatar,
  id: user.id,
  isVerified: user.isVerified,
  name: user.name,
  type: user.type,
  username: user.username,
});

export default userToUserRef;
