import { Item } from '@websktop/commons';
import { useCallback } from 'react';

import { useSetOpenModalsCount } from 'modals';

import { useSelection } from '../../selection';

/**
 * This hook is used to select items which current user just created.
 * We do not want to select those items if user has any modal opened
 * or has changed his selection during item creation request.
 *
 * `previousSelection` will be stale during async api call, but it is good,
 * since we need to know which items were selected in the moment of function invokation.
 */
const useUpdateSelectionSafe = () => {
  const [previousSelection, { set: setSelection }] = useSelection();
  const setOpenModalsCount = useSetOpenModalsCount();

  return useCallback(
    (selection: Item['id'][]) => {
      /**
       * When the function that is created by this hook is called from a context menu,
       * which is closed after the function is called, all the hooks which were created inside of context menu
       * get unmounted when context menu closed, hence we cannot get the most recent atom value by using
       * `useLatest(useIsAnyModalOpen())`. For this reason we use a setter hack.
       */
      const isAnyModalOpen = (() => {
        let result;

        setOpenModalsCount((openModalsCount) => {
          result = openModalsCount > 0;
          return openModalsCount;
        });

        return result;
      })();

      if (isAnyModalOpen) {
        return;
      }

      setSelection((currentSelection) =>
        currentSelection === previousSelection ? selection : currentSelection,
      );
    },
    [previousSelection, setOpenModalsCount, setSelection],
  );
};

export default useUpdateSelectionSafe;
