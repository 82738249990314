import useMergedRef from '@react-hook/merged-ref';
import classNames from 'classnames';
import { FunctionComponent, ReactNode, useRef, useState } from 'react';
import { useRootClose } from 'react-overlays';

import { useStaticTooltip } from '../Tooltip';

import styles from './Cue.module.scss';

interface Props {
  children?: ReactNode;
  className?: string;
}

const Cue: FunctionComponent<Props> = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDismissed, setIsDismissed] = useState(false);
  const cueRef = useRef<HTMLButtonElement>(null);

  const handleDismiss = () => {
    if (isOpen) {
      setIsDismissed(true);
      setIsOpen(false);
    }
  };

  const handleClick = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  const handleRootClose = (event: any) => {
    if (cueRef.current && !cueRef.current.contains(event.target)) {
      handleDismiss();
    }
  };

  const triggerProps = useStaticTooltip(children, {
    isShown: isOpen,
  });

  useRootClose(cueRef, handleRootClose, { disabled: isDismissed || !isOpen });

  const ref = useMergedRef(triggerProps.ref, cueRef);

  if (isDismissed) {
    return null;
  }

  return (
    <button
      {...triggerProps}
      className={classNames(styles.cue, className)}
      ref={ref}
      onClick={handleClick}
      onContextMenu={handleDismiss}
      onMouseDown={handleDismiss}
    />
  );
};

export default Cue;
