import { Folder, UpdateFolderPayload } from '../types';

const folderToUpdatePayload = (folder: Folder): UpdateFolderPayload => ({
  name: folder.name,
  note: folder.note,
  parentId: folder.parentId,
  x: folder.x,
  y: folder.y,
});

export default folderToUpdatePayload;
