import { Item, Websktop } from '../types';

import addItemToFolder from './addItemToFolder';

const addItemToWebsktop = (websktop: Websktop, item: Item): Websktop => ({
  ...websktop,
  folder: addItemToFolder(websktop.folder, item),
});

export default addItemToWebsktop;
