import classNames from 'classnames';
import { FunctionComponent, HTMLProps, ReactNode } from 'react';

import styles from './Toggle.module.scss';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'label' | 'size'> {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  fill?: boolean;
  id: string;
  labelLeft?: ReactNode;
  labelRight?: ReactNode;
  size?: 'medium' | 'large';
  title: string;
}

const Toggle: FunctionComponent<Props> = ({
  checked,
  className,
  disabled,
  fill,
  id,
  labelLeft,
  labelRight,
  size = 'medium',
  title,
  onChange,
  ...props
}) => (
  <label
    className={classNames(styles.toggle, className, {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
      [styles.fill]: fill,
      [styles.medium]: size === 'medium',
      [styles.large]: size === 'large',
    })}
    htmlFor={id}
    title={title}
  >
    <input
      checked={checked}
      className={styles.input}
      disabled={disabled}
      id={id}
      type="checkbox"
      onChange={onChange}
      {...props}
    />

    {labelLeft && <div className={classNames(styles.label, styles.labelLeft)}>{labelLeft}</div>}

    <div className={styles.container}>
      <div className={styles.circle} />
    </div>

    {labelRight && <div className={classNames(styles.label, styles.labelRight)}>{labelRight}</div>}
  </label>
);

export default Toggle;
