import { FunctionComponent, lazy, ReactNode } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import uuidBase62 from 'uuid-base62';

import { AppLayout } from 'components';
import { useScrollToTop } from 'hooks';
import { useOpenReplay, usePiwik } from 'modules/analytics';
import routing from 'modules/routing';
import WebsktopEager from 'pages/app/Websktop';
import { useIsAuthenticated, useIsOnboarded, useSyncUser } from 'state';

import {
  useHandleDemoExpiration,
  useHandleTrialExpiration,
  useTrackSsoAuth,
  useUpdateApp,
} from './hooks';

const ManageWebsktops = lazy(() => import('pages/app/ManageWebsktops'));
const UserSettings = lazy(() => import('pages/app/UserSettings'));
const WebsktopLazy = lazy(() => import('pages/app/Websktop'));
const Upgrade = lazy(() => import('pages/app/Upgrade'));
const ChangePassword = lazy(() => import('pages/auth/ChangePassword'));
const Onboarding = lazy(() => import('pages/auth/Onboarding'));
const ResetPassword = lazy(() => import('pages/auth/ResetPassword'));
const SignIn = lazy(() => import('pages/auth/SignIn'));
const SignUp = lazy(() => import('pages/auth/SignUp'));
const Verify = lazy(() => import('pages/auth/Verify'));
const Changelog = lazy(() => import('pages/changelog/Changelog'));
const Landing = lazy(() => import('pages/landing'));
const PersonalDataRequest = lazy(() => import('pages/legal/PersonalDataRequest'));
const PrivacyPolicy = lazy(() => import('pages/legal/PrivacyPolicy'));
const TermsOfService = lazy(() => import('pages/legal/TermsOfService'));
const Pricing = lazy(() => import('pages/pricing'));
const Roadmap = lazy(() => import('pages/roadmap/Roadmap'));

// When rendering in extension scope we do not want to see Websktop splash screen,
// that's why we need to bundle the Websktop page component in main chunk.
const Websktop = process.env.REACT_APP_TARGET === 'extension' ? WebsktopEager : WebsktopLazy;

/**
 * This is a :pan-wiesio: solution which is forced by react-router v6,
 * since router requires to specify separate routes for different paths,
 * hence it remounts websktop folder unnecesarily and flickers
 */
const WebsktopContentRouter: FunctionComponent = () => {
  const indexMatch = useMatch(routing.index);
  const websktopMatch = useMatch(routing.websktop.toString());
  const websktopFolderMatch = useMatch(routing.websktop.folder.toString());

  if (!indexMatch && !websktopMatch && !websktopFolderMatch) {
    return <Navigate to={routing.index} />;
  }

  const encodedWebsktopId = websktopFolderMatch
    ? websktopFolderMatch.params.websktopId
    : websktopMatch?.params.websktopId;
  const encodedFolderId = websktopFolderMatch?.params.folderId;

  const websktopId = encodedWebsktopId && uuidBase62.decode(encodedWebsktopId);
  const folderId = encodedFolderId && uuidBase62.decode(encodedFolderId);

  return <Websktop folderId={folderId} websktopId={websktopId} />;
};

const AuthenticatedRouter: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  useSyncUser();
  return <AppLayout sidebar>{children}</AppLayout>;
};

const Router: FunctionComponent = () => {
  const isAuthenticated = useIsAuthenticated();
  const isOnboarded = useIsOnboarded();

  const commonRoutes = (
    <>
      <Route path={routing.changelog} element={<Changelog />} />
      <Route path={routing.legal.personalDataRequest} element={<PersonalDataRequest />} />
      <Route path={routing.legal.privacyPolicy} element={<PrivacyPolicy />} />
      <Route path={routing.legal.termsOfService} element={<TermsOfService />} />
      <Route path={routing.pricing} element={<Pricing />} />
      <Route path={routing.roadmap} element={<Roadmap />} />
      <Route path={routing.auth.signUp.toString()} element={<SignUp />} />
      <Route path={routing.auth.changePassword} element={<ChangePassword />} />
    </>
  );

  useScrollToTop();
  usePiwik();
  useOpenReplay();
  useTrackSsoAuth();
  useHandleDemoExpiration();
  useHandleTrialExpiration();
  useUpdateApp();

  if (!isAuthenticated) {
    return (
      <Routes>
        <Route path={routing.websktop.toString()} element={<WebsktopContentRouter />} />
        <Route path={routing.websktop.folder.toString()} element={<WebsktopContentRouter />} />
        <Route path={routing.index} element={<Landing />} />
        <Route path={routing.auth.resetPassword} element={<ResetPassword />} />
        <Route path={routing.auth.signIn} element={<SignIn />} />
        <Route path={routing.auth.verify} element={<Verify />} />
        {commonRoutes}
        <Route path="*" element={<Navigate to={routing.index} />} />
      </Routes>
    );
  }

  if (!isOnboarded) {
    return <Onboarding />;
  }

  return (
    <AuthenticatedRouter>
      <Routes>
        <Route path={routing.userSettings} element={<UserSettings />} />
        {commonRoutes}
        <Route path={routing.manageWebsktops} element={<ManageWebsktops />} />
        <Route path={routing.upgrade.toString()} element={<Upgrade />} />
        <Route path="*" element={<WebsktopContentRouter />} />
      </Routes>
    </AuthenticatedRouter>
  );
};

export default Router;
