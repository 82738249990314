import { Folder, getFolder, Websktop as WebsktopType } from '@websktop/commons';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { useTitle } from 'react-use';

import { CHROME_EXTENSION_ID } from 'constants/extension';
import { useIsDragging } from 'hooks';
import { useTrackPageView } from 'modules/analytics';
import routing from 'modules/routing';
import {
  useAllItemsLoadable,
  useDefaultWebsktopId,
  useSetCurrentWebsktopId,
  useWebsktopLoadable,
} from 'state';

import { UnknownError, Websktop as WebsktopComponent, WebsktopErrorBoundary } from './components';
import { useRefetchErroredWebsktop } from './hooks';
import styles from './Websktop.module.scss';

interface Props {
  folderId?: Folder['id'];
  websktopId?: WebsktopType['id'];
}

interface SafeProps {
  folderId?: Folder['id'];
  websktopId: WebsktopType['id'];
}

const getPageTitle = (websktopName: string | null, folderName: string | null): string => {
  const name = folderName || websktopName;
  return name && name.trim().length > 0 ? `${name} - Websktop` : 'Websktop';
};

// This component is used to fix the bug caused by recoil, when
// child component rerenders before parent component after state update,
// even though they both are subscribed to the same atom changes
const WebsktopSafetyWrapper: FunctionComponent<Props> = (props) => {
  const defaultWebsktopId = useDefaultWebsktopId();
  const websktopId = props.websktopId || defaultWebsktopId;

  if (!websktopId) {
    return <UnknownError />;
  }

  return (
    <div className={styles.layout}>
      <Websktop {...props} websktopId={websktopId} />
    </div>
  );
};

const Websktop: FunctionComponent<SafeProps> = ({ folderId, websktopId }) => {
  const defaultWebsktopId = useDefaultWebsktopId();

  useRefetchErroredWebsktop(websktopId);
  const isDragging = useIsDragging();
  const websktopLoadable = useWebsktopLoadable(websktopId);
  const websktop = websktopLoadable.state === 'hasValue' ? websktopLoadable.contents : null;
  const setCurrentWebsktopId = useSetCurrentWebsktopId();
  const allItemsLoadble = useAllItemsLoadable();
  const websktopName = websktop ? websktop.name : null;
  const folderName = useMemo(
    () => (folderId && websktop ? getFolder(websktop.folder, folderId).name : null),
    [websktop, folderId],
  );
  const title = getPageTitle(websktopName, folderName);

  useTitle(title);
  useTrackPageView(title, { shouldTrack: websktopLoadable.state === 'hasValue' });

  const defaultFolderId = websktop ? websktop.folder.id : null;

  useEffect(() => {
    if (allItemsLoadble.state === 'hasValue') {
      if (typeof chrome !== 'undefined') {
        chrome.runtime?.sendMessage(CHROME_EXTENSION_ID, {
          action: 'set-all-items',
          items: allItemsLoadble.contents,
        });
      }
    }
  }, [allItemsLoadble]);

  useEffect(() => {
    setCurrentWebsktopId(websktopId);

    return () => {
      setCurrentWebsktopId(null);
    };
  }, [setCurrentWebsktopId, websktopId]);

  if (
    !isDragging &&
    defaultWebsktopId &&
    websktopId === defaultWebsktopId &&
    folderId === defaultFolderId
  ) {
    return <Navigate replace to={routing.index} />;
  }

  return (
    <WebsktopErrorBoundary
      error={websktopLoadable.state === 'hasError' ? websktopLoadable.contents : undefined}
      websktopId={websktopId}
    >
      <WebsktopComponent folderId={folderId} websktopId={websktopId} />
    </WebsktopErrorBoundary>
  );
};

export default WebsktopSafetyWrapper;
