import classNames from 'classnames';
import { forwardRef, InputHTMLAttributes, ReactNode } from 'react';

import LabeledInput from '../LabeledInput';

import styles from './TextInput.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  addonLeft?: ReactNode;
  addonRight?: ReactNode;
  description?: string;
  error?: string;
  id: string;
  info?: ReactNode;
  label: ReactNode;
  type?: 'email' | 'password' | 'text' | 'URL';
}

const TextInput = forwardRef<HTMLInputElement, Props>(
  (
    {
      addonLeft,
      addonRight,
      children,
      className,
      description,
      error,
      id,
      info,
      label,
      required,
      ...props
    },
    ref,
  ) => (
    <LabeledInput
      className={classNames(styles.textInput, className, {
        [styles.withAddonLeft]: addonLeft,
        [styles.withAddonRight]: addonRight,
      })}
      description={description}
      error={error}
      id={id}
      info={info}
      label={label}
      required={required}
    >
      <div className={styles.content}>
        {addonLeft && <div className={classNames(styles.addon, styles.addonLeft)}>{addonLeft}</div>}

        <input
          className={styles.input}
          id={id}
          name={id}
          ref={ref}
          required={required}
          {...props}
        />

        {addonRight && (
          <div className={classNames(styles.addon, styles.addonRight)}>{addonRight}</div>
        )}
      </div>

      {children}
    </LabeledInput>
  ),
);

export default TextInput;
