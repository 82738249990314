import { Point } from '@websktop/commons';
import { clamp } from 'lodash-es';

const getRelativePoint = (element: HTMLElement | null, viewportRelativePoint: Point): Point => {
  if (element === null) {
    return { x: 0, y: 0 };
  }

  const containerRect = element.getBoundingClientRect();

  const x = clamp(
    viewportRelativePoint.x - containerRect.left + element.scrollLeft,
    0,
    element.scrollWidth,
  );
  const y = clamp(
    viewportRelativePoint.y - containerRect.top + element.scrollTop,
    0,
    element.scrollHeight,
  );

  return { x, y };
};

export default getRelativePoint;
