import { Plan, Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useCopyToClipboard } from 'react-use';
import { useNavigate } from 'react-router-dom';

import { ContextMenu, ContextMenuProps, Menu } from 'components';
import { useIsOnline } from 'hooks';
import { getAbsoluteUrl } from 'lib';
import { useUpgradeModal } from 'modals';
import { exportBookmarks } from 'modules/bookmarks-exporter';
import routing from 'modules/routing';
import {
  useActiveWebsktopId,
  useAuthenticatedUser,
  useDefaultWebsktopId,
  useForkWebsktop,
  usePermissions,
  useShowErrorNotification,
  useSubscribeWebsktop,
  useUnsubscribeWebsktop,
  useWebsktop,
  useWebsktopRef,
} from 'state';

import { useDeleteWebsktopModal } from '../delete-websktop-modal';
import { useEditWebsktopModal } from '../edit-websktop-modal';
import { useSharingSettingsModal } from '../sharing-settings-modal';

interface Props extends Omit<ContextMenuProps, 'children'> {
  websktopId: Websktop['id'];
}

const WebsktopContextMenu: FunctionComponent<Props> = ({ websktopId, onClose, ...props }) => {
  const isOnline = useIsOnline();
  const navigate = useNavigate();
  const user = useAuthenticatedUser();
  const upgradeModal = useUpgradeModal();
  const websktopRef = useWebsktopRef(websktopId);
  const websktop = useWebsktop(websktopId);
  const { canViewWebsktop } = usePermissions(websktopId);
  const showErrorNotification = useShowErrorNotification();
  const defaultWebsktopId = useDefaultWebsktopId();
  const deleteWebsktopModal = useDeleteWebsktopModal();
  const editWebsktopModal = useEditWebsktopModal();
  const sharingSettingsModal = useSharingSettingsModal();
  const { canDeleteWebsktop, canEditWebsktop } = usePermissions(websktopId);
  const forkWebsktop = useForkWebsktop(websktopId);
  const subscribeWebsktop = useSubscribeWebsktop(websktopId);
  const unsubscribeWebsktop = useUnsubscribeWebsktop(websktopId);
  const [, copy] = useCopyToClipboard();
  const activeWebsktopId = useActiveWebsktopId();
  const areSharingSettingsEnabled = isOnline && canViewWebsktop;
  const isCloneEnabled = isOnline;
  const isDefaultWebsktop = websktopId === defaultWebsktopId;
  const isDeleteEnabled = !isDefaultWebsktop && canDeleteWebsktop && isOnline;
  const isEditEnabled = canEditWebsktop && isOnline;
  const isSubscriptionEnabled = isOnline && (!isDefaultWebsktop || !websktopRef.isSubscribed);

  const handleCopyName = () => {
    if (websktopRef) {
      copy(websktopRef.name);
    }
    onClose();
  };

  const handleCopyUrl = () => {
    const websktopUrl = getAbsoluteUrl(routing.websktop(websktopId));
    copy(websktopUrl);
    onClose();
  };

  const handleDelete = () => {
    deleteWebsktopModal.open({ websktopId });
    onClose();
  };

  const handleEdit = () => {
    editWebsktopModal.open({ websktopId });
    onClose();
  };

  const handleSharingSettings = () => {
    sharingSettingsModal.open({ websktopId });
    onClose();
  };

  const handleFork = async () => {
    if (user.subscription.plan === Plan.FREE) {
      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to clone this Websktop?</h2>
            Professional Plan supports <strong>unlimited</strong> number of Websktops.
          </div>
        ),
        source: 'websktop context menu - fork websktop',
      });
    } else {
      const websktop = await forkWebsktop();
      navigate(routing.websktop(websktop.id));
    }

    onClose();
  };

  const handleSubscription = async () => {
    try {
      if (websktopRef.isSubscribed) {
        await unsubscribeWebsktop();
      } else {
        await subscribeWebsktop();
      }

      if (websktopId === activeWebsktopId) {
        navigate(routing.index);
      }

      onClose();
    } catch (error) {
      showErrorNotification(error);
    }
  };

  const handleExport = () => {
    exportBookmarks(websktop.folder.items);
  };

  return (
    <ContextMenu onClose={onClose} {...props}>
      <Menu>
        <Menu.ItemsGroup>
          <Menu.Item.Link
            href={routing.websktop(websktopId)}
            icon="external-link-square-alt"
            type="internal"
            onClick={onClose}
          >
            Open
          </Menu.Item.Link>

          <Menu.Item.Link
            href={routing.websktop(websktopId)}
            icon="external-link-square-alt"
            target="_blank"
            type="external"
            onClick={onClose}
          >
            Open in new tab
          </Menu.Item.Link>
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          <Menu.Item.Button icon="clipboard" onClick={handleCopyUrl}>
            Copy URL
          </Menu.Item.Button>

          {websktopRef && websktopRef.name.trim().length > 0 && (
            <Menu.Item.Button icon="clipboard" onClick={handleCopyName}>
              Copy name
            </Menu.Item.Button>
          )}
        </Menu.ItemsGroup>

        {(areSharingSettingsEnabled || isEditEnabled) && (
          <Menu.ItemsGroup>
            {areSharingSettingsEnabled && (
              <Menu.Item.Button icon="share-alt" onClick={handleSharingSettings}>
                Sharing settings
              </Menu.Item.Button>
            )}

            {isEditEnabled && (
              <Menu.Item.Button icon="pencil-alt" onClick={handleEdit}>
                Edit
              </Menu.Item.Button>
            )}
          </Menu.ItemsGroup>
        )}

        {isCloneEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button icon="code-branch" onClick={handleFork}>
              Clone
            </Menu.Item.Button>

            <Menu.Item.Button icon="file-export" onClick={handleExport}>
              Export bookmarks
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}

        {(isDeleteEnabled || isSubscriptionEnabled) && (
          <Menu.ItemsGroup>
            {isSubscriptionEnabled && (
              <Menu.Item.Button icon="thumbtack" variant="default" onClick={handleSubscription}>
                {websktopRef.isSubscribed ? 'Unpin' : 'Pin'}
              </Menu.Item.Button>
            )}

            {isDeleteEnabled && (
              <Menu.Item.Button icon="trash-alt" variant="danger" onClick={handleDelete}>
                Delete
              </Menu.Item.Button>
            )}
          </Menu.ItemsGroup>
        )}
      </Menu>
    </ContextMenu>
  );
};

export default WebsktopContextMenu;
