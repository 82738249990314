import { atom, selector, useRecoilValue, useSetRecoilState } from 'recoil';

import { allItemsSelector } from 'state/websktops';

import type { SearchState } from './types';

export const searchQueryAtom = atom<string>({
  key: 'search-query',
  default: '',
});

export const isSearchFocusedAtom = atom<boolean>({
  key: 'is-search-focused',
  default: false,
});

export const searchResultsAtom = atom<SearchState['results']>({
  key: 'search-results',
  default: [],
});

// Since our search is evaluated asynchronously in web worker,
// results might not be in sync with available items.
// For that reason we filter out non-existing items from search results.
export const safeSearchResultsSelector = selector<SearchState['results']>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'safe-search-results',
  get: ({ get }) => {
    const searchResults = get(searchResultsAtom);
    const allItemsIds = get(allItemsSelector).map((item) => item.id);
    return searchResults.filter((result) => allItemsIds.includes(result.item.id));
  },
});

export const useIsSearchFocused = () => useRecoilValue(isSearchFocusedAtom);

export const useIsSearchActive = () => {
  const query = useRecoilValue(searchQueryAtom);
  const isSearchFocused = useIsSearchFocused();
  return isSearchFocused && query.length > 0;
};

export const useSetIsSearchFocused = () => useSetRecoilState(isSearchFocusedAtom);

export const useSearchResults = () => useRecoilValue(searchResultsAtom);
