import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const inviteUsersModalAtom = createModalAtom<State>('invite-users-modal');

export const useInviteWebsktopUsersModal = () => useModal(inviteUsersModalAtom);
