import { SECOND } from '@websktop/commons';
import { noop } from 'lodash-es';
import { useEffect } from 'react';

import api from 'api';
import { getServiceWorker } from 'serviceWorkerManager';
import { ServiceWorkerClientMessage } from 'types';

import history from '../history';

const BUILD_ID = process.env.REACT_APP_GIT_COMMIT_SHA;
const CHECK_BUILD_ID_INTERVAL = 30 * SECOND;

let needsUpdate = false;

const updateApplication = async () => {
  const serviceWorker = await getServiceWorker();

  if (!serviceWorker) {
    window.location.reload();
    return;
  }

  serviceWorker.addEventListener('activated', () => {
    window.location.reload();
  });

  const message: ServiceWorkerClientMessage = { action: 'invalidate-static-caches' };
  await serviceWorker.messageSW(message);
  await serviceWorker.update();
};

const runAppUpdateChecks = (): number => {
  let intervalId: number | undefined = undefined;

  const checkBuildId = async () => {
    try {
      const buildId = await api.system.getBuildId();

      if (buildId !== BUILD_ID) {
        window.clearInterval(intervalId);
        needsUpdate = true;
      }
    } catch {}
  };

  checkBuildId();

  intervalId = window.setInterval(checkBuildId, CHECK_BUILD_ID_INTERVAL);

  return intervalId;
};

const useUpdateApp = () => {
  useEffect(() => {
    const intervalId = runAppUpdateChecks();

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      if (needsUpdate) {
        updateApplication();
      }
    });

    return () => {
      unlisten();
    };
  }, []);
};

export default process.env.NODE_ENV === 'production' && process.env.REACT_APP_TARGET !== 'extension'
  ? useUpdateApp
  : noop;
