import { useEffect } from 'react';

import { TRIAL_EXPIRED_MODAL_STORAGE_KEY } from 'constants/trial';
import { useTrialExpiredModal } from 'modals';
import { useIsTrialExpired, useUser } from 'state';

const useHandleTrialExpiration = () => {
  const trialExpiredModal = useTrialExpiredModal();
  const isTrialExpired = useIsTrialExpired();
  const user = useUser();
  const userId = user ? user.id : undefined;
  const openModal = trialExpiredModal.open;

  useEffect(() => {
    const trialExpiredModal = localStorage.getItem(TRIAL_EXPIRED_MODAL_STORAGE_KEY);
    const userIdsWhoClosedModal = trialExpiredModal ? trialExpiredModal.split(',') : [];

    if (isTrialExpired && userId && !userIdsWhoClosedModal.includes(userId)) {
      openModal();
    }
  }, [isTrialExpired, openModal, userId]);
};

export default useHandleTrialExpiration;
