import Cookies from 'js-cookie';

import { DEMO_DATA_EXPIRES_AT_STORAGE_KEY, DEMO_USER_ID_SESSION_STORAGE_KEY } from 'constants/demo';
import { TRIAL_EXPIRED_MODAL_STORAGE_KEY } from 'constants/trial';

const preserveLocalStorageValue = (key: string) => {
  const value = localStorage.getItem(key);

  const restore = () => {
    if (value !== null) {
      localStorage.setItem(key, value);
    }
  };

  return { restore };
};

const preserveSessionStorageValue = (key: string) => {
  const value = sessionStorage.getItem(key);

  const restore = () => {
    if (value !== null) {
      sessionStorage.setItem(key, value);
    }
  };

  return { restore };
};

const clearSessionData = () => {
  const demoUserId = preserveSessionStorageValue(DEMO_USER_ID_SESSION_STORAGE_KEY);
  const demoExpiresAt = preserveSessionStorageValue(DEMO_DATA_EXPIRES_AT_STORAGE_KEY);
  const trialExpiredModal = preserveLocalStorageValue(TRIAL_EXPIRED_MODAL_STORAGE_KEY);

  Object.keys(Cookies.get()).forEach((cookie) => {
    Cookies.remove(cookie, { domain: document.location.host });
  });
  sessionStorage.clear();
  localStorage.clear();

  trialExpiredModal.restore();
  demoUserId.restore();
  demoExpiresAt.restore();
};

export default clearSessionData;
