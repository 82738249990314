import { isObject, Timestamp } from '@websktop/commons';

interface BaseItem {
  addDate?: Timestamp;
  name: string;
}

export interface Bookmark extends BaseItem {
  icon?: string;
  url: string;
}

export interface Folder extends BaseItem {
  children: Item[];
}

export type Item = Folder | Bookmark;

export const isBookmark = (item: unknown): item is Bookmark =>
  isObject(item) && typeof item.url === 'string' && typeof item.name === 'string';

export const isFolder = (item: unknown): item is Folder =>
  isObject(item) && Array.isArray(item.children);
