import { findItem, Item, Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import { atom, useRecoilState } from 'recoil';

import { useShowErrorNotification } from '../notifications';
import { useRenameItem, useWebsktop } from '../websktops';

import { EmptyState, ItemRename, State } from './types';

const EMPTY_STATE: EmptyState = {
  isEditing: false,
  item: undefined,
  name: undefined,
};

const itemRenameAtom = atom<State>({ key: 'item-rename', default: EMPTY_STATE });

const useItemRename = (websktopId: Websktop['id']): ItemRename => {
  const [state, setState] = useRecoilState(itemRenameAtom);
  const websktop = useWebsktop(websktopId);
  const renameItem = useRenameItem(websktopId);
  const showErrorNotification = useShowErrorNotification();

  const editItem = useCallback(
    (itemId: Item['id']) => {
      const item = findItem(websktop.folder, itemId);

      if (typeof item === 'undefined') {
        throw new Error('Item does not exist');
      }

      setState({
        isEditing: true,
        item,
        name: item.name,
      });
    },
    [setState, websktop.folder],
  );

  const setName = useCallback(
    (name: string) => {
      setState((current) => {
        if (!current.isEditing) {
          throw new Error('Cannot set name when not editing');
        }

        return { ...current, name };
      });
    },
    [setState],
  );

  const submit = useCallback(async () => {
    if (!state.isEditing) {
      throw new Error('Cannot submit when not editing');
    }

    try {
      setState(EMPTY_STATE);
      await renameItem(state.item, state.name.trim());
    } catch (error) {
      showErrorNotification(error);
    }
  }, [renameItem, setState, showErrorNotification, state]);

  const cancel = useCallback(() => {
    setState(EMPTY_STATE);
  }, [setState]);

  return { ...state, cancel, editItem, setName, submit };
};

export default useItemRename;
