import { BillingCycle, Plan as PlanType } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import { Badge, Features, Panel, Price } from 'components';
import { formatPrice } from 'lib';
import { useUser } from 'state';
import { Currency } from 'types';

import styles from './Plan.module.scss';

interface Props {
  action: ReactNode;
  billingCycle: BillingCycle;
  className?: string;
  currency: Currency;
  features: ReactNode[];
  monthlyPrice: number;
  name: string;
  plan: PlanType;
}

const Plan: FunctionComponent<Props> = ({
  action,
  billingCycle,
  className,
  currency,
  features,
  monthlyPrice,
  name,
  plan,
}) => {
  const user = useUser();
  const userPlan = user ? user.subscription.plan : null;

  return (
    <Panel
      className={classNames(styles.plan, className, {
        [styles.withDiscount]: plan !== PlanType.FREE && billingCycle === 'year',
      })}
    >
      <div className={styles.name}>
        <div>{name} Plan</div>

        {plan === userPlan && (
          <Badge
            className={styles.yourPlan}
            size="small"
            tooltip="This is your current plan"
            variant="default"
          >
            Your plan
          </Badge>
        )}
      </div>

      <div className={styles.price}>
        <Price currency={currency} price={monthlyPrice} />

        {plan !== PlanType.FREE && billingCycle === 'year' && (
          <Badge
            className={styles.discount}
            tooltip="16.7% discount when paying annually"
            variant="success"
          >
            -16.7%
          </Badge>
        )}

        <span className={styles.billingCycle}>/ month</span>
      </div>

      <div className={styles.billing}>
        {plan === PlanType.FREE && <>Free, forever.</>}

        {plan !== PlanType.FREE && billingCycle === 'month' && (
          <>Billed {formatPrice(monthlyPrice, currency)} monthly.</>
        )}

        {plan !== PlanType.FREE && billingCycle === 'year' && (
          <>Billed {formatPrice(12 * monthlyPrice, currency)} annually.</>
        )}
      </div>

      <div className={styles.action}>{action}</div>

      <hr className={styles.divider} />

      <Features features={features} />
    </Panel>
  );
};

export default Plan;
