import type { Link, UpdateLinkPayload } from '../types';

import iconToPayload from './iconToPayload';

const linkToUpdatePayload = (link: Link): UpdateLinkPayload => ({
  name: link.name,
  note: link.note,
  parentId: link.parentId,
  primaryIcon: iconToPayload(link.primaryIcon),
  url: link.url,
  x: link.x,
  y: link.y,
});

export default linkToUpdatePayload;
