import { Point } from '../types';

const getBoundaries = <T extends Point>(points: T[]) => {
  return points.reduce(
    ({ maxX, maxY, minX, minY }, { x, y }) => ({
      maxX: Math.max(maxX, x),
      maxY: Math.max(maxY, y),
      minX: Math.min(minX, x),
      minY: Math.min(minY, y),
    }),
    {
      maxX: Number.MIN_SAFE_INTEGER,
      maxY: Number.MIN_SAFE_INTEGER,
      minX: Number.MAX_SAFE_INTEGER,
      minY: Number.MAX_SAFE_INTEGER,
    },
  );
};

export default getBoundaries;
