import { Folder, isFolder, Item } from '../types';

const addItemToFolder = (folder: Folder, newItem: Item): Folder => {
  if (newItem.parentId === folder.id) {
    return { ...folder, items: [...folder.items, newItem] };
  }

  return {
    ...folder,
    items: folder.items.map((item) => {
      if (isFolder(item)) {
        return addItemToFolder(item, newItem);
      }

      return item;
    }),
  };
};

export default addItemToFolder;
