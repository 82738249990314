import { HTMLProps } from 'react';
import { useSize } from 'react-use';

import { ICON_HEIGHT, ICON_WIDTH } from '../constants';

const useSizer = (props: HTMLProps<HTMLDivElement>) => {
  return useSize(() => <div {...props} />, {
    height: ICON_HEIGHT,
    width: ICON_WIDTH,
  });
};

export default useSizer;
