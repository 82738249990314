import { ServerMessage } from '@websktop/commons';
import { useEffect } from 'react';

import { useSetAnyWebsktop } from 'state';

import getActiveWebsktopsSocket from '../getActiveWebsktopsSocket';

const useWebsktopUpdates = () => {
  const socket = getActiveWebsktopsSocket();
  const setWebsktop = useSetAnyWebsktop();

  useEffect(() => {
    socket.on(ServerMessage.SET_WEBSKTOP, setWebsktop);

    return () => {
      socket.off(ServerMessage.SET_WEBSKTOP, setWebsktop);
    };
  }, [setWebsktop, socket]);
};

export default useWebsktopUpdates;
