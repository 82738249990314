import { Folder, getLinksDeep, isAnyItemAncestorOf, Websktop } from '@websktop/commons';
import { FunctionComponent, useMemo } from 'react';
import { useCopyToClipboard } from 'react-use';

import { ContextMenu, ContextMenuProps, Menu } from 'components';
import { useIsOnline, useOpenLinks } from 'hooks';
import { getAbsoluteUrl, isSafeUrl, pluralize } from 'lib';
import { useEditFolderModal } from 'modals';
import { exportBookmarks } from 'modules/bookmarks-exporter';
import routing from 'modules/routing';
import { useHotkey, WebsktopHotkey } from 'modules/websktop-hotkeys';
import { useClipboard, useClipboardItems, useFolder, useItemRename, usePermissions } from 'state';

import { useDeleteFolderModal } from '../delete-folder-modal';

interface Props extends Omit<ContextMenuProps, 'children'> {
  folderId: Folder['id'];
  websktopId: Websktop['id'];
}

const HOTKEY_DELAY = 50;

const FolderContextMenu: FunctionComponent<Props> = ({
  folderId,
  websktopId,
  onClose,
  ...props
}) => {
  const isOnline = useIsOnline();
  const { canEditItems } = usePermissions(websktopId);
  const openLinks = useOpenLinks();
  const folder = useFolder(websktopId, folderId);
  const itemRename = useItemRename(websktopId);
  const editFolderModal = useEditFolderModal();
  const deleteFolderModal = useDeleteFolderModal();
  const [, copy] = useCopyToClipboard();
  const clipboard = useClipboard(websktopId);
  const links = useMemo(() => getLinksDeep([folder]), [folder]);
  const safeLinks = links.filter((link) => isSafeUrl(link.url));
  const clipboardItems = useClipboardItems();
  const isMovingFolderIntoItself =
    clipboardItems.mode === 'cut' && isAnyItemAncestorOf(clipboardItems.items, folder);
  const isCutEnabled = canEditItems && isOnline;
  const isDeleteEnabled = canEditItems && isOnline;
  const isEditEnabled = canEditItems && isOnline;
  const isPasteEnabled = canEditItems && isOnline;
  const isRenameEnabled = canEditItems && isOnline;

  const handleOpenInNewTabsDeep = () => {
    openLinks(safeLinks);
    onClose();
  };

  const handleCopy = () => {
    clipboard.copy([folder]);
    onClose();
  };

  const handleCopyUrl = () => {
    const url = getAbsoluteUrl(routing.websktop.folder(websktopId, folder.id));
    copy(url);
    onClose();
  };

  const handleCopyName = () => {
    copy(folder.name);
    onClose();
  };

  const handleCut = () => {
    clipboard.cut([folder]);
    onClose();
  };

  const handleDelete = () => {
    deleteFolderModal.open({ folderId, websktopId });
    onClose();
  };

  const handleEdit = () => {
    editFolderModal.open({ folder, websktopId });
    onClose();
  };

  const handlePaste = () => {
    clipboard.paste({ targetFolder: folder });
    onClose();
  };

  const handleRename = () => {
    itemRename.editItem(folderId);
    onClose();
  };

  const handleExport = () => {
    exportBookmarks(folder.items);
  };

  const isCopyHotkeyPressed = useHotkey('copy', handleCopy, { delay: HOTKEY_DELAY });
  const isCutHotkeyPressed = useHotkey('cut', handleCut, { delay: HOTKEY_DELAY });
  const isDeleteHotkeyPressed = useHotkey('delete', handleDelete, { delay: HOTKEY_DELAY });
  const isPasteHotkeyPressed = useHotkey('paste', handlePaste, {
    delay: HOTKEY_DELAY,
    disabled: !clipboard.canPaste || isMovingFolderIntoItself,
  });
  const isEditHotkeyPressed = useHotkey('edit', handleEdit, { delay: HOTKEY_DELAY });
  const isRenameHotkeyPressed = useHotkey('rename', handleRename, { delay: HOTKEY_DELAY });

  return (
    <ContextMenu onClose={onClose} {...props}>
      <Menu>
        <Menu.ItemsGroup>
          <Menu.Item.Link
            addon={<WebsktopHotkey action="open" />}
            href={routing.websktop.folder(websktopId, folderId)}
            icon="external-link-square-alt"
            onClick={onClose}
          >
            Open
          </Menu.Item.Link>

          <Menu.Item.Link
            addon={<WebsktopHotkey action="open-in-new-tab" />}
            href={routing.websktop.folder(websktopId, folderId)}
            icon="external-link-square-alt"
            target="_blank"
            type="external"
            onClick={onClose}
          >
            Open in new tab
          </Menu.Item.Link>

          <Menu.Item.Button
            addon={<WebsktopHotkey action="open-deep" />}
            disabled={safeLinks.length === 0}
            icon="external-link-square-alt"
            onClick={handleOpenInNewTabsDeep}
          >
            Open {safeLinks.length}{' '}
            {pluralize(safeLinks.length, { one: 'bookmark', many: 'bookmarks' })} in new{' '}
            {pluralize(safeLinks.length, { one: 'tab', many: 'tabs' })} (deep)
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          <Menu.Item.Button icon="clipboard" onClick={handleCopyUrl}>
            Copy folder URL
          </Menu.Item.Button>

          {folder.name.trim().length > 0 && (
            <Menu.Item.Button icon="clipboard" onClick={handleCopyName}>
              Copy name
            </Menu.Item.Button>
          )}
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          {isCutEnabled && (
            <Menu.Item.Button
              addon={<WebsktopHotkey action="cut" />}
              icon="cut"
              pressed={isCutHotkeyPressed}
              onClick={handleCut}
            >
              Cut
            </Menu.Item.Button>
          )}

          <Menu.Item.Button
            addon={<WebsktopHotkey action="copy" />}
            icon="clipboard"
            pressed={isCopyHotkeyPressed}
            onClick={handleCopy}
          >
            Copy
          </Menu.Item.Button>

          {isPasteEnabled && (
            <Menu.Item.Button
              addon={<WebsktopHotkey action="paste" />}
              disabled={!clipboard.canPaste || isMovingFolderIntoItself}
              icon="arrow-down"
              pressed={isPasteHotkeyPressed}
              onClick={handlePaste}
            >
              Paste
            </Menu.Item.Button>
          )}
        </Menu.ItemsGroup>

        {(isEditEnabled || isRenameEnabled) && (
          <Menu.ItemsGroup>
            {isRenameEnabled && (
              <Menu.Item.Button
                addon={<WebsktopHotkey action="rename" />}
                icon="pencil-alt"
                pressed={isRenameHotkeyPressed}
                onClick={handleRename}
              >
                Rename
              </Menu.Item.Button>
            )}

            {isEditEnabled && (
              <Menu.Item.Button
                addon={<WebsktopHotkey action="edit" />}
                icon="pencil-alt"
                pressed={isEditHotkeyPressed}
                onClick={handleEdit}
              >
                Edit
              </Menu.Item.Button>
            )}
          </Menu.ItemsGroup>
        )}

        <Menu.ItemsGroup>
          <Menu.Item.Button icon="file-export" onClick={handleExport}>
            Export bookmarks
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        {isDeleteEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button
              addon={<WebsktopHotkey action="delete" />}
              icon="trash-alt"
              pressed={isDeleteHotkeyPressed}
              variant="danger"
              onClick={handleDelete}
            >
              Delete
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}
      </Menu>
    </ContextMenu>
  );
};

export default FolderContextMenu;
