import { FunctionComponent } from 'react';

import { Button } from 'components';

interface Props {
  className?: string;
}

const RefreshPageButton: FunctionComponent<Props> = ({ className }) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <Button className={className} icon="sync-alt" onClick={handleRefresh}>
      Refresh page
    </Button>
  );
};

export default RefreshPageButton;
