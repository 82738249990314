import auth from './auth';
import favicon from './favicon';
import system from './system';
import uploads from './uploads';
import user from './user';
import websktop from './websktop';

const api = { auth, favicon, system, uploads, user, websktop };

export default api;
