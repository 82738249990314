import { DEMO_ACCOUNT_TTL, HOUR, MINUTE, SECOND } from '@websktop/commons';
import { FunctionComponent, useState } from 'react';
import { useInterval } from 'react-use';

import routing from 'modules/routing';
import { useAuthenticatedUser, useLogout } from 'state';
import { InfoIcon, LinkButton } from 'components';

import Notice from '../Notice';

import styles from './DemoNotice.module.scss';

const formatTime = (milliseconds: number): string => {
  const hours = Math.floor(milliseconds / HOUR);
  const minutes = Math.floor((milliseconds - hours * HOUR) / MINUTE);
  const seconds = Math.floor((milliseconds - hours * HOUR - minutes * MINUTE) / SECOND);
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
};

const DemoNotice: FunctionComponent = () => {
  const user = useAuthenticatedUser();
  const logout = useLogout();
  const [expiresIn, setExpiresIn] = useState(DEMO_ACCOUNT_TTL - (Date.now() - user.createdAt));

  const handleSignUp = async () => {
    await logout();
    window.location.href = routing.auth.signUp.toString();
  };

  useInterval(
    () => {
      const newExpiresIn = Math.max(DEMO_ACCOUNT_TTL - (Date.now() - user.createdAt), 0);
      setExpiresIn(newExpiresIn);
    },
    expiresIn > 0 ? SECOND : null,
  );

  return (
    <Notice>
      Demo expires in {formatTime(expiresIn)}
      <InfoIcon
        className={styles.infoIcon}
        tooltip={
          <span>
            During sign up you will be able to choose whether you want to keep your demo data or
            start with a clean slate.
          </span>
        }
      />
      <LinkButton className={styles.signUp} type="button" onClick={handleSignUp}>
        Sign up
      </LinkButton>
    </Notice>
  );
};

export default DemoNotice;
