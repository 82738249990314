import { isFolder, Item } from '../types';

import findItem from './findItem';

const isAnyItemAncestorOf = (items: Item[], item: Item): boolean => {
  const folders = items.filter(isFolder);
  return folders.some((folder) => folder.parentId !== item.id && findItem(folder, item.id));
};

export default isAnyItemAncestorOf;
