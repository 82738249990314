import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';
import ReactModal from 'react-modal';

import Button from '../Button';
import Panel from '../Panel';

import Footer from './Footer';
import styles from './Modal.module.scss';

export interface Props {
  children: ReactNode;
  className?: string;
  footer?: ReactNode;
  header: ReactNode;
  isOpen: boolean;
  panelClassName?: string;
  onClose: () => void;
}

const Modal: FunctionComponent<Props> = ({
  children,
  className,
  footer,
  header,
  isOpen,
  panelClassName,
  onClose,
}) => {
  return (
    <ReactModal
      className={classNames(styles.modal, className)}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      shouldCloseOnOverlayClick={false}
      onRequestClose={onClose}
    >
      <div
        className={classNames(styles.content, {
          [styles.withFooter]: footer,
        })}
      >
        <Panel className={classNames(styles.panel, panelClassName)}>
          <div className={styles.header}>
            <div>{header}</div>
            <div className={styles.closeContainer}>
              <Button icon="times" onClick={onClose} />
            </div>
          </div>

          <div className={styles.children}>{children}</div>

          {footer && <div className={styles.footer}>{footer}</div>}
        </Panel>
      </div>
    </ReactModal>
  );
};

export default Object.assign(Modal, {
  Footer,
});
