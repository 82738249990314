import { CSSProperties } from 'react';

import { FormState } from '../types';

const getPreviewStyle = (formState: FormState): CSSProperties => {
  const { backgroundColor, backgroundType } = formState;

  if (backgroundType === 'cover') {
    return {
      backgroundColor,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    };
  }

  if (backgroundType === 'contain') {
    return {
      backgroundColor,
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    };
  }

  if (backgroundType === 'repeat') {
    return {
      backgroundColor,
      backgroundPosition: '0 0',
      backgroundSize: 'auto',
      backgroundRepeat: 'repeat',
    };
  }

  throw new Error('Invalid background type');
};

export default getPreviewStyle;
