import { BaseTile, GridLayoutOptions } from '../types';

import getItemsCount from './getItemsCount';

const getColumnsCount = <Tile extends BaseTile>(
  tiles: Tile[],
  { spacingColumn, tileWidth, width }: GridLayoutOptions,
) => {
  return tiles.reduce(
    (result, tile) => Math.max(result, tile.x + 1),
    getItemsCount(width, tileWidth, spacingColumn),
  );
};

export default getColumnsCount;
