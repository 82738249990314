import classNames from 'classnames';
import { forwardRef, SVGProps } from 'react';

import { ICON_HEIGHT, ICON_WIDTH } from 'modules/grid-layout';

import styles from './DropTarget.module.scss';

interface Props extends SVGProps<SVGSVGElement> {}

const DropTarget = forwardRef<SVGSVGElement, Props>(({ className, ...props }, ref) => (
  <svg
    className={classNames(className, styles.dropTarget)}
    ref={ref}
    version="1.0"
    viewBox={`0 0 ${ICON_WIDTH} ${ICON_HEIGHT}`}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect fill="transparent" height={ICON_HEIGHT} width={ICON_WIDTH} x={0} y={0} />
  </svg>
));

export default DropTarget;
