import type { CreateLinkPayload, Link } from '../types';

import iconToPayload from './iconToPayload';

const linkToCreatePayload = (link: Link): CreateLinkPayload => ({
  name: link.name,
  note: link.note,
  parentId: link.parentId,
  primaryIcon: iconToPayload(link.primaryIcon),
  url: link.url,
  x: link.x,
  y: link.y,
});

export default linkToCreatePayload;
