import { Plan } from '@websktop/commons';
import { useCallback } from 'react';

import { useNewWebsktopModal, useUpgradeModal } from 'modals';
import { useAuthenticatedUser } from 'state';

const useCreateNewWebsktop = () => {
  const user = useAuthenticatedUser();
  const newWebsktopModal = useNewWebsktopModal();
  const upgradeModal = useUpgradeModal();
  const plan = user.subscription.plan;

  const createNewWebsktop = useCallback(() => {
    if (plan === Plan.FREE) {
      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to create more Websktops?</h2>
            Professional Plan supports <strong>unlimited</strong> number of Websktops.
          </div>
        ),
        source: 'sidebar - new websktop',
      });
    } else {
      newWebsktopModal.open();
    }
  }, [newWebsktopModal, plan, upgradeModal]);

  return createNewWebsktop;
};

export default useCreateNewWebsktop;
