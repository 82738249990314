import api from './lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const system = {
  async getBuildId(): Promise<string> {
    return api.getText(`${API_ORIGIN}/build-id`);
  },
};

export default system;
