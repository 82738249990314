import { Item } from '@websktop/commons';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useDragLayer } from 'react-dnd';
import { Portal } from 'react-portal';

import { DND_WEBSKTOP_ITEM } from 'constants/websktop';
import { useBodyClassNames } from 'hooks';
import { useAppLayout } from 'modules/app-layout';
import { Grid, GridLayoutOptions, WebsktopIcon } from 'modules/grid-layout';

import ItemIcon from '../ItemIcon';

import styles from './DragLayer.module.scss';

interface Props {
  className?: string;
  contentOffsetY: number;
  height: number;
  items: Item[];
  options: GridLayoutOptions;
  scrollX: number;
  scrollY: number;
  width: number;
}

const DragLayer: FunctionComponent<Props> = ({
  className,
  height,
  items,
  options,
  scrollX,
  scrollY,
  contentOffsetY,
  width,
}) => {
  const { sidebarWidth } = useAppLayout();
  const { currentOffset, initialOffset, isDragging, itemType, sourceItem } = useDragLayer(
    (monitor) => ({
      currentOffset: monitor.getSourceClientOffset(),
      initialOffset: monitor.getInitialSourceClientOffset(),
      isDragging: monitor.isDragging(),
      itemType: monitor.getItemType(),
      sourceItem: monitor.getItem(),
    }),
  );

  useBodyClassNames({ [styles.globalNoScroll]: isDragging });

  if (!isDragging || !initialOffset || !currentOffset || itemType !== DND_WEBSKTOP_ITEM) {
    return null;
  }

  const left = currentOffset.x - initialOffset.x - scrollX + sidebarWidth;
  const top = currentOffset.y - initialOffset.y - scrollY + contentOffsetY;

  return (
    <Portal>
      <Grid<Item>
        className={classNames(styles.dragLayer, className)}
        options={options}
        tabIndex={-1}
        tiles={items}
        style={{ left, top, width: `${width - left}px`, height: `${height - top}px` }}
      >
        {({ tile }) => (
          <WebsktopIcon
            image={<ItemIcon item={tile} />}
            isSelected={tile.id === sourceItem.id}
            name={tile.name}
            type="internal"
            url=""
            onNameCancel={noop}
            onNameChange={noop}
            onNameSubmit={noop}
          />
        )}
      </Grid>
    </Portal>
  );
};

export default DragLayer;
