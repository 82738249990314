import { Folder, Websktop } from '@websktop/commons';
import { useKey } from 'react-use';

import { arrowKeyToDirection, isArrowKey } from 'lib';
import { getNextTileId } from 'modules/grid-layout';
import { useFolder, useSelection } from 'state';
import { ArrowKey } from 'types';

import { UseWebsktopHotkey } from '../types';

import { focusSidebar } from './lib';

type Params = { folderId: Folder['id']; websktopId: Websktop['id'] };

const useArrowNavigation: UseWebsktopHotkey<Params> = ({ folderId, websktopId }, isEnabled) => {
  const [selection, { set }] = useSelection();
  const { items } = useFolder(websktopId, folderId);

  useKey(
    (event) => isEnabled && isArrowKey(event.key),
    (event) => {
      const [selectedItemId] = selection;
      const arrowKey = event.key as ArrowKey;
      const direction = arrowKeyToDirection(arrowKey);
      const nextItemId = getNextTileId(items, selectedItemId, direction);

      if (!nextItemId && direction === 'left') {
        focusSidebar();
        return;
      }

      if (nextItemId) {
        event.preventDefault();
        event.stopPropagation();
        set([nextItemId]);
      }
    },
    undefined,
    [isEnabled, items, selection, set],
  );
};

export default useArrowNavigation;
