import { ServerMessage, SetWebsktopOnlineUsersHandler, Websktop } from '@websktop/commons';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { websktopOnlineUsersAtomFamily } from '../state';

import getActiveWebsktopsSocket from '../getActiveWebsktopsSocket';

const useWebsktopOnlineUsersUpdates = (websktopId: Websktop['id']) => {
  const socket = getActiveWebsktopsSocket();
  const setOnlineUsers = useSetRecoilState(websktopOnlineUsersAtomFamily(websktopId));

  useEffect(() => {
    const handleOnlineUsersUpdate: SetWebsktopOnlineUsersHandler = (eventWebsktopId, users) => {
      if (eventWebsktopId === websktopId) {
        setOnlineUsers(users);
      }
    };

    socket.on(ServerMessage.SET_ONLINE_USERS, handleOnlineUsersUpdate);

    return () => {
      socket.off(ServerMessage.SET_ONLINE_USERS, handleOnlineUsersUpdate);
    };
  }, [setOnlineUsers, socket, websktopId]);
};

export default useWebsktopOnlineUsersUpdates;
