import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { atom, AtomEffect, selector, useRecoilValue, useSetRecoilState } from 'recoil';

import { ClipboardMimeType } from 'constants/mime-type';
import { getUrlsFromHtml, getUrlsFromText, systemClipboard } from 'lib';

import { EMPTY_ITEMS_STATE, EMPTY_STATE } from './constants';
import { decodeItemsState, onActivationTriggeringInputEvent } from './lib';
import { ItemsState, State } from './types';

let lastWriteTimestamp = 0;

const syncClipboard: AtomEffect<State> = ({ setSelf }) => {
  return onActivationTriggeringInputEvent(async () => {
    try {
      const start = Date.now();
      const clipboardState = await systemClipboard.read();

      if (start < lastWriteTimestamp) {
        return;
      }

      setSelf((currentState) => {
        return isEqual(clipboardState, currentState) ? currentState : clipboardState;
      });
    } catch {
      // Couldn't read clipboard data (no permissions, or window is not focused).
      // Can be safely ignored.
    }
  });
};

const clipboardStateAtom = atom<State>({
  key: 'clipboard',
  default: EMPTY_STATE,
  effects: [syncClipboard],
});

const clipboardItemsSelector = selector<ItemsState>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'clipboard-items',
  get: ({ get }) => {
    const html = get(clipboardStateAtom)[ClipboardMimeType.HTML];

    if (!html) {
      return EMPTY_ITEMS_STATE;
    }

    return decodeItemsState(html) || EMPTY_ITEMS_STATE;
  },
});

const clipboardUrlsSelector = selector<string[]>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'clipboard-urls',
  get: ({ get }) => {
    const html = get(clipboardStateAtom)[ClipboardMimeType.HTML];
    const text = get(clipboardStateAtom)[ClipboardMimeType.TEXT];
    const urlsFromHtml = getUrlsFromHtml(html);
    const urlsFromText = getUrlsFromText(text);
    const urls = urlsFromHtml.length > 0 ? urlsFromHtml : urlsFromText;
    return urls;
  },
});

const canPasteSelector = selector<boolean>({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'can-paste',
  get: ({ get }) => {
    const urls = get(clipboardUrlsSelector);
    const itemsState = get(clipboardItemsSelector);
    return itemsState.items.length > 0 || urls.length > 0;
  },
});

export const useClipboardItems = () => useRecoilValue(clipboardItemsSelector);

export const useSetClipboardState = () => {
  const setClipboardState = useSetRecoilState(clipboardStateAtom);

  return useCallback(
    async (state: State) => {
      setClipboardState(state);
      lastWriteTimestamp = Date.now();
      await systemClipboard.write(state);
      lastWriteTimestamp = Date.now();
    },
    [setClipboardState],
  );
};

export const useCanPaste = () => useRecoilValue(canPasteSelector);
