import { FunctionComponent } from 'react';

import EmojiIcon from '../EmojiIcon';
import FaviconIcon from '../FaviconIcon';
import UploadedIcon from '../UploadedIcon';

interface Props {
  className?: string;
  iconEmoji?: string;
  iconUrl?: string;
  name: string;
  size: number;
  url: string;
}

const LinkIcon: FunctionComponent<Props> = ({ className, iconEmoji, iconUrl, name, size, url }) => {
  if (iconUrl) {
    return <UploadedIcon alt={name} className={className} url={iconUrl} size={size} />;
  }

  if (iconEmoji) {
    return <EmojiIcon className={className} emoji={iconEmoji} size={size} />;
  }

  return <FaviconIcon className={className} size={size} url={url} />;
};

export default LinkIcon;
