import { MouseEventHandler } from 'react';

import openLink from './openLink';

const MIDDLE_MOUSE_BUTTON = 1;

const createMultiLinkHandler = (
  hrefs: string[],
  options: Parameters<typeof openLink>[1],
): MouseEventHandler<HTMLAnchorElement> => {
  return (event) => {
    const isCtrlOpen = event.ctrlKey || event.metaKey; // handles "Ctrl+Enter" & "Ctrl+Click"
    const isMiddleMouseButtonClick = event.button === MIDDLE_MOUSE_BUTTON;

    if (!isCtrlOpen && !isMiddleMouseButtonClick) {
      // There still are 3 native ways left to interact with links:
      // 1. "Click"/"Enter" opens original href
      // 2. "Alt+Click" opens original href in a new tab
      // 3. "Shift+Click" opens original href in a new window
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    for (const href of hrefs) {
      openLink(href, options);
    }
  };
};

export default createMultiLinkHandler;
