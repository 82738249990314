import { selector, useRecoilValue } from 'recoil';

import { searchResultsAtom } from './state';

const searchResultsIdsSetSelector = selector({
  cachePolicy_UNSTABLE: { eviction: 'most-recent' },
  key: 'search-results-ids-set',
  get: ({ get }) => {
    const searchResults = get(searchResultsAtom);
    const ids = searchResults.map((result) => result.item.id);
    return new Set(ids);
  },
});

export const useSearchResultsIdsSet = () => useRecoilValue(searchResultsIdsSetSelector);
