import { useCallback } from 'react';

import { Notification } from 'types';

import { useSetNotifications } from '../atom';

type RemoveNotification = (id: Notification['id']) => void;

const useRemoveNotification = (): RemoveNotification => {
  const setNotifications = useSetNotifications();

  const removeNotification: RemoveNotification = useCallback(
    (id) => {
      setNotifications((notifications) => {
        return notifications.filter((notification) => notification.id !== id);
      });
    },
    [setNotifications],
  );

  return removeNotification;
};

export default useRemoveNotification;
