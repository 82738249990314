import {
  LoginPayload,
  RegisterPayload,
  ResendVerificationEmailPayload,
  ResetPasswordPayload,
  UpdatePasswordPayload,
  User,
  VerifyAccountPayload,
} from '@websktop/commons';

import api from './lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const auth = {
  async demo(): Promise<User> {
    return api.postJson<User>(`${API_ORIGIN}/account/demo`);
  },

  async login(payload: LoginPayload): Promise<User> {
    return api.postJson<User>(`${API_ORIGIN}/account/login`, payload);
  },

  async logout(): Promise<void> {
    await api.post(`${API_ORIGIN}/account/logout`);
  },

  async register(payload: RegisterPayload): Promise<void> {
    await api.post(`${API_ORIGIN}/account/register`, payload);
  },

  async resendVerificationEmail(payload: ResendVerificationEmailPayload): Promise<void> {
    await api.post(`${API_ORIGIN}/account/resend-verification-link`, payload);
  },

  async resetPassword(payload: ResetPasswordPayload): Promise<void> {
    await api.post(`${API_ORIGIN}/account/reset-password`, payload);
  },

  async updatePassword(payload: UpdatePasswordPayload): Promise<void> {
    await api.post(`${API_ORIGIN}/account/update-password`, payload);
  },

  async verify(payload: VerifyAccountPayload): Promise<User> {
    return api.postJson<User>(`${API_ORIGIN}/account/verify`, payload);
  },
};

export default auth;
