import {
  Icon,
  iconToPayload,
  Link,
  updateItemInWebsktop,
  UpdateLinkPayload,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetAnyWebsktop } from '../atom';
import { ImageUpload } from '../types';

type UpdateLink = (
  link: Link,
  payload: UpdateLinkPayload,
  uploads?: {
    primaryIcon?: ImageUpload;
  },
) => Promise<void>;

const useUpdateLink = (websktopId: Websktop['id']): UpdateLink => {
  const setWebsktop = useSetAnyWebsktop();
  const optimisticUpdates = useOptimisticUpdates();

  const updateLink = useCallback<UpdateLink>(
    async (link, payload, uploads) => {
      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop) => {
        const upload =
          typeof uploads?.primaryIcon?.fallbackUrl === 'undefined'
            ? link.primaryIcon.upload
            : { id: '', url: uploads.primaryIcon.fallbackUrl };

        const primaryIcon: Icon = {
          ...link.primaryIcon,
          ...payload.primaryIcon,
          upload,
        };

        return updateItemInWebsktop(websktop, { ...link, ...payload, primaryIcon });
      });

      try {
        const primaryIconUpload = await uploads?.primaryIcon?.promise;
        let primaryIconUploadId = link.primaryIcon.upload?.id ?? null;

        if (typeof primaryIconUpload !== 'undefined') {
          primaryIconUploadId = primaryIconUpload?.id ?? null;
        }

        const websktop = await api.websktop.patchLink({
          linkId: link.id,
          websktopId,
          ...payload,
          primaryIcon: {
            ...iconToPayload(link.primaryIcon),
            ...payload.primaryIcon,
            uploadId: primaryIconUploadId,
          },
        });

        setWebsktop(websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, websktopId],
  );

  return updateLink;
};

export default useUpdateLink;
