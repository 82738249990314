import { Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import { useClipboard, useItems, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useCopy: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const { copy } = useClipboard(websktopId);
  const items = useItems(websktopId, selection);

  const handleCopy = useCallback(() => copy(items), [copy, items]);

  useHotkey('copy', handleCopy, { disabled: !isEnabled || items.length === 0 });
};

export default useCopy;
