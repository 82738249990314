import { isFolder, isLink, Item } from '@websktop/commons';
import { FunctionComponent } from 'react';

import { FolderIcon, LinkIcon } from 'modules/entity-icons';
import styles from './Result.module.scss';

const ICON_SIZE = parseInt(styles.ICON_SIZE, 10);

interface Props {
  className?: string;
  item: Item;
}

const Icon: FunctionComponent<Props> = ({ className, item }) => {
  if (isFolder(item)) {
    return <FolderIcon className={className} size={ICON_SIZE} />;
  }

  if (isLink(item)) {
    return (
      <LinkIcon
        className={className}
        iconEmoji={item.primaryIcon.emoji}
        iconUrl={item.primaryIcon.upload?.url}
        name={item.name}
        size={ICON_SIZE}
        url={item.url}
      />
    );
  }

  throw new Error('Given item is neither a bookmark nor a folder');
};

export default Icon;
