import classNames from 'classnames';
import { FunctionComponent, HTMLProps, ReactNode } from 'react';

import Icon from '../Icon';
import { useTooltip } from '../Tooltip';

import styles from './InfoIcon.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  className?: string;
  tooltip?: ReactNode;
}

const InfoIcon: FunctionComponent<Props> = ({
  className,
  tooltip,
  onBlur,
  onFocus,
  onMouseOut,
  onMouseOver,
  ...props
}) => {
  const tooltipProps = useTooltip(tooltip, {
    onBlur,
    onFocus,
    onMouseOut,
    onMouseOver,
  });

  return (
    <div
      className={classNames(styles.infoIcon, className)}
      tabIndex={0}
      {...props}
      {...tooltipProps}
    >
      <Icon className={styles.icon} icon="info-circle" />
    </div>
  );
};

export default InfoIcon;
