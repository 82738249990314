import { Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';

import { useTooltip } from 'components';
import { useShowErrorNotification, useSubscribeWebsktop } from 'state';

import StatusBarButton from '../StatusBarButton';

interface Props {
  websktopId: Websktop['id'];
}

const SubscribeButton: FunctionComponent<Props> = ({ websktopId }) => {
  const subscribe = useSubscribeWebsktop(websktopId);
  const showErrorNotification = useShowErrorNotification();

  const tooltipProps = useTooltip(
    <>
      <strong>Pin Websktop</strong>
      <div>Pin this Websktop to your sidebar</div>
    </>,
    { placement: 'bottom' },
  );

  const [{ loading: isLoading }, handleSubscribe] = useAsyncFn(async () => {
    try {
      await subscribe();
    } catch (error) {
      showErrorNotification(error);
      throw error;
    }
  }, [showErrorNotification, subscribe]);

  return (
    <StatusBarButton
      disabled={isLoading}
      icon="thumbtack"
      onClick={handleSubscribe}
      {...tooltipProps}
    />
  );
};

export default SubscribeButton;
