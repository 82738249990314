type Precision = 'minutes' | 'seconds';

const formatTime = (date: number | Date, precision: Precision = 'minutes'): string => {
  const dateObject = typeof date === 'number' ? new Date(date) : date;
  const hours = String(dateObject.getHours()).padStart(2, '0');
  const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  const seconds = String(dateObject.getSeconds()).padStart(2, '0');

  if (precision === 'minutes') {
    return `${hours}:${minutes}`;
  }

  return `${hours}:${minutes}:${seconds}`;
};

export default formatTime;
