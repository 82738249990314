import { findEmptyPoint, findItem, Folder, Websktop } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, HTMLProps, memo, useMemo, useRef } from 'react';
import { useWindowSize } from 'react-use';

import { Button } from 'components';
import { useMediaQuery } from 'hooks';
import BackgroundContextMenu from 'modals/background-context-menu';
import { useWebsktop } from 'state';

import styles from './MenuButton.module.scss';

interface Props extends Omit<HTMLProps<HTMLButtonElement>, 'type'> {
  className?: string;
  folderId: Folder['id'];
  isMenuOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
  onOpen: () => void;
}

const BUTTON_SIZE = parseInt(styles.BUTTON_SIZE, 10);
const PADDING_MOBILE = 16;
const PADDING_DESKTOP = 24;
const OFFSET_X_MOBILE = PADDING_MOBILE;
const OFFSET_X_DESKTOP = PADDING_DESKTOP;
const OFFSET_Y_MOBILE = BUTTON_SIZE + 2 * PADDING_MOBILE;
const OFFSET_Y_DESKTOP = BUTTON_SIZE + 2 * PADDING_DESKTOP;

const getMenuPoint = (windowSize: ReturnType<typeof useWindowSize>, isMobile: boolean) => ({
  x: windowSize.width - (isMobile ? OFFSET_X_MOBILE : OFFSET_X_DESKTOP),
  y: windowSize.height - (isMobile ? OFFSET_Y_MOBILE : OFFSET_Y_DESKTOP),
});

const MenuButton: FunctionComponent<Props> = ({
  className,
  folderId,
  isMenuOpen,
  websktopId,
  onClose,
  onOpen,
  ...props
}) => {
  const websktop = useWebsktop(websktopId);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const windowSize = useWindowSize();
  const isMobile = useMediaQuery('<s', false);
  const menuPoint = useMemo(() => getMenuPoint(windowSize, isMobile), [isMobile, windowSize]);
  const availableWebsktopPoint = useMemo(() => {
    const folder = findItem(websktop.folder, folderId) as Folder;
    const points = folder.items.map((item) => ({ x: item.x, y: item.y }));
    return findEmptyPoint(points);
  }, [folderId, websktop.folder]);

  return (
    <>
      <Button
        {...props}
        className={classNames(className, styles.button, {
          [styles.menuOpen]: isMenuOpen,
        })}
        icon={isMenuOpen ? 'times' : 'ellipsis-h'}
        ref={buttonRef}
        size="large"
        variant={isMenuOpen ? 'default' : 'primary'}
        onClick={isMenuOpen ? onClose : onOpen}
      />

      {isMenuOpen && (
        <BackgroundContextMenu
          folderId={folderId}
          gridPoint={availableWebsktopPoint}
          isOpen
          placement="top-end"
          point={menuPoint}
          websktopId={websktopId}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default memo(MenuButton);
