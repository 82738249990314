import { FunctionComponent } from 'react';

import { KeyboardShortcut } from 'components';

import hotkeysMap from './hotkeysMap';
import { Action } from './types';

interface Props {
  action: Action;
}

const WebsktopHotkey: FunctionComponent<Props> = ({ action }) => (
  <KeyboardShortcut hotkey={hotkeysMap[action]} />
);

export default WebsktopHotkey;
