import { ClipboardMimeType } from 'constants/mime-type';

import { ItemsState, State } from './types';

export const DATA_ATTRIBUTE_NAME = 'data-websktop-json';

export const EMPTY_ITEMS_STATE: ItemsState = {
  actionId: undefined,
  items: [],
  mode: 'copy',
  websktopId: undefined,
};

export const EMPTY_STATE: State = {
  [ClipboardMimeType.HTML]: '',
  [ClipboardMimeType.TEXT]: '',
};
