import { isLink, Item, Link } from '../types';

import getLinksMapDeep from './getLinksMapDeep';

const getLinksDeep = (items: Item[]): Link[] => {
  return items.reduce<Link[]>((result, item) => {
    if (isLink(item)) {
      return [...result, item];
    }

    return [...result, ...Object.values(getLinksMapDeep(item))];
  }, []);
};

export default getLinksDeep;
