import { Folder, getLinksDeep, isFolder, isLink, Item, Link, Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useOpenLinks } from 'hooks';
import { isSafeUrl, openLink } from 'lib';
import routing from 'modules/routing';
import { useItems, useSelection, useUserSettings } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

const isSingleLink = (items: Item[]): items is [Link] => {
  return items.length === 1 && isLink(items[0]);
};

const isSingleFolder = (items: Item[]): items is [Folder] => {
  return items.length === 1 && isFolder(items[0]);
};

type Params = { websktopId: Websktop['id'] };

const useOpen: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const navigate = useNavigate();
  const openLinks = useOpenLinks();
  const [selection] = useSelection();
  const items = useItems(websktopId, selection);
  const userSettings = useUserSettings();

  const handleOpen = useCallback(() => {
    if (isSingleLink(items)) {
      const [link] = items;

      if (isSafeUrl(link.url)) {
        openLink(link.url, {
          rel: 'noopener noreferrer',
          target: userSettings.forceNewTab ? '_blank' : '_self',
        });
      }
    } else if (isSingleFolder(items)) {
      const [folder] = items;
      navigate(routing.websktop.folder(websktopId, folder.id));
    } else {
      const links = getLinksDeep(items);
      const safeLinks = links.filter((link) => isSafeUrl(link.url));
      openLinks(safeLinks);
    }
  }, [items, navigate, openLinks, userSettings.forceNewTab, websktopId]);

  useHotkey('open', handleOpen, {
    disabled: !isEnabled || items.length === 0,
    preventDefault: true,
  });
};

export default useOpen;
