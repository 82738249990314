import classNames from 'classnames';
import { forwardRef } from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

import { Props as ButtonProps, styles as buttonStyles } from '../Button';

import styles from './Link.module.scss';

export interface Props extends Omit<RouterLinkProps, 'to'> {
  disabled?: boolean;
  href?: string;
  inline?: ButtonProps['inline'];
  size?: ButtonProps['size'];
  type?: 'internal' | 'mailto' | 'external';
  variant?: ButtonProps['variant'];
}

const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      children,
      className: classNameProp,
      href,
      inline,
      size,
      type = 'internal',
      variant,
      ...props
    },
    ref,
  ) => {
    const disabled = props.disabled;
    const isButton = typeof variant !== 'undefined';
    const className = classNames(styles.link, classNameProp, {
      [styles.button]: isButton,
      [buttonStyles.button]: isButton,
      [buttonStyles.compact]: isButton && !children,
      [buttonStyles.danger]: isButton && variant === 'danger',
      [buttonStyles.default]: isButton && variant === 'default',
      [buttonStyles.inline]: isButton && inline,
      [buttonStyles.large]: size === 'large',
      [buttonStyles.primary]: isButton && variant === 'primary',
    });

    if (type === 'external' || typeof href === 'undefined' || disabled) {
      return (
        <a
          className={className}
          href={disabled ? undefined : href}
          rel="noopener noreferrer"
          ref={ref}
          {...props}
        >
          {children}
        </a>
      );
    }

    if (type === 'mailto') {
      return (
        <a className={className} href={`mailto:${href}`} ref={ref} {...props}>
          {children}
        </a>
      );
    }

    return (
      <RouterLink className={className} ref={ref} to={href} {...props}>
        {children}
      </RouterLink>
    );
  },
);

export default Link;
