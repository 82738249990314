import {
  arrangeNewItems,
  Folder,
  isMovingFolderIntoItself,
  Item,
  Point,
  removeItemsFromFolder,
} from '@websktop/commons';

const moveItems = (items: Item[], targetFolder: Folder, targetPoint?: Point): Item[] => {
  if (items.length === 0) {
    return [];
  }

  if (isMovingFolderIntoItself(items, targetFolder.id)) {
    return items;
  }

  const isMovingWithinFolder = items[0].parentId === targetFolder.id;
  const itemsIds = items.map(({ id }) => id);
  const folder = isMovingWithinFolder
    ? removeItemsFromFolder(targetFolder, itemsIds)
    : targetFolder;
  const locations = arrangeNewItems(folder, items, targetPoint);

  return Object.entries(locations).map(([id, { x, y }]) => {
    const item = items.find((item) => item.id === id)!;
    return { ...item, parentId: folder.id, x, y };
  });
};

export default moveItems;
