import { ClientToServerEvents, ServerToClientEvents } from '@websktop/commons';
import { io, Socket } from 'socket.io-client';

const REACT_APP_URL = process.env.REACT_APP_URL;

const socket: Socket<ServerToClientEvents, ClientToServerEvents> = io(REACT_APP_URL, {
  autoConnect: false,
  path: '/websocket',
  transports: ['websocket'],
  secure: true,
  withCredentials: true,
});

export default socket;
