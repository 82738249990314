import { Id, Websktop } from '../types';

import removeItemsFromFolder from './removeItemsFromFolder';

const removeItemsFromWebsktop = (websktop: Websktop, itemsIds: Id[]): Websktop => ({
  ...websktop,
  folder: removeItemsFromFolder(websktop.folder, itemsIds),
});

export default removeItemsFromWebsktop;
