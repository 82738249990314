import useMergedRef from '@react-hook/merged-ref';
import {
  Folder,
  getBoundaries,
  isFolder,
  isFolderPointEmpty,
  isItem,
  Item,
  Websktop,
} from '@websktop/commons';
import classNames from 'classnames';
import { FocusEventHandler, forwardRef, MouseEvent, useMemo, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { useEvent, useLatest, useScroll } from 'react-use';
import { NativeTypes } from 'react-dnd-html5-backend';

import { WEBSKTOP_CONTENT_ELEMENT_ID } from 'constants/dom';
import { DND_WEBSKTOP_ITEM } from 'constants/websktop';
import { useIsOnline, useScrollSize, useTouchContextMenu } from 'hooks';
import { getRelativePoint, isCtrlKey } from 'lib';
import { useBackgroundContextMenu } from 'modals';
import { useIsNewUser } from 'modules/auth';
import { getUrlsFromDropEvent } from 'modules/dnd';
import { getGridLayoutOptions, getNextTileId, Grid, useSizer } from 'modules/grid-layout';
import {
  useBackgroundPreview,
  useCreateLinksFromUrls,
  useFolder,
  useFolderCreate,
  useItemWebsktopMapLoadable,
  useMoveItems,
  usePermissions,
  useShowErrorNotification,
  useWebsktop,
} from 'state';

import { useDraggedItemsPositions, useItemsSelection } from '../../hooks';
import { getClosestWebsktopLocationFromPoint, TouchEventManager } from '../../lib';
import ContextMenuCue from '../ContextMenuCue';
import DragLayer from '../DragLayer';
import DropTarget from '../DropTarget';
import UsersCursors from '../UsersCursors';
import WebsktopItem from '../WebsktopItem';

import styles from './Content.module.scss';
import { getBackgroundStyle } from './lib';

const MOUSE_BUTTON_LEFT = 0;

interface Props {
  className?: string;
  folderId: Folder['id'];
  websktopId: Websktop['id'];
}

const Content = forwardRef<HTMLDivElement, Props>(({ className, folderId, websktopId }, ref) => {
  const isOnline = useIsOnline();
  const websktop = useWebsktop(websktopId);
  const folder = useFolder(websktopId, folderId);
  const isNewUser = useIsNewUser();
  const moveItems = useMoveItems(websktopId);
  const createLinksFromUrls = useCreateLinksFromUrls(websktopId);
  const folderCreate = useFolderCreate(websktopId);
  const items = useMemo(
    () => (folderCreate.folder ? [...folder.items, folderCreate.folder] : folder.items),
    [folder.items, folderCreate.folder],
  );
  const itemWebsktopMapLoadable = useItemWebsktopMapLoadable();
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [contentRef, { scrollHeight, scrollWidth }] = useScrollSize();
  const { x: scrollX, y: scrollY } = useScroll(rootRef);
  const rectangleRef = useRef<HTMLDivElement | null>(null);
  const isMouseDownRef = useRef<boolean>(false);
  const selection = useItemsSelection(folder, rootRef, rectangleRef);
  const { canEditItems } = usePermissions(websktopId);
  const selectionRef = useLatest(selection);
  const [draggedItemsPositions, changeDropPoint] = useDraggedItemsPositions(
    folder,
    selection.items,
  );
  const [sizer, { width, height }] = useSizer({ className: styles.sizer });
  const gridLayoutOptions = useMemo(() => getGridLayoutOptions(width, height), [height, width]);
  const backgroundContextMenu = useBackgroundContextMenu();
  const showErrorNotification = useShowErrorNotification();
  const backgroundPreview = useBackgroundPreview();

  const isDragEnabled = canEditItems && isOnline;
  const isDropEnabled = canEditItems && isOnline;
  const contentOffsetY = window.innerHeight - height;

  useEvent('dragend', () => {
    changeDropPoint(null);
  });

  const [, dropRef] = useDrop<Item, Promise<void>, {}>(
    () => ({
      accept: isDropEnabled
        ? [DND_WEBSKTOP_ITEM, NativeTypes.HTML, NativeTypes.TEXT, NativeTypes.URL]
        : [],
      canDrop: (event, monitor) => {
        const point = monitor.getClientOffset();

        if (point === null) {
          return false;
        }

        const isNativeDrop = !isItem(event);

        if (isNativeDrop) {
          return getUrlsFromDropEvent(event).length > 0;
        }

        const websktopPoint = getClosestWebsktopLocationFromPoint(
          getRelativePoint(rootRef.current, point),
        );

        if (websktopPoint.x < 0 || websktopPoint.y < 0) {
          return false;
        }

        if (websktopPoint === null) {
          return false;
        }

        const targetItem = folder.items.find(
          (item) => item.x === websktopPoint.x && item.y === websktopPoint.y,
        );
        const isTargetSelected = targetItem ? selection.ids.includes(targetItem.id) : false;
        const isTargetAFolder = isFolder(targetItem);
        return isFolderPointEmpty(folder, websktopPoint) || isTargetAFolder || isTargetSelected;
      },

      drop: async (event, monitor) => {
        const point = monitor.getClientOffset();

        changeDropPoint(null);

        if (point === null) {
          return;
        }

        const isNativeDrop = !isItem(event);
        const websktopPoint = getClosestWebsktopLocationFromPoint(
          getRelativePoint(rootRef.current, point),
        );

        if (websktopPoint === null) {
          return;
        }

        if (isNativeDrop) {
          const urls = getUrlsFromDropEvent(event);
          createLinksFromUrls({ urls, parentId: folder.id, ...websktopPoint });
          return;
        }

        if (itemWebsktopMapLoadable.state !== 'hasValue' || selection.items.length === 0) {
          return;
        }

        const item = event;
        const { minX, minY } = getBoundaries(selection.items);
        const x = Math.max(websktopPoint.x - (item.x - minX), 0);
        const y = Math.max(websktopPoint.y - (item.y - minY), 0);
        const sourceWebsktopId = itemWebsktopMapLoadable.contents[selection.items[0].id];

        try {
          await moveItems({
            items: selection.items,
            sourceWebsktopId,
            targetFolder: folder,
            targetPoint: { x, y },
          });
        } catch (error) {
          showErrorNotification(error);
          throw error;
        }
      },

      hover: (item: Item, monitor) => {
        const point = monitor.getClientOffset();

        if (!monitor.canDrop()) {
          changeDropPoint(null);
          return null;
        }

        if (point === null) {
          return null;
        }

        const relativePoint = getRelativePoint(rootRef.current, point);
        const websktopPoint = getClosestWebsktopLocationFromPoint(relativePoint);

        if (websktopPoint === null) {
          changeDropPoint(null);
          return;
        }

        const { minX, minY } = getBoundaries(selection.items);
        const x = Math.max(websktopPoint.x - (item.x - minX), 0);
        const y = Math.max(websktopPoint.y - (item.y - minY), 0);

        changeDropPoint({ x, y });
      },
    }),
    [
      isDropEnabled,
      createLinksFromUrls,
      folder,
      items,
      moveItems,
      rootRef,
      selection.ids,
      selection.items,
      changeDropPoint,
      showErrorNotification,
    ],
  );

  const backgroundRef = useMergedRef(contentRef, dropRef, rootRef, ref);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const point = { x: event.clientX, y: event.clientY };
    const gridPoint = getClosestWebsktopLocationFromPoint(getRelativePoint(rootRef.current, point));
    backgroundContextMenu.open({ folderId: folder.id, gridPoint, point, websktopId });
  };

  const handleTouchContextMenu = useTouchContextMenu((event) => {
    // We need to ignore an event if it was already handled in WebsktopItem.
    // We cannot stopPropagation of item's event, since event has to reach `window.document`, on which react-dnd listens.
    if (TouchEventManager.isHandled(event)) {
      return;
    }

    const point = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    const gridPoint = getClosestWebsktopLocationFromPoint(getRelativePoint(rootRef.current, point));
    backgroundContextMenu.open({ folderId: folder.id, gridPoint, point, websktopId });
  });

  const handleMouseDown = (event: MouseEvent) => {
    isMouseDownRef.current = true;

    if (event.button === MOUSE_BUTTON_LEFT) {
      if (!isCtrlKey(event)) {
        selection.set([]);
      }

      selection.drawStart({ x: event.clientX, y: event.clientY });
    }
  };

  const handleFocus: FocusEventHandler = (event) => {
    // We only want to focus first item if user navigated to Websktop using keyboard
    if (isMouseDownRef.current) {
      return;
    }

    if (selection.ids.length === 0 && items.length > 0) {
      const firstItemId = getNextTileId(items, null, 'right');
      if (firstItemId) {
        selection.set([firstItemId]);
      }
    }
  };

  useEvent('mousemove', (event: MouseEvent) => {
    if (rootRef.current) {
      selection.drawMove({ x: event.clientX, y: event.clientY });
    }
  });

  useEvent('mouseup', () => {
    isMouseDownRef.current = false;

    if (selection.isDrawing) {
      selection.drawEnd();
    }
  });

  const draggedItemsTiles = useMemo(() => {
    return draggedItemsPositions.map((item) => ({ ...item, id: `${item.x}-${item.y}` }));
  }, [draggedItemsPositions]);

  return (
    <div
      className={classNames(styles.content, className)}
      data-tour="websktop"
      id={WEBSKTOP_CONTENT_ELEMENT_ID}
      ref={backgroundRef}
      style={getBackgroundStyle(backgroundPreview || websktop)}
      tabIndex={0}
      onContextMenu={handleContextMenu}
      onFocus={handleFocus}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchContextMenu}
    >
      {sizer}

      <div className={styles.selection} ref={rectangleRef} />

      <DragLayer
        className={styles.dragLayerItems}
        height={scrollHeight}
        items={selection.items}
        options={gridLayoutOptions}
        scrollX={scrollX}
        scrollY={scrollY}
        contentOffsetY={contentOffsetY}
        width={scrollWidth}
      />

      {isDropEnabled && draggedItemsTiles.length > 0 && (
        <Grid
          className={styles.dragLayer}
          options={gridLayoutOptions}
          style={{
            height: scrollHeight,
            width: scrollWidth,
          }}
          tiles={draggedItemsTiles}
        >
          {({ tile }) => <DropTarget key={tile.id} />}
        </Grid>
      )}

      <Grid<Item>
        className={classNames({ [styles.selecting]: selection.isDrawing })}
        options={gridLayoutOptions}
        tiles={items}
      >
        {({ tile }) => (
          <WebsktopItem
            isDragEnabled={isDragEnabled}
            isDropEnabled={isDropEnabled}
            item={tile}
            key={tile.id}
            isMultipleSelection={selection.ids.length > 1}
            isSelected={selection.ids.includes(tile.id)}
            selectionRef={selectionRef}
            websktopId={websktopId}
          />
        )}
      </Grid>

      <UsersCursors folderId={folderId} websktopId={websktopId} />

      {isNewUser && <ContextMenuCue className={styles.contextMenuCue} />}
    </div>
  );
});

export default Content;
