import { UserRole, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, LabeledInput, Message, Modal, TextareaInput } from 'components';
import { useUniqueId } from 'hooks';
import { useFormState } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import RolesDropdown from 'modules/roles-dropdown';
import { useInviteWebsktopUsers } from 'state';

import styles from './InviteUsersModal.module.scss';
import { textToList } from './lib';

interface FormState {
  users: string;
  role: UserRole;
}

interface Props {
  className?: string;
  isOpen: boolean;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const InviteUsersModal: FunctionComponent<Props> = ({ className, isOpen, websktopId, onClose }) => {
  const formId = useUniqueId();
  const dropdownId = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const inviteWebsktopUsers = useInviteWebsktopUsers(websktopId);
  const [form, { patchFormState }] = useFormState<FormState>({
    role: UserRole.VIEWER,
    users: '',
  });

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      return handleUnmountedError(async () => {
        const users = textToList(form.users);
        const invitations = users.map((emailOrUsername) => ({ emailOrUsername, role: form.role }));
        await inviteWebsktopUsers(invitations);
        onClose();
      });
    },
    [form, handleUnmountedError, inviteWebsktopUsers, onClose],
  );

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button form={formId} loading={isSubmitting} icon="plus" type="submit" variant="primary">
            Add
          </Button>
        </Modal.Footer>
      }
      header={<h2>Add users to Websktop</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={formId} onSubmit={handleSubmit}>
        <LabeledInput id={dropdownId} label="Role">
          <RolesDropdown
            autoFocus
            value={form.role}
            onChange={(role) => patchFormState({ role })}
          />
        </LabeledInput>

        <TextareaInput
          disabled={isSubmitting}
          description="You can use emails and usernames. Separate multiple values with commas or new lines."
          id="users"
          label="Users"
          placeholder="Emails or usernames"
          rows={3}
          value={form.users}
          onChange={(event) => patchFormState({ users: event.target.value })}
        />

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default InviteUsersModal;
