import { UserRole, WebsktopRef } from '@websktop/commons';

import { Permissions } from 'types';

import { useUser } from '../atom';

const NO_PERMISSIONS: Permissions = {
  canDeleteWebsktop: false,
  canEditItems: false,
  canEditWebsktop: false,
  canViewWebsktop: false,
};

const VIEWER_PERMISSIONS: Permissions = {
  canDeleteWebsktop: false,
  canEditItems: false,
  canEditWebsktop: false,
  canViewWebsktop: true,
};

const EDITOR_PERMISSIONS: Permissions = {
  canDeleteWebsktop: false,
  canEditItems: true,
  canEditWebsktop: true,
  canViewWebsktop: true,
};

const ADMIN_PERMISSIONS: Permissions = {
  canDeleteWebsktop: true,
  canEditItems: true,
  canEditWebsktop: true,
  canViewWebsktop: true,
};

const usePermissions = (websktopId: WebsktopRef['id']): Permissions => {
  const user = useUser();

  if (!user) {
    return NO_PERMISSIONS;
  }

  const websktopRef = user.websktopsRefs.find((websktopRef) => websktopRef.id === websktopId);

  if (websktopRef?.userRole === UserRole.VIEWER) {
    return VIEWER_PERMISSIONS;
  }

  if (websktopRef?.userRole === UserRole.EDITOR) {
    return EDITOR_PERMISSIONS;
  }

  if (websktopRef?.userRole === UserRole.ADMIN) {
    return ADMIN_PERMISSIONS;
  }

  return NO_PERMISSIONS;
};

export default usePermissions;
