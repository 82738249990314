import { Folder, isFolder, Item, Link } from '@websktop/commons';

const linkToHtml = (link: Link): string => {
  const name = link.name.trim().length > 0 ? link.name : link.url;
  return `<li><a href="${link.url}" rel="noopener noreferrer" target="_blank">${name}</a></li>`;
};

const folderToHtml = (folder: Folder): string => {
  const name = folder.name.trim().length > 0 ? folder.name : '';
  return `<li>${name}${itemsToHtml(folder.items)}</li>`;
};

const itemsToHtml = (items: Item[]): string => {
  if (items.length === 0) {
    return '';
  }

  const innerHtml = items
    .map((item) => (isFolder(item) ? folderToHtml(item) : linkToHtml(item)))
    .join('');

  return `<ul>${innerHtml}</ul>`;
};

export default itemsToHtml;
