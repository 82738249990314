import { Folder, Websktop } from '@websktop/commons';
import { FormEvent, FunctionComponent, useCallback } from 'react';
import { useAsyncFn } from 'react-use';

import { Button, Message, Modal, TextareaInput, TextInput } from 'components';
import { useUniqueId } from 'hooks';
import { getFormFieldsErrors, useFormState } from 'lib';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { useUpdateFolder } from 'state';

import styles from './EditFolderModal.module.scss';

interface FormState {
  name: string;
  note: string;
}

interface Props {
  className?: string;
  isOpen: boolean;
  folder: Folder;
  websktopId: Websktop['id'];
  onClose: () => void;
}

const EditFolderModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  folder,
  websktopId,
  onClose,
}) => {
  const id = useUniqueId();
  const handleUnmountedError = useUnmountedErrorHandler();
  const updateFolder = useUpdateFolder(websktopId);
  const [form, { patchFormState }] = useFormState<FormState>({
    name: folder.name,
    note: folder.note,
  });

  const submit = useCallback(async () => {
    await updateFolder(folder, {
      parentId: folder.parentId,
      x: folder.x,
      y: folder.y,
      name: form.name.trim(),
      note: form.note,
    });
  }, [folder, form, updateFolder]);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      onClose();

      return handleUnmountedError(async () => {
        await submit();
      });
    },
    [handleUnmountedError, onClose, submit],
  );

  const formErrors = getFormFieldsErrors<keyof FormState>(error);

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button form={id} loading={isSubmitting} icon="check" type="submit" variant="primary">
            Save
          </Button>
        </Modal.Footer>
      }
      header={<h2>Edit folder</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <TextInput
          autoFocus
          disabled={isSubmitting}
          error={formErrors.name?.message}
          id="name"
          label="Name"
          value={form.name}
          onChange={(event) => patchFormState({ name: event.target.value })}
        />

        <TextareaInput
          disabled={isSubmitting}
          error={formErrors.note?.message}
          id="note"
          label="Notes"
          rows={3}
          value={form.note}
          onChange={(event) => patchFormState({ note: event.target.value })}
        />

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default EditFolderModal;
