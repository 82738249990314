import { Websktop, WebsktopRef } from '../types';

type Rest = Omit<Omit<WebsktopRef, keyof Websktop>, 'iconUrl'>;

const websktopToWebsktopRef = (websktop: Websktop, rest: Rest): WebsktopRef => ({
  backgroundColor: websktop.backgroundColor,
  id: websktop.id,
  name: websktop.name,
  primaryIcon: websktop.primaryIcon,
  textColor: websktop.textColor,
  ...rest,
});

export default websktopToWebsktopRef;
