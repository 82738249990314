import { getLink, Link, Websktop } from '@websktop/commons';
import { useMemo } from 'react';

import { useWebsktop } from '../atom';

const useLink = (websktopId: Websktop['id'], linkId: Link['id']): Link => {
  const websktop = useWebsktop(websktopId);
  const rootFolder = websktop.folder;
  const link = useMemo(() => getLink(rootFolder, linkId), [rootFolder, linkId]);
  return link;
};

export default useLink;
