import { isObject } from './lib';

import ErrorType from './ErrorType';

export default class ApiError<Payload = unknown> extends Error {
  payload?: Payload;
  type?: ErrorType;

  constructor(message: string, payload?: Payload, type?: ErrorType) {
    super(message);
    this.payload = payload;
    this.type = type;
  }

  toJSON() {
    return {
      message: this.message,
      payload: this.payload,
      type: this.type,
    };
  }
}

export const isApiError = <Payload>(value: unknown): value is ApiError<Payload> => {
  if (!isObject(value)) {
    return false;
  }

  return typeof value.message === 'string';
};
