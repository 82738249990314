import { ServerMessage, SetUserCursorHandler, Websktop } from '@websktop/commons';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { websktopUsersCursorsAtomFamily } from '../state';

import getActiveWebsktopsSocket from '../getActiveWebsktopsSocket';

const useUsersCursorsUpdates = (websktopId: Websktop['id']) => {
  const socket = getActiveWebsktopsSocket();
  const setUsersCursors = useSetRecoilState(websktopUsersCursorsAtomFamily(websktopId));

  useEffect(() => {
    const handleUserCursorUpdate: SetUserCursorHandler = (eventWebsktopId, userId, cursor) => {
      if (eventWebsktopId === websktopId) {
        setUsersCursors((usersCursors) => ({ ...usersCursors, [userId]: cursor }));
      }
    };

    socket.on(ServerMessage.SET_USER_CURSOR, handleUserCursorUpdate);

    return () => {
      socket.off(ServerMessage.SET_USER_CURSOR, handleUserCursorUpdate);
    };
  }, [setUsersCursors, socket, websktopId]);
};

export default useUsersCursorsUpdates;
