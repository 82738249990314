import { Websktop } from '@websktop/commons';
import classNames from 'classnames';
import { noop } from 'lodash-es';
import { FunctionComponent, MouseEventHandler, useState } from 'react';

import { DropdownButton, Menu } from 'components';

import AccessEntry from '../AccessEntry';

import styles from './ReadAccess.module.scss';

interface Props {
  className?: string;
  disabled?: boolean;
  value: Websktop['readAccess'];
  onChange: (value: Websktop['readAccess']) => void;
  onClick?: MouseEventHandler;
}

const ReadAccess: FunctionComponent<Props> = ({
  className,
  disabled,
  value,
  onChange,
  onClick = noop,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (readAccess: Websktop['readAccess']) => {
    setIsOpen(false);
    onChange(readAccess);
  };

  const handleClick: MouseEventHandler = (event) => {
    onClick(event);

    if (!event.defaultPrevented) {
      setIsOpen(() => !isOpen);
    }
  };

  return (
    <AccessEntry className={classNames(styles.readAccess, className)} label="General access">
      <DropdownButton
        disabled={disabled}
        icon={value === 'private' ? 'lock' : 'globe'}
        isOpen={isOpen}
        label={<div className={styles.label}>{value === 'private' ? 'Private' : 'Public'}</div>}
        size="small"
        title={
          value === 'private'
            ? 'Only people with access can open with the link'
            : 'Anyone on the internet with the link can view'
        }
        onClick={handleClick}
        onClose={() => setIsOpen(false)}
      >
        <Menu>
          <Menu.ItemsGroup>
            <Menu.Item.Button icon="lock" onClick={() => handleChange('private')}>
              <div>
                <strong>Private</strong>
              </div>
              <div>Only people with access can open with the link</div>
            </Menu.Item.Button>

            <Menu.Item.Button icon="globe" onClick={() => handleChange('public')}>
              <div>
                <strong>Public</strong>
              </div>
              <div>Anyone on the internet with the link can view</div>
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        </Menu>
      </DropdownButton>
    </AccessEntry>
  );
};

export default ReadAccess;
