import { findItem, Folder, isFolder, Websktop } from '@websktop/commons';
import classNames from 'classnames';
import { CSSProperties, FunctionComponent, ReactNode, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { Link, useNavigate } from 'react-router-dom';

import { DND_WEBSKTOP_ITEM } from 'constants/websktop';
import { useBodyClassNames, useIsOnline } from 'hooks';
import { useItems, useMoveItems, usePermissions, useSelection } from 'state';

import styles from './Breadcrumb.module.scss';
import routing from 'modules/routing';

interface Props {
  children: ReactNode;
  current: boolean;
  folder: Folder;
  href: string;
  inactive: boolean;
  style?: CSSProperties;
  title?: string;
  websktopId: Websktop['id'];
}

const OPEN_FOLDER_DELAY = 1000;

const Breadcrumb: FunctionComponent<Props> = ({
  children,
  current,
  folder,
  href,
  inactive,
  style,
  title,
  websktopId,
}) => {
  const navigate = useNavigate();
  const isOnline = useIsOnline();
  const { canEditItems } = usePermissions(websktopId);
  const [itemsIds] = useSelection();
  const items = useItems(websktopId, itemsIds);
  const moveItems = useMoveItems(websktopId);
  const isDropEnabled = canEditItems && isOnline;

  const [{ canDrop, isOver }, dropRef] = useDrop(
    {
      accept: isDropEnabled ? DND_WEBSKTOP_ITEM : [],
      canDrop: () => !items.filter(isFolder).some((item: Folder) => findItem(item, folder.id)),
      drop: () => {
        moveItems({ items, targetFolder: folder });
      },
      collect(monitor) {
        return {
          canDrop: monitor.canDrop(),
          isOver: monitor.isOver(),
        };
      },
    },
    [folder, items, moveItems],
  );

  useEffect(() => {
    if (!isOver || !canDrop) {
      return;
    }

    const timeoutId = setTimeout(
      () => navigate(routing.websktop.folder(websktopId, folder.id)),
      OPEN_FOLDER_DELAY,
    );

    return () => {
      clearTimeout(timeoutId);
    };
  }, [canDrop, folder.id, isOver, navigate, websktopId]);

  useBodyClassNames({
    [styles.globalCopyCursor]: isOver && canDrop,
    [styles.globalNoDropCursor]: isOver && !canDrop,
  });

  if (current) {
    return (
      <span className={classNames(styles.breadcrumb, styles.current)} style={style} title={title}>
        {children}
      </span>
    );
  }

  return (
    <Link
      className={classNames(styles.breadcrumb, {
        [styles.canDrop]: canDrop,
        [styles.inactive]: inactive,
        [styles.isOver]: isOver,
      })}
      ref={dropRef}
      style={style}
      title={title}
      to={href}
      type="internal"
    >
      {children}
    </Link>
  );
};

export default Breadcrumb;
