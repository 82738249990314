import {
  PatchUserPayload,
  PatchUserSettingsPayload,
  UpdateSubscriptionsPayload,
  UpdateWebsktopRefOrderPayload,
  User,
  UserSettings,
  WebsktopRef,
} from '@websktop/commons';

import api from './lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const user = {
  async get(): Promise<User> {
    return api.getJson<User>(`${API_ORIGIN}/profile`);
  },

  async getSettings(): Promise<UserSettings> {
    return api.getJson<UserSettings>(`${API_ORIGIN}/settings`);
  },

  async moveWebsktopRef(payload: UpdateWebsktopRefOrderPayload): Promise<WebsktopRef[]> {
    return api.patchJson<WebsktopRef[]>(`${API_ORIGIN}/user/move-websktop`, payload);
  },

  async patch(payload: PatchUserPayload): Promise<User> {
    return api.patchJson<User>(`${API_ORIGIN}/profile`, payload);
  },

  async patchSettings(payload: PatchUserSettingsPayload): Promise<UserSettings> {
    return api.patchJson<UserSettings>(`${API_ORIGIN}/settings`, payload);
  },

  async startTrial(): Promise<User> {
    return api.postJson<User>(`${API_ORIGIN}/trial`);
  },

  async updateSubscriptions(params: UpdateSubscriptionsPayload): Promise<User> {
    return api.patchJson<User>(`${API_ORIGIN}/user/subscriptions`, params);
  },
};

export default user;
