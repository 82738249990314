import { BaseTile, GridLayoutOptions } from '../types';

import getItemsCount from './getItemsCount';

const getRowsCount = <Tile extends BaseTile>(
  tiles: Tile[],
  { height, spacingRow, tileHeight }: GridLayoutOptions,
) => {
  return tiles.reduce(
    (result, tile) => Math.max(result, tile.y + 1),
    getItemsCount(height, tileHeight, spacingRow),
  );
};

export default getRowsCount;
