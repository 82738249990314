import { useCallback } from 'react';

import api from 'api';

const useLogin = (): typeof api.auth.login => {
  const login: typeof api.auth.login = useCallback(async (payload) => {
    const user = await api.auth.login(payload);
    return user;
  }, []);

  return login;
};

export default useLogin;
