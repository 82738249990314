import classNames from 'classnames';
import { FunctionComponent, HTMLProps, ReactNode } from 'react';

import styles from './Radio.module.scss';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  checked: boolean;
  className?: string;
  disabled?: boolean;
  id: string;
  label: ReactNode;
  title: string;
}

const Radio: FunctionComponent<Props> = ({
  checked,
  className,
  disabled,
  id,
  label,
  title,
  onChange,
  ...props
}) => (
  <label
    className={classNames(styles.radio, className, {
      [styles.checked]: checked,
      [styles.disabled]: disabled,
    })}
    htmlFor={id}
    title={title}
  >
    <input
      checked={checked}
      className={styles.input}
      disabled={disabled}
      id={id}
      type="radio"
      onChange={onChange}
      {...props}
    />

    <div className={styles.icon} />

    <div className={styles.content}>{label}</div>
  </label>
);

export default Radio;
