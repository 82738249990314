import ApiError, { isApiError } from './ApiError';
import ApiFormErrorPayload, { isApiFormErrorPayload } from './ApiFormErrorPayload';

type ApiFormError<Fields extends string = string> = WithRequired<
  ApiError<ApiFormErrorPayload<Fields>>,
  'payload'
>;

export default ApiFormError;

export const isApiFormError = <Fields extends string>(
  value: unknown,
): value is ApiFormError<Fields> => isApiError(value) && isApiFormErrorPayload(value.payload);
