import { Folder, Websktop } from '@websktop/commons';

import { useIsOnline } from 'hooks';
import { usePermissions } from 'state';

import {
  useArrowNavigation,
  useCopy,
  useCut,
  useDelete,
  useEdit,
  useFolderUp,
  useOpen,
  useOpenDeep,
  useOpenInNewTab,
  usePaste,
  useRename,
  useSelectAll,
} from './hooks';

type Data = {
  websktopId: Websktop['id'];
  folderId: Folder['id'];
};

const useWebsktopHotkeys = ({ folderId, websktopId }: Data, isEnabled: boolean) => {
  const isOnline = useIsOnline();
  const { canEditItems } = usePermissions(websktopId);
  const isCutEnabled = isEnabled && canEditItems && isOnline;
  const isDeleteEnabled = isEnabled && canEditItems && isOnline;
  const isEditEnabled = isEnabled && canEditItems && isOnline;
  const isPasteEnabled = isEnabled && canEditItems && isOnline;
  const isRenameEnabled = isEnabled && canEditItems && isOnline;

  useArrowNavigation({ folderId, websktopId }, isEnabled);
  useCopy({ websktopId }, isEnabled);
  useCut({ websktopId }, isCutEnabled);
  useDelete({ websktopId }, isDeleteEnabled);
  useEdit({ websktopId }, isEditEnabled);
  useFolderUp({ folderId, websktopId }, isEnabled);
  useOpen({ websktopId }, isEnabled);
  useOpenDeep({ websktopId }, isEnabled);
  useOpenInNewTab({ websktopId }, isEnabled);
  usePaste({ folderId, websktopId }, isPasteEnabled);
  useRename({ websktopId }, isRenameEnabled);
  useSelectAll({ folderId, websktopId }, isEnabled);
};

export default useWebsktopHotkeys;
