import classNames from 'classnames';
import { FunctionComponent } from 'react';

import EmojiIcon from '../EmojiIcon';
import InitialsIcon from '../InitialsIcon';
import UploadedIcon from '../UploadedIcon';

import styles from './WebsktopIcon.module.scss';

interface Props {
  backgroundColor: string;
  className?: string;
  iconEmoji: string;
  iconUrl?: string | null;
  name: string;
  size: number;
  textColor: string;
}

const WebsktopIcon: FunctionComponent<Props> = ({
  backgroundColor,
  className,
  iconEmoji,
  iconUrl,
  name,
  size,
  textColor,
}) => {
  if (iconUrl) {
    return (
      <UploadedIcon
        alt={name}
        className={classNames(styles.websktopIcon, className)}
        size={size}
        url={iconUrl}
      />
    );
  }

  if (iconEmoji) {
    return (
      <EmojiIcon
        className={classNames(styles.websktopIcon, className)}
        emoji={iconEmoji}
        size={size}
      />
    );
  }

  return (
    <InitialsIcon
      backgroundColor={backgroundColor}
      className={classNames(styles.websktopIcon, className)}
      name={name}
      size={size}
      textColor={textColor}
    />
  );
};

export default WebsktopIcon;
