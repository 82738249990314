import { Item, Websktop } from '../types';

import addItemToWebsktop from './addItemToWebsktop';
import removeItemFromWebsktop from './removeItemFromWebsktop';

const updateItemInWebsktop = (websktop: Websktop, item: Item): Websktop => {
  return addItemToWebsktop(removeItemFromWebsktop(websktop, item.id), item);
};

export default updateItemInWebsktop;
