import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

import Icon from '../Icon';

import styles from './Message.module.scss';

type Variant = 'error' | 'info' | 'success' | 'warning';

interface Props extends HTMLProps<HTMLDivElement> {
  variant?: Variant;
}

const iconsMap: Record<Variant, IconProp> = {
  error: 'exclamation-circle',
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
};

const Message = forwardRef<HTMLDivElement, Props>(
  ({ children, className, variant, ...props }, ref) => (
    <div
      className={classNames(styles.message, className, {
        [styles.default]: typeof variant === 'undefined',
        [styles.error]: variant === 'error',
        [styles.info]: variant === 'info',
        [styles.success]: variant === 'success',
        [styles.warning]: variant === 'warning',
      })}
      ref={ref}
      {...props}
    >
      {variant && <Icon className={styles.icon} icon={iconsMap[variant]} />}
      <div className={styles.content}>{children}</div>
    </div>
  ),
);

export default Message;
