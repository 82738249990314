import { moveWebsktopRef, WebsktopRef } from '@websktop/commons';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import api from 'api';
import { useIsOnline } from 'hooks';
import { useErrorNotification } from 'modules/notifications';
import routing from 'modules/routing';
import { useAuthenticatedUser, useSetUser } from 'state';

import { DND_SIDEBAR_ITEM } from '../constants';
import DraggableWebsktopLink from '../DraggableWebsktopLink';
import WebsktopLinkDragLayer from '../WebsktopLinkDragLayer';

const WebsktopLinks: FunctionComponent = () => {
  const isOnline = useIsOnline();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useAuthenticatedUser();
  const setUser = useSetUser();
  const [draggedItemId, setDraggedItemId] = useState<WebsktopRef['id'] | null>(null);
  const [items, setItems] = useState(user.websktopsRefs);
  const isDropEnabled = isOnline;

  const [{ error }, updateWebsktopOrder] = useAsyncFn(
    async (params: Parameters<typeof api.user.moveWebsktopRef>[0]) => {
      try {
        setUser((user) => (user ? { ...user, websktopsRefs: items } : user));
        const updatedWebsktopsRefs = await api.user.moveWebsktopRef(params);
        setUser((user) => (user ? { ...user, websktopsRefs: updatedWebsktopsRefs } : user));
      } catch (error) {
        setItems(user.websktopsRefs);
        throw error;
      }
    },
    [items, setUser, user],
  );

  useErrorNotification(error);

  const onMoveWebsktopRef = useCallback(
    (draggedItemId: WebsktopRef['id'], targetItemId: WebsktopRef['id']) => {
      const movedItem = items.find(({ id }) => id === draggedItemId);
      const targetItem = items.find(({ id }) => id === targetItemId);

      if (!movedItem || !targetItem || movedItem === targetItem) {
        return;
      }

      const newWebsktopsRefs = moveWebsktopRef(user.websktopsRefs, draggedItemId, targetItem.order);

      setDraggedItemId(draggedItemId);
      setItems(newWebsktopsRefs);
    },
    [items, user.websktopsRefs],
  );

  useEffect(() => {
    setItems(user.websktopsRefs);
  }, [user]);

  const [, containerRef] = useDrop(
    {
      accept: isDropEnabled ? DND_SIDEBAR_ITEM : [],
      drop() {
        const updatedWebsktopRef = items.find(({ id }) => id === draggedItemId);

        if (updatedWebsktopRef) {
          const hasDefaultWebsktopChanged = items[0].id !== user.websktopsRefs[0].id;

          updateWebsktopOrder({
            order: updatedWebsktopRef.order,
            websktopId: updatedWebsktopRef.id,
          });

          if (hasDefaultWebsktopChanged && location.pathname === routing.index) {
            navigate(routing.websktop(user.websktopsRefs[0].id));
          }
        }
      },
    },
    [isDropEnabled, items, navigate, user],
  );

  return (
    <>
      <div ref={containerRef}>
        {items
          .filter((item) => item.isSubscribed)
          .map((websktopRef) => (
            <DraggableWebsktopLink
              key={websktopRef.id}
              websktopRef={websktopRef}
              onMoveWebsktopRef={onMoveWebsktopRef}
            />
          ))}
      </div>
      <WebsktopLinkDragLayer />
    </>
  );
};

export default WebsktopLinks;
