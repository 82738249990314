import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './LinkButton.module.scss';

const LinkButton: FunctionComponent<HTMLProps<HTMLButtonElement>> = ({
  children,
  className,
  ...props
}) => (
  <button className={classNames(styles.linkButton, className)} {...props} type="button">
    {children}
  </button>
);

export default LinkButton;
