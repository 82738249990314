import { useState } from 'react';
import { useEvent } from 'react-use';

const useIsDragging = (): boolean => {
  const [isDragging, setIsDragging] = useState(false);

  useEvent('dragstart', () => setIsDragging(true));
  useEvent('dragend', () => setIsDragging(false));
  useEvent('drop', () => setIsDragging(false));

  return isDragging;
};

export default useIsDragging;
