import { Entity } from '@websktop/commons';

import { OptimisticUpdate } from './types';

const applyOptimisticUpdates = <E extends Entity>(
  entity: E,
  optimisticUpdates: OptimisticUpdate<E>[],
): E => {
  return optimisticUpdates.reduce(
    (result, { entityId, id, update }) => (result.id === entityId ? update(result, id) : result),
    entity,
  );
};

export default applyOptimisticUpdates;
