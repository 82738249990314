import { DEFAULT_WEBSKTOP_NAME, getItemsDeep, User, Websktop } from '@websktop/commons';

const isNewUser = (user: User | null, websktops: Websktop[]): boolean | undefined => {
  if (!user) {
    return undefined;
  }

  const hasOneWebsktop = websktops.length === 1;

  if (!hasOneWebsktop) {
    return false;
  }

  const websktop = websktops[0];
  const hasDefaultName = websktop.name === DEFAULT_WEBSKTOP_NAME;
  const { [websktop.folder.id]: defaultFolder, ...items } = getItemsDeep(websktop.folder);
  const isEmpty = Object.entries(items).length === 0;

  return hasDefaultName && isEmpty;
};

export default isNewUser;
