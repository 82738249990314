import { BillingCycle, Plan } from '@websktop/commons';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMount, usePrevious } from 'react-use';

import { Button, Modal } from 'components';
import { trackEvent } from 'modules/analytics';
import { CurrencyInput, BillingCycleInput, Plans } from 'modules/plans';
import routing from 'modules/routing';
import { useAuthenticatedUser } from 'state';
import { Currency } from 'types';

import styles from './UpgradePlanModal.module.scss';

interface Props {
  isOpen: boolean;
  message?: ReactNode;
  title?: ReactNode;
  onClose: () => void;
}

const UpgradePlanModal: FunctionComponent<Props> = ({
  isOpen,
  message,
  title = 'Upgrade plan',
  onClose,
}) => {
  const navigate = useNavigate();
  const user = useAuthenticatedUser();
  const plan = user.subscription.plan;
  const previousPlan = usePrevious(plan);
  const hasUpgradedPlan = previousPlan === Plan.FREE && plan === Plan.PROFESSIONAL;
  const [currency, setCurrency] = useState<Currency>('usd');
  const [billingCycle, setBillingCycle] = useState<BillingCycle>('year');

  useMount(() => {
    trackEvent('upgrade-plan-modal', 'open');
  });

  useEffect(() => {
    if (hasUpgradedPlan) {
      onClose();
    }
  }, [hasUpgradedPlan, onClose]);

  const handleClose = () => {
    trackEvent('upgrade-plan-modal', 'close');
    onClose();
  };

  const handlePurchase = async () => {
    trackEvent('upgrade-plan-modal', 'purchase-pro-plan');
    navigate(routing.upgrade({ billingCycle, currency }));
    onClose();
  };

  return (
    <Modal
      className={styles.modal}
      header={<h1>{title}</h1>}
      isOpen={isOpen}
      panelClassName={styles.panel}
      onClose={handleClose}
    >
      {message}

      <Plans
        actionProfessionalPlan={
          <Button variant="primary" onClick={handlePurchase}>
            Purchase
          </Button>
        }
        billingCycle={billingCycle}
        className={styles.plans}
        currency={currency}
      />

      <div className={styles.controls}>
        <CurrencyInput value={currency} onChange={setCurrency} />
        <BillingCycleInput value={billingCycle} onChange={setBillingCycle} />
      </div>
    </Modal>
  );
};

export default UpgradePlanModal;
