import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Image } from 'components';
import { getAbsoluteUrl } from 'lib';

import styles from './UploadedIcon.module.scss';

interface Props {
  alt?: string;
  className?: string;
  size: number;
  url: string;
}

const UploadedIcon: FunctionComponent<Props> = ({ alt, className, size, url }) => (
  <Image
    alt={alt}
    className={classNames(styles.uploadedIcon, className)}
    draggable={false}
    height={size}
    src={getAbsoluteUrl(url)}
    width={size}
  />
);

export default UploadedIcon;
