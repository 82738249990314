import { CSSProperties } from 'react';

import { Rectangle } from 'types';

const getSelectionStyle = (rectangle: Rectangle | null): CSSProperties => {
  if (!rectangle) {
    return { display: 'none' };
  }

  return {
    display: 'block',
    left: `${rectangle.x}px`,
    top: `${rectangle.y}px`,
    width: `${rectangle.width}px`,
    height: `${rectangle.height}px`,
  };
};

export default getSelectionStyle;
