import { arrangeNewItems, Folder, Item, Point, removeItemsFromFolder } from '@websktop/commons';
import { isEqual } from 'lodash-es';
import { useCallback, useMemo, useState } from 'react';

type Action = (point: Point | null) => void;

type State = Point[];

const useDraggedItemsPositions = (folder: Folder, draggedItems: Item[]): [State, Action] => {
  const [dropPoint, setDropPoint] = useState<Point | null>(null);

  const changeDropPoint = useCallback<Action>(
    (point) => {
      setDropPoint((current) => {
        return isEqual(current, point) ? current : point;
      });
    },
    [setDropPoint],
  );

  const positions: Point[] | null = useMemo(() => {
    if (dropPoint === null) {
      return [];
    }

    const draggedItemsIds = draggedItems.map(({ id }) => id);
    const folderWithoutDraggedItems = removeItemsFromFolder(folder, draggedItemsIds);
    const locations = arrangeNewItems(folderWithoutDraggedItems, draggedItems, dropPoint);

    return Object.values(locations);
  }, [draggedItems, dropPoint, folder]);

  return [positions, changeDropPoint];
};

export default useDraggedItemsPositions;
