import { noop } from 'lodash-es';
import {
  FocusEvent,
  FocusEventHandler,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

import useStaticTooltip from './useStaticTooltip';

interface Props {
  className?: string;
  placement?: 'top' | 'right' | 'bottom' | 'left';
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onMouseOut?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
}

interface TriggerProps {
  'aria-describedby'?: string;
  ref: (referenceElement: HTMLElement | null) => void;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  onMouseOut?: MouseEventHandler;
  onMouseOver?: MouseEventHandler;
}

const useTooltip = (
  tooltip: ReactNode,
  {
    className,
    placement = 'top',
    onBlur = noop,
    onFocus = noop,
    onMouseOut = noop,
    onMouseOver = noop,
  }: Props = {},
): TriggerProps => {
  const [isShown, setIsShown] = useState<boolean>(false);

  const handleBlur = useCallback(
    (event: FocusEvent) => {
      onBlur(event);
      setIsShown(false);
    },
    [onBlur],
  );

  const handleFocus = useCallback(
    (event: FocusEvent) => {
      onFocus(event);
      setIsShown(true);
    },
    [onFocus],
  );

  const handleMouseOut = useCallback(
    (event: MouseEvent) => {
      onMouseOut(event);
      setIsShown(false);
    },
    [onMouseOut],
  );

  const handleMouseOver = useCallback(
    (event: MouseEvent) => {
      onMouseOver(event);
      setIsShown(true);
    },
    [onMouseOver],
  );

  const triggerProps = useStaticTooltip(tooltip, { className, isShown, placement });

  const finalTriggerProps = useMemo(
    () => ({
      ...triggerProps,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onMouseOut: handleMouseOut,
      onMouseOver: handleMouseOver,
    }),
    [handleBlur, handleFocus, handleMouseOut, handleMouseOver, triggerProps],
  );

  return finalTriggerProps;
};

export default useTooltip;
