import { Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';

import LoadingError from './LoadingError';
import NoAccessError from './NoAccessError';
import NotFoundError from './NotFoundError';
import UnknownError from './UnknownError';

const NO_ACCESS_ERROR_MESSAGE = 'No access to view this Websktop';
const LOADING_ERROR_MESSAGE = 'Failed to fetch';
const NOT_FOUND_ERROR_MESSAGE = 'Websktop not found';

interface Props {
  error: Error;
  websktopId: Websktop['id'];
}

const WebsktopError: FunctionComponent<Props> = ({ error, websktopId }) => {
  if (error.message === NO_ACCESS_ERROR_MESSAGE) {
    return <NoAccessError websktopId={websktopId} />;
  }

  if (error.message === LOADING_ERROR_MESSAGE) {
    return <LoadingError />;
  }

  if (error.message === NOT_FOUND_ERROR_MESSAGE) {
    return <NotFoundError />;
  }

  return <UnknownError />;
};

export default WebsktopError;
