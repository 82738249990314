import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

const openModalsCountAtom = atom<number>({
  default: 0,
  key: 'open-modals-count',
});

export const useIsAnyModalOpen = () => useRecoilValue(openModalsCountAtom) > 0;

export const useSetOpenModalsCount = () => useSetRecoilState(openModalsCountAtom);
