import { RefObject, useEffect, useRef, useState } from 'react';

const useFocusIn = <T extends HTMLElement>(): [boolean, RefObject<T>] => {
  const ref = useRef<T>(null);
  const [isFocusIn, setIsFocusIn] = useState(false);

  useEffect(() => {
    const handler = (event: FocusEvent) => {
      const isFocusIn = Boolean(ref.current && event.composedPath().includes(ref.current));
      setIsFocusIn(isFocusIn);
    };

    document.body.addEventListener('focusin', handler);

    return () => {
      document.body.removeEventListener('focusin', handler);
    };
  }, []);

  return [isFocusIn, ref];
};

export default useFocusIn;
