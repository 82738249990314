import { Folder, Websktop } from '@websktop/commons';
import { FunctionComponent, memo, useCallback, useRef } from 'react';
import { useEvent, useLatest } from 'react-use';
import { useThrottledCallback } from 'use-debounce';

import { Icon } from 'components';
import {
  useUpdateCursorPosition,
  useUser,
  useWebsktopOnlineUsers,
  useWebsktopUsersCursors,
} from 'state';

import styles from './UsersCursors.module.scss';

interface Props {
  folderId: Folder['id'];
  websktopId: Websktop['id'];
}

const THROTTLE_DELAY = 15;

const UsersCursors: FunctionComponent<Props> = ({ folderId, websktopId }) => {
  const currentUser = useUser();
  const ref = useRef<HTMLDivElement>(null);
  const users = useWebsktopOnlineUsers(websktopId);
  const cursors = useWebsktopUsersCursors(websktopId);
  const updateCursorPosition = useUpdateCursorPosition(websktopId, folderId);
  const updateCursorPositionThrottled = useThrottledCallback(updateCursorPosition, THROTTLE_DELAY);
  const updateCursorPositionThrottledRef = useLatest(updateCursorPositionThrottled);

  const mouseMoveHandler = useCallback(
    (event: MouseEvent) => {
      if (!ref.current || users.length <= 1) {
        return;
      }

      const { x: contentOffsetX, y: contentOffsetY } = ref.current.getBoundingClientRect();
      const x = Math.max(event.clientX - contentOffsetX, 0);
      const y = Math.max(event.clientY - contentOffsetY, 0);
      updateCursorPositionThrottledRef.current({ x, y });
    },
    [updateCursorPositionThrottledRef, users.length],
  );

  useEvent('mousemove', mouseMoveHandler);

  return (
    <div className={styles.cursors} ref={ref}>
      {users
        .filter((user) => cursors[user.id]?.folderId === folderId)
        .map((user) => {
          if (currentUser && currentUser.id === user.id) {
            return null;
          }

          const cursor = cursors[user.id];
          return (
            <div
              className={styles.userCursor}
              key={user.id}
              style={{
                color: cursor.color,
                transform: `translate(${cursor.position.x}px, ${cursor.position.y}px)`,
              }}
            >
              <Icon className={styles.cursor} icon="mouse-pointer" />
              <span className={styles.name} style={{ backgroundColor: cursor.color }}>
                {user.name || user.username}
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default memo(UsersCursors);
