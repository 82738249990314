import { getLinksDeep, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useOpenLinks } from 'hooks';
import { isSafeUrl } from 'lib';
import { useItems, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useOpenDeep: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const openLinks = useOpenLinks();
  const [selection] = useSelection();
  const items = useItems(websktopId, selection);

  const handleOpenDeep = useCallback(() => {
    const links = getLinksDeep(items);
    const safeLinks = links.filter((link) => isSafeUrl(link.url));
    openLinks(safeLinks);
  }, [items, openLinks]);

  useHotkey('open-deep', handleOpenDeep, { disabled: !isEnabled || items.length === 0 });
};

export default useOpenDeep;
