import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import { Icon } from 'components';

import styles from './SolidColor.module.scss';

interface Props extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
  checked?: boolean;
  className?: string;
  color: string;
  disabled?: boolean;
  id: string;
  title?: string;
}

const SolidColor: FunctionComponent<Props> = ({
  checked,
  className,
  color,
  disabled,
  id,
  title,
  onChange,
  ...props
}) => (
  <label
    className={classNames(styles.solidColor, className, {
      [styles.disabled]: disabled,
    })}
    htmlFor={id}
    style={{ backgroundColor: color }}
    title={title}
  >
    <input
      checked={checked}
      className={styles.input}
      disabled={disabled}
      id={id}
      type="radio"
      onChange={onChange}
      {...props}
    />

    {checked && (
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} icon="check" />
      </div>
    )}
  </label>
);

export default SolidColor;
