import { isLink, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useEditFolderModal, useEditLinkModal } from 'modals';
import { useItems, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useEdit: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const editFolderModal = useEditFolderModal();
  const editLinkModal = useEditLinkModal();
  const items = useItems(websktopId, selection);
  const [item] = items;

  const handleEdit = useCallback(() => {
    if (isLink(item)) {
      editLinkModal.open({ link: item, websktopId });
    } else {
      editFolderModal.open({ folder: item, websktopId });
    }
  }, [editFolderModal, editLinkModal, item, websktopId]);

  useHotkey('edit', handleEdit, {
    disabled: !isEnabled || items.length !== 1,
    preventDefault: isEnabled,
  });
};

export default useEdit;
