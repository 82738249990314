import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const personalizeModalAtom = createModalAtom<State>('personalize-modal');

export const usePersonalizeModal = () => useModal(personalizeModalAtom);
