import { ICON_HEIGHT, ICON_WIDTH, SPACING_COLUMN, SPACING_ROW } from '../constants';
import { GridLayoutOptions } from '../types';

const getGridLayoutOptions = (width: number, height: number): GridLayoutOptions => ({
  height,
  tileHeight: ICON_HEIGHT,
  tileWidth: ICON_WIDTH,
  spacingColumn: SPACING_COLUMN,
  spacingRow: SPACING_ROW,
  width,
});

export default getGridLayoutOptions;
