import classNames from 'classnames';
import { FunctionComponent, ReactNode, useEffect, useId, useState } from 'react';
import { ColorPickerBaseProps } from 'react-colorful/dist/types';
import Modal from 'react-modal';
import { useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';

import { TextInput } from 'components';

import SolidColor from '../SolidColor';

import styles from './ColorInput.module.scss';
import { MODIFIERS } from './constants';

interface Props {
  checked?: boolean;
  children?: ReactNode;
  className?: string;
  ColorPicker: (props: Partial<ColorPickerBaseProps<string>>) => JSX.Element;
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const ColorInput: FunctionComponent<Props> = ({
  checked,
  children,
  className,
  ColorPicker,
  label,
  value,
  onChange,
}) => {
  const buttonId = useId();
  const inputId = useId();
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {
    attributes,
    styles: popperStyles,
    update,
  } = usePopper(referenceElement, popperElement, {
    modifiers: MODIFIERS,
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [isOpen, update]);

  const handleRootClose = (event: any) => {
    if (
      popperElement &&
      !popperElement.contains(event.target) &&
      referenceElement !== event.target
    ) {
      setIsOpen(false);
    }
  };

  useRootClose(popperElement, handleRootClose, { disabled: !isOpen });

  return (
    <>
      <TextInput
        addonLeft={
          <SolidColor
            checked={checked}
            className={styles.customColor}
            color={value}
            id={buttonId}
            onChange={() => onChange(value)}
            onClick={() => setIsOpen(true)}
          />
        }
        className={className}
        id={inputId}
        label={label}
        // https://github.com/regexhq/hex-color-regex/blob/75d0457/index.js
        pattern="^#([0-9a-fA-F]{3,4}|[0-9a-fA-F]{4}(?:[0-9a-fA-F]{2}){1,2})\b$"
        ref={setReferenceElement}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        onFocus={(event) => onChange(event.target.value)}
      >
        {children}
      </TextInput>

      <Modal
        className={styles.modal}
        isOpen={isOpen}
        overlayClassName={styles.overlay}
        onRequestClose={() => setIsOpen(false)}
      >
        <div
          className={classNames(styles.dropdown, {
            [styles.closed]: !isOpen,
          })}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          <ColorPicker color={value} onChange={onChange} />
        </div>
      </Modal>
    </>
  );
};

export default ColorInput;
