import { FunctionComponent } from 'react';

import { Button } from 'components';
import { getServiceWorker } from 'serviceWorkerManager';
import { useShowNotification } from 'state';
import { ServiceWorkerClientMessage } from 'types';

interface Props {
  className?: string;
}

const ClearCacheButton: FunctionComponent<Props> = ({ className }) => {
  const showNotification = useShowNotification();

  const handleClearCache = async () => {
    sessionStorage.clear();
    localStorage.clear();

    try {
      const serviceWorker = await getServiceWorker();

      if (serviceWorker) {
        const message: ServiceWorkerClientMessage = { action: 'invalidate-static-caches' };
        await serviceWorker.messageSW(message);
        await serviceWorker.update();

        showNotification({
          type: 'success',
          title: 'Cache cleared',
        });
      } else {
        throw new Error('No service worker');
      }
    } catch (error) {
      showNotification({
        type: 'warning',
        title: 'Cache partially cleared',
        content: 'Failed to clear service worker cache.',
      });
    } finally {
      window.location.reload();
    }
  };

  return (
    <Button className={className} icon="trash-alt" onClick={handleClearCache}>
      Clear cache
    </Button>
  );
};

export default ClearCacheButton;
