import { Folder, Item, Point } from '@websktop/commons';
import { uniq } from 'lodash-es';
import {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDragLayer } from 'react-dnd';
import { useThrottledCallback } from 'use-debounce';

import { applyStyle, createRectangle, getRelativePoint } from 'lib';
import { useAllItemsLoadable, useOptimisticUpdates, useSelection } from 'state';

import { getItemsInRectangle, getSelectionStyle } from '../lib';
import { Rectangle } from 'types';
import { useLatest } from 'react-use';

export interface ItemsSelection {
  drawEnd: () => void;
  drawMove: (point: Point) => void;
  drawStart: (point: Point) => void;
  ids: Item['id'][];
  isDrawing: boolean;
  items: Item[];
  reset: () => void;
  set: (itemsIds: Item['id'][]) => void;
  toggle: (ids: Item['id'][]) => void;
}

const STYLE_HIDE: CSSProperties = { display: 'none' };

const THROTTLE_DELAY = 100;

const useItemsSelection = (
  folder: Folder,
  elementRef: MutableRefObject<HTMLElement | null>,
  rectangleRef: MutableRefObject<HTMLElement | null>,
): ItemsSelection => {
  const { has: isOptimisticId } = useOptimisticUpdates();
  const [selection, { add, reset, set, toggle: toggleSelection }] = useSelection();
  const [rectangleIds, setRectangleIds] = useState<string[]>([]);
  const [start, setStart] = useState<Point | null>(null);
  const [shouldResetSelection, setShouldResetSelection] = useState<boolean>(false);
  const isDragging = useDragLayer((monitor) => monitor.isDragging());
  const isDrawingRef = useRef(false);
  const selectedIds = useMemo(
    () => uniq([...selection, ...rectangleIds]),
    [selection, rectangleIds],
  );
  const allItemsLoadable = useAllItemsLoadable();
  const items = useMemo(() => {
    return allItemsLoadable.state === 'hasValue'
      ? allItemsLoadable.contents.filter((item) => selection.includes(item.id))
      : [];
  }, [allItemsLoadable.contents, allItemsLoadable.state, selection]);

  const toggle = useCallback((newIds: Item['id'][]) => toggleSelection(newIds), [toggleSelection]);

  const drawStart = useCallback(
    (point: Point) => {
      isDrawingRef.current = true;
      setStart(getRelativePoint(elementRef.current, point));
      setRectangleIds([]);
    },
    [isDrawingRef, elementRef],
  );

  const updateRectangleIds = useCallback(
    (rectangle: Rectangle) => {
      if (!isDrawingRef.current) {
        return;
      }

      const items = getItemsInRectangle(folder, rectangle);
      const itemsIds = items.map((item) => item.id).filter((id) => !isOptimisticId(id));
      setRectangleIds(itemsIds);
    },
    [folder, isDrawingRef, isOptimisticId],
  );

  const updateRectangleIdsThrottled = useThrottledCallback(updateRectangleIds, THROTTLE_DELAY);
  const updateRectangleIdsThrottledRef = useLatest(updateRectangleIdsThrottled);

  const drawMove = useCallback(
    (point: Point) => {
      if (!start || !rectangleRef.current) {
        return;
      }

      const end = getRelativePoint(elementRef.current, point);
      const rectangle = createRectangle(start, end);
      const style = getSelectionStyle(rectangle);
      applyStyle(rectangleRef.current, style);
      updateRectangleIdsThrottledRef.current(rectangle);
    },
    [elementRef, rectangleRef, start, updateRectangleIdsThrottledRef],
  );

  const drawEnd = useCallback(() => {
    isDrawingRef.current = false;
    setStart(null);
    applyStyle(rectangleRef.current, STYLE_HIDE);
    add(rectangleIds);
    setRectangleIds([]);
  }, [add, isDrawingRef, rectangleIds, rectangleRef]);

  useEffect(() => {
    setShouldResetSelection(true);
    setStart(null);
    applyStyle(rectangleRef.current, STYLE_HIDE);
  }, [folder.id, rectangleRef]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (shouldResetSelection && !isDragging) {
      reset();
      setShouldResetSelection(false);
    }
  }, [isDragging, reset, shouldResetSelection]);

  return {
    drawEnd,
    drawMove,
    drawStart,
    ids: selectedIds,
    isDrawing: Boolean(start),
    items,
    reset,
    set,
    toggle,
  };
};

export default useItemsSelection;
