import { isError } from '@websktop/commons';
import { useCallback } from 'react';

import { Notification } from 'types';

import { useShowNotification } from '../actions';

type ShowErrorNotification = (error: unknown) => Notification['id'];

const useShowErrorNotification = (): ShowErrorNotification => {
  const showNotification = useShowNotification();

  const showErrorNotification: ShowErrorNotification = useCallback(
    (error: unknown) => {
      const title = isError(error) ? error.message : 'Unknown error';
      return showNotification({ title, type: 'error' });
    },
    [showNotification],
  );

  return showErrorNotification;
};

export default useShowErrorNotification;
