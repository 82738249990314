import { Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useItemRename, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useRename: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const { editItem } = useItemRename(websktopId);

  const handleRename = useCallback(() => {
    editItem(selection[0]);
  }, [editItem, selection]);

  useHotkey('rename', handleRename, {
    disabled: !isEnabled || selection.length !== 1,
    preventDefault: isEnabled,
  });
};

export default useRename;
