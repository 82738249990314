import {
  Folder,
  Item,
  Point,
  removeItemsFromWebsktop,
  updateItemsInWebsktop,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetAnyWebsktop } from '../atom';
import { moveItems as moveWebsktopItems } from '../lib';

type MoveItems = ({
  items,
  sourceWebsktopId,
  targetFolder,
  targetPoint,
}: {
  items: Item[];
  sourceWebsktopId?: Websktop['id'];
  targetFolder: Folder;
  targetPoint?: Point;
}) => Promise<void>;

const useMoveItems = (websktopId: Websktop['id']): MoveItems => {
  const setAnyWebsktop = useSetAnyWebsktop();
  const optimisticUpdates = useOptimisticUpdates();

  const moveItems: MoveItems = useCallback(
    async ({ items, sourceWebsktopId = websktopId, targetFolder, targetPoint }) => {
      if (items.length === 0) {
        return;
      }

      const movedItemsOptimistic = moveWebsktopItems(items, targetFolder, targetPoint);

      if (movedItemsOptimistic === items) {
        throw new Error('Cannot move folder into itself');
      }

      const targetWebsktopOptimisticUpdate = optimisticUpdates.create<Websktop>(
        websktopId,
        (websktop) => updateItemsInWebsktop(websktop, movedItemsOptimistic),
      );

      const sourceWebsktopOptimisticUpdate = optimisticUpdates.create<Websktop>(
        sourceWebsktopId,
        (websktop) => {
          if (sourceWebsktopId === websktopId) {
            return websktop;
          }

          const itemsIds = items.map((item) => item.id);
          return removeItemsFromWebsktop(websktop, itemsIds);
        },
      );

      try {
        const { sourceWebsktop, targetWebsktop } = await api.websktop.moveItems({
          websktopId,
          itemsIds: items.map((item) => item.id),
          sourceFolderId: items[0].parentId!,
          sourceWebsktopId,
          targetFolderId: targetFolder.id,
          targetPoint,
        });
        setAnyWebsktop(sourceWebsktop);
        setAnyWebsktop(targetWebsktop);
      } finally {
        optimisticUpdates.dispose(targetWebsktopOptimisticUpdate.id);
        optimisticUpdates.dispose(sourceWebsktopOptimisticUpdate.id);
      }
    },
    [optimisticUpdates, setAnyWebsktop, websktopId],
  );

  return moveItems;
};

export default useMoveItems;
