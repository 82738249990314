import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const websktopPublishedModalAtom = createModalAtom<State>('websktop-published-modal');

export const useWebsktopPublishedModal = () => useModal<State>(websktopPublishedModalAtom);
