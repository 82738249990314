import { FunctionComponent } from 'react';

import { Toggle } from 'components';
import { useUniqueId } from 'hooks';
import { Currency } from 'types';

interface Props {
  className?: string;
  value: Currency;
  onChange: (value: Currency) => void;
}

const CurrencyInput: FunctionComponent<Props> = ({ className, value, onChange }) => {
  const id = useUniqueId();

  return (
    <Toggle
      checked={value === 'eur'}
      className={className}
      id={id}
      labelLeft="USD"
      labelRight="EUR"
      size="large"
      title="Choose the currency you would like to pay in"
      onChange={(event) => onChange(event.currentTarget.checked ? 'eur' : 'usd')}
    />
  );
};

export default CurrencyInput;
