import type Id from './Id';
import { isObject } from './lib';

export default interface Entity {
  id: Id;
}

export const isEntity = (value: unknown): value is Record<string, unknown> => {
  return isObject(value) && typeof value.id === 'string';
};
