import { Link } from '@websktop/commons';
import { FunctionComponent } from 'react';

import { Button, Modal } from 'components';
import { openLink, pluralize } from 'lib';

interface Props {
  className?: string;
  isOpen: boolean;
  links: Link[];
  onClose: () => void;
}

const OpenLinksConfirmationModal: FunctionComponent<Props> = ({
  className,
  isOpen,
  links,
  onClose,
}) => {
  const handleConfirm = () => {
    onClose();

    for (const link of links) {
      openLink(link.url, { target: '_blank', rel: 'noopener noreferrer' });
    }
  };

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button autoFocus icon="check" variant="primary" onClick={handleConfirm}>
            Yes
          </Button>
        </Modal.Footer>
      }
      header={<h2>That's a lot of tabs</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      Are you sure you want to open{' '}
      {pluralize(links.length, { one: '1 tab', many: `${links.length} tabs` })}?
    </Modal>
  );
};

export default OpenLinksConfirmationModal;
