import classNames from 'classnames';
import { FunctionComponent, HTMLProps, Suspense } from 'react';

import { SplashLoader } from 'components';
import { CONTENT_ELEMENT_ID, SIDEBAR_ELEMENT_ID } from 'constants/dom';

import Sidebar from '../Sidebar';

import styles from './AppLayout.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  sidebar?: boolean;
}

const AppLayout: FunctionComponent<Props> = ({ children, className, sidebar, ...props }) => {
  return (
    <div className={classNames(styles.appLayout, className)} {...props}>
      {sidebar && <Sidebar className={styles.sidebar} id={SIDEBAR_ELEMENT_ID} />}
      <div className={styles.content} id={CONTENT_ELEMENT_ID}>
        <Suspense fallback={<SplashLoader />}>{children}</Suspense>
      </div>
    </div>
  );
};

export default AppLayout;
