import { type default as Entity, isEntity } from './Entity';
import type Folder from './Folder';
import { type default as Icon, isIcon } from './Icon';
import type Timestamp from './Timestamp';

export default interface Link extends Entity {
  createdAt: Timestamp;
  name: string;
  note: string;
  parentId: Folder['id'];
  primaryIcon: Icon;
  updatedAt: Timestamp;
  url: string;
  x: number;
  y: number;
}

export const isLink = (value: unknown): value is Link => {
  return (
    isEntity(value) &&
    isIcon(value.primaryIcon) &&
    typeof value.name === 'string' &&
    typeof value.note === 'string' &&
    typeof value.parentId === 'string' &&
    typeof value.url === 'string' &&
    typeof value.x === 'number' &&
    typeof value.y === 'number'
  );
};
