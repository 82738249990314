import { CSSProperties } from 'react';

const applyStyle = (element: HTMLElement | null, style: CSSProperties) => {
  if (!element) {
    return;
  }

  for (const [key, value] of Object.entries(style)) {
    element.style[key as any] = value;
  }
};

export default applyStyle;
