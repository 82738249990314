import { Folder, UpdateFolderPayload, updateItemInWebsktop, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';

import { useSetWebsktop } from '../atom';

type UpdateFolder = (item: Folder, payload: UpdateFolderPayload) => Promise<void>;

const useUpdateFolder = (websktopId: Websktop['id']): UpdateFolder => {
  const setWebsktop = useSetWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();

  const updateFolder = useCallback(
    async (folder: Folder, payload: UpdateFolderPayload) => {
      const folderId = folder.id;

      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop, id) => {
        return updateItemInWebsktop(websktop, { ...folder, ...payload });
      });

      try {
        const websktop = await api.websktop.patchFolder({ websktopId, folderId, ...payload });
        setWebsktop(websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, websktopId],
  );

  return updateFolder;
};

export default useUpdateFolder;
