import { getUrlsFromHtml, getUrlsFromText } from 'lib';

import { isHtmlDropEvent, isTextDropEvent, isUrlsDropEvent } from '../types';

const getUrlsFromDropEvent = (event: unknown): string[] => {
  if (isUrlsDropEvent(event) && event.urls.length > 0) {
    return event.urls;
  }

  if (isHtmlDropEvent(event)) {
    const urls = getUrlsFromHtml(event.html);

    if (urls.length > 0) {
      return urls;
    }
  }

  if (isTextDropEvent(event)) {
    return getUrlsFromText(event.text);
  }

  return [];
};

export default getUrlsFromDropEvent;
