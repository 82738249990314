import { FunctionComponent } from 'react';

import { Panel } from 'components';
import { RefreshPageButton } from 'modules/errors';

import styles from './LoadingError.module.scss';

const LoadingError: FunctionComponent = () => {
  return (
    <Panel className={styles.error}>
      <h1>Loading error</h1>

      <p>Your internet connection is unstable.</p>

      <RefreshPageButton />
    </Panel>
  );
};

export default LoadingError;
