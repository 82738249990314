import { Link, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  link: Link;
  websktopId: Websktop['id'];
}

const editLinkModalAtom = createModalAtom<State>('edit-link-modal');

export const useEditLinkModal = () => useModal(editLinkModalAtom);
