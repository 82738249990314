import { UserType } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn } from 'react-use';

import { patchQueryParams } from 'lib';
import { useAppLayout } from 'modules/app-layout';
import { useErrorNotification } from 'modules/notifications';
import routing from 'modules/routing';
import { useAuthenticatedUser, useLogout } from 'state';

import Icon from '../../Icon';
import { styles as linkStyles } from '../Link';

import styles from './SignOutButton.module.scss';

interface Props {
  className?: string;
}

const SignOutButton: FunctionComponent<Props> = ({ className }) => {
  const { isSidebarWide } = useAppLayout();
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const logout = useLogout();

  const [{ error, loading: isSubmitting }, handleLogout] = useAsyncFn(async () => {
    await logout();

    if (user.type === UserType.DEMO) {
      window.location.href = routing.index;
    } else {
      window.location.href = patchQueryParams(routing.auth.signIn, { signedOut: 'true' });
    }
  }, [logout, navigate, user]);

  useErrorNotification(error);

  return (
    <button
      className={classNames(linkStyles.link, styles.signOut, className)}
      disabled={isSubmitting}
      title="Sign out"
      type="button"
      onClick={handleLogout}
    >
      <Icon className={linkStyles.icon} icon="sign-out-alt" />
      {isSidebarWide && <div className={linkStyles.name}>Sign out</div>}
    </button>
  );
};

export default SignOutButton;
