import { isApiError } from '@websktop/commons';

import { HttpError } from 'types';

const handleErrors = async (response: Response): Promise<Response> => {
  if (!response.ok) {
    try {
      const json = await response.json();

      if (isApiError(json)) {
        throw new HttpError(json.message, response.status, json.payload);
      }
    } finally {
      // do nothing
    }

    throw new HttpError(response.statusText, response.status);
  }

  return response;
};

const toJson = (response: Response): Promise<any> => response.json();

const toText = (response: Response): Promise<string> => response.text();

const api = {
  get(
    url: Parameters<typeof fetch>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<Response> {
    return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      ...parameters,
    }).then(handleErrors);
  },

  getJson<ResponsePayload>(
    url: Parameters<typeof fetch>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<ResponsePayload> {
    return api
      .get(url, {
        ...parameters,
        headers: {
          Accept: 'application/json',
          ...parameters.headers,
        },
      })
      .then(toJson);
  },

  getText(
    url: Parameters<typeof fetch>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<string> {
    return api
      .get(url, {
        ...parameters,
        headers: {
          Accept: 'text/plain',
          ...parameters.headers,
        },
      })
      .then(toText);
  },

  patch(
    url: Parameters<typeof fetch>[0],
    payload?: Parameters<typeof JSON.stringify>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<Response> {
    return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      method: 'PATCH',
      body: JSON.stringify(payload),
      ...parameters,
      headers: {
        'content-type': 'application/json',
        ...parameters.headers,
      },
    }).then(handleErrors);
  },

  patchJson<ResponsePayload>(
    url: Parameters<typeof fetch>[0],
    payload?: Parameters<typeof JSON.stringify>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<ResponsePayload> {
    return api.patch(url, payload, parameters).then(toJson);
  },

  post(
    url: Parameters<typeof fetch>[0],
    payload?: Parameters<typeof JSON.stringify>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<Response> {
    return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      body: JSON.stringify(payload),
      ...parameters,
      headers: {
        'content-type': 'application/json',
        ...parameters.headers,
      },
    }).then(handleErrors);
  },

  postForm<ResponsePayload>(
    url: Parameters<typeof fetch>[0],
    formData?: FormData,
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<ResponsePayload> {
    return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      method: 'POST',
      body: formData,
      ...parameters,
    })
      .then(handleErrors)
      .then(toJson);
  },

  postJson<ResponsePayload>(
    url: Parameters<typeof fetch>[0],
    payload?: Parameters<typeof JSON.stringify>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<ResponsePayload> {
    return api.post(url, payload, parameters).then(toJson);
  },

  delete(
    url: Parameters<typeof fetch>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<Response> {
    return fetch(url, {
      credentials: 'include',
      mode: 'cors',
      method: 'DELETE',
      ...parameters,
      headers: {
        Accept: 'application/json',
        ...parameters.headers,
      },
    }).then(handleErrors);
  },

  deleteJson<ResponsePayload>(
    url: Parameters<typeof fetch>[0],
    parameters: Parameters<typeof fetch>[1] = {},
  ): Promise<ResponsePayload> {
    return api.delete(url, parameters).then(toJson);
  },
};

export default api;
