import { UpdateWebsktopUsersRolesPayload, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useSetWebsktopUsers } from '../atom';

const useUpdateWebsktopUsersRoles = (websktopId: Websktop['id']) => {
  const setWebsktopUsers = useSetWebsktopUsers(websktopId);

  const updateWebsktopUsersRoles = useCallback(
    async (roles: UpdateWebsktopUsersRolesPayload) => {
      const websktopUserRefs = await api.websktop.updateUsersRoles({ websktopId, roles });

      setWebsktopUsers(websktopUserRefs);

      return websktopUserRefs;
    },
    [setWebsktopUsers, websktopId],
  );
  return updateWebsktopUsersRoles;
};

export default useUpdateWebsktopUsersRoles;
