import classNames from 'classnames';
import { forwardRef, HTMLProps } from 'react';

import styles from './Panel.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  variant?: 'default' | 'error' | 'info' | 'success';
}

const Panel = forwardRef<HTMLDivElement, Props>(
  ({ children, className, variant = 'default', ...props }, ref) => (
    <div
      className={classNames(styles.panel, className, {
        [styles.default]: variant === 'default',
        [styles.error]: variant === 'error',
        [styles.info]: variant === 'info',
        [styles.success]: variant === 'success',
      })}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  ),
);

export default Panel;
