import { FunctionComponent, Suspense } from 'react';

import { LoadingModal } from 'components';

import BackgroundContextMenu, { useBackgroundContextMenu } from './background-context-menu';
import FolderContextMenu, { useFolderContextMenu } from './folder-context-menu';
import ItemsContextMenu, { useItemsContextMenu } from './items-context-menu';
import LinkContextMenu, { useLinkContextMenu } from './link-context-menu';
import SearchModal, { useSearchModal } from './search-modal';

const UnauthenticatedModals: FunctionComponent = () => {
  const searchModal = useSearchModal();
  const backgroundContextMenu = useBackgroundContextMenu();
  const folderContextMenu = useFolderContextMenu();
  const itemsContextMenu = useItemsContextMenu();
  const linkContextMenu = useLinkContextMenu();

  return (
    <>
      {/* Modals */}

      {searchModal.isOpen && (
        <Suspense fallback={<LoadingModal onClose={searchModal.close} />}>
          <SearchModal isOpen={searchModal.isOpen} onClose={searchModal.close} />
        </Suspense>
      )}

      {/* Context Menus */}

      {backgroundContextMenu.isOpen && (
        <BackgroundContextMenu
          gridPoint={backgroundContextMenu.data.gridPoint}
          isOpen={backgroundContextMenu.isOpen}
          folderId={backgroundContextMenu.data.folderId}
          point={backgroundContextMenu.data.point}
          websktopId={backgroundContextMenu.data.websktopId}
          onClose={backgroundContextMenu.close}
        />
      )}

      {folderContextMenu.isOpen && (
        <FolderContextMenu
          folderId={folderContextMenu.data.folderId}
          isOpen={folderContextMenu.isOpen}
          point={folderContextMenu.data.point}
          websktopId={folderContextMenu.data.websktopId}
          onClose={folderContextMenu.close}
        />
      )}

      {itemsContextMenu.isOpen && (
        <ItemsContextMenu
          isOpen={itemsContextMenu.isOpen}
          itemsIds={itemsContextMenu.data.itemsIds}
          point={itemsContextMenu.data.point}
          websktopId={itemsContextMenu.data.websktopId}
          onClose={itemsContextMenu.close}
        />
      )}

      {linkContextMenu.isOpen && (
        <LinkContextMenu
          isOpen={linkContextMenu.isOpen}
          linkId={linkContextMenu.data.linkId}
          point={linkContextMenu.data.point}
          websktopId={linkContextMenu.data.websktopId}
          onClose={linkContextMenu.close}
        />
      )}
    </>
  );
};

export default UnauthenticatedModals;
