import { Plan, Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useAsyncFn } from 'react-use';
import { useNavigate } from 'react-router-dom';

import { useTooltip } from 'components';
import { useUpgradeModal } from 'modals';
import routing from 'modules/routing';
import { useAuthenticatedUser, useForkWebsktop } from 'state';

import StatusBarButton from '../StatusBarButton';

interface Props {
  websktopId: Websktop['id'];
}

const CloneButton: FunctionComponent<Props> = ({ websktopId }) => {
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const upgradeModal = useUpgradeModal();
  const forkWebsktop = useForkWebsktop(websktopId);
  const tooltipProps = useTooltip(
    <>
      <strong>Clone Websktop</strong>
      <div>Clone this Websktop and manage on your own</div>
    </>,
    { placement: 'bottom' },
  );

  const [{ loading: isLoading }, fork] = useAsyncFn(async () => {
    const websktop = await forkWebsktop();
    navigate(routing.websktop(websktop.id));
  }, [forkWebsktop, navigate]);

  const handleClick = () => {
    if (user.subscription.plan === Plan.FREE) {
      upgradeModal.open({
        message: (
          <div>
            <h2>Would you like to clone this Websktop?</h2>
            Professional Plan supports <strong>unlimited</strong> number of Websktops.
          </div>
        ),
        source: 'clone websktop button',
      });
    } else {
      fork();
    }
  };

  return (
    <StatusBarButton
      disabled={isLoading}
      icon="code-branch"
      onClick={handleClick}
      {...tooltipProps}
    />
  );
};

export default CloneButton;
