import { useKey } from 'react-use';

import { getTabbableElements } from 'lib';

interface Options {
  direction?: 'horizontal' | 'vertical';
  disabled?: boolean;
}

const useTabbableElementsNavigation = (
  element: HTMLElement | null,
  { direction = 'vertical', disabled }: Options = {},
) => {
  useKey(
    direction === 'vertical' ? 'ArrowUp' : 'ArrowLeft',
    (event) => {
      if (disabled) {
        return;
      }

      event.preventDefault();

      const tabbableElements = getTabbableElements(element);
      const indexOfActiveElement = tabbableElements.findIndex(
        (element) => element === document.activeElement,
      );

      if (indexOfActiveElement === -1) {
        tabbableElements[tabbableElements.length - 1]?.focus();
      } else {
        const previousIndex =
          (indexOfActiveElement + tabbableElements.length - 1) % tabbableElements.length;
        tabbableElements[previousIndex]?.focus();
      }
    },
    undefined,
    [disabled, element],
  );

  useKey(
    direction === 'vertical' ? 'ArrowDown' : 'ArrowRight',
    (event) => {
      if (disabled) {
        return;
      }

      event.preventDefault();

      const tabbableElements = getTabbableElements(element);
      const indexOfActiveElement = tabbableElements.findIndex(
        (element) => element === document.activeElement,
      );

      if (indexOfActiveElement === -1) {
        tabbableElements[0]?.focus();
      } else {
        const nextIndex = (indexOfActiveElement + 1) % tabbableElements.length;
        tabbableElements[nextIndex]?.focus();
      }
    },
    undefined,
    [disabled, element],
  );
};

export default useTabbableElementsNavigation;
