import { FunctionComponent, HTMLProps } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';

import { useIsTouchDevice } from 'hooks';

interface Props extends HTMLProps<HTMLDivElement> {}

const Dnd: FunctionComponent<Props> = ({ children }) => {
  const isTouchDevice = useIsTouchDevice();
  const dndBackend = isTouchDevice ? TouchBackend : HTML5Backend;
  const options = isTouchDevice ? { enableMouseEvents: true } : undefined;

  return (
    <DndProvider backend={dndBackend} options={options}>
      {children}
    </DndProvider>
  );
};

export default Dnd;
