import isUserTextInputElement from './isUserTextInputElement';

const isUserInputElement = (element: unknown): boolean => {
  return (
    isUserTextInputElement(element) ||
    element instanceof HTMLButtonElement ||
    element instanceof HTMLAnchorElement
  );
};

export default isUserInputElement;
