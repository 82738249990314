import { Link } from '@websktop/commons';
import { v4 as uuidV4 } from 'uuid';

import { NULL_ICON_PAYLOAD } from 'constants/icons';

interface Params extends Pick<Link, 'id' | 'parentId' | 'url' | 'x' | 'y'> {
  name?: string;
  note?: string;
  primaryIcon?: Link['primaryIcon'];
}

const createOptimisticLink = ({
  id,
  name = '',
  note = '',
  parentId,
  primaryIcon = {
    ...NULL_ICON_PAYLOAD,
    id: uuidV4(),
    upload: null,
  },
  url,
  x,
  y,
}: Params): Link => ({
  createdAt: Date.now(),
  id,
  name,
  note,
  parentId,
  primaryIcon,
  updatedAt: Date.now(),
  url,
  x,
  y,
});

export default createOptimisticLink;
