import { User, UserCursor, UserRef, Websktop } from '@websktop/commons';
import { atomFamily, useRecoilValue } from 'recoil';

export const websktopOnlineUsersAtomFamily = atomFamily<UserRef[], Websktop['id']>({
  key: 'websktop-online-users',
  default: [],
});

export const websktopUsersCursorsAtomFamily = atomFamily<
  Record<User['id'], UserCursor>,
  Websktop['id']
>({
  key: 'websktop-users-cursors',
  default: {},
});

export const useWebsktopOnlineUsers = (websktopId: Websktop['id']) =>
  useRecoilValue(websktopOnlineUsersAtomFamily(websktopId));

export const useWebsktopUsersCursors = (websktopId: Websktop['id']) =>
  useRecoilValue(websktopUsersCursorsAtomFamily(websktopId));
