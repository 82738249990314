import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './ItemsGroup.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {}

const ItemsGroup: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <div className={classNames(styles.itemsGroup, className)} {...props}>
    {children}
  </div>
);

export default ItemsGroup;
