import {
  folderToUpdatePayload,
  isLink,
  Item,
  linkToUpdatePayload,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';

import useUpdateFolder from './useUpdateFolder';
import useUpdateLink from './useUpdateLink';

type RenameLink = (item: Item, name: string) => Promise<void>;

const useRenameItem = (websktopId: Websktop['id']): RenameLink => {
  const updateLink = useUpdateLink(websktopId);
  const updateFolder = useUpdateFolder(websktopId);

  const renameItem = useCallback(
    async (item: Item, name: string) => {
      if (item.name === name) {
        return;
      }

      return isLink(item)
        ? updateLink(item, { ...linkToUpdatePayload(item), name })
        : updateFolder(item, { ...folderToUpdatePayload(item), name });
    },
    [updateFolder, updateLink],
  );

  return renameItem;
};

export default useRenameItem;
