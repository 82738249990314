import { useState } from 'react';
import { useKey, useMountedState } from 'react-use';

import { wait } from 'lib';

import { UseHotkey } from '../../types';

import useKeyFilter from './useKeyFilter';

const useHotkey: UseHotkey = (action, callback, options) => {
  const getIsMounted = useMountedState();
  const keyFilter = useKeyFilter(action);
  const [isHotkeyPressed, setIsHotkeyPressed] = useState<boolean>(false);

  useKey(
    (event: KeyboardEvent) => !options?.disabled && keyFilter(event),
    (event) => {
      if (options?.preventDefault) {
        event.preventDefault();
      }

      if (options?.stopPropagation) {
        event.stopPropagation();
      }

      if (typeof options?.delay === 'number') {
        setIsHotkeyPressed(true);
        wait(options.delay).then(() => {
          if (getIsMounted()) {
            setIsHotkeyPressed(false);
          }

          callback(event);
        });
      } else {
        callback(event);
      }
    },
    options,
    [callback, getIsMounted, keyFilter, options],
  );

  return isHotkeyPressed;
};

export default useHotkey;
