import { FunctionComponent } from 'react';

import api from 'api';
import { Image } from 'components';

interface Props {
  className?: string;
  size: number;
  url: string;
}

const FaviconIcon: FunctionComponent<Props> = ({ className, size, url }) => (
  <Image
    className={className}
    draggable={false}
    height={size}
    src={api.favicon.getUrl(url)}
    width={size}
  />
);

export default FaviconIcon;
