import { isError } from '@websktop/commons';

const isChunkLoadFailedError = (value: unknown): boolean => {
  if (!isError(value)) {
    return false;
  }

  const lowercaseMessage = value.message.toLowerCase();

  return (
    lowercaseMessage.includes('loading') &&
    lowercaseMessage.includes('chunk') &&
    lowercaseMessage.includes('failed') &&
    lowercaseMessage.includes('https://websktop.com/static/')
  );
};

export default isChunkLoadFailedError;
