import { Point } from '@websktop/commons';

import { Rectangle } from 'types';

const createRectangle = (point1: Point, point2: Point): Rectangle => ({
  x: Math.min(point1.x, point2.x),
  y: Math.min(point1.y, point2.y),
  width: Math.abs(point1.x - point2.x),
  height: Math.abs(point1.y - point2.y),
});

export default createRectangle;
