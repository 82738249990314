import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import styles from './AccessEntry.module.scss';

interface Props {
  className?: string;
  children?: ReactNode;
  label: ReactNode;
}

const AccessEntry: FunctionComponent<Props> = ({ children, className, label }) => (
  <div className={classNames(styles.accessEntry, className)}>
    <div>{label}</div>
    <div className={styles.setting}>{children}</div>
  </div>
);

export default AccessEntry;
