import { Folder, isFolder } from '../types';

import findItem from './findItem';

const getFolder = (folder: Folder, folderId: Folder['id']): Folder => {
  const item = findItem(folder, folderId);

  if (!isFolder(item)) {
    throw new Error('Folder does not exist');
  }

  return item;
};

export default getFolder;
