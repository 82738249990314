import { isFolder, isLink, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { useDeleteFolderModal, useDeleteItemsModal, useDeleteLinkModal } from 'modals';
import { useItems, useSelection } from 'state';

import { useHotkey } from './lib';
import { UseWebsktopHotkey } from '../types';

type Params = { websktopId: Websktop['id'] };

const useDelete: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const deleteFolderModal = useDeleteFolderModal();
  const deleteItemsModal = useDeleteItemsModal();
  const deleteLinkModal = useDeleteLinkModal();
  const items = useItems(websktopId, selection);
  const [item] = items;

  const handleDeleteItems = useCallback(() => {
    deleteItemsModal.open({ itemsIds: selection, websktopId });
  }, [deleteItemsModal, selection, websktopId]);

  const handleDeleteLink = useCallback(() => {
    deleteLinkModal.open({ linkId: item.id, websktopId });
  }, [deleteLinkModal, item, websktopId]);

  const handleDeleteFolder = useCallback(() => {
    deleteFolderModal.open({ folderId: item.id, websktopId });
  }, [deleteFolderModal, item, websktopId]);

  useHotkey('delete', handleDeleteItems, { disabled: !isEnabled || selection.length <= 1 });

  useHotkey('delete', handleDeleteLink, {
    disabled: !isEnabled || selection.length !== 1 || !isLink(item),
  });

  useHotkey('delete', handleDeleteFolder, {
    disabled: !isEnabled || selection.length !== 1 || !isFolder(item),
  });
};

export default useDelete;
