import { Websktop } from '@websktop/commons';

import { urlToFile } from 'lib';
import { EntityIcon } from 'types';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const getWebsktopIcon = async (websktop: Websktop): Promise<EntityIcon> => {
  if (websktop.primaryIcon.upload?.url) {
    return urlToFile(API_ORIGIN + websktop.primaryIcon.upload.url);
  }

  if (websktop.primaryIcon.emoji) {
    return websktop.primaryIcon.emoji;
  }

  return null;
};

export default getWebsktopIcon;
