import { isUrl } from 'lib';

/**
 * "- lorem"    -> "lorem"
 * "  - ipsum"  -> "ipsum"
 */
const removeLeadingBulletPoint = (text: string): string => text.replace(/^\s*-?\s*/, '');

/**
 * CRLF -> LF
 * LF -> LF
 */
const normalizeLineEndings = (text: string): string => text.replace(/\r?\n/g, '\n');

/**
 * itemsToText adds link names to URLs in parens. This function cleans these names up.
 *
 * "https://example.com (Example Page)" -> "https://example.com"
 */
const removeTrailingLinkName = (text: string): string => text.replace(/\s+\(.*$/gm, '');

const getUrlsFromText = (text: string): string[] => {
  const potentialUrls = normalizeLineEndings(text).split('\n');

  return potentialUrls
    .map((url) => url.trim())
    .map(removeLeadingBulletPoint)
    .map(removeTrailingLinkName)
    .filter(isUrl);
};

export default getUrlsFromText;
