import { isLink, Item } from '@websktop/commons';

import { fetchImageBase64, getAbsoluteUrl } from 'lib';

import { Item as BookmarkItem } from '../../bookmarks-manager';

const itemsToBookmarksTree = async (items: Item[]): Promise<BookmarkItem[]> =>
  Promise.all(
    items.map(async (item): Promise<BookmarkItem> => {
      if (isLink(item)) {
        return {
          addDate: Math.floor(item.createdAt / 1000),
          icon: item.primaryIcon.upload
            ? await fetchImageBase64(getAbsoluteUrl(item.primaryIcon.upload.url)).catch(
                () => undefined,
              )
            : undefined,
          name: item.name,
          url: item.url,
        };
      }

      return {
        addDate: Math.floor(item.createdAt / 1000),
        children: await itemsToBookmarksTree(item.items),
        name: item.name,
      };
    }),
  );

export default itemsToBookmarksTree;
