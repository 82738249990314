import { useAllWebsktops, useUser } from 'state';

import { isNewUser } from '../lib';

const useIsNewUser = (): boolean | undefined => {
  const user = useUser();
  const websktops = useAllWebsktops();
  return isNewUser(user, websktops);
};

export default useIsNewUser;
