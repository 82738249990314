import { FunctionComponent } from 'react';

import routing from 'modules/routing';
import { Link } from 'components';

import Notice from '../Notice';

import styles from './UnauthenticatedUserNotice.module.scss';

const UnauthenticatedUserNotice: FunctionComponent = () => (
  <Notice>
    Want to build a Websktop like this?
    <span className={styles.actions}>
      <Link className={styles.link} href={routing.auth.signUp.toString()}>
        Sign up
      </Link>

      <Link className={styles.link} href={routing.index} type="internal">
        Learn more
      </Link>
    </span>
  </Notice>
);

export default UnauthenticatedUserNotice;
