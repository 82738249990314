import { TouchEvent } from 'react';

type WebsktopTouchEvent = TouchEvent<any> & {
  handled?: boolean;
};

export default class TouchEventManager {
  static markHandled(event: WebsktopTouchEvent) {
    event.handled = true;
  }

  static isHandled(event: WebsktopTouchEvent) {
    return Boolean(event.handled);
  }
}
