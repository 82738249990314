import useMergedRef from '@react-hook/merged-ref';
import classNames from 'classnames';
import { FunctionComponent, HTMLProps, useState } from 'react';

import { useFocusIn, useIsOnline } from 'hooks';
import { useAppLayout } from 'modules/app-layout';
import routing from 'modules/routing';
import { useCreateNewWebsktop } from 'modules/user-flows';

import { useArrowNavigation } from './hooks';
import SidebarLink, { styles as linkStyles } from './Link';
import styles from './Sidebar.module.scss';
import SignOutButton from './SignOutButton';
import ToggleSidebarButton from './ToggleSidebarButton';
import WebsktopLinks from './WebsktopLinks';

interface Props extends HTMLProps<HTMLDivElement> {}

const EMAIL = process.env.REACT_APP_EMAIL_CONTACT;

const Sidebar: FunctionComponent<Props> = ({ className, ...restProps }) => {
  const isOnline = useIsOnline();
  const { isSidebarWide } = useAppLayout();
  const createNewWebsktop = useCreateNewWebsktop();
  const [sidebarElement, setSidebarElement] = useState<HTMLElement | null>(null);
  const [isSidebarFocusedWithin, sidebarFocusWithinRef] = useFocusIn();

  useArrowNavigation(sidebarElement, { disabled: !isSidebarFocusedWithin });

  const mergedRef = useMergedRef(sidebarFocusWithinRef, setSidebarElement);

  return (
    <div className={classNames(styles.sidebar, className)} ref={mergedRef} {...restProps}>
      <div className={styles.group} data-tour="multiple-websktops">
        <WebsktopLinks />

        {isSidebarWide && (
          <button
            className={classNames(linkStyles.link, styles.createNewWebsktop, styles.action)}
            onClick={createNewWebsktop}
          >
            + Create new Websktop
          </button>
        )}
      </div>

      {isOnline && (
        <>
          <div className={classNames(styles.group, styles.buttons)}>
            <SidebarLink href={routing.manageWebsktops} icon="list-ul" title="Manage Websktops">
              Manage Websktops
            </SidebarLink>

            <SidebarLink href={routing.userSettings} icon="cog" title="User settings">
              User settings
            </SidebarLink>
          </div>

          <div className={classNames(styles.group, styles.buttons)}>
            <SidebarLink href={`mailto:${EMAIL}`} icon="headset" title="Contact us">
              Contact us
            </SidebarLink>

            <SignOutButton className={styles.action} />
          </div>

          <div className={classNames(styles.group, styles.buttons)}>
            <ToggleSidebarButton className={styles.action} />
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
