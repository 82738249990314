import { useCallback } from 'react';

import api from 'api';
import { clearSessionData } from 'lib';

/**
 * You must redirect using window.location.href after calling await logout()
 */
const useLogout = (): typeof api.auth.logout => {
  const logout: typeof api.auth.logout = useCallback(async () => {
    try {
      await api.auth.logout();
    } catch {
      // We should logout even if api returns an error, eg. 401 (when demo account expired)
      // Ignore the error
    } finally {
      clearSessionData();
    }
  }, []);

  return logout;
};

export default useLogout;
