import { getLinksDeep, isFolder, Item, Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';

import { ContextMenu, ContextMenuProps, Menu } from 'components';
import { useIsOnline, useOpenLinks } from 'hooks';
import { isSafeUrl, pluralize } from 'lib';
import { useDeleteItemsModal } from 'modals';
import { exportBookmarks } from 'modules/bookmarks-exporter';
import { useHotkey, WebsktopHotkey } from 'modules/websktop-hotkeys';
import { useClipboard, useItems, usePermissions } from 'state';

interface Props extends Omit<ContextMenuProps, 'children'> {
  itemsIds: Item['id'][];
  websktopId: Websktop['id'];
}

const HOTKEY_DELAY = 50;

const ItemsContextMenu: FunctionComponent<Props> = ({
  itemsIds,
  point,
  websktopId,
  onClose,
  ...props
}) => {
  const isOnline = useIsOnline();
  const { canEditItems } = usePermissions(websktopId);
  const items = useItems(websktopId, itemsIds);
  const deleteItemsModal = useDeleteItemsModal();
  const clipboard = useClipboard(websktopId);
  const openLinks = useOpenLinks();
  const links = getLinksDeep(items);
  const safeLinks = links.filter((link) => isSafeUrl(link.url));
  const isCutEnabled = canEditItems && isOnline;
  const isDeleteEnabled = canEditItems && isOnline;
  const isAnyFolderSelected = items.some(isFolder);

  const handleCopy = () => {
    clipboard.copy(items);
    onClose();
  };

  const handleCut = () => {
    clipboard.cut(items);
    onClose();
  };

  const handleDelete = () => {
    const itemsIds = items.map((item) => item.id);
    deleteItemsModal.open({ itemsIds, websktopId });
    onClose();
  };

  const handleOpen = () => {
    openLinks(safeLinks);
    onClose();
  };

  const handleExport = () => {
    exportBookmarks(items);
  };

  const isCopyHotkeyPressed = useHotkey('copy', handleCopy, { delay: HOTKEY_DELAY });
  const isCutHotkeyPressed = useHotkey('cut', handleCut, { delay: HOTKEY_DELAY });
  const isDeleteHotkeyPressed = useHotkey('delete', handleDelete, { delay: HOTKEY_DELAY });

  return (
    <ContextMenu point={point} onClose={onClose} {...props}>
      <Menu>
        <Menu.ItemsGroup>
          <Menu.Item.Button
            addon={<WebsktopHotkey action="open" />}
            disabled={safeLinks.length === 0}
            icon="external-link-square-alt"
            onClick={handleOpen}
          >
            Open {safeLinks.length}{' '}
            {pluralize(safeLinks.length, { one: 'bookmark', many: 'bookmarks' })} in new{' '}
            {pluralize(safeLinks.length, { one: 'tab', many: 'tabs' })}
            {isAnyFolderSelected && ' (deep)'}
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          {isCutEnabled && (
            <Menu.Item.Button
              addon={<WebsktopHotkey action="cut" />}
              icon="cut"
              pressed={isCutHotkeyPressed}
              onClick={handleCut}
            >
              Cut
            </Menu.Item.Button>
          )}

          <Menu.Item.Button
            addon={<WebsktopHotkey action="copy" />}
            icon="clipboard"
            pressed={isCopyHotkeyPressed}
            onClick={handleCopy}
          >
            Copy
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        <Menu.ItemsGroup>
          <Menu.Item.Button icon="file-export" onClick={handleExport}>
            Export bookmarks
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        {isDeleteEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button
              addon={<WebsktopHotkey action="delete" />}
              icon="trash-alt"
              pressed={isDeleteHotkeyPressed}
              variant="danger"
              onClick={handleDelete}
            >
              Delete
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}
      </Menu>
    </ContextMenu>
  );
};

export default ItemsContextMenu;
