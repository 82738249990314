import { Link, Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { useCopyToClipboard } from 'react-use';

import { ContextMenu, ContextMenuProps, Menu } from 'components';
import { useIsOnline } from 'hooks';
import { isSafeUrl, openLink } from 'lib';
import { useDeleteLinkModal, useEditLinkModal } from 'modals';
import { useHotkey, WebsktopHotkey } from 'modules/websktop-hotkeys';
import { useClipboard, useLink, useItemRename, usePermissions, useUserSettings } from 'state';

interface Props extends Omit<ContextMenuProps, 'children'> {
  linkId: Link['id'];
  websktopId: Websktop['id'];
}

const HOTKEY_DELAY = 50;

const LinkContextMenu: FunctionComponent<Props> = ({ linkId, websktopId, onClose, ...props }) => {
  const isOnline = useIsOnline();
  const { canEditItems } = usePermissions(websktopId);
  const userSettings = useUserSettings();
  const link = useLink(websktopId, linkId);
  const itemRename = useItemRename(websktopId);
  const editLinkModal = useEditLinkModal();
  const deleteLinkModal = useDeleteLinkModal();
  const [, copy] = useCopyToClipboard();
  const clipboard = useClipboard(websktopId);
  const isCutEnabled = canEditItems && isOnline;
  const isDeleteEnabled = canEditItems && isOnline;
  const isEditEnabled = canEditItems && isOnline;
  const isRenameEnabled = canEditItems && isOnline;

  const handleCopy = () => {
    clipboard.copy([link]);
    onClose();
  };

  const handleCopyName = () => {
    copy(link.name);
    onClose();
  };

  const handleCut = () => {
    clipboard.cut([link]);
    onClose();
  };

  const handleDelete = () => {
    deleteLinkModal.open({ linkId: link.id, websktopId });
    onClose();
  };

  const handleEdit = () => {
    editLinkModal.open({ link, websktopId });
    onClose();
  };

  const handleOpen = () => {
    openLink(link.url, { rel: 'noopener noreferrer', target: '_self' });
    onClose();
  };

  const handleRename = () => {
    itemRename.editItem(linkId);
    onClose();
  };

  const isCopyHotkeyPressed = useHotkey('copy', handleCopy, { delay: HOTKEY_DELAY });
  const isCutHotkeyPressed = useHotkey('cut', handleCut, { delay: HOTKEY_DELAY });
  const isDeleteHotkeyPressed = useHotkey('delete', handleDelete, { delay: HOTKEY_DELAY });
  const isEditHotkeyPressed = useHotkey('edit', handleEdit, { delay: HOTKEY_DELAY });
  const isRenameHotkeyPressed = useHotkey('rename', handleRename, { delay: HOTKEY_DELAY });

  return (
    <ContextMenu onClose={onClose} {...props}>
      <Menu>
        <Menu.ItemsGroup>
          {!userSettings.forceNewTab && (
            <Menu.Item.Button
              addon={<WebsktopHotkey action="open" />}
              disabled={!isSafeUrl(link.url)}
              icon="external-link-square-alt"
              onClick={handleOpen}
            >
              Open
            </Menu.Item.Button>
          )}

          {isSafeUrl(link.url) && (
            <Menu.Item.Link
              addon={
                <WebsktopHotkey action={userSettings.forceNewTab ? 'open' : 'open-in-new-tab'} />
              }
              href={link.url}
              icon="external-link-square-alt"
              target="_blank"
              type="external"
              onClick={onClose}
            >
              Open in new tab
            </Menu.Item.Link>
          )}
        </Menu.ItemsGroup>

        {link.name.trim().length > 0 && (
          <Menu.ItemsGroup>
            <Menu.Item.Button icon="clipboard" onClick={handleCopyName}>
              Copy name
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}

        <Menu.ItemsGroup>
          {isCutEnabled && (
            <Menu.Item.Button
              addon={<WebsktopHotkey action="cut" />}
              icon="cut"
              pressed={isCutHotkeyPressed}
              onClick={handleCut}
            >
              Cut
            </Menu.Item.Button>
          )}

          <Menu.Item.Button
            addon={<WebsktopHotkey action="copy" />}
            icon="clipboard"
            pressed={isCopyHotkeyPressed}
            onClick={handleCopy}
          >
            Copy
          </Menu.Item.Button>
        </Menu.ItemsGroup>

        {(isEditEnabled || isRenameEnabled) && (
          <Menu.ItemsGroup>
            {isRenameEnabled && (
              <Menu.Item.Button
                addon={<WebsktopHotkey action="rename" />}
                icon="pencil-alt"
                pressed={isRenameHotkeyPressed}
                onClick={handleRename}
              >
                Rename
              </Menu.Item.Button>
            )}

            {isEditEnabled && (
              <Menu.Item.Button
                addon={<WebsktopHotkey action="edit" />}
                icon="pencil-alt"
                pressed={isEditHotkeyPressed}
                onClick={handleEdit}
              >
                Edit
              </Menu.Item.Button>
            )}
          </Menu.ItemsGroup>
        )}

        {isDeleteEnabled && (
          <Menu.ItemsGroup>
            <Menu.Item.Button
              addon={<WebsktopHotkey action="delete" />}
              icon="trash-alt"
              pressed={isDeleteHotkeyPressed}
              variant="danger"
              onClick={handleDelete}
            >
              Delete
            </Menu.Item.Button>
          </Menu.ItemsGroup>
        )}
      </Menu>
    </ContextMenu>
  );
};

export default LinkContextMenu;
