import { Link, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  linkId: Link['id'];
  websktopId: Websktop['id'];
}

const deleteLinkModalAtom = createModalAtom<State>('delete-link-modal');

export const useDeleteLinkModal = () => useModal(deleteLinkModalAtom);
