import { isLink, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import { isSafeUrl, openLink } from 'lib';
import routing from 'modules/routing';
import { useItems, useSelection } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { websktopId: Websktop['id'] };

const useOpenInNewTab: UseWebsktopHotkey<Params> = ({ websktopId }, isEnabled) => {
  const [selection] = useSelection();
  const items = useItems(websktopId, selection);

  const handleOpenInNewTab = useCallback(() => {
    const [item] = items;

    if (isLink(item)) {
      if (isSafeUrl(item.url)) {
        openLink(item.url, { rel: 'noopener noreferrer', target: '_blank' });
      }
    } else {
      const url = routing.websktop.folder(websktopId, item.id);
      openLink(url, { rel: 'noopener noreferrer', target: '_blank' });
    }
  }, [items, websktopId]);

  useHotkey('open-in-new-tab', handleOpenInNewTab, { disabled: !isEnabled || items.length !== 1 });
};

export default useOpenInNewTab;
