import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { UserRole } from '@websktop/commons';

export const getRoleDescription = (role: UserRole): string => {
  if (role === UserRole.ADMIN) {
    return 'Can view, edit, delete & control permissions';
  }

  if (role === UserRole.EDITOR) {
    return 'Can view & edit the contents';
  }

  if (role === UserRole.VIEWER) {
    return 'Can view the contents';
  }

  if (role === UserRole.NONE) {
    return 'No access';
  }

  throw new Error(`Unknown UserRole: ${role}`);
};

export const getRoleIcon = (role: UserRole): IconProp => {
  if (role === UserRole.ADMIN) {
    return 'user-secret';
  }

  if (role === UserRole.EDITOR) {
    return 'pencil-alt';
  }

  if (role === UserRole.VIEWER) {
    return 'eye';
  }

  if (role === UserRole.NONE) {
    return 'trash-alt';
  }

  throw new Error(`Unknown UserRole: ${role}`);
};

export const getRoleLabel = (role: UserRole): string => {
  if (role === UserRole.ADMIN) {
    return 'Admin';
  }

  if (role === UserRole.EDITOR) {
    return 'Editor';
  }

  if (role === UserRole.VIEWER) {
    return 'Viewer';
  }

  if (role === UserRole.NONE) {
    return 'No access';
  }

  throw new Error(`Unknown UserRole: ${role}`);
};

export const getRoleVariant = (role: UserRole) => {
  if (role === UserRole.ADMIN) {
    return 'info';
  }

  if (role === UserRole.EDITOR) {
    return 'success';
  }

  if (role === UserRole.VIEWER) {
    return 'default';
  }

  if (role === UserRole.NONE) {
    return 'error';
  }

  throw new Error(`Unknown UserRole: ${role}`);
};
