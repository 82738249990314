import { Folder, isFolder, isLink, Link } from '../types';

const getLinksMapDeep = (folder: Folder): Record<Link['id'], Link> => {
  const links: Record<Link['id'], Link> = {};

  for (const item of folder.items) {
    if (isFolder(item)) {
      Object.assign(links, getLinksMapDeep(item));
    } else if (isLink(item)) {
      links[item.id] = item;
    }
  }

  return links;
};

export default getLinksMapDeep;
