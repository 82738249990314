import { BillingCycle, Folder, Websktop } from '@websktop/commons';
import queryString from 'query-string';
import uuidBase62 from 'uuid-base62';

import { Currency } from 'types';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const routing = {
  index: '/',
  auth: {
    changePassword: '/change-password',
    facebook: `${API_ORIGIN}/account/facebook`,
    github: `${API_ORIGIN}/account/github`,
    google: `${API_ORIGIN}/account/google`,
    resetPassword: '/reset-password',
    signIn: '/sign-in',
    signUp: '/sign-up',
    verify: '/verify',
  },
  changelog: '/changelog',
  legal: {
    personalDataRequest: '/personal-data-request',
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',
  },
  manageWebsktops: '/manage-websktops',
  pricing: '/pricing',
  upgrade: Object.assign(
    ({ billingCycle, currency }: { billingCycle: BillingCycle; currency: Currency }) =>
      `/upgrade?${queryString.stringify({ billingCycle, currency })}`,
    {
      toString: () => '/upgrade',
    },
  ),
  roadmap: '/roadmap',
  websktop: Object.assign(
    (websktopId: Websktop['id']) => `/websktop/${uuidBase62.encode(websktopId)}`,
    {
      folder: Object.assign(
        (websktopId: Websktop['id'], folderId: Folder['id']) =>
          `/websktop/${uuidBase62.encode(websktopId)}/${uuidBase62.encode(folderId)}`,
        { toString: () => '/websktop/:websktopId/:folderId' },
      ),
      toString: () => '/websktop/:websktopId',
    },
  ),
  userSettings: '/user-settings',
};

export default routing;
