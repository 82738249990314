type DefaultIcon = null;

type EmojiIcon = string;

type UploadedIcon = File;

type EntityIcon = DefaultIcon | EmojiIcon | UploadedIcon;

export const isDefaultIcon = (value: unknown): value is DefaultIcon => {
  return value === null;
};

export const isEmojiIcon = (value: unknown): value is EmojiIcon => {
  return typeof value === 'string';
};

export const isUploadedIcon = (value: unknown): value is UploadedIcon => {
  return value instanceof File;
};

export default EntityIcon;
