export const INSTRUCTIONS = [
  // {
  //   name: 'Brave',
  //   href: '?',
  // },
  {
    name: 'Google Chrome',
    href: 'https://support.google.com/chrome/answer/95314?hl=en&co=GENIE.Platform%3DDesktop',
  },
  {
    name: 'Microsoft Edge',
    href: 'https://support.microsoft.com/en-us/microsoft-edge/change-your-browser-home-page-a531e1b8-ed54-d057-0262-cc5983a065c6',
  },
  {
    name: 'Mozilla Firefox',
    href: 'https://support.mozilla.org/en-US/kb/how-to-set-the-home-page',
  },
  // {
  //   name: 'Opera',
  //   href: '?',
  // },
  {
    name: 'Safari',
    href: 'https://support.apple.com/guide/safari/change-your-homepage-ibrw1020/mac',
  },
  {
    name: 'Vivaldi',
    href: 'https://help.vivaldi.com/desktop/appearance-customization/starting-vivaldi/',
  },
];
