import {
  addItemsToWebsktop,
  CreateFolderPayload,
  findEmptyPoint,
  getFolder,
  Websktop,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useOptimisticUpdates } from '../../optimistic-updates';
import { useSetWebsktop, useWebsktop } from '../atom';
import { createOptimisticFolder } from '../lib';

import useUpdateSelectionSafe from './useUpdateSelectionSafe';

type CreateFolder = (payload: CreateFolderPayload) => Promise<void>;

const useCreateFolder = (websktopId: Websktop['id']): CreateFolder => {
  const setWebsktop = useSetWebsktop(websktopId);
  const websktop = useWebsktop(websktopId);
  const optimisticUpdates = useOptimisticUpdates();
  const updateSelectionSafe = useUpdateSelectionSafe();

  const createFolder: CreateFolder = useCallback(
    async (payload) => {
      const folder = getFolder(websktop.folder, payload.parentId!);
      const points = folder.items.map((item) => ({ x: item.x, y: item.y }));
      const { x, y } = findEmptyPoint(
        points,
        typeof payload.x !== 'undefined' && typeof payload.y !== 'undefined'
          ? { x: payload.x, y: payload.y }
          : undefined,
      );
      const optimisticUpdate = optimisticUpdates.create<Websktop>(websktopId, (websktop, id) => {
        const optimisticFolder = createOptimisticFolder({ id, ...payload, x, y });
        return addItemsToWebsktop(websktop, [optimisticFolder]);
      });

      try {
        const response = await api.websktop.createFolder({ websktopId, ...payload });
        updateSelectionSafe([response.folder.id]);
        setWebsktop(response.websktop);
      } finally {
        optimisticUpdates.dispose(optimisticUpdate.id);
      }
    },
    [optimisticUpdates, setWebsktop, updateSelectionSafe, websktop.folder, websktopId],
  );

  return createFolder;
};

export default useCreateFolder;
