import { Folder, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  folder: Folder;
  websktopId: Websktop['id'];
}

const editFolderModalAtom = createModalAtom<State>('edit-folder-modal');

export const useEditFolderModal = () => useModal(editFolderModalAtom);
