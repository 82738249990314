import { Link } from '@websktop/commons';
import { useCallback } from 'react';

import { openLink } from 'lib';
import { useOpenLinksConfirmationModal } from 'modals';

const MIN_LINKS_CONFIRMATION_COUNT = 8;

const useOpenLinks = () => {
  const openLinksConfirmationModal = useOpenLinksConfirmationModal();
  const { open } = openLinksConfirmationModal;

  const openLinks = useCallback(
    (links: Link[]) => {
      if (links.length >= MIN_LINKS_CONFIRMATION_COUNT) {
        open({ links });
        return;
      }

      for (const link of links) {
        openLink(link.url, { rel: 'noopener noreferrer', target: '_blank' });
      }
    },
    [open],
  );

  return openLinks;
};

export default useOpenLinks;
