import socket from './socket';

const getActiveWebsktopsSocket = () => {
  if (!socket.active) {
    socket.open();
  }

  return socket;
};

export default getActiveWebsktopsSocket;
