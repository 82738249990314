import { Folder, Websktop } from '@websktop/commons';
import { useEvent } from 'react-use';

import { ClipboardMimeType } from 'constants/mime-type';
import { useClipboard, useFolder } from 'state';

import { UseWebsktopHotkey } from '../types';

type Params = { folderId: Folder['id']; websktopId: Websktop['id'] };

const usePaste: UseWebsktopHotkey<Params> = ({ folderId, websktopId }, isEnabled) => {
  const clipboard = useClipboard(websktopId);
  const folder = useFolder(websktopId, folderId);

  useEvent('paste', ({ clipboardData }: ClipboardEvent) => {
    if (!clipboardData || !isEnabled) {
      return;
    }

    const state = {
      [ClipboardMimeType.HTML]: clipboardData.getData(ClipboardMimeType.HTML),
      [ClipboardMimeType.TEXT]: clipboardData.getData(ClipboardMimeType.TEXT),
    };

    clipboard.paste({ targetFolder: folder, state });
  });
};

export default usePaste;
