import { StatusCode, Websktop } from '@websktop/commons';
import { useEffect, useState } from 'react';
import { useNetworkState, usePrevious } from 'react-use';

import api from 'api';
import { isHttpError } from 'types';

import { getSyncInterval } from '../../lib';
import { useSetWebsktopUsers } from '../atom';

const useSyncWebsktopUsers = (id: Websktop['id']) => {
  const setWebsktopUsers = useSetWebsktopUsers(id);
  const { effectiveType: connectionType, online: isOnline } = useNetworkState();
  const interval = getSyncInterval(connectionType);
  const [error, setError] = useState<unknown | null>(null);
  const wasOffline = usePrevious(!isOnline);

  useEffect(() => {
    if (!isOnline) {
      return;
    }

    const refetch = async () => {
      try {
        const users = await api.websktop.getUsers(id);
        setWebsktopUsers(users);
      } catch (error) {
        if (
          isHttpError(error) &&
          (error.status === StatusCode.UNAUTHENTICATED || error.status === StatusCode.FORBIDDEN)
        ) {
          setError(error);
        }
      }
    };

    const intervalId = setInterval(refetch, interval);

    if (wasOffline) {
      refetch();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [id, interval, isOnline, setWebsktopUsers, wasOffline]);

  if (error) {
    throw error;
  }
};

export default useSyncWebsktopUsers;
