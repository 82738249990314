import classNames from 'classnames';
import { ChangeEvent, CSSProperties, FunctionComponent, useMemo } from 'react';

import { FILE_INPUT_ACCEPT } from 'constants/images';
import { createFileList } from 'lib';

import Button from '../Button';
import LabeledFileInput, { Props as LabeledFileInputProps } from '../LabeledFileInput';
import LabeledInput from '../LabeledInput';
import Loader from '../Loader';

import styles from './ImageInput.module.scss';

interface Props
  extends Pick<LabeledFileInputProps, 'disabled' | 'error' | 'id' | 'label' | 'required'> {
  className?: string;
  description?: string;
  isLoading?: boolean;
  previewStyle: CSSProperties;
  value: File | null;
  onChange: (value: File | null) => void;
}

const ImageInput: FunctionComponent<Props> = ({
  className,
  disabled,
  description,
  error,
  id,
  isLoading,
  label,
  previewStyle,
  required,
  value,
  onChange,
}) => {
  const fileList = useMemo(() => (value ? createFileList(value) : null), [value]);
  const backgroundImage = useMemo(
    () => (value ? `url(${URL.createObjectURL(value)})` : ''),
    [value],
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files && files.length > 0 ? files[0] : null;
    onChange(file);
  };

  const handleDelete = () => {
    onChange(null);
  };

  if ((!fileList || !value) && !isLoading) {
    return (
      <LabeledFileInput
        accept={FILE_INPUT_ACCEPT}
        className={className}
        description={description}
        disabled={disabled}
        error={error}
        id={id}
        label={label}
        required={required}
        value={fileList}
        onChange={handleChange}
      />
    );
  }

  return (
    <LabeledInput
      className={classNames(styles.imageInput, className)}
      description={description}
      error={error}
      id={id}
      label={label}
      required={required}
    >
      <div className={styles.content}>
        {!value && (
          <div className={styles.preview} style={previewStyle}>
            <Loader />
          </div>
        )}

        {value && (
          <div
            className={classNames(styles.preview, {
              [styles.error]: error,
            })}
            style={{
              backgroundImage,
              ...previewStyle,
            }}
          />
        )}

        <Button
          className={styles.delete}
          disabled={disabled || isLoading}
          icon="trash-alt"
          size="small"
          title="Delete custom image"
          variant="danger"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </LabeledInput>
  );
};

export default ImageInput;
