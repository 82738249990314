import { Placement } from '@popperjs/core';
import { Point } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, MouseEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import Modal from 'react-modal';
import { useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';
import { useEvent } from 'react-use';

import { useTabbableElementsNavigation } from 'hooks';

import styles from './ContextMenu.module.scss';
import createVirtualReference from './createVirtualReference';

export interface Props {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  placement?: Placement;
  point: Point | undefined;
  onClose: () => void;
}

const RIGHT_BUTTON_CODE = 2;

const modifiers = [
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['bottom-start', 'top-start'],
    },
  },
];

const ContextMenu: FunctionComponent<Props> = ({
  children,
  className,
  isOpen,
  placement = 'bottom-start',
  point,
  onClose,
}) => {
  const virtualReference = useMemo(() => createVirtualReference(point), [point]);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { attributes, styles: popperStyles } = usePopper(virtualReference, popperElement, {
    modifiers,
    placement,
  });

  const handleContextMenu = useCallback((event: MouseEvent) => {
    event.preventDefault();
  }, []);

  const handleRightClick = useCallback(
    (event: Event) => {
      if (
        isOpen &&
        event instanceof globalThis.MouseEvent &&
        event.button === RIGHT_BUTTON_CODE &&
        !popperElement?.contains(event.target as Node | null)
      ) {
        onClose();
      }
    },
    [isOpen, onClose, popperElement],
  );

  useRootClose(popperElement, onClose, { disabled: !isOpen });
  useEvent<HTMLElement>('mousedown', handleRightClick, document.body, { capture: true });

  useTabbableElementsNavigation(popperElement, { disabled: !isOpen });

  return (
    <Modal
      className={styles.modal}
      overlayClassName={styles.overlay}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <div
        className={classNames(styles.contextMenu, className)}
        onContextMenu={handleContextMenu}
        ref={setPopperElement}
        style={popperStyles.popper}
        {...attributes.popper}
      >
        {children}
      </div>
    </Modal>
  );
};

export default ContextMenu;
