import classNames from 'classnames';
import { ChangeEvent, FunctionComponent, InputHTMLAttributes } from 'react';

import Button from '../Button';

import styles from './FileInput.module.scss';

export interface Props extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  className?: string;
  id: string;
  value: FileList | null;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const hasValue = (value: FileList | null): boolean => Boolean(value && value.length > 0);

const getDisplayValue = (value: FileList | null): string => {
  if (!value || !hasValue(value)) {
    return 'No file chosen';
  }

  return Array.from(value)
    .map(({ name }) => name)
    .join(', ');
};

const FileInput: FunctionComponent<Props> = ({
  accept,
  className,
  disabled,
  id,
  value,
  onChange,
  ...props
}) => (
  <div
    className={classNames(styles.fileInput, className, {
      [styles.disabled]: disabled,
      [styles.hasValue]: hasValue(value),
    })}
  >
    <Button
      className={styles.addonAfter}
      disabled={disabled}
      icon="file"
      tabIndex={-1}
      title="Browse..."
    >
      Browse...
    </Button>

    <div className={styles.value}>{getDisplayValue(value)}</div>

    <input
      accept={accept}
      className={styles.input}
      disabled={disabled}
      id={id}
      name={id}
      type="file"
      onChange={onChange}
      {...props}
    />
  </div>
);

export default FileInput;
