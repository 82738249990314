import { Folder } from '@websktop/commons';

interface Params extends Pick<Folder, 'id' | 'parentId' | 'x' | 'y'> {
  name?: string;
  note?: string;
}

const createOptimisticFolder = ({ id, name = '', note = '', parentId, x, y }: Params): Folder => ({
  createdAt: Date.now(),
  id,
  items: [],
  name,
  note,
  parentId,
  updatedAt: Date.now(),
  x,
  y,
});

export default createOptimisticFolder;
