import { BillingCycle, Plan } from '@websktop/commons';
import { ReactNode } from 'react';

import { Currency } from 'types';

type Prices = Record<BillingCycle, Record<Currency, number>>;

type PlanConfig = {
  features: ReactNode[];
  name: string;
  prices: Prices;
  type: Plan;
};

export const FREE_PLAN: PlanConfig = {
  type: Plan.FREE,
  name: 'Free',
  prices: {
    year: {
      eur: 0,
      usd: 0,
    },
    month: {
      eur: 0,
      usd: 0,
    },
  },
  features: [
    <>
      <strong>1</strong> Websktop
    </>,
    <>
      <strong>Unlimited</strong> links &amp; folders
    </>,
    <>
      <strong>1 MB</strong> image size limit
    </>,
    <>
      <strong>Live chat</strong> support
    </>,
  ],
};

export const PROFESSIONAL_PLAN: PlanConfig = {
  type: Plan.PROFESSIONAL,
  name: 'Professional',
  prices: {
    year: {
      eur: 2.5,
      usd: 2.5,
    },
    month: {
      eur: 3,
      usd: 3,
    },
  },
  features: [
    <>
      <strong>Unlimited</strong> Websktops
    </>,
    <>
      <strong>Unlimited</strong> links &amp; folders
    </>,
    <>
      <strong>25 MB</strong> image size limit
    </>,
    <>
      <strong>Priority live chat</strong> support
    </>,
    <>
      <strong>Public</strong> Websktops
    </>,
    <>
      <strong>Shared</strong> Websktops
    </>,
    <>
      <strong>Collaborative</strong> editing
    </>,
  ],
};
