import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import Icon from '../Icon';

import styles from './Features.module.scss';

interface Props {
  className?: string;
  features: ReactNode[];
}

const Features: FunctionComponent<Props> = ({ className, features }) => (
  <div className={classNames(styles.features, className)}>
    {features.map((feature, featureIndex) => (
      <div className={styles.feature} key={featureIndex}>
        <Icon className={styles.check} icon="check" /> <span>{feature}</span>
      </div>
    ))}
  </div>
);

export default Features;
