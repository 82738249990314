// https://html.spec.whatwg.org/multipage/interaction.html#user-activation-processing-model
// https://stackoverflow.com/a/56388462
// https://javascript.plainenglish.io/user-gesture-restricted-web-apis-d794454453f7
const onActivationTriggeringInputEvent = (callback: (event: Event) => void) => {
  const handleEvent = (event: Event) => {
    if (event.isTrusted) {
      callback(event);
    }
  };

  // const handleKeyDown = (event: KeyboardEvent) => {
  //   if (event.isTrusted && event.key !== 'Esc') {
  //     callback(event);
  //   }
  // };

  // const handlePointerDown = (event: PointerEvent) => {
  //   if (event.isTrusted && event.pointerType === 'mouse') {
  //     callback(event);
  //   }
  // };

  // const handlePointerUp = (event: PointerEvent) => {
  //   if (event.isTrusted && event.pointerType !== 'mouse') {
  //     callback(event);
  //   }
  // };

  // document.addEventListener('change', handleEvent);
  document.addEventListener('click', handleEvent);
  // document.addEventListener('contextmenu', handleEvent);
  // document.addEventListener('dblclick', handleEvent);
  document.addEventListener('focus', handleEvent);
  // document.addEventListener('keydown', handleKeyDown);
  // document.addEventListener('mousedown', handleEvent);
  document.addEventListener('mouseup', handleEvent);
  // document.addEventListener('pointerdown', handlePointerDown);
  // document.addEventListener('pointerup', handlePointerUp);
  // document.addEventListener('reset', handleEvent);
  // document.addEventListener('submit', handleEvent);
  document.addEventListener('touchend', handleEvent);

  return () => {
    // document.removeEventListener('change', handleEvent);
    document.removeEventListener('click', handleEvent);
    // document.removeEventListener('contextmenu', handleEvent);
    // document.removeEventListener('dblclick', handleEvent);
    document.removeEventListener('focus', handleEvent);
    // document.removeEventListener('keydown', handleKeyDown);
    // document.removeEventListener('mousedown', handleEvent);
    document.removeEventListener('mouseup', handleEvent);
    // document.removeEventListener('pointerdown', handlePointerDown);
    // document.removeEventListener('pointerup', handlePointerUp);
    // document.removeEventListener('reset', handleEvent);
    // document.removeEventListener('submit', handleEvent);
    document.removeEventListener('touchend', handleEvent);
  };
};

export default onActivationTriggeringInputEvent;
