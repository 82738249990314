import { ClipboardMimeType } from 'constants/mime-type';

import { ItemsState, State } from '../types';

import encodeItemsStateToHtml from './encodeItemsStateToHtml';
import encodeItemsStateToText from './encodeItemsStateToText';

const encodeItemsState = (state: ItemsState): State => {
  return {
    [ClipboardMimeType.HTML]: encodeItemsStateToHtml(state),
    [ClipboardMimeType.TEXT]: encodeItemsStateToText(state),
  };
};

export default encodeItemsState;
