import { ClipboardMimeType } from 'constants/mime-type';

type State = Record<ClipboardMimeType, string>;

let arePermissionsRejected = false;

const readText = (): Promise<string> => {
  return navigator.clipboard.readText().catch(() => '');
};

const readHtml = async (): Promise<string> => {
  // We only need to re-throw an error when we cannot read the data from clipboard.
  // It can happen when window is not focused, or user didn't grant us clipboard read permissions.
  const [clipboardItem] = await navigator.clipboard.read().catch((error) => {
    if (error.name === 'NotAllowedError') {
      arePermissionsRejected = true;
      throw error;
    }

    return [null];
  });

  if (!clipboardItem) {
    return '';
  }

  return clipboardItem
    .getType(ClipboardMimeType.HTML)
    .then((blob) => blob.text())
    .catch(() => '');
};

const systemClipboard = {
  clear: (): Promise<void> => {
    try {
      return navigator.clipboard.writeText('');
    } catch {
      return Promise.resolve();
    }
  },

  read: async (): Promise<State> => {
    if (arePermissionsRejected) {
      throw new Error('NotAllowedError - Permissions rejected');
    }

    const [text, html] = await Promise.all([readText(), readHtml()]);

    return {
      [ClipboardMimeType.HTML]: html,
      [ClipboardMimeType.TEXT]: text,
    };
  },

  write: (state: State): Promise<void> => {
    const text = state[ClipboardMimeType.TEXT];
    const html = state[ClipboardMimeType.HTML];

    try {
      return navigator.clipboard.write([
        new ClipboardItem({
          [ClipboardMimeType.TEXT]: new Blob([text], { type: ClipboardMimeType.TEXT }),
          [ClipboardMimeType.HTML]: new Blob([html], { type: ClipboardMimeType.HTML }),
        }),
      ]);
    } catch {
      return Promise.resolve();
    }
  },
};

export default systemClipboard;
