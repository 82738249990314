import { WebsktopRef } from '../types';

const moveWebsktopRef = (
  websktopsRefs: WebsktopRef[],
  websktopId: WebsktopRef['id'],
  newOrder: WebsktopRef['order'],
): WebsktopRef[] => {
  const sortedWebsktopsRefs = [...websktopsRefs].sort((a, b) => a.order - b.order);
  const websktopRef = websktopsRefs.find((websktopsRef) => websktopsRef.id === websktopId);

  if (!websktopRef) {
    throw new Error('WebsktopRef does not exist');
  }

  sortedWebsktopsRefs.splice(sortedWebsktopsRefs.indexOf(websktopRef), 1);
  sortedWebsktopsRefs.splice(newOrder, 0, websktopRef);

  return sortedWebsktopsRefs.map((websktopRef, index) => ({
    ...websktopRef,
    order: index,
  }));
};

export default moveWebsktopRef;
