import { findItem, Folder, Websktop } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent, HTMLProps, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { atom, useRecoilState } from 'recoil';

import { Icon, useTooltip } from 'components';
import { STATUS_BAR_ICON_SIZE } from 'constants/css';
import { WebsktopIcon } from 'modules/entity-icons';
import routing from 'modules/routing';
import { getFolderBreadcrumbs, useDefaultWebsktopId, useWebsktop } from 'state';

import Breadcrumb from './Breadcrumb';
import styles from './Breadcrumbs.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {
  folderId: Folder['id'];
  websktopId: Websktop['id'];
}

const deepestVisitedFolderIdInBranchAtom = atom<Folder['id'] | null>({
  key: 'breadcrumbs-deepest-visited-folder-id',
  default: null,
});

const Breadcrumbs: FunctionComponent<Props> = ({ className, folderId, websktopId, ...props }) => {
  const defaultWebsktopId = useDefaultWebsktopId();
  const websktop = useWebsktop(websktopId);
  const [deepestVisitedFolderIdInBranch, setDeepestVisitedFolderIdInBranch] = useRecoilState(
    deepestVisitedFolderIdInBranchAtom,
  );
  const breadcrumbs = useMemo(() => {
    if (!deepestVisitedFolderIdInBranch) {
      return [];
    }

    const isFolderInWebsktop = findItem(websktop.folder, deepestVisitedFolderIdInBranch);

    if (!isFolderInWebsktop) {
      return [];
    }

    return getFolderBreadcrumbs(websktop, deepestVisitedFolderIdInBranch);
  }, [deepestVisitedFolderIdInBranch, websktop]);
  const currentBreadcrumbIndex = breadcrumbs.findIndex((breadcrumb) => breadcrumb.id === folderId);
  const canMoveUp = currentBreadcrumbIndex >= 1;
  const folderUpTooltipProps = useTooltip(canMoveUp ? 'Navigate folder up' : 'Cannot navigate up', {
    placement: 'bottom',
  });

  useEffect(() => {
    const currentFolder = findItem(websktop.folder, folderId);
    const isFolderAscendantOfPreviousFolder =
      deepestVisitedFolderIdInBranch && currentFolder
        ? Boolean(findItem(currentFolder as Folder, deepestVisitedFolderIdInBranch))
        : false;

    if (!isFolderAscendantOfPreviousFolder) {
      setDeepestVisitedFolderIdInBranch(folderId);
    }
  }, [
    deepestVisitedFolderIdInBranch,
    folderId,
    setDeepestVisitedFolderIdInBranch,
    websktop.folder,
  ]);

  const getBreadcrumbHref = (breadcrumb: Folder): string => {
    const isRoot = breadcrumb.id === websktop.folder.id;

    if (isRoot && websktop.id === defaultWebsktopId) {
      return routing.index;
    }

    if (isRoot) {
      return routing.websktop(websktop.id);
    }

    return routing.websktop.folder(websktop.id, breadcrumb.id);
  };

  return (
    <div className={classNames(styles.breadcrumbs, className)} {...props}>
      <div className={styles.actions}>
        <Link
          className={classNames(styles.folderUp, {
            [styles.disabled]: !canMoveUp,
          })}
          to={canMoveUp ? getBreadcrumbHref(breadcrumbs[currentBreadcrumbIndex - 1]) : {}}
          type="internal"
          {...folderUpTooltipProps}
        >
          <Icon className={styles.icon} icon="level-up-alt" />
        </Link>
      </div>

      <div className={styles.crumbs}>
        {breadcrumbs.map((breadcrumb, index) => (
          <Breadcrumb
            current={index === currentBreadcrumbIndex}
            folder={breadcrumb}
            href={getBreadcrumbHref(breadcrumb)}
            inactive={index >= currentBreadcrumbIndex}
            key={breadcrumb.id}
            style={{
              zIndex: breadcrumbs.length - index,
            }}
            title={breadcrumb.name}
            websktopId={websktopId}
          >
            {index === 0 && (
              <WebsktopIcon
                backgroundColor={websktop.backgroundColor}
                className={styles.websktopIcon}
                iconEmoji={websktop.primaryIcon.emoji}
                iconUrl={websktop.primaryIcon.upload?.url}
                name={websktop.name}
                size={STATUS_BAR_ICON_SIZE}
                textColor={websktop.textColor}
              />
            )}

            {index > 0 && (
              <Icon
                className={classNames(styles.icon, styles.folderIcon)}
                icon={index === currentBreadcrumbIndex ? 'folder-open' : 'folder'}
              />
            )}

            {breadcrumb.name && <span className={styles.name}>{breadcrumb.name}</span>}

            {!breadcrumb.name && (
              <span className={classNames(styles.name, styles.unnamed)}>
                {index === 0 ? 'Unnamed Websktop' : 'Unnamed folder'}
              </span>
            )}
          </Breadcrumb>
        ))}
      </div>
    </div>
  );
};

export default Breadcrumbs;
