import classNames from 'classnames';
import { FunctionComponent } from 'react';

import Loader from '../Loader';
import Modal, { Props } from '../Modal';

import styles from './LoadingModal.module.scss';

const LoadingModal: FunctionComponent<Omit<Props, 'children' | 'header' | 'isOpen'>> = ({
  className,
  ...props
}) => (
  <Modal className={classNames(className)} header={<h2>Loading</h2>} isOpen {...props}>
    <div className={styles.loaderContainer}>
      <Loader />
    </div>
  </Modal>
);

export default LoadingModal;
