import queryString from 'query-string';

import { normalizeUrl } from 'lib';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const favicon = {
  getUrl(url: string): string {
    try {
      const { origin } = new URL(normalizeUrl(url) || url);
      const query = queryString.stringify({ url: origin });
      return `${API_ORIGIN}/icon?${query}`;
    } catch {
      return url;
    }
  },
};

export default favicon;
