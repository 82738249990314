import { StatusCode } from '@websktop/commons';

import { isHttpError } from '../HttpError';

const isAccountNotVerifiedError = (value: unknown): boolean => {
  return (
    isHttpError(value) &&
    value.status === StatusCode.FORBIDDEN &&
    value.message === 'Account not verified'
  );
};

export default isAccountNotVerifiedError;
