import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './Key.module.scss';

interface Props extends HTMLProps<HTMLElement> {}

const Key: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <kbd className={classNames(styles.key, className)} {...props}>
    {children}
  </kbd>
);

export default Key;
