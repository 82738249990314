import { DEMO_ACCOUNT_TTL, UserType } from '@websktop/commons';
import { useEffect, useState } from 'react';

import { useUser } from '../atom';

const useIsDemoExpired = (): boolean => {
  const user = useUser();
  const [isExpired, setIsExpired] = useState(
    Boolean(
      user && user.type === UserType.DEMO && DEMO_ACCOUNT_TTL - (Date.now() - user.createdAt) < 0,
    ),
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    if (user.type !== UserType.DEMO) {
      setIsExpired(false);
      return;
    }

    const expiresIn = DEMO_ACCOUNT_TTL - (Date.now() - user.createdAt);

    if (expiresIn < 0) {
      return;
    }

    const timerId = setTimeout(setIsExpired, expiresIn, true);

    return () => {
      clearTimeout(timerId);
    };
  }, [user]);

  return isExpired;
};

export default useIsDemoExpired;
