import fuzzysort from 'fuzzysort';
import { FunctionComponent, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  result: Fuzzysort.Result | null;
}

const Highlight: FunctionComponent<Props> = ({ children, result }) => {
  if (!result) {
    return <>{children}</>;
  }

  const highlight = fuzzysort.highlight(result, (children, index) => (
    <mark key={index}>{children}</mark>
  ));

  if (!highlight) {
    return <>{children}</>;
  }

  return <>{highlight}</>;
};

export default Highlight;
