import { ClientMessage, Folder, Point, Websktop } from '@websktop/commons';
import { useCallback } from 'react';

import getActiveWebsktopsSocket from '../getActiveWebsktopsSocket';

const useUpdateCursorPosition = (websktopId: Websktop['id'], folderId: Folder['id']) => {
  const socket = getActiveWebsktopsSocket();

  return useCallback(
    (position: Point) => {
      socket.emit(ClientMessage.UPDATE_CURSOR, websktopId, folderId, position);
    },
    [folderId, socket, websktopId],
  );
};

export default useUpdateCursorPosition;
