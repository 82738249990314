import { Plan } from '@websktop/commons';
import { FormEvent, FunctionComponent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncFn, useLatest } from 'react-use';

import { Button, IconInput, Message, Modal, TextInput } from 'components';
import { NULL_ICON_PAYLOAD } from 'constants/icons';
import { DEFAULT_WEBSKTOP_BACKGROUND_COLOR, DEFAULT_WEBSKTOP_TEXT_COLOR } from 'constants/websktop';
import { useUniqueId, useUploadFile } from 'hooks';
import { getFormFieldsErrors, useFormState } from 'lib';
import { WebsktopIcon } from 'modules/entity-icons';
import { IMAGE_SIZE } from 'modules/grid-layout';
import { useUnmountedErrorHandler } from 'modules/notifications';
import { UpgradeImageSizePromo } from 'modules/plans';
import routing from 'modules/routing';
import { useAuthenticatedUser, useCreateWebsktop } from 'state';
import { EntityIcon, isEmojiIcon, isFileSizeError, isUploadedIcon } from 'types';

import styles from './NewWebsktopModal.module.scss';

interface FormState {
  name: string;
  primaryIcon: EntityIcon;
}

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

const NewWebsktopModal: FunctionComponent<Props> = ({ className, isOpen, onClose }) => {
  const id = useUniqueId();
  const user = useAuthenticatedUser();
  const navigate = useNavigate();
  const handleUnmountedError = useUnmountedErrorHandler();
  const [form, { patchFormState }] = useFormState<FormState>({ name: '', primaryIcon: null });
  const createWebsktop = useCreateWebsktop();
  const [{ error: uploadError, file, uploadPromise }, setFile] = useUploadFile();
  const fileUrl = useMemo(() => (file ? URL.createObjectURL(file) : undefined), [file]);
  const createWebsktopPayload = useMemo(
    () => ({
      backgroundId: null,
      name: form.name,
      primaryIcon: {
        ...NULL_ICON_PAYLOAD,
        emoji: isEmojiIcon(form.primaryIcon) ? form.primaryIcon : '',
        uploadId: isUploadedIcon(form.primaryIcon) ? fileUrl || null : null,
      },
    }),
    [form, fileUrl],
  );
  const onCloseRef = useLatest(onClose);

  const [{ error, loading: isSubmitting }, handleSubmit] = useAsyncFn(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      return handleUnmountedError(async () => {
        const createdWebsktop = await createWebsktop(createWebsktopPayload, {
          fallbackUrl: isUploadedIcon(form.primaryIcon) ? fileUrl : undefined,
          promise: isUploadedIcon(form.primaryIcon) ? uploadPromise : undefined,
        });
        onCloseRef.current();
        navigate(routing.websktop(createdWebsktop.id));
      });
    },
    [
      createWebsktop,
      createWebsktopPayload,
      fileUrl,
      form,
      handleUnmountedError,
      navigate,
      onCloseRef,
      uploadPromise,
    ],
  );

  const formErrors = getFormFieldsErrors<keyof FormState>(error);

  const handlePrimaryIconChange = (primaryIcon: EntityIcon) => {
    if (isUploadedIcon(primaryIcon)) {
      setFile(primaryIcon);
    }

    patchFormState({ primaryIcon });
  };

  return (
    <Modal
      className={className}
      footer={
        <Modal.Footer>
          <Button form={id} loading={isSubmitting} icon="plus" type="submit" variant="primary">
            Create
          </Button>
        </Modal.Footer>
      }
      header={<h2>New Websktop</h2>}
      isOpen={isOpen}
      onClose={onClose}
    >
      <form id={id} onSubmit={handleSubmit}>
        <TextInput
          autoFocus
          disabled={isSubmitting}
          error={formErrors.name?.message}
          id="name"
          label="Name"
          required
          value={form.name}
          onChange={(event) => patchFormState({ name: event.target.value })}
        />

        <IconInput id="icon" label="Icon" onChange={handlePrimaryIconChange}>
          <WebsktopIcon
            backgroundColor={DEFAULT_WEBSKTOP_BACKGROUND_COLOR}
            className={className}
            iconEmoji={isEmojiIcon(form.primaryIcon) ? form.primaryIcon : ''}
            iconUrl={isUploadedIcon(form.primaryIcon) ? fileUrl : undefined}
            name={form.name}
            size={IMAGE_SIZE}
            textColor={DEFAULT_WEBSKTOP_TEXT_COLOR}
          />
        </IconInput>

        {isFileSizeError(uploadError) && user.subscription.plan === Plan.FREE && (
          <UpgradeImageSizePromo />
        )}

        {error && (
          <Message className={styles.error} variant="error">
            {error.message}
          </Message>
        )}
      </form>
    </Modal>
  );
};

export default NewWebsktopModal;
