import styles from './ColorInput.module.scss';

const OFFSET = parseInt(styles.OFFSET, 10);
const PREVENT_OVERFLOW = parseInt(styles.PREVENT_OVERFLOW, 10);

export const MODIFIERS = [
  {
    name: 'preventOverflow',
    options: {
      padding: PREVENT_OVERFLOW,
      rootBoundary: 'document',
    },
  },
  {
    name: 'flip',
    options: {
      padding: OFFSET,
      rootBoundary: 'document',
    },
  },
  {
    name: 'offset',
    options: {
      offset: [0, OFFSET],
    },
  },
];
