import { useCallback } from 'react';

import api from 'api';

import { useSetUser } from '../atom';

type StartTrial = () => Promise<void>;

const useStartTrial = (): StartTrial => {
  const setUser = useSetUser();

  const startTrial: StartTrial = useCallback(async () => {
    const user = await api.user.startTrial();
    setUser(user);
  }, [setUser]);

  return startTrial;
};

export default useStartTrial;
