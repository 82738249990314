import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import styles from './Footer.module.scss';

interface Props extends HTMLProps<HTMLDivElement> {}

const Footer: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <div className={classNames(styles.footer, className)} {...props}>
    {children}
  </div>
);

export default Footer;
