import { Websktop } from '@websktop/commons';
import { useMemo } from 'react';
import { ConnectDropTarget, useDrop } from 'react-dnd';

import { DND_WEBSKTOP_ITEM } from 'constants/websktop';
import {
  useAllItemsLoadable,
  useItemWebsktopMapLoadable,
  useMoveItems,
  usePermissions,
  useSelection,
  useWebsktopLoadable,
} from 'state';

interface Params {
  websktopId: Websktop['id'];
}

type Return = [{ canDrop: boolean; isOver: boolean }, ConnectDropTarget];

const useItemsDrop = ({ websktopId }: Params, isEnabled: boolean): Return => {
  const permissions = usePermissions(websktopId);
  const [selectedItemsIds] = useSelection();
  const websktopLoadable = useWebsktopLoadable(websktopId);
  const allItemsLoadable = useAllItemsLoadable();
  const itemWebsktopMapLoadable = useItemWebsktopMapLoadable();
  const moveItems = useMoveItems(websktopId);

  const items = useMemo(() => {
    if (allItemsLoadable.state !== 'hasValue') {
      return [];
    }

    return allItemsLoadable.contents.filter((item) => selectedItemsIds.includes(item.id));
  }, [allItemsLoadable.contents, allItemsLoadable.state, selectedItemsIds]);

  const sourceWebsktopId =
    itemWebsktopMapLoadable.state === 'hasValue' && items.length > 0
      ? itemWebsktopMapLoadable.contents[items[0].id]
      : null;
  const isItemsDndEnabled = isEnabled && websktopId !== sourceWebsktopId;

  const [{ isOver, canDrop }, ref] = useDrop({
    accept: isItemsDndEnabled ? DND_WEBSKTOP_ITEM : [],
    canDrop: () =>
      permissions.canEditItems &&
      websktopLoadable.state === 'hasValue' &&
      items.length > 0 &&
      sourceWebsktopId !== null,
    drop: () => {
      if (sourceWebsktopId === null || websktopLoadable.state !== 'hasValue') {
        return;
      }

      moveItems({ items, sourceWebsktopId, targetFolder: websktopLoadable.contents.folder });
    },
    collect(monitor) {
      return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
    },
  });

  return [{ isOver, canDrop }, ref];
};

export default useItemsDrop;
