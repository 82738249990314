import {
  CreateWebsktopPayload,
  UserRole,
  Websktop,
  websktopToWebsktopRef,
} from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';
import { NULL_ICON_PAYLOAD } from 'constants/icons';

import { useShowErrorNotification } from '../../notifications';
import { useAddWebsktopRef, useAuthenticatedUser } from '../../user';
import { ImageUpload } from '../types';

type CreateWebsktop = (
  payload: CreateWebsktopPayload,
  iconUpload?: ImageUpload,
) => Promise<Websktop>;

const useCreateWebsktop = (): CreateWebsktop => {
  const user = useAuthenticatedUser();
  const showErrorNotification = useShowErrorNotification();
  const addWebsktopRef = useAddWebsktopRef();

  const createWebsktop: CreateWebsktop = useCallback(
    async (payload, iconUpload) => {
      try {
        const primaryIconUpload = await iconUpload?.promise;
        const primaryIconUploadId = primaryIconUpload?.id ?? null;
        const websktop = await api.websktop.createWebsktop({
          ...payload,
          primaryIcon: {
            ...NULL_ICON_PAYLOAD,
            ...payload.primaryIcon,
            uploadId: primaryIconUploadId,
          },
        });
        const websktopRefOrder = user.websktopsRefs.length;
        addWebsktopRef(
          websktopToWebsktopRef(websktop, {
            isSubscribed: true,
            order: websktopRefOrder,
            userRole: UserRole.ADMIN,
          }),
        );
        return websktop;
      } catch (error) {
        showErrorNotification(error);
        throw error;
      }
    },
    [addWebsktopRef, showErrorNotification, user],
  );

  return createWebsktop;
};

export default useCreateWebsktop;
