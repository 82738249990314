import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Currency } from 'types';

import styles from './Price.module.scss';

interface Props {
  className?: string;
  currency: Currency;
  price: number;
}

const Price: FunctionComponent<Props> = ({ className, currency, price }) => {
  const full = Math.floor(price);
  const cents = Math.round((price - full) * 100);

  return (
    <div className={classNames(styles.price, className)}>
      {currency === 'usd' && <span className={styles.usd}>$</span>}
      <span>{full}</span>
      <sup className={styles.cents}>{String(cents).padStart(2, '0')}</sup>
      {currency === 'eur' && <span className={styles.eur}>€</span>}
    </div>
  );
};

export default Price;
