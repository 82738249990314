import { isUserInputElement } from 'lib';
import { useEffect, useState } from 'react';

const useIsAnyInputFocused = (): boolean => {
  const [isAnyInputFocused, setIsAnyInputFocused] = useState<boolean>(false);

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      setIsAnyInputFocused(isUserInputElement(event.target));
    };

    const handleFocusOut = () => {
      setIsAnyInputFocused(false);
    };

    document.body.addEventListener('focusin', handleFocusIn);
    document.body.addEventListener('focusout', handleFocusOut);

    return () => {
      document.body.removeEventListener('focusin', handleFocusIn);
      document.body.removeEventListener('focusout', handleFocusOut);
    };
  }, []);

  return isAnyInputFocused;
};

export default useIsAnyInputFocused;
