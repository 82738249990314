import { Link } from '@websktop/commons';

import { urlToFile } from 'lib';
import { EntityIcon } from 'types';

const API_ORIGIN = process.env.REACT_APP_API_ORIGIN;

const getLinkIcon = async (link: Link): Promise<EntityIcon> => {
  if (link.primaryIcon.upload?.url) {
    return urlToFile(API_ORIGIN + link.primaryIcon.upload.url);
  }

  if (link.primaryIcon.emoji) {
    return link.primaryIcon.emoji;
  }

  return null;
};

export default getLinkIcon;
