import { Entity } from '@websktop/commons';
import { atom, useRecoilState } from 'recoil';

import { State } from './types';

export const INITIAL_STATE = [];

export const optimisticUpdatesAtom = atom({ key: 'optimistic-updates', default: INITIAL_STATE });

export const useOptimisticUpdatesState = <E extends Entity>() => {
  return useRecoilState<State<E>>(optimisticUpdatesAtom as any);
};
