const addCommand = (command: any[]): void => {
  if (typeof window._paq === 'undefined') {
    return;
  }

  const firstUndefinedIndex = command.findIndex((value) => typeof value === 'undefined');
  const finalCommand = firstUndefinedIndex === -1 ? command : command.slice(0, firstUndefinedIndex);
  window._paq.push(finalCommand);
};

export default addCommand;
