import { UserRole, Websktop, websktopToWebsktopRef } from '@websktop/commons';
import { useCallback } from 'react';

import api from 'api';

import { useShowErrorNotification } from '../../notifications';
import { useAddWebsktopRef, useAuthenticatedUser } from '../../user';

type ForkWebsktop = () => Promise<Websktop>;

const useForkWebsktop = (websktopId: Websktop['id']): ForkWebsktop => {
  const user = useAuthenticatedUser();
  const showErrorNotification = useShowErrorNotification();
  const addWebsktopRef = useAddWebsktopRef();

  const forkWebsktop: ForkWebsktop = useCallback(async () => {
    try {
      const websktop = await api.websktop.fork(websktopId);
      const websktopRefOrder = user.websktopsRefs.length;
      addWebsktopRef(
        websktopToWebsktopRef(websktop, {
          isSubscribed: true,
          order: websktopRefOrder,
          userRole: UserRole.ADMIN,
        }),
      );
      return websktop;
    } catch (error) {
      showErrorNotification(error);
      throw error;
    }
  }, [addWebsktopRef, showErrorNotification, user.websktopsRefs.length, websktopId]);

  return forkWebsktop;
};

export default useForkWebsktop;
