import { Folder, Websktop } from '@websktop/commons';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import routing from 'modules/routing';
import { useFolder, useWebsktop } from 'state';

import { UseWebsktopHotkey } from '../types';

import { useHotkey } from './lib';

type Params = { folderId: Folder['id']; websktopId: Websktop['id'] };

const useFolderUp: UseWebsktopHotkey<Params> = ({ folderId, websktopId }, isEnabled) => {
  const navigate = useNavigate();
  const websktop = useWebsktop(websktopId);
  const rootFolderId = websktop.folder.id;
  const { parentId } = useFolder(websktopId, folderId);

  const handleFolderUp = useCallback(() => {
    navigate(routing.websktop.folder(websktopId, parentId!));
  }, [navigate, parentId, websktopId]);

  useHotkey('folderUp', handleFolderUp, { disabled: !isEnabled || folderId === rootFolderId });
};

export default useFolderUp;
