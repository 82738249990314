import { StatusCode } from '@websktop/commons';

import { isHttpError } from '../HttpError';

const isDemoExpiredError = (error: unknown) =>
  isHttpError(error) &&
  error.status === StatusCode.FORBIDDEN &&
  error.message === 'Demo account expired';

export default isDemoExpiredError;
