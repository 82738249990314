import { Plan } from '@websktop/commons';
import { useEffect, useState } from 'react';

import { useUser } from '../atom';

const CHECK_INTERVAL = 1000;

const useIsTrialExpired = (): boolean => {
  const user = useUser();
  const plan = user?.subscription.plan;
  const trial = user ? user.subscriptions.find(({ isTrial }) => isTrial) : undefined;
  const isTrialExpired = Boolean(trial && trial.expiresAt && trial.expiresAt < Date.now());
  const [isExpired, setIsExpired] = useState(plan === Plan.FREE && isTrialExpired);
  const expiresAt = trial?.expiresAt;

  useEffect(() => {
    setIsExpired(plan === Plan.FREE && isTrialExpired);
  }, [plan, isTrialExpired]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typeof expiresAt !== 'number') {
        return;
      }

      const expiresIn = expiresAt - Date.now();

      if (expiresIn < 0) {
        setIsExpired(true);
      }
    }, CHECK_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, [expiresAt]);

  return isExpired;
};

export default useIsTrialExpired;
