import { useEffect } from 'react';

import { useShowErrorNotification } from 'state';

const useErrorNotification = (error: unknown) => {
  const showErrorNotification = useShowErrorNotification();

  useEffect(() => {
    if (error) {
      showErrorNotification(error);
    }
  }, [error, showErrorNotification]);
};

export default useErrorNotification;
