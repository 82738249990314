import { ArrowKey, Direction } from 'types';

const ARROW_TO_DIRECTION: Record<ArrowKey, 'left' | 'right' | 'up' | 'down'> = {
  ArrowLeft: 'left',
  ArrowRight: 'right',
  ArrowUp: 'up',
  ArrowDown: 'down',
};

const arrowKeyToDirection = (arrowKey: ArrowKey): Direction => {
  return ARROW_TO_DIRECTION[arrowKey];
};

export default arrowKeyToDirection;
