// @ts-ignore
import variables from 'styles/variables.scss';

export const BREAKPOINTS = {
  xs: 480,
  s: 768,
  m: 992,
  l: 1200,
  xl: 1400,
};

export const SIDEBAR_WEBSKTOP_ICON_SIZE = parseInt(variables.SIDEBAR_WEBSKTOP_ICON_SIZE, 10);
export const SIDEBAR_WIDTH = parseInt(variables.SIDEBAR_WIDTH, 10);
export const SIDEBAR_WIDTH_WIDE = parseInt(variables.SIDEBAR_WIDTH_WIDE, 10);
export const STATUS_BAR_HEIGHT = parseInt(variables.STATUS_BAR_HEIGHT, 10);
export const STATUS_BAR_ICON_SIZE = parseInt(variables.STATUS_BAR_ICON_SIZE, 10);
