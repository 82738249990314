import data from '@emoji-mart/data';
import { init } from 'emoji-mart';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { unstable_HistoryRouter as HistoryRouter, HistoryRouterProps } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AppLoader, ErrorBoundary, SplashLoader } from 'components';
import Modals from 'modals';
import { openReplayTracker } from 'modules/analytics';
import { Dnd } from 'modules/dnd';
import Notifications from 'modules/notifications';
import Router, { history } from 'modules/router';

import './index.scss';
import { registerServiceWorker } from './serviceWorkerManager';
import './styles/cookie-consent.scss';
import './styles/variables.scss';

openReplayTracker?.start();
init({ data });

const container = document.getElementById('root');

if (container === null) {
  throw new Error('There is no #root element');
}

const root = createRoot(container);

Modal.setAppElement(container);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <HistoryRouter history={history as unknown as HistoryRouterProps['history']}>
        <RecoilRoot>
          <Dnd>
            <Suspense fallback={<SplashLoader />}>
              <Suspense fallback={<AppLoader />}>
                <Router />
              </Suspense>
            </Suspense>
            <Suspense>
              <Modals />
              <Notifications />
            </Suspense>
          </Dnd>
        </RecoilRoot>
      </HistoryRouter>
    </ErrorBoundary>
  </React.StrictMode>,
);

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_TARGET !== 'extension') {
  registerServiceWorker();
}
