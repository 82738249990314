import brave from 'assets/brave.svg';
import firefox from 'assets/firefox.svg';
import googleChrome from 'assets/google-chrome.svg';
import microsoftEdge from 'assets/microsoft-edge.svg';
import opera from 'assets/opera.svg';
import safari from 'assets/safari.svg';
import vivaldi from 'assets/vivaldi.svg';

export const INSTRUCTIONS = [
  {
    icon: brave,
    name: 'Brave',
    href: 'https://support.brave.com/hc/en-us/articles/360019782291-How-do-I-import-or-export-browsing-data-',
  },
  {
    icon: googleChrome,
    name: 'Google Chrome',
    href: 'https://support.google.com/chrome/answer/96816?hl=en#:~:text=Move%20bookmarks%20to%20another%20browser',
  },
  {
    icon: microsoftEdge,
    name: 'Microsoft Edge',
    href: 'https://answers.microsoft.com/en-us/microsoftedge/forum/all/how-do-i-export-my-favorites-in-the-new-microsoft/6de99f40-5312-4e6b-87c5-cf05be9ccacc',
  },
  {
    icon: firefox,
    name: 'Mozilla Firefox',
    href: 'https://support.mozilla.org/en-US/kb/export-firefox-bookmarks-to-backup-or-transfer',
  },
  {
    icon: opera,
    name: 'Opera',
    href: 'https://help.opera.com/en/latest/features/#:~:text=Import%20or%20export%20bookmarks%20from%20other%20web%20browsers',
  },
  {
    icon: safari,
    name: 'Safari',
    href: 'https://support.apple.com/guide/safari/import-bookmarks-and-passwords-ibrw1015/mac#aria-ibrw52131fd9',
  },
  {
    icon: vivaldi,
    name: 'Vivaldi',
    href: 'https://help.vivaldi.com/desktop/tools/import-and-export-browser-data/#Export_bookmarks',
  },
];
