import { WebsktopRef } from '@websktop/commons';
import classNames from 'classnames';
import isHotkey from 'is-hotkey';
import { DragEventHandler, forwardRef, KeyboardEventHandler, MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';

import { SIDEBAR_WEBSKTOP_ICON_SIZE } from 'constants/css';
import { useIsActive, useTouchContextMenu } from 'hooks';
import { useDeleteWebsktopModal, useWebsktopContextMenu } from 'modals';
import { useAppLayout } from 'modules/app-layout';
import { WebsktopIcon } from 'modules/entity-icons';
import routing from 'modules/routing';
import { useAuthenticatedUser, useDefaultWebsktopId, useOptimisticUpdates } from 'state';

import { styles as linkStyles } from '../Link';

import styles from './WebsktopLink.module.scss';

interface Props {
  className?: string;
  websktopRef: WebsktopRef;
  onDragEnd?: DragEventHandler<HTMLAnchorElement>;
  onDragStart?: DragEventHandler<HTMLAnchorElement>;
}

const WebsktopLink = forwardRef<HTMLAnchorElement, Props>(
  ({ className, websktopRef, onDragEnd, onDragStart }, ref) => {
    const { isSidebarWide } = useAppLayout();
    const { has: isOptimisticId } = useOptimisticUpdates();
    const websktopId = websktopRef.id;
    const user = useAuthenticatedUser();
    const defaultWebsktopId = useDefaultWebsktopId();
    const isDisabled = isOptimisticId(websktopId);
    const isIndexActive = useIsActive(routing.index);
    const isWebsktopActive = useIsActive(routing.websktop(websktopId));
    const isDefaultWebsktop = websktopId === defaultWebsktopId;
    const isActive = isWebsktopActive || (isDefaultWebsktop && isIndexActive);
    const [firstWebsktopRef] = user.websktopsRefs;
    const deleteWebsktopModal = useDeleteWebsktopModal();
    const websktopContextMenu = useWebsktopContextMenu();

    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const point = { x: event.clientX, y: event.clientY };
      websktopContextMenu.open({ point, websktopId });
    };

    const touchContextMenuHandler = useTouchContextMenu((event) => {
      const point = { x: event.touches[0].clientX, y: event.touches[0].clientY };
      websktopContextMenu.open({ point, websktopId });
    });

    const handleKeyDown: KeyboardEventHandler<HTMLAnchorElement> = (event) => {
      if (isHotkey('delete', event) && websktopId !== defaultWebsktopId) {
        deleteWebsktopModal.open({ websktopId });
      }
    };

    return (
      <>
        <NavLink
          className={classNames(linkStyles.link, className, {
            [linkStyles.active]: isActive,
            [styles.active]: isActive,
            [styles.disabled]: isDisabled,
          })}
          draggable={false}
          ref={ref}
          title={websktopRef.name || 'Unnamed Websktop'}
          to={websktopId === firstWebsktopRef.id ? routing.index : routing.websktop(websktopId)}
          onContextMenu={handleContextMenu}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          onKeyDown={handleKeyDown}
          onTouchStart={touchContextMenuHandler}
        >
          <WebsktopIcon
            backgroundColor={websktopRef.backgroundColor}
            className={styles.icon}
            iconEmoji={websktopRef.primaryIcon.emoji}
            iconUrl={websktopRef.primaryIcon.upload?.url}
            name={websktopRef.name}
            size={SIDEBAR_WEBSKTOP_ICON_SIZE}
            textColor={websktopRef.textColor}
          />

          {isSidebarWide && <div className={linkStyles.name}>{websktopRef.name}</div>}
        </NavLink>
      </>
    );
  },
);

export default WebsktopLink;
