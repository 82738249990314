import { getItemsDeep, isFolder, Item } from '@websktop/commons';

import pluralize from './pluralize';

const getItemsCountMessage = (items: Item[]): string => {
  const itemsDeep = Object.values(
    items.reduce((result, item) => ({ ...result, ...getItemsDeep(item) }), {}),
  );
  const foldersCount = itemsDeep.filter(isFolder).length;
  const linksCount = itemsDeep.length - foldersCount;
  const foldersCountMessage =
    foldersCount === 0
      ? undefined
      : pluralize(foldersCount, { one: '1 folder', many: `${foldersCount} folders` });
  const linksCountMessage =
    linksCount === 0
      ? undefined
      : pluralize(linksCount, { one: '1 bookmark', many: `${linksCount} bookmarks` });

  return [foldersCountMessage, linksCountMessage].filter(Boolean).join(' & ');
};

export default getItemsCountMessage;
