import { Item, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  itemsIds: Item['id'][];
  websktopId: Websktop['id'];
}

const deleteItemsModalAtom = createModalAtom<State>('delete-items-modal');

export const useDeleteItemsModal = () => useModal(deleteItemsModalAtom);
