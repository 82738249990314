import { isHtmlElement } from 'types';

const DEFAULT_SAFETY_MARGIN = 8;

const ensureChildInView = (
  parent: HTMLElement | null,
  child: HTMLElement | null | number,
  safetyMargin: number = DEFAULT_SAFETY_MARGIN,
) => {
  if (parent === null) {
    return;
  }

  if (typeof child === 'number') {
    const childIndex = child;
    ensureInView(parent, parent.children[childIndex] as HTMLElement, safetyMargin);
  } else {
    ensureInView(parent, child, safetyMargin);
  }
};

const ensureInView = (parent: HTMLElement, child: HTMLElement | null, safetyMargin: number) => {
  if (!isHtmlElement(parent) || !isHtmlElement(child) || !parent.contains(child)) {
    return;
  }

  const { height: childHeight } = child.getBoundingClientRect();
  const { height: parentHeight } = parent.getBoundingClientRect();
  const isChildAboveVisibleArea = child.offsetTop < parent.scrollTop;
  const isChildBelowVisibleArea = child.offsetTop + childHeight > parent.scrollTop + parentHeight;

  if (isChildAboveVisibleArea) {
    const y = child.offsetTop - safetyMargin;
    parent.scrollTo(parent.scrollLeft, y);
  } else if (isChildBelowVisibleArea) {
    const y = child.offsetTop + childHeight + safetyMargin - parentHeight;
    parent.scrollTo(parent.scrollLeft, y);
  }
};

export default ensureChildInView;
