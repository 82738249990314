import { Point } from '@websktop/commons';
import { TouchEventHandler, useCallback, useRef } from 'react';
import { useEvent, useLatest } from 'react-use';

const useLongTouch = <T>(
  callback: TouchEventHandler<T>,
  delay: number = 500,
): TouchEventHandler<T> => {
  const touchStartPoint = useRef<Point | null>(null);
  const touchContextMenuTimerId = useRef<ReturnType<typeof setTimeout> | null>(null);
  const callbackRef = useLatest(callback);

  const clearTouchTimeout = useCallback(() => {
    if (touchContextMenuTimerId.current) {
      clearTimeout(touchContextMenuTimerId.current);
    }
  }, []);

  const onTouchStart: TouchEventHandler<T> = useCallback(
    (event) => {
      touchStartPoint.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };

      touchContextMenuTimerId.current = setTimeout(() => {
        if (touchStartPoint.current) {
          callbackRef.current(event);
        }
      }, delay);

      return clearTouchTimeout;
    },
    [callbackRef, clearTouchTimeout, delay],
  );

  useEvent('touchend', () => {
    touchStartPoint.current = null;
    clearTouchTimeout();
  });

  useEvent('touchmove', (event) => {
    if (!touchStartPoint.current) {
      return;
    }

    const point = { x: event.touches[0].clientX, y: event.touches[0].clientY };
    const startPoint = touchStartPoint.current;

    if (!startPoint) {
      return;
    }

    // While touching, finger can slide a bit, but until it is in 10 pixel touch area,
    // we want to still consider it as a long touch.
    const movedTooFar =
      Math.abs(point.x - startPoint.x) > 5 || Math.abs(point.y - startPoint.y) > 5;

    if (movedTooFar) {
      touchStartPoint.current = null;
      clearTouchTimeout();
    }
  });

  return onTouchStart;
};

export default useLongTouch;
