import { Folder, Item, Point } from '../types';

import findEmptyPoint from './findEmptyPoint';
import getBoundaries from './getBoundaries';
import sortPoints from './sortPoints';

const arrangeNewItems = (
  targetFolder: Folder,
  items: Item[],
  point: Point = { x: 0, y: 0 },
): Record<Item['id'], Point> => {
  const itemsBoundaries = getBoundaries(items);
  const points = targetFolder.items.map((item) => ({ x: item.x, y: item.y }));
  const pointsMap: Record<Item['id'], Point> = {};
  const sortedItems = sortPoints(items);

  for (const item of sortedItems) {
    const x = Math.max(point.x + item.x - itemsBoundaries.minX, 0);
    const y = Math.max(point.y + item.y - itemsBoundaries.minY, 0);
    const emptyPoint = findEmptyPoint(points, { x, y });
    points.push(emptyPoint);
    pointsMap[item.id] = emptyPoint;
  }

  return pointsMap;
};

export default arrangeNewItems;
