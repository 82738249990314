import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import { FunctionComponent, HTMLProps } from 'react';

import { Button } from 'components';
import { Notification as NotificationType } from 'types';

import Icon from '../Icon';

import styles from './Notification.module.scss';

const iconsMap: Record<NotificationType['type'], IconProp> = {
  error: 'exclamation-circle',
  info: 'info-circle',
  success: 'check-circle',
  warning: 'exclamation-triangle',
};

interface Props
  extends Omit<NotificationType, 'id'>,
    Omit<HTMLProps<HTMLDivElement>, 'content' | 'title' | 'type'> {
  className?: string;
  onClose?: () => void;
}

const Notification: FunctionComponent<Props> = ({
  className,
  content,
  title,
  type,
  onClose,
  ...props
}) => (
  <div
    className={classNames(styles.notification, className, {
      [styles.error]: type === 'error',
      [styles.info]: type === 'info',
      [styles.success]: type === 'success',
      [styles.warning]: type === 'warning',
    })}
    {...props}
  >
    <div className={styles.iconContainer}>
      <Icon className={styles.icon} icon={iconsMap[type]} />
    </div>

    <div className={styles.content}>
      <h3 className={styles.title}>{title}</h3>
      {content && <div>{content}</div>}
    </div>

    <Button autoFocus className={styles.closeButton} icon="times" onClick={onClose} />
  </div>
);

export default Notification;
