import Folder from '../Folder';
import Point from '../Point';
import Websktop from '../Websktop';

export enum ClientMessage {
  JOIN_WEBSKTOP = 'join-websktop',
  LEAVE_WEBSKTOP = 'leave-websktop',
  UPDATE_CURSOR = 'update-cursor',
}

export type JoinWebsktopHandler = (websktopId: Websktop['id']) => void;

export type LeaveWebsktopHandler = (websktopId: Websktop['id']) => void;

export type UpdateCursorHandler = (
  websktopId: Websktop['id'],
  folderId: Folder['id'],
  position: Point,
) => void;

export type ClientToServerEvents = {
  [ClientMessage.LEAVE_WEBSKTOP]: LeaveWebsktopHandler;
  [ClientMessage.JOIN_WEBSKTOP]: JoinWebsktopHandler;
  [ClientMessage.UPDATE_CURSOR]: UpdateCursorHandler;
};
