import { useCallback } from 'react';
import { RecoilState, useRecoilState } from 'recoil';

import { useSetOpenModalsCount } from 'modals';

import { EMPTY_STATE } from './constants';
import { Modal, ModalActions, ModalState } from './types';

const useModal = <Data>(modalAtom: RecoilState<ModalState<Data>>): Modal<Data> => {
  const [state, setState] = useRecoilState<ModalState<Data>>(modalAtom);
  const setOpenModalsCount = useSetOpenModalsCount();

  const close = useCallback<ModalActions<Data>['close']>(() => {
    setState(EMPTY_STATE);
    setOpenModalsCount((count) => count - 1);
  }, [setOpenModalsCount, setState]);

  const open = useCallback<ModalActions<Data>['open']>(
    // @ts-ignore
    (data) => {
      // @ts-ignore
      setState({ data, isOpen: true });
      setOpenModalsCount((count) => count + 1);
    },
    [setOpenModalsCount, setState],
  );

  return { ...state, close, open };
};

export default useModal;
