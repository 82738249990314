import classNames from 'classnames';
import { FunctionComponent, ReactNode } from 'react';

import styles from './Notice.module.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const Notice: FunctionComponent<Props> = ({ children, className }) => (
  <div className={classNames(className, styles.notice)}>{children}</div>
);

export default Notice;
