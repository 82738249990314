import { Folder, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  folderId: Folder['id'];
  point: Point;
  websktopId: Websktop['id'];
}

const folderContextMenuAtom = createModalAtom<State>('folder-context-menu');

export const useFolderContextMenu = () => useModal(folderContextMenuAtom);
