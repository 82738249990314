import { User, WebsktopRef } from '../types';

const updateUserWebsktopRef = (user: User, websktopRef: WebsktopRef): User => ({
  ...user,
  websktopsRefs: user.websktopsRefs.map((userWebsktopRef) => {
    return userWebsktopRef.id === websktopRef.id ? websktopRef : userWebsktopRef;
  }),
});

export default updateUserWebsktopRef;
