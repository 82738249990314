import { Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  point: Point;
  websktopId: Websktop['id'];
}

const websktopContextMenuAtom = createModalAtom<State>('websktop-context-menu');

export const useWebsktopContextMenu = () => useModal(websktopContextMenuAtom);
