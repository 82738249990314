import { useMemo } from 'react';

import { getColumnsCount, getRowsCount, createGrid } from '../lib';
import { BaseTile, GridLayoutOptions } from '../types';

const useGrid = <Tile extends BaseTile>(
  tiles: Tile[],
  options: GridLayoutOptions,
): (Tile | null)[][] => {
  const rowsCount = useMemo(() => getRowsCount(tiles, options), [tiles, options]);
  const columnsCount = useMemo(() => getColumnsCount(tiles, options), [tiles, options]);
  const grid = useMemo(
    () => createGrid(tiles, columnsCount, rowsCount),
    [tiles, columnsCount, rowsCount],
  );

  return grid;
};

export default useGrid;
