import { FunctionComponent, useMemo } from 'react';

import { SearchDropdown } from 'modules/search';
import { useSearch } from 'state';

interface Props {
  className?: string;
}

const MAX_RESULTS_COUNT = 30;

const Search: FunctionComponent<Props> = ({ className }) => {
  const [{ isLoaded, query, results }, setQuery] = useSearch();
  const topResults = useMemo(() => results.slice(0, MAX_RESULTS_COUNT), [results]);

  if (!isLoaded) {
    return null;
  }

  return (
    <SearchDropdown
      className={className}
      query={query}
      results={topResults}
      onQueryChange={setQuery}
    />
  );
};

export default Search;
