import { Folder, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  folderId: NonNullable<Folder['id']>;
  gridPoint: Point | null;
  point: Point;
  websktopId: Websktop['id'];
}

const backgroundContextMenuAtom = createModalAtom<State>('background-context-menu');

export const useBackgroundContextMenu = () => useModal(backgroundContextMenuAtom);
