import { Folder, getFoldersMapDeep, Websktop } from '@websktop/commons';

const getFolderBreadcrumbs = (websktop: Websktop, folderId: Folder['id']) => {
  const folders = getFoldersMapDeep(websktop.folder);
  const breadcrumbs: Folder[] = [];
  let folder: Folder | null = folders[folderId];

  while (folder) {
    breadcrumbs.push(folder);
    folder = folder.parentId ? folders[folder.parentId] : null;
  }

  breadcrumbs.reverse();
  breadcrumbs[0] = { ...breadcrumbs[0], name: websktop.name };

  return breadcrumbs;
};

export default getFolderBreadcrumbs;
