import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Folder, FolderOpen } from 'icons';

import styles from './FolderIcon.module.scss';

interface Props {
  className?: string;
  isOpen?: boolean;
  size: number;
}

const FolderIcon: FunctionComponent<Props> = ({ className, isOpen, size }) => {
  if (isOpen) {
    return (
      <FolderOpen
        className={classNames(styles.folderIcon, className)}
        style={{ height: size, width: size }}
      />
    );
  }

  return (
    <Folder
      className={classNames(styles.folderIcon, className)}
      style={{ height: size, width: size }}
    />
  );
};

export default FolderIcon;
