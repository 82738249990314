import { Point } from '../types';

const createGrid = (points: Point[]): Record<number, Record<number, true>> => {
  return points.reduce((result, point) => {
    if (typeof result[point.y] === 'undefined') {
      result[point.y] = {};
    }

    result[point.y][point.x] = true;

    return result;
  }, {});
};

const findEmptyPoint = (points: Point[], origin: Point = { x: 0, y: 0 }): Point => {
  const grid = createGrid(points);

  for (let i = 0; ; ++i) {
    for (let j = i; j >= 0; --j) {
      const y = origin.y + i - j;
      const x = origin.x + j;
      const isEmpty = !grid[y] || !grid[y][x];

      if (isEmpty) {
        return { x, y };
      }
    }
  }
};

export default findEmptyPoint;
