import classNames from 'classnames';
import { FunctionComponent, HTMLProps, useEffect, useRef } from 'react';
import { useIntersection, useMedia } from 'react-use';

import styles from './Video.module.scss';

type VideFormat = 'mp4' | 'webm';
type Sources = Partial<Record<VideFormat, string>>;

interface Props extends HTMLProps<HTMLVideoElement> {
  sources: {
    desktop: RequireAtLeastOne<Sources>;
    mobile?: RequireAtLeastOne<Sources>;
  };
}

const MOBILE_MEDIA_QUERY = '(max-width: 768px)';

const getFallback = (downloadUrl: string) => (
  <>
    Your browser doesn’t support video. You can{' '}
    <a download href={downloadUrl}>
      download it
    </a>{' '}
    instead.
  </>
);

const Video: FunctionComponent<Props> = ({ autoPlay, className, sources, ...props }) => {
  const isMobile = useMedia(MOBILE_MEDIA_QUERY, false);
  const ref = useRef<HTMLVideoElement>(null);
  const intersection = useIntersection(ref, {});
  const isInTheMiddleOfViewport = intersection?.isIntersecting;
  const urls = isMobile && sources.mobile ? sources.mobile : sources.desktop;

  useEffect(() => {
    const videoElement = ref.current;

    if (!autoPlay || !videoElement) {
      return;
    }

    if (isInTheMiddleOfViewport) {
      videoElement.play();
    } else {
      videoElement.pause();
    }
  }, [autoPlay, isInTheMiddleOfViewport]);

  return (
    <video
      className={classNames(styles.video, className)}
      controls
      preload="none"
      ref={ref}
      {...props}
    >
      {urls.webm && <source src={urls.webm} type="video/webm" />}
      {urls.mp4 && <source src={urls.mp4} type="video/mp4" />}
      {getFallback(urls.mp4 || urls.webm!)}
    </video>
  );
};

export default Video;
