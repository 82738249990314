import { Item } from '@websktop/commons';

import { createBookmarksHtml } from 'modules/bookmarks-manager';

import { downloadHtmlFile, itemsToBookmarksTree } from './lib';

const FILENAME = 'bookmarks.html';

const exportBookmarks = async (items: Item[]) => {
  const bookmarksTree = await itemsToBookmarksTree(items);
  const html = createBookmarksHtml(bookmarksTree);
  downloadHtmlFile(html, FILENAME);
};

export default exportBookmarks;
