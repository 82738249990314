import { isFolder, isLink, Item } from '@websktop/commons';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Highlight, Link, LinkProps } from 'components';
import { isSafeUrl } from 'lib';
import routing from 'modules/routing';
import { useItemWebsktopMap } from 'state';
import { SearchResult } from 'types';

import Icon from './Icon';
import styles from './Result.module.scss';

interface Props extends LinkProps {
  isSelected?: boolean;
  item: Item;
  matches: SearchResult['matches'];
}

const Result: FunctionComponent<Props> = ({ className, isSelected, item, matches, ...props }) => {
  const itemWebsktopMap = useItemWebsktopMap();
  const websktopId = itemWebsktopMap[item.id];
  const href = isFolder(item) ? routing.websktop.folder(websktopId, item.id) : item.url;

  return (
    <Link
      className={classNames(styles.result, {
        [styles.selected]: isSelected,
      })}
      href={isSafeUrl(href) ? href : undefined}
      tabIndex={-1}
      title={href}
      type={isFolder(item) ? 'internal' : 'external'}
      {...props}
    >
      <div className={styles.iconContainer}>
        <Icon className={styles.icon} item={item} />
      </div>

      <div className={styles.info}>
        <div className={styles.name}>
          <Highlight result={matches.name}>{item.name}</Highlight>
        </div>

        {isLink(item) && (
          <div className={styles.url}>
            <Highlight result={matches.url}>{item.url}</Highlight>
          </div>
        )}
      </div>
    </Link>
  );
};

export default Result;
