import { useMatch } from 'react-router-dom';
import uuidBase62 from 'uuid-base62';

import routing from 'modules/routing';
import { useDefaultWebsktopId, useIsAuthenticated } from 'state/user';
import { Websktop } from '@websktop/commons';

const useActiveWebsktopId = (): Websktop['id'] | null => {
  const isAuthenticated = useIsAuthenticated();
  const defaultWebsktopId = useDefaultWebsktopId();

  const indexMatch = useMatch(routing.index);
  const websktopMatch = useMatch(routing.websktop.toString());
  const websktopFolderMatch = useMatch(routing.websktop.folder.toString());

  if (websktopMatch && websktopMatch.params.websktopId) {
    return uuidBase62.decode(websktopMatch.params.websktopId);
  }

  if (websktopFolderMatch && websktopFolderMatch.params.websktopId) {
    return uuidBase62.decode(websktopFolderMatch.params.websktopId);
  }

  if (indexMatch && isAuthenticated) {
    return defaultWebsktopId;
  }

  return null;
};

export default useActiveWebsktopId;
