import isHotkey from 'is-hotkey';
import { useMemo } from 'react';

import hotkeysMap from '../../hotkeysMap';
import { Action } from '../../types';

const useKeyFilter = (action: Action) => {
  return useMemo(() => isHotkey(hotkeysMap[action]), [action]);
};

export default useKeyFilter;
