import { Point } from '../types';

const sortPoints = <T extends Point>(points: T[]): T[] => {
  return [...points].sort((point1, point2) => {
    if (point1.y < point2.y) {
      return -1;
    }

    if (point1.y > point2.y) {
      return 1;
    }

    return point1.x - point2.x;
  });
};

export default sortPoints;
