import classNames from 'classnames';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { useRootClose } from 'react-overlays';
import { usePopper } from 'react-popper';

import { useTabbableElementsNavigation } from 'hooks';

import Button, { Props as ButtonProps } from '../Button';

import { MODIFIERS } from './constants';
import styles from './DropdownButton.module.scss';

interface Props extends Omit<ButtonProps, 'label' | 'onClick'> {
  className?: string;
  isOpen: boolean;
  label: ReactNode;
  onClick: ButtonProps['onClick'];
  onClose: () => void;
}

const DropdownButton: FunctionComponent<Props> = ({
  children,
  className,
  isOpen,
  label,
  size,
  variant,
  onClose,
  ...buttonProps
}) => {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const {
    attributes,
    styles: popperStyles,
    update,
  } = usePopper(referenceElement, popperElement, {
    modifiers: MODIFIERS,
    placement: 'bottom-start',
  });

  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [isOpen, update]);

  const handleRootClose = (event: any) => {
    if (
      popperElement &&
      !popperElement.contains(event.target) &&
      referenceElement !== event.target
    ) {
      onClose();
    }
  };

  useRootClose(popperElement, handleRootClose, { disabled: !isOpen });
  useTabbableElementsNavigation(popperElement, { disabled: !isOpen });

  return (
    <>
      <Button
        {...buttonProps}
        className={classNames(styles.button, className, {
          [styles.danger]: variant === 'danger',
          [styles.default]: variant === 'default',
          [styles.large]: size === 'large',
          [styles.open]: isOpen,
          [styles.primary]: variant === 'primary',
          [styles.small]: size === 'small',
        })}
        size={size}
        variant={variant}
        ref={setReferenceElement}
      >
        {label}
        <svg className={styles.triangle} viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg">
          <polygon points="1000,175 0,175 500,875" fill="currentColor" />
        </svg>
      </Button>

      <Modal
        className={styles.modal}
        overlayClassName={styles.overlay}
        isOpen={isOpen}
        onRequestClose={onClose}
      >
        <div
          className={classNames(styles.dropdown, {
            [styles.closed]: !isOpen,
          })}
          ref={setPopperElement}
          style={popperStyles.popper}
          {...attributes.popper}
        >
          {children}
        </div>
      </Modal>
    </>
  );
};

export default DropdownButton;
