import { Dispatch, SetStateAction, useCallback, useState } from 'react';

interface Actions<FormState> {
  patchFormState: (patch: Partial<FormState>) => void;
  setFormState: Dispatch<SetStateAction<FormState>>;
}

const useFormState = <FormState>(initalFormState: FormState): [FormState, Actions<FormState>] => {
  const [formState, setFormState] = useState<FormState>(initalFormState);

  const patchFormState = useCallback((patch: Partial<FormState>) => {
    setFormState((current) => ({ ...current, ...patch }));
  }, []);

  return [formState, { patchFormState, setFormState }];
};

export default useFormState;
