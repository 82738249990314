import { isObject } from './lib';

import ApiError from './ApiError';

export default interface ApiFormErrorPayload<Fields extends string> {
  fields: Partial<Record<Fields, ApiError>>;
}

export const isApiFormErrorPayload = <Fields extends string>(
  value: unknown,
): value is ApiFormErrorPayload<Fields> => {
  return isObject(value) && isObject(value.fields);
};
