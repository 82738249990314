import { isUserSubscribedToWebsktop, Websktop } from '@websktop/commons';
import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Panel } from 'components';
import { patchQueryParams } from 'lib';
import routing from 'modules/routing';
import { useIsAuthenticated, useUser } from 'state';

import styles from './NoAccessError.module.scss';
import UnsubscribeButton from './UnsubscribeButton';

interface Props {
  websktopId: Websktop['id'];
}

const NoAccessError: FunctionComponent<Props> = ({ websktopId }) => {
  const location = useLocation();
  const isAuthenticated = useIsAuthenticated();
  const user = useUser();
  const isSubscribedToWebsktop = isUserSubscribedToWebsktop(user, websktopId);

  if (!isAuthenticated) {
    return <Navigate to={patchQueryParams(routing.auth.signIn, { redirect: location.pathname })} />;
  }

  return (
    <Panel className={styles.error}>
      <h1>Websktop unavailable</h1>

      {isSubscribedToWebsktop && (
        <>
          <p>You no longer have access to this Websktop.</p>
          <UnsubscribeButton className={styles.removeButton} websktopId={websktopId} />
        </>
      )}

      {!isSubscribedToWebsktop && (
        <p>The Websktop does not exist or you don't have access to it.</p>
      )}
    </Panel>
  );
};

export default NoAccessError;
