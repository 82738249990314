import { BackgroundImage } from '@websktop/commons';
import { CSSProperties } from 'react';
import { atom, useRecoilState } from 'recoil';

const personalizePreviewBackgroundColorAtom = atom<CSSProperties['backgroundColor']>({
  key: 'personalize-preview-background-color',
  default: undefined,
});

const personalizePreviewBackgroundImageAtom = atom<BackgroundImage | null | undefined>({
  key: 'personalize-preview-background-image',
  default: undefined,
});

const personalizePreviewTextColorAtom = atom<CSSProperties['color']>({
  key: 'personalize-preview-text-color',
  default: undefined,
});

const personalizePreviewTextShadowColorAtom = atom<CSSProperties['color']>({
  key: 'personalize-preview-text-shadow-color',
  default: undefined,
});

export const usePersonalizePreviewBackgroundColor = () =>
  useRecoilState(personalizePreviewBackgroundColorAtom);

export const usePersonalizePreviewBackgroundImage = () =>
  useRecoilState(personalizePreviewBackgroundImageAtom);

export const usePersonalizePreviewTextColor = () => useRecoilState(personalizePreviewTextColorAtom);
export const usePersonalizePreviewTextShadowColor = () =>
  useRecoilState(personalizePreviewTextShadowColorAtom);

export const useBackgroundPreview = () => {
  const [background] = usePersonalizePreviewBackgroundImage();
  const [backgroundColor] = usePersonalizePreviewBackgroundColor();
  const [textColor] = usePersonalizePreviewTextColor();
  const [textShadowColor] = usePersonalizePreviewTextShadowColor();

  if (
    typeof background === 'undefined' ||
    typeof backgroundColor === 'undefined' ||
    typeof textColor === 'undefined' ||
    typeof textShadowColor === 'undefined'
  ) {
    return undefined;
  }

  return { background, backgroundColor, textColor, textShadowColor };
};
