import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Cue, Icon } from 'components';
import { useIsTouchDevice } from 'hooks';

import styles from './ContextMenuCue.module.scss';

interface Props {
  className?: string;
}

const ContextMenuCue: FunctionComponent<Props> = ({ className }) => {
  const isTouchDevice = useIsTouchDevice();

  return (
    <Cue className={classNames(className, styles.cue)}>
      <div className={styles.cueContent}>
        <p>
          {isTouchDevice ? 'Press and hold your finger' : 'Right-click'} anywhere in this area to
          open up a context menu.
        </p>

        <p>
          You can also open up the context menu with the{' '}
          <span className={styles.menuButton}>
            <Icon icon="ellipsis-h" />
          </span>{' '}
          button in the lower right corner of the screen.
        </p>
      </div>
    </Cue>
  );
};

export default ContextMenuCue;
