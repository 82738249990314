import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { useUpgradeModal } from 'modals';
import { canStartTrial } from 'modules/auth';
import { useAuthenticatedUser } from 'state';

export const TOUR_COMPLETED_STORAGE_KEY = 'tour-completed';

const isTourRunningAtom = atom<boolean>({
  key: TOUR_COMPLETED_STORAGE_KEY,
  default: false,
});

export const useIsTourRunning = () => useRecoilValue(isTourRunningAtom);

export const useCompleteTour = () => {
  const user = useAuthenticatedUser();
  const setIsTourRunning = useSetRecoilState(isTourRunningAtom);
  const { open: openUpgradeModal } = useUpgradeModal();

  const completeTour = useCallback(() => {
    setIsTourRunning(false);

    if (canStartTrial(user)) {
      openUpgradeModal({
        message: (
          <div>
            <h2>Would you like to try our Professional Plan?</h2>
            <p>30 days for free. No credit card required.</p>
          </div>
        ),
        source: 'tour completed',
      });
    }
  }, [setIsTourRunning, openUpgradeModal, user]);

  return completeTour;
};

export const useRunTour = () => {
  const setIsTourRunning = useSetRecoilState(isTourRunningAtom);

  const runTour = useCallback(() => {
    setIsTourRunning(true);
  }, [setIsTourRunning]);

  return runTour;
};
