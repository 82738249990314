import { Folder, Point, Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  parentId: NonNullable<Folder['parentId']>;
  point: Point;
  websktopId: Websktop['id'];
}

const importBookmarksModalAtom = createModalAtom<State>('import-bookmarks-modal');

export const useImportBookmarksModal = () => useModal(importBookmarksModalAtom);
