import Tracker from '@openreplay/tracker';

const openReplayTracker =
  process.env.REACT_APP_TARGET === 'extension'
    ? undefined
    : new Tracker({
        projectKey: 'l6ZLIqbdaEKMmvcl2vK6',
        ingestPoint: 'https://openreplay.limesquid.com/ingest',
      });

export default openReplayTracker;
