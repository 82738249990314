import { Websktop } from '@websktop/commons';

import { createModalAtom, useModal } from 'modules/modal';

interface State {
  websktopId: Websktop['id'];
}

const editWebsktopModalAtom = createModalAtom<State>('edit-websktop-modal');

export const useEditWebsktopModal = () => useModal(editWebsktopModalAtom);
