import { type default as Entity, isEntity } from './Entity';
import type Item from './Item';
import type Timestamp from './Timestamp';

export default interface Folder extends Entity {
  createdAt: Timestamp;
  items: Item[];
  name: string;
  note: string;
  parentId: Folder['id'] | null;
  updatedAt: Timestamp;
  x: number;
  y: number;
}

export const isFolder = (value: unknown): value is Folder => {
  return (
    isEntity(value) &&
    Array.isArray(value.items) &&
    typeof value.name === 'string' &&
    typeof value.note === 'string' &&
    (typeof value.parentId === 'string' || value.parentId === null) &&
    typeof value.x === 'number' &&
    typeof value.y === 'number'
  );
};
