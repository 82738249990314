const pluralize = (
  count: number,
  { one, many, zero = many }: { many: string; one: string; zero?: string },
) => {
  if (count === 1) {
    return one;
  }

  if (count === 0) {
    return zero;
  }

  return many;
};

export default pluralize;
