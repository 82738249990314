import addCommand from './addCommand';

/**
 * @see https://developers.piwik.pro/en/latest/data_collection/web/javascript_tracking_client/api.html?highlight=trackEvent#custom-events
 */
const trackEvent = (
  category: string,
  action: string,
  name?: string,
  value?: number,
  dimensions?: Record<string, string>,
) => {
  addCommand(['trackEvent', category, action, name, value, dimensions]);
};

export default trackEvent;
