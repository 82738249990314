import { Item, Websktop } from '../types';

import addItemsToFolder from './addItemsToFolder';

const addItemsToWebsktop = (websktop: Websktop, items: Item[]): Websktop => ({
  ...websktop,
  folder: addItemsToFolder(websktop.folder, items),
});

export default addItemsToWebsktop;
