import { Websktop } from '@websktop/commons';
import { useEffect } from 'react';

import { useRefetchWebsktop, useWebsktopLoadable } from 'state';

// We only need to refetch when route changed, otherwise there is an infinite loop
const useRefetchErroredWebsktop = (websktopId: Websktop['id']) => {
  const { state } = useWebsktopLoadable(websktopId);
  const refetchWebsktop = useRefetchWebsktop(websktopId);

  useEffect(() => {
    if (state === 'hasError') {
      refetchWebsktop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [websktopId]);
};

export default useRefetchErroredWebsktop;
