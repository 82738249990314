import classNames from 'classnames';
import { FunctionComponent } from 'react';

import styles from './EmojiIcon.module.scss';

interface Props {
  className?: string;
  emoji: string;
  size: number;
}

/**
 * Emoji's actual width & height are larger than the font-size we set on them.
 * This empirically-chosen factor is meant to account for this.
 * It's been tested on Windows, Linux & Android.
 */
const SIZE_FACTOR = 0.775;

const EmojiIcon: FunctionComponent<Props> = ({ className, emoji, size }) => (
  <span className={classNames(styles.emojiIcon, className)} style={{ height: size, width: size }}>
    <em-emoji id={emoji} size={Math.round(size * SIZE_FACTOR)} />
  </span>
);

export default EmojiIcon;
